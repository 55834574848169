import * as Yup from 'yup';
export default function setFormikObjectRequired(textFieldProps,textFieldValuerequired) {   

  const obj = {}
  for(let i =0;i<textFieldProps.length;i+=1){
    if(textFieldValuerequired[i] !== '')
    obj[textFieldProps[i]] = Yup.string().required(textFieldValuerequired[i]);
    else
    obj[textFieldProps[i]] = Yup.string();
  }

  return obj
}
