import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

import BDataGrid from '../BDataGrid'
import * as ipconfig from "../../config/ipconfig";
import * as config from "../../config/config";

export default function TrafficAnalyzeOfConversionRates(props) {
    // const [dayDate, setdayDate] = React.useState('2022/5/19');
    const [dayDateStart, setdayDateStart] = React.useState('');
    const [dayDateEnd, setdayDateEnd] = React.useState('');
    const [data, setdata] = React.useState('');
    const { dayDate, setdayDate } = props

    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);
    const { loading } = progressStore;
    const [first, setfirst] = React.useState(true);
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;

    useEffect(() => {
        if (first && dayDate !== '') {
            const utc01 = new Date(`${moment(dayDate).format('YYYY/MM')}/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
            const dd = moment(dayDate, "YYYY-MM").daysInMonth()
            const utc02 = new Date(`${moment(dayDate).format('YYYY/MM')}/${dd}  23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
            setdayDateStart(utc01)
            setdayDateEnd(utc02)
            // dispatch({ type: "START" });
            dispatch({ type: "START" });
            getData(utc01, utc02)
            setfirst(false)
        }
    }, [dayDate]);


    const searchOnClick = () => {
        const utc01 = new Date(`${moment(dayDate).format('YYYY/MM')}/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
        const dd = moment(dayDate, "YYYY-MM").daysInMonth()
        const utc02 = new Date(`${moment(dayDate).format('YYYY/MM')}/${dd}  23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
        // console.log(utc01)
        // console.log(utc02)
        setdayDateStart(utc01)
        setdayDateEnd(utc02)
        if (utc01 !== dayDateStart) {
            dispatch({ type: "START" });
            getData(utc01, utc02)
        }
    }

    // 取德標格資料
    const getData = async (d1, d2) => {
        const date1 = d1
        const date2 = d2
        // const difference = 8
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/travel/getSpeedLimitEvent`, { "token": users.token, "date1": date1, "date2": date2 })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                if (response.data.resultCode === "Success") {
                // console.log(response.data)
                    const list = []
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;
                        obj.rownum = i + 1;
                        obj.id = i + 1;
                        list.push(obj)
                    }
                    setdata(list)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                dispatch({ type: "END" });
                // always executed
            });
        // setOpen(true);
    };
    // count, speed, speedLimit, licensePlateNumber, specialMark

    const columns = [
        { field: "id", headerName: "id", width: 70, hideable: true },
        { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
        { field: "count", headerName: "超速次數", flex: 0.35, sortable: true, headerAlign: 'center', align: 'center', },
        { field: "licensePlateNumber", headerName: "車牌", width: 120, flex: 0.2, headerAlign: 'center', align: 'center', },
        { field: "specialMark", headerName: "特殊註記", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center', },
    ];

    return (
        <Grid>

            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" mb={5} >
                <Stack direction="row" spacing={2}
                    sx={{
                        '& label ': {
                            fontSize: config.selectLabelFontSize,
                            top: config.selectLabelTop
                        },
                        '& input': {
                            fontSize: config.selectItemFontSize,
                        },
                    }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}  >
                        <DatePicker
                            label="選擇日期"
                            views={['month', 'year']}
                            // views={['year']}
                            value={dayDate}
                            onChange={(newValue) => {
                                setdayDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Stack>
                <Button variant="contained" size="large" item xs={2}
                    disabled={loading}
                    onClick={searchOnClick} >查詢</Button>
                {loading && (
                    <CircularProgress
                        size={80}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            "z-index": 50,
                        }}
                    />
                )}
            </Stack>

            <Grid container spacing={3} >
                <Grid item xs={12} style={{ height: "100%" }}
                    sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontSize: config.tableTitleFontSize
                        },
                        '& .MuiDataGrid-cellContent': {
                            fontSize: config.tableContentFontSize
                        }
                    }}>
                    <BDataGrid data={data} columns={columns}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        export="true"
                    />
                </Grid>


            </Grid>

        </Grid>

    );
}
