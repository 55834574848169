
import * as types from "../config/types";

const initialState = {
  loading: false,
  drivers: [],
  error: null
};

export default function driverManage (state = initialState, action)  {
  switch (action.type) {
    case types.GET_DRIVER_LIST_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case types.GET_DRIVER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        drivers: action.payload.drivers.data
      };
    }
    case types.GET_DRIVER_LIST_FAILED: {
      return {
        ...state,
        loading: false,
        error: "FAILED",    
      };
    }
    default:
      return state;
  }
};

