import { call, put, takeEvery } from "redux-saga/effects";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../config/types";
import * as ipconfig from "../config/ipconfig";

const apiUrl = `https://${ipconfig.apiIP}/api/v1/auth/changePassword`;

function getApi(parse) {
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    
    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
};

function* fetchUserChangePassword(action) {
  try {
    // console.log('2');
    const usersChangePassword = yield call(getApi, action.payload.parse);
    yield put({type: "CHANGE_TOKEN_STATUS", payload: usersChangePassword.resultCode});    
    
    if(usersChangePassword.resultCode === 'Failed')
      yield put(getUserChangePasswordError('FAILED',usersChangePassword.resultText));
    else{
      yield put(getUsersChangePasswordSuccess(usersChangePassword));     
    }
  } catch (error) {
    yield put(getUserChangePasswordError(error.message));
  }
}

export const getUsersChangePasswordSuccess = (usersChangePassword) => {
  // console.log("request users success");
  return {
    type: types.GET_USERS_CHANGE_PASSWORD_SUCCESS,
    payload: {
      loading: false,
      usersChangePassword: usersChangePassword
    }
  };
};

export const getUserChangePasswordError = (error,errorText) => {
  // console.log("request users error");
  return {
    type: types.GET_USERS_CHANGE_PASSWORD_FAILED,
    error: error,
    errorText:errorText
  };
};

function* userChangePasswordSaga() {
  yield takeEvery(types.GET_USERS_CHANGE_PASSWORD_REQUEST, fetchUserChangePassword);
}

export default userChangePasswordSaga;
