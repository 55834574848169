import { useIdleTimer } from 'react-idle-timer'
import faker from 'faker';
import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import { set, sub, formatDistanceToNow } from 'date-fns';
import axios from 'axios';
import moment from 'moment';
import zhTW from 'date-fns/locale/zh-TW'
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton
} from '@mui/material';
// utils
import { mockImgAvatar } from '../../utils/mockImages';
// components
import Notice from '../../components/Notice';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import * as ipconfig from "../../config/ipconfig";

// ----------------------------------------------------------------------
const NOTIFICATIONS = [
  {
    id: faker.datatype.uuid(),
    title: 'Your order is placed',
    description: 'waiting for shipping',
    avatar: null,
    type: 'order_placed',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true
  },
  {
    id: faker.datatype.uuid(),
    title: faker.name.findName(),
    description: 'answered to your comment on the Minimal',
    avatar: mockImgAvatar(2),
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new message',
    description: '5 unread messages',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new mail',
    description: 'sent from Guido Padberg',
    avatar: null,
    type: 'mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false
  },
  {
    id: faker.datatype.uuid(),
    title: 'Delivery processing',
    description: 'Your order is being shipped',
    avatar: null,
    type: 'order_shipped',
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false
  }
];

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <br />
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        {/* {noCase(notification.description)} */}
        {notification.description}
      </Typography>
      <br />
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        {/* {noCase(notification.description)} */}
        {notification.description2}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
      title
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,
    title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      to="#"
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            {/* <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} /> */}
            {/* {formatDistanceToNow(new Date(notification.createdAt), { locale: zhTW })} */}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const dispatch = useDispatch();
  const notificationsData = useSelector((state) => state.notificationsReaderStstus);
  const usersStore = useSelector((state) => state.userLogin);
  const { loading, users, error, nackbarState } = usersStore;

  // let intervalId = null;
  useEffect(() => {
    // Vehicle_management();
    // 定時讀取車機清單
    // eslint-disable-next-line no-const-assign
    // intervalId = setInterval(() => {
    //   updateNotifications();
    // }, 30000);
    // return function cleanup() {
    //   clearInterval(intervalId);
    // };
  }, [notificationsData]);

  /* eslint-disable */
  const updateNotifications = () => {

    const notificationsDataList = [];

    // console.log(notificationsData)
    for (let i = 0; i < notificationsData.notificationsData.length; i += 1) {
      const tempData = notificationsData.notificationsData[i].data;
      const type = tempData.type;

      const obj = {}
      obj.id = faker.datatype.uuid();
      obj.createdAt = notificationsData.notificationsData[i].date;
      obj.isUnRead = notificationsData.notificationsData[i].isUnRead;

      if (type === 'readerStatus') {
        if (tempData.description.includes('斷線')) {
          const title = `${tempData.title}`;
          const description1 = tempData.description;
          obj.title = title;
          obj.description = description1;
          notificationsDataList.push(obj)
        }
      }
    }
    // for(let i = 0 ; i<notifications.length;i+=1)
    // {
    //   notificationsDataList.push(notifications[i]);
    // }
    setNotifications(notificationsDataList);
    // console.log(notificationsDataList)
  }

  useEffect(() => {
    // alert 訊息

    updateNotifications();
    // console.log(notifications)
  }, [notificationsData])


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  // 取得路段資料
  const setRead = async () => {
    await axios
      .post(`https://${ipconfig.apiIP}/api/v1/auth/NotificationIsRead`, { "token": users.token })
      .then(async (response) => {
        dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
        if (response.data.resultCode === "Success") {
          // console.log(response.data.resultCode)
          const currentdate = new Date();
          const utc0 = new Date(currentdate).toISOString().slice(0, 19).replace('T', ' ')
          localStorage.setItem('readTime1', utc0);
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      })
      .then(() => {
        // always executed
        // // console.dir('leonReaderlist api then')
      });
    // setOpen(true);
  };
  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={alertTriangleFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">通知</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              您有 {totalUnRead > 99 ? '99+' : totalUnRead} 設備斷線
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 'auto', }, maxHeight: { xs: 360 } }} >
          <List style={{ background: '#ffffff' }}
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }} >
                {/* New */}
              </ListSubheader>
            }
          >
            {notifications.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          {/* <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button> */}
        </Box>
      </MenuPopover>
      {/* <Notice /> */}
    </>
  );
}
