
import * as types from "../config/types";

const initialState = {
  loading: false,
  cms: [],
  error: null
};
export default function cmsManage (state = initialState, action)  {
  switch (action.type) {
    case types.GET_CMS_LIST_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case types.GET_CMS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        cms: action.payload.cms.rows
      };
    }
    case types.GET_CMS_LIST_FAILED: {
      return {
        ...state,
        loading: false,
        error: "FAILED",    
      };
    }
    default:
      return state;
  }
};

