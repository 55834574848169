import { call, put, takeEvery } from "redux-saga/effects";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../config/types";
import * as ipconfig from "../config/ipconfig";

const apiUrl = `https://${ipconfig.apiIP}/api/v1/cms/list`;

function getApi(parse) {
  console.log(apiUrl);
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
};

function* fetchCmsList(action) {
  try {
    // console.log('cms');
    const cms = yield call(getApi, action.payload.parse);
    yield put({ type: "CHANGE_TOKEN_STATUS", payload: cms.resultCode });

    if (cms.resultCode === 'Failed')
      yield put(getCmsError());
    else
      yield put(getCmsSuccess(cms));
  } catch (error) {
    yield put(getCmsError(error.message));
  }
}

export const getCmsSuccess = (cms) => {
  // console.log("request cms success");
  return {
    type: types.GET_CMS_LIST_SUCCESS,
    payload: {
      loading: false,
      cms: cms
    }
  };
};

export const getCmsError = () => {
  // console.log("request cms error");
  return {
    type: types.GET_CMS_LIST_FAILED,
    error: "FAILED"
  };
};

function* cmsSaga() {
  yield takeEvery(types.GET_CMS_LIST_REQUEST, fetchCmsList);
}

export default cmsSaga;
