import { useState } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, FormControlLabel, Switch } from '@mui/material';

// components
import Page from '../components/Page';
import { LoginForm } from '../components/authentication/login';
import SystemInfoStore from '../Store/useSystemInfoStore'; // 修正導入


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const platform = SystemInfoStore((state) => state.platform);
  const togglePlatform = SystemInfoStore((state) => state.togglePlatform);
  return (
    <RootStyle title={`Login | ${platform}`}
      style={{

        backgroundImage: `url(${'/static/login.png'})`,
        'background-size': 'cover',
        width: '100%',
        height: '100%',
      }}
    >
      <Container maxWidth="lg">
        <ContentStyle>
          <Stack sx={{ mb: 3 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <Typography variant="h4" gutterBottom sx={{ color: 'text.secondary' }}>
                {platform}
              </Typography>
            </Stack>
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
