/* eslint-disable prefer-destructuring */
import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import { Typography, Box, IconButton, CircularProgress } from '@mui/material';
import { PlayArrow, Pause, Refresh } from '@mui/icons-material';
import * as tf from '@tensorflow/tfjs';
import * as cocossd from '@tensorflow-models/coco-ssd';

// 節流函數
const throttle = (func, limit) => {
    let inThrottle;
    return function (...args) {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;
            setTimeout(() => inThrottle = false, limit);
        }
    }
}
const classNameMapping = {
    person: '人',
    bicycle: '自行車',
    car: '汽車',
    motorcycle: '機車',
    bus: '公車',
    truck: '卡車',
    dog: '狗',
    cat: '貓'
    // 可以依需求增加更多對照
};

const LiveTrafficImage = memo(({ cctvInfo }) => {
    const imgRef = useRef(null);
    const canvasRef = useRef(null);
    const animationFrameRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [model, setModel] = useState(null);
    const [isDetecting, setIsDetecting] = useState(false);
    const [detectionInterval, setDetectionInterval] = useState(null);

    // 新增一個 useEffect 來處理攝影機切換
    useEffect(() => {
        // 如果正在播放中且有新的攝影機資訊，就停止目前的串流
        if (isPlaying) {
            stopStream();
        }
    }, [cctvInfo?.dns_url]); // 只監聽 dns_url 的變化

    // 優化模型載入
    useEffect(() => {
        let isMounted = true;

        const loadModel = async () => {
            try {
                setLoading(true);
                // 使用 WebGL 後端加速
                await tf.setBackend('webgl');
                const loadedModel = await cocossd.load({
                    base: 'lite_mobilenet_v2',
                });
                if (isMounted) {
                    setModel(loadedModel);
                    setError(null);
                }
            } catch (err) {
                console.error('Failed to load model:', err);
                if (isMounted) {
                    setError('模型載入失敗');
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        loadModel();
        return () => {
            isMounted = false;
        };
    }, []);

    // 優化物件偵測
    const detectObjects = useCallback(async () => {
        if (!model || !imgRef.current || !canvasRef.current || isDetecting) {
            return;
        }

        try {
            setIsDetecting(true);
            const img = imgRef.current;

            if (!img.complete || img.width === 0 || img.height === 0) {
                return;
            }

            const predictions = await model.detect(img);

            // 不管有沒有偵測到物件都呼叫 drawPredictions
            requestAnimationFrame(() => drawPredictions(predictions));

        } catch (err) {
            console.error('物件偵測錯誤:', err);
            setError(`物件偵測失敗: ${err.message}`);
        } finally {
            setIsDetecting(false);
        }
    }, [model]);

    // 優化繪製預測框
    const drawPredictions = useCallback((predictions) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = imgRef.current;

        if (!canvas || !ctx || !img) return;

        // 設定 canvas 大小
        if (canvas.width !== img.width || canvas.height !== img.height) {
            canvas.width = img.width;
            canvas.height = img.height;
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (predictions && predictions.length > 0) {
            predictions.forEach(prediction => {
                if (prediction.score > 0.5) {
                    const [x, y, width, height] = prediction.bbox;

                    // 繪製框線
                    ctx.strokeStyle = '#00FF00';
                    ctx.lineWidth = 2;
                    ctx.strokeRect(x, y, width, height);

                    // 準備文字
                    const translatedClass = translateClass(prediction.class);
                    const label = `${translatedClass} ${Math.round(prediction.score * 100)}%`;
                    ctx.font = '16px Arial';
                    const textWidth = ctx.measureText(label).width + 10;

                    // 計算標籤位置，確保不超出邊界
                    let labelX = x + width; // 預設在右側
                    let labelY = y + height / 2;

                    // 檢查是否會超出右邊界
                    if (labelX + textWidth > canvas.width) {
                        labelX = x - textWidth; // 如果會超出右邊界，改放在左側
                    }

                    // 如果左側也放不下，就放在框內
                    if (labelX < 0) {
                        labelX = x + 2;
                    }

                    // 檢查垂直位置
                    if (labelY < 20) {
                        labelY = 20;
                    } else if (labelY > canvas.height - 10) {
                        labelY = canvas.height - 10;
                    }

                    // 繪製半透明背景
                    ctx.fillStyle = 'rgba(0, 255, 0, 0.3)';
                    ctx.fillRect(labelX, labelY - 10, textWidth, 20);

                    // 繪製文字
                    ctx.fillStyle = '#000000';
                    ctx.fillText(label, labelX + 5, labelY + 5);
                }
            });
        }
    }, []);

    // 優化圖片載入監聽
    useEffect(() => {
        const img = imgRef.current;
        if (!img) return;

        const throttledDetectObjects = throttle(detectObjects, 1000);

        img.crossOrigin = "anonymous";

        const handleImageError = () => {
            setLoading(false);
        };

        const handleImageLoad = () => {
            setLoading(false);
            setError(null);
            throttledDetectObjects();
        };

        img.addEventListener('error', handleImageError);
        img.addEventListener('load', handleImageLoad);

        return () => {
            img.removeEventListener('error', handleImageError);
            img.removeEventListener('load', handleImageLoad);
        };
    }, [detectObjects]);

    // 優化串流開始
    const startStream = useCallback(() => {
        if (!imgRef.current || !cctvInfo?.dns_url) return;

        setLoading(true);
        setError(null);
        setIsPlaying(true);
        setMessage('');

        imgRef.current.crossOrigin = "anonymous";
        const streamUrl = `${cctvInfo.dns_url}?_t=${new Date().getTime()}`;
        imgRef.current.src = streamUrl;

        const interval = setInterval(() => {
            if (!isDetecting) {
                detectObjects();
            }
        }, 500);
        // 設定 60 秒後自動停止
        setTimeout(() => {
            stopStream();
            setMessage('串流已自動停止');
        }, 60000);  // 60000 毫秒 = 60 秒

        setDetectionInterval(interval);
    }, [cctvInfo, detectObjects, isDetecting]);

    // 優化串流停止
    const stopStream = useCallback(() => {
        setIsPlaying(false);

        if (imgRef.current) {
            imgRef.current.src = '';
        }

        if (detectionInterval) {
            clearInterval(detectionInterval);
            setDetectionInterval(null);
        }

        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        }

        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
        }

        setMessage('串流已停止');
    }, [detectionInterval]);

    // 清理機制
    useEffect(() => {
        return () => {
            if (detectionInterval) {
                clearInterval(detectionInterval);
            }
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [detectionInterval]);

    const translateClass = (className) => {
        return classNameMapping[className.toLowerCase()] || className;
    };
    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ p: 1 }}>
                {cctvInfo?.name || '請選擇攝影機'}
            </Typography>
            <Box sx={{
                flexGrow: 1,
                backgroundColor: '#e0e0e0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
            }}>
                {loading && <CircularProgress sx={{ position: 'absolute' }} />}
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <img
                        ref={imgRef}
                        alt="MJPEG Stream"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            display: isPlaying ? 'block' : 'none',
                        }}
                    />
                    <canvas
                        ref={canvasRef}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            pointerEvents: 'none',
                        }}
                    />
                </div>
                {error && <Typography color="error">{error}</Typography>}
                {message && (
                    <Typography sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center'
                    }}>
                        {message}
                    </Typography>
                )}
                {!cctvInfo && (
                    <Typography sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center'
                    }}>
                        請在地圖上選擇一個攝影機
                    </Typography>
                )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                <IconButton
                    color="primary"
                    onClick={isPlaying ? stopStream : startStream}
                    disabled={!cctvInfo || loading}
                >
                    {isPlaying ? <Pause /> : <PlayArrow />}
                </IconButton>
                <IconButton
                    color="primary"
                    onClick={startStream}
                    disabled={!cctvInfo || !isPlaying || loading}
                >
                    <Refresh />
                </IconButton>
            </Box>
        </Box>
    );
});

LiveTrafficImage.displayName = 'LiveTrafficImage';

export default LiveTrafficImage;