import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
// material
import {
  Button,
  Grid,
  Tabs,
  Tab,
  Typography,
  Box,
  Container,
  Snackbar, List
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Page from '../components/Page';
import BMap from '../components/BMap';
import { SimpleDialog } from '../components/dialog';

import { fetchCmsList } from "../actions";
import TTable from '../components/TTable'

import { CmsForm } from '../components/editData/index';
import Tooltip from '../css/Tooltip.module.css'
import * as config from "../config/config";


const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));


// 定義切換頁
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};





function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    'style': { 'font-size': '24px' },
  };
}

export default function EnhancedTable() {
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '項目'
    },
    {
      id: 'cmsName',
      numeric: true,
      disablePadding: true,
      fillter: true,
      label: '資訊可變標誌名稱',
      type: 'render',
      fn: (row) => {
        const { cmsName, latitude, longitude } = row
        const color = '#00AB55';
        const words = (cmsName || '').split('(');
        if (Array.isArray(words)) {
          if (words.length > 1)
            words[1] = `(${words[1]}`
        }
        if (words === undefined)
          return (null);
        if (typeof words === 'string')
          return (words);
        const children = words.map((val) => (
          <div >{val}</div>
        ));
        /* eslint-disable */
        return (
          <div variant="" style={{ color: color, cursor: "pointer" }} onClick={
            () => {
              Object.keys(openmap._layers).forEach(key => {
                // console.log(key)
                // console.log(openmap._layers[key] )
                if (openmap._layers[key] !== undefined)
                  if (row.id === openmap._layers[key].options.id) {
                    openmap._layers[key].openPopup()
                  }
              })
              // openmap.fitBounds(bounds);
              openmap.flyTo([latitude, longitude], 20)
            }
          }>
            {children}
          </div>
        )
      }
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '連線狀態',
      type: 'format',
      fn: (enable) => {
        const enableStr = enable ? '連線' : '斷線';
        const color = enable ? 'green' : 'red';
        return (
          <div style={{ color: color }}>
            {enableStr}
          </div>
        )
      }
    },
    {
      id: 'enable',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '狀態',
      type: 'format',
      fn: (enable) => {
        const enableStr = enable ? '啟用' : '停用';
        return (
          enableStr
        )
      }
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '設備編號'
    },
    {
      id: 'content',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '警示提醒內容',
      type: 'format',
      fn: (cmsContent) => {
        return (
          <div style={{
            fontSize: config.tableContentFontSize, 'background-color': '#FFC10729', 'padding': '5px 5px 5px 5px', 'border-radius': 10
          }}>
            <a style={{ fontSize: config.tableContentFontSize, color: '#B78103' }} >
              {cmsContent}
            </a>
          </div>

        )
      }
    },
    {
      id: 'address',
      numeric: true,
      disablePadding: true,
      fillter: true,
      label: '設置位置',
      type: 'render',
      fn: (row) => {
        const { address } = row
        const words = (address || '').split('(');
        if (Array.isArray(words)) {
          if (words.length > 1)
            words[1] = `(${words[1]}`
        }
        if (words === undefined)
          return (null);
        if (typeof words === 'string')
          return (words);
        const children = words.map((val) => (
          <div >{val}</div>
        ));
        /* eslint-disable */
        return (
          <div>
            {children}
          </div>
        )
      }
    },
    {
      id: 'leonReaderName',
      numeric: true,
      disablePadding: true,
      fillter: true,
      label: '智慧感知讀取器名稱',
      type: 'render',
      fn: (row) => {
        // 組合 cctv_code 和 leonReaderName
        const combinedName = `${row.cctv_code || ''}_${row.address || ''}`;

        return (
          <div style={{
            'backgroundColor': '#1890ff29',
            'borderRadius': 10,
            'padding': '5px 5px 5px 5px'
          }}>
            <a style={{
              fontSize: config.tableContentFontSize,
              color: '#0C53B7'
            }}>
              {combinedName}
            </a>
          </div>
        );
      }
    },
    {
      id: 'updateTime',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '更新時間',
      type: 'format',
      fn: (data) => {
        const time = new Date(data).toLocaleString()
        return (
          time
        )
      }
    },
    {
      type: 'render',
      fn: (row) => {
        const { name, cmsName, cmsProductNumber, cmsObject, cmsContent, address, latitude, longitude, description, updateTime, createTime, enable } = row
        return (
          <Button variant="outlined" onClick={
            () => {
              let tempDescription = description;
              if (description === null)
                tempDescription = '';
              setInfo(row.id, row.cms_limit_id, row.cms_object_id, name, cmsName, cmsProductNumber,
                cmsObject, cmsContent, address, latitude, longitude, tempDescription, updateTime, createTime, enable);
              // // console.log(leonTagID)
              // setValue(1)
            }
          }>
            編輯
          </Button>
        )
      }
    }
  ];

  // 車機清單
  const [Vehiclelist, setVehiclelist] = React.useState([]);
  const [reason, setreason] = React.useState(' ');
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const cmsStore = useSelector((state) => state.cmsManage);
  const { users } = usersStore;
  const { cms, error } = cmsStore;
  useEffect(() => {

    if (cms.length === 0) {
      Vehicle_management();
      setVehiclelist([]);
      setVehiclelist(cms);
    }
    else {
      setVehiclelist([]);
      setVehiclelist(cms);
      console.log(cms);
    }
  }, [cms, error, users]);

  const [mapcenter, setmapcenter] = useState([config.mapCenter.lat, config.mapCenter.lng]);
  const [openmap, setopenmap] = useState(null);

  useEffect(() => {
    if (openmap != null) {
      const bounds = config.mapBounds;
      for (let i = 0; i < cms.length; i += 1) {
        if (cms[i].latitude !== null || cms[i].longitude !== null) {
          if (i === 0) {
            bounds[0][0] = cms[i].latitude
            bounds[1][0] = cms[i].latitude
            bounds[0][1] = cms[i].longitude
            bounds[1][1] = cms[i].longitude
          } else if (cms[i].latitude !== 0) {
            if (cms[i].latitude > bounds[0][0])
              bounds[0][0] = cms[i].latitude
            else if (cms[i].latitude < bounds[1][0])
              bounds[1][0] = cms[i].latitude
            if (cms[i].longitude > bounds[0][1])
              bounds[0][1] = cms[i].longitude
            else if (cms[i].longitude < bounds[1][1])
              bounds[1][1] = cms[i].longitude
          }
        }
      }
      bounds[0][0] += 0.001
      bounds[0][1] += 0.001
      bounds[1][0] -= 0.001
      bounds[1][1] -= 0.001
      openmap.fitBounds(bounds)
    }

  }, [cms, openmap]);

  // 圖標顯示內容
  const popupcontent = (row, textColor) => {
    const r = row
    // console.log(r)
    return (
      <div>
        <div className={Tooltip.text_root}>

          <div className='pb-4'>
            <span className={Tooltip.text_name}>{row.address}</span>
          </div>
          <div className='pb-4'>
            <span className={Tooltip.text_address}>智慧感知讀取器名稱 : {row.leonReaderName}</span>
          </div>
          <div className='pb-4'>
            <span className={Tooltip.text_address}>設備編號 : {row.name}</span>
          </div>
        </div>
      </div>
    )
  }
  // 讀取車機清單
  // eslint-disable-next-line camelcase
  const Vehicle_management = async () => {
    dispatch(fetchCmsList({ "userName": users.userName, "token": users.token }));
  };

  // 關閉彈跳釋出
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



  // tab切換
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [type, setType] = useState();


  const [cmsID, setcmsID] = useState(0);
  const [cmsLimitID, setcmsLimitID] = useState();
  const [cmsObjectID, setcmsObjectID] = useState();
  const [name, setname] = useState();
  const [cmsName, setcmsName] = useState();
  const [cmsProductNumber, setcmsProductNumber] = useState();
  const [cmsObject, etcmsObject] = useState();
  const [cmsContent, setcmsContent] = useState();
  const [address, setaddress] = useState();
  const [latitude, setlatitude] = useState();
  const [longitude, setlongitude] = useState();
  const [description, setdescription] = useState();
  const [updateTime, setupdateTime] = useState();
  const [createTime, setcreateTime] = useState();
  const [enable, setenable] = useState();

  const setInfo = (cmsID, cmsLimitID, cmsObjectID, name, cmsName, cmsProductNumber, cmsObject, cmsContent, address, latitude, longitude, description, updateTime, createTime, enable) => {
    setcmsID(cmsID);
    setcmsLimitID(cmsLimitID);
    setcmsObjectID(cmsObjectID);
    setname(name);
    setcmsName(cmsName);
    setcmsProductNumber(cmsProductNumber);
    etcmsObject(cmsObject);
    setcmsContent(cmsContent);
    setaddress(address);
    setlatitude(latitude);
    setlongitude(longitude);
    setdescription(description);
    setupdateTime(updateTime);
    setcreateTime(createTime);
    setenable(enable);
    setType('update');
    setValue(1);
  };

  const handleDialogClose = (value) => {
    setDialogOpen(false);
    setType(value);
    if (value !== '')
      setValue(1);
  };


  const insetForm = () => {
    setcmsID('');
    setcmsLimitID('');
    setcmsObjectID('');
    setname('');
    setcmsName('');
    setcmsProductNumber('');
    setcmsProductNumber('');
    etcmsObject('');
    setcmsContent('');
    setaddress('');
    setlatitude('');
    setlongitude('');
    setdescription('');
    setupdateTime('');
    setcreateTime('');
    setenable(1);
    setType('insert');
    setValue(1);
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  return (
    <Page title="智慧管理識別平台">
      <Container maxWidth="xl">
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {reason}
          </Alert>
        </Snackbar>

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="資訊可變標誌資訊" {...a11yProps(0)} />
          <Tab label="詳細資訊" {...a11yProps(1)} />
          <Button variant="contained" size="large" style={{ marginLeft: "auto", marginRight: "24px" }}
            onClick={insetForm}
          >新增</Button>

        </Tabs>

        <TabPanel value={value} index={0}>


          <Grid item xs={12} style={{ position: 'relative' }} mb={3}>
            <BMap mapcenter={mapcenter} setopenmap={setopenmap} openmap={openmap}
              MarkerArray={Vehiclelist}
              popupcontent={popupcontent}
            />

          </Grid>

          <Grid item xs={12}
            sx={{
              '& th': {
                fontSize: config.tableTitleFontSize
              },
              '& td': {
                fontSize: config.tableContentFontSize
              }
            }}>
            <TTable Vehiclelist={Vehiclelist} headCells={headCells} />
          </Grid>




        </TabPanel>

        <TabPanel value={value} index={1}>

          <CmsForm
            cmsID={cmsID}
            enable={enable}
            cmsLimitID={cmsLimitID}
            cmsObjectID={cmsObjectID}
            name={name}
            cmsName={cmsName}
            cmsProductNumber={cmsProductNumber}
            cmsObject={cmsObject}
            cmsContent={cmsContent}
            address={address}
            latitude={latitude}
            longitude={longitude}
            description={description}
            updateTime={updateTime}
            createTime={createTime}
            type={type}
            setValue={setValue}
          />

        </TabPanel>
        <SimpleDialog
          open={dialogOpen}
          onClose={handleDialogClose}
        />
      </Container>
    </Page>
  );
}
