import { call, put, takeEvery } from "redux-saga/effects";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../config/types";
import * as ipconfig from "../config/ipconfig";

const apiUrl = `https://${ipconfig.apiIP}/api/v1/auth/userOperationRecord`;

function getApi(parse) {
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    
    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
};

function* fetchUserOperationRecord(action) {
  try {
    // console.log('2');
    const userOperationRecord = yield call(getApi, action.payload.parse);
    yield put({type: "CHANGE_TOKEN_STATUS", payload: userOperationRecord.resultCode});   
    if(userOperationRecord.resultCode === 'Failed')
      yield put(getUserOperationRecordError('FAILED'));
      // yield call(getUserOperationRecordError,'FAILED');
    else{
      yield put(getUserOperationRecordSuccess(userOperationRecord));   
      // yield call(getUserOperationRecordSuccess,userOperationRecord);  
    }
  } catch (error) {
    yield put(getUserOperationRecordError(error.message));
  }
}

export const getUserOperationRecordSuccess = (userOperationRecord) => {
  // console.log("request getUserOperationRecord success");
  return {
    type: types.GET_USERS_OPERATION_RECORD_SUCCESS,
    payload: {
      loading: false,
      userOperationRecord: userOperationRecord
    }
  };
};

export const getUserOperationRecordError = (error) => {
  // console.log("request users error");
  return {
    type: types.GET_USERS_OPERATION_RECORD_FAILED,
    error: error
  };
};

function* userOperationRecordSaga() {
  yield takeEvery(types.GET_USERS_OPERATION_RECORD_REQUEST, fetchUserOperationRecord);
}

export default userOperationRecordSaga;
