import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AAppConversionRates from '../AAppConversionRates';


export default function TrafficAnalyzeOfConversionRates(props) {
    const { sessionlist, sessionselect, tagNam, chartTitle } = props
    const { sessionTurningTrafficList } = props
    const { startDate, endDate } = props
    const { data } = props
    const { vehicleselect, vehicleList } = props
    const { chartTimeUnitlist, chartTimeUnitSelect, chartUnit, time } = props
    const { unitAmountValue } = props

    const dispatch = useDispatch();
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;

    useEffect(() => {
        if (sessionTurningTrafficList.length > 0) {
            setTableData();
            dispatch({ type: "END" });
        }
    }, [data, sessionTurningTrafficList, vehicleselect, unitAmountValue]);


    // const [chartTitle, setchartTitle] = useState("");
    const [chartSubtitle, setchartSubtitle] = useState("");

    const [chartOptions, setchartOptions] = useState({});
    const [chartData, setchartData] = useState([]);

    const setTableData = async () => {
        // setreaderGroupInfoNameMap(rGroupInfoNameMap);
        // setsessionDataOfTrafficFlow(list);      
        // const list = sessionDataOfTrafficFlow;

        // // console.log(data)
        // const dataListTemp = [];
        // for (let i = 0; i < data.length; i += 1){
        //     if(sessionlist[select].description === data[i].reader_group_info_name){
        //         dataListTemp.push(data[i].Hours);
        //     }
        // }
        // const dataList = [...new Set(dataListTemp)]; 
        // 5 , 15 , 30 , 60
        const chartTimeUnitValue = chartTimeUnitlist[chartTimeUnitSelect - 1].value
        const dataList = []
        const loopLength = 60 / chartTimeUnitValue;
        const hourStart = time === 'morning' ? 7 : 17;
        const hourEnd = time === 'morning' ? 9 : 19;

        for (let i = hourStart; i < hourEnd; i += 1) {
            for (let j = 0; j < loopLength; j += 1) {
                dataList.push(`${i}:${j * chartTimeUnitValue}`);
            }
        }
        // if(time === 'morning'){
        //     dataList.push('7:0');
        //     dataList.push('7:15');
        //     dataList.push('7:30');
        //     dataList.push('7:45');
        //     dataList.push('8:0');
        //     dataList.push('8:15');
        //     dataList.push('8:30');
        //     dataList.push('8:45');
        // }
        // else{
        //     dataList.push('17:0');
        //     dataList.push('17:15');
        //     dataList.push('17:30');
        //     dataList.push('17:45');
        //     dataList.push('18:0');
        //     dataList.push('18:15');
        //     dataList.push('18:30');
        //     dataList.push('18:45');
        // }
        // // console.log(dataList);

        setChartData(dataList);
        // // console.dir(list)
        // // console.dir(sessionSelect)
        // const list2 = [];
        // for (let i = 0; i < list.length; i += 1){        
        //     for (let j = 0; j < sessionSelect.length; j += 1){
        //         if(list[i].sessionID === sessionSelect[j])
        //         list2.push(list[i])
        //     }
        // }
        // settabledata(list);
        // // console.log(list2)
        // if(list2.length > 0){
        //     setchartTitle(list2[0].readerGroupInfoName);
        //     setChartData(list2);
        // }

    }


    // 統計顯示相關
    const setChartData = (dataList) => {
        // A : Leon
        // B : notLeon
        // C : total

        // // console.log(vehicleList)
        // // console.log(vehicleList[vehicleselect-1])

        // {"id":1,"description":"本計畫之大型車輛","type":"isLeon"},
        // {"id":2,"description":"非本計畫之大型車輛","type":"isTruckNotLeon"},
        // {"id":3,"description":"全部車輛","type":"total"},
        // let tagName;
        // if(vehicleList[vehicleselect-1].type === 'isLeon'){
        //     tagName = 'isLeonTag'

        // }
        // if(vehicleList[vehicleselect-1].type === 'isTruckNotLeon'){
        //     tagName = 'isTruckNotLeon'

        // }
        // if(vehicleList[vehicleselect-1].type === 'total'){
        //     tagName = 'total'

        // }


        // A : 左
        // B : 直
        // C : 右
        // D : 其他車輛(不包含大車) 
        const dataA = [];
        const dataB = [];
        const dataC = [];
        const dataD = [];
        const dataAA = [];
        const dataBB = [];
        const dataCC = [];
        const dataDD = [];

        // 先建立arraySize避免沒資料，會導致錯位
        for (let i = 0; i < dataList.length; i += 1) {
            dataA.push(0);
            dataB.push(0);
            dataC.push(0);
            dataD.push(0);
            dataAA[i] = [];
            dataBB[i] = [];
            dataCC[i] = [];
            dataDD[i] = [];
        }
        // // console.log(data)
        for (let i = 0; i < dataList.length; i += 1) {
            // // console.log(`i : ${i}`)    
            for (let j = 0; j < data.length; j += 1) {
                if (sessionTurningTrafficList[sessionselect].description === data[j].reader_group_info_name && dataList[i] === data[j][chartUnit]) {
                    const isetagData = !Number.isNaN(data[j].iseTag)
                    const etagData = isetagData ? parseInt(data[j].iseTag, 10) : 9999
                    if (vehicleList[vehicleselect - 1].type === 'isLeon') {
                        if (data[j].is_leonTag !== 0) {
                            if (data[j].direction === '左轉') {
                                dataAA[i].push(data[j].count * unitAmountValue)
                            }
                            else if (data[j].direction === '直行') {
                                dataBB[i].push(data[j].count * unitAmountValue)
                            }
                            else if (data[j].direction === '右轉') {
                                dataCC[i].push(data[j].count * unitAmountValue)
                            }
                        }
                    }
                    else if (vehicleList[vehicleselect - 1].type === 'etagCar') {
                        if (isetagData && etagData === 3) {
                            if (data[j].direction === '左轉') {
                                dataAA[i].push(data[j].count)
                            }
                            else if (data[j].direction === '直行') {
                                dataBB[i].push(data[j].count)
                            }
                            else if (data[j].direction === '右轉') {
                                dataCC[i].push(data[j].count)
                            }
                        }
                    }
                    else if (vehicleList[vehicleselect - 1].type === 'etagTruck') {
                        if (isetagData && etagData >= 4 && etagData <= 5) {
                            if (data[j].direction === '左轉') {
                                dataAA[i].push(data[j].count * unitAmountValue)
                            }
                            else if (data[j].direction === '直行') {
                                dataBB[i].push(data[j].count * unitAmountValue)
                            }
                            else if (data[j].direction === '右轉') {
                                dataCC[i].push(data[j].count * unitAmountValue)
                            }
                        }
                    }
                    else if (vehicleList[vehicleselect - 1].type === 'total') {
                        const isleonFlag = data[j].is_leonTag !== 0;
                        const isetagCar = isetagData && etagData === 3;
                        const isetagTruck = isetagData && etagData >= 4 && etagData <= 5;
                        if (isetagCar) {
                            if (data[j].direction === '左轉') {
                                dataAA[i].push(data[j].count)
                            }
                            else if (data[j].direction === '直行') {
                                dataBB[i].push(data[j].count)
                            }
                            else if (data[j].direction === '右轉') {
                                dataCC[i].push(data[j].count)
                            }
                        }
                        else if (isleonFlag || isetagTruck) {
                            if (data[j].direction === '左轉') {
                                dataAA[i].push(data[j].count * unitAmountValue)
                            }
                            else if (data[j].direction === '直行') {
                                dataBB[i].push(data[j].count * unitAmountValue)
                            }
                            else if (data[j].direction === '右轉') {
                                dataCC[i].push(data[j].count * unitAmountValue)
                            }
                        }
                    }
                    // if(data[j].isLeonTag !== 0)
                    //     dataAA[i].push(data[j].count)                    
                    // else if(data[j].isLeonTag === 0 && data[j].isTruckNotLeon)                    
                    //     dataBB[i].push(data[j].count)            
                    // else if(data[j].isLeonTag === 0 && data[j].isTruckNotLeon === 0)
                    //     dataDD[i].push(data[j].count)
                }
            }
        }
        // // console.log(dataAA)
        // // console.log(dataBB)
        for (let i = 0; i < dataList.length; i += 1) {
            for (let j = 0; j < dataAA[i].length; j += 1) {
                dataA[i] += dataAA[i][j];
            }
            for (let j = 0; j < dataBB[i].length; j += 1) {
                dataB[i] += dataBB[i][j];
            }
            for (let j = 0; j < dataCC[i].length; j += 1) {
                dataC[i] += dataCC[i][j];
            }
            // dataA[i] = dataAA[i].length > 0 ? parseInt(dataA[i]/dataAA[i].length,10):0;
            // dataB[i] = dataBB[i].length > 0 ? parseInt(dataB[i]/dataBB[i].length,10):0;
            // dataC[i] = dataCC[i].length > 0 ? parseInt(dataC[i]/dataCC[i].length,10):0;
        }

        const data1 = [
            {
                name: '左轉',
                data: dataA,
                color: '#00AB55'
            },
            {
                name: '直行',
                data: dataB,
                color: '#FF9700'
            },
            // {
            //   name: '非本計畫之車輛',
            //   data: dataC,
            //   color: '#2D99FF'
            // },
            {
                name: '右轉',
                data: dataC,
                color: '#2D99FF'
            },
        ];
        setchartData(data1);
        setChartOption(dataList);
    }

    // 統計顯示相關
    const setChartOption = (chartLabels) => {
        // const currentdate = new Date(); 
        // const datetime = `${currentdate.getMonth()+1}/${currentdate.getDate()}`
        // const subTitle = `統計日期 ${currentdate.getFullYear()}/${currentdate.getMonth()+1}/${currentdate.getDate()}`
        // setchartSubtitle(subTitle);
        const chartLabels1 = []
        const chartTimeUnitValue = chartTimeUnitlist[chartTimeUnitSelect - 1].value
        // const dataList = []
        const loopLength = 60 / chartTimeUnitValue;
        const hourStart = time === 'morning' ? 7 : 17;
        const hourEnd = time === 'morning' ? 9 : 19;

        for (let i = hourStart; i < hourEnd; i += 1) {
            for (let j = 0; j < loopLength; j += 1) {
                if (j * chartTimeUnitValue < 10)
                    chartLabels1.push(`${i}:0${j * chartTimeUnitValue}`);
                else
                    chartLabels1.push(`${i}:${j * chartTimeUnitValue}`);
            }
        }

        // // console.log(chartLabels)
        const chartOption = {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                zoom: {
                    enabled: true
                },
                toolbar: {
                    show: true,
                    offsetY: -30,
                    export: {
                        csv: {
                            filename: undefined,
                        },
                        png: {
                            filename: undefined,
                        }
                    }
                },
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%',
                    barHeight: '100%',
                    horizontal: false,
                }
            },
            stroke: {
                show: true,
            },
            labels: chartLabels1,
            xaxis: {
                type: 'category',
            },
            yaxis: {
                title: {
                    text: ["數量　　", "　"],
                    rotate: 0,
                    style: {
                        fontSize: '16px',
                        fontWeight: 600,
                    }
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                x: {
                    show: true,
                    formatter: function (x, opts) {
                        if (typeof x !== 'undefined') {
                            return opts.w.globals.labels[opts.dataPointIndex]
                        }
                        return x;
                    }
                },
                y: {
                    formatter: (y, opts) => {
                        if (typeof y !== 'undefined') {
                            return `${y.toFixed(0)}`;
                        }
                        return y;
                    }
                }
            }
        };
        setchartOptions(chartOption);
    }
    // const columns = [
    //     { field: "id", headerName: "id", width: 70, hideable: true },
    //     { field: "readerGroupInfoName", headerName: "路段名稱(起)", flex: 0.6, width: 330, sortable: true, headerAlign: 'center',align: 'center',},
    //     // { field: "total", headerName:  "總共車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center', },
    //     // { field: "isLeon", headerName:  "本計畫車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center',},
    //     // { field: "notLeon", headerName:  "非本計畫車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center',},
    // ];

    // if(tagNam === 'total')
    //     columns.push({ field: "total", headerName:  "總車流數",  flex: 0.4,width: 200, sortable: true, headerAlign: 'center', align: 'center', })
    // else
    //     columns.push({ field: "isLeon", headerName:  "本計畫車輛之車流數",  flex: 0.4,width: 200, sortable: true, headerAlign: 'center', align: 'center', })

    return (

        <div>
            <AAppConversionRates title={`${sessionTurningTrafficList[sessionselect].description} - ${chartTitle}`} subtitle="" chartOptions={chartOptions} data={chartData} />
        </div>

    );
}
