import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// material
import {
  Button,
  Grid,
  TextField,
  Tabs,
  Tab,
  Typography,
  Stack,
  Card,
  Table,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Snackbar, List, ListItem, ListItemAvatar, ListItemText, DialogTitle, Dialog
} from '@mui/material';
import L from 'leaflet';
import MuiAlert from '@mui/material/Alert';
import Page from '../components/Page';
import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
// eslint-disable-next-line import/named
import { CarListToolbar, abnormalCarListToolbar } from '../components/_dashboard/user';
import { SimpleDialog } from '../components/dialog';

import { fetchDriverList } from "../actions";
import TTable from '../components/TTable'

import { LeonTagRecord, TruckRecord, DriverRecord, ReaderRecord } from '../components/record/index';



import { DriverForm } from '../components/editData/index';
import { DriverInsertForm } from '../components/insertData/index';
import * as config from "../config/config";

// import { LeonTagInsertForm } from '../components/insertData/index';


const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));


// 定義切換頁
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    'style': { 'font-size': '24px' },
  };
}

export default function EnhancedTable() {

  const headCells = [
    {
      id: 'rownum',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '項目'
    },
    {
      id: 'enable',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '狀態',
      type: 'format',
      fn: (enable) => {
        const enableStr = enable ? '啟用' : '停用';
        return (
          enableStr
        )
      }
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '姓名',
      type: 'format',
      fn: (data) => {
        if (data !== null && data !== '') {
          const nameString = data
          let name = "";
          for (let i = 0; i < nameString.length; i += 1)
            if (i === 0)
              name += `${nameString[i]}`
            else if (i === nameString.length - 1 && i !== 1)
              name += `${nameString[i]}`
            else
              name += `*`
          return (
            name
          )
        }
      }
    },
    {
      id: 'gender',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '性別'
    },
    {
      id: 'age',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '年齡'
    },
    // {
    //   id: 'identification',
    //   numeric: false,
    //   disablePadding: true,
    //   fillter: true,
    //   label: '身分證'
    // },
    // {
    //   id: 'phone',
    //   numeric: false,
    //   disablePadding: true,
    //   fillter: true,
    //   label: '手機'
    // },  
    {
      id: 'phone',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '電話',
      type: 'format',
      fn: (data) => {
        if (data !== null && data !== '') {
          const phoneString = data
          let name = "";
          for (let i = 0; i < phoneString.length; i += 1)
            if (i <= 2)
              name += `${phoneString[i]}`
            else if (i >= phoneString.length - 3)
              name += `${phoneString[i]}`
            else
              name += `*`
          return (
            name
          )
        }
      }
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: 'Email'
    },
    {
      id: 'specialMark',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '特殊註記',
      type: 'format',
      fn: (data) => {
        if (data !== '' && data !== null) {
          return (
            <Label
              variant="ghost"
              color="error"
            >
              {data}
            </Label>
          )
        }
      }
    },
    {
      id: 'updateTime',
      numeric: false,
      disablePadding: false,
      fillter: false,
      label: '更新時間',
      type: 'format',
      fn: (data) => {
        const time = new Date(data).toLocaleString()
        return (
          time
        )
      }

    },
    {
      type: 'render',
      fn: (row) => {
        const { identification, name, gender, age, phone, email, specialMark, description, updateTime } = row
        return (
          <Button variant="outlined" onClick={
            () => {
              // alert(leonTagID)
              const specialMarkInfoid = (row.specialMark_info_id === null || row.specialMark_info_id === '' || row.specialMark_info_id === undefined) ? [] : JSON.parse(row.specialMark_info_id);

              setInfo(row.id, row.team_id, identification, name, gender, age, phone, email, specialMark,
                specialMarkInfoid, description, updateTime, row.enable);
              // // console.log(leonTagID)
              // setValue(1)
            }
          }>
            編輯
          </Button>
        )
      }
    }
  ];
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:
  const Region = "彰化縣";
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('status');
  const [selected, setSelected] = React.useState([]);
  // 車機清單
  const [Vehiclelist, setVehiclelist] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterName, setFilterName] = React.useState('');
  const [reason, setreason] = React.useState(' ');
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [marker, setMarker] = useState(null);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const driversStore = useSelector((state) => state.driverManage);
  const { users } = usersStore;
  const { drivers, error } = driversStore;
  const [first, setfirst] = React.useState(true);
  // const { loading, drivers, error } = driversStore;    
  // eslint-disable-next-line prefer-const
  let intervalId = null;
  let map;
  useEffect(() => {
    if (first) {
      Vehicle_management();
      setVehiclelist([]);
      setVehiclelist(drivers);
      setfirst(false)
    }


    // 定時讀取車機清單
    // eslint-disable-next-line no-const-assign
    // intervalId = setInterval(() => {
    //   Vehicle_management();
    // }, 50000);
    // componentWillUnmount
    // return function cleanup() {
    //   clearInterval(intervalId);
    // };
  }, [first]);

  useEffect(() => {
    // console.log(1);
    // if (error === "FAILED")
    //   window.location.href = '/login';
    // if (users.resultCode !== "Success")
    //   window.location.href = '/login';
    if (drivers.length === 0) {
      // Vehicle_management();
      setVehiclelist([]);
      setVehiclelist(drivers);
      // console.log(drivers);
    }
    else {
      setVehiclelist([]);
      setVehiclelist(drivers);
      // console.log(drivers);
    }
    // 定時讀取車機清單
    // eslint-disable-next-line no-const-assign
    // intervalId = setInterval(() => {
    //   Vehicle_management();
    // }, 50000);
    // componentWillUnmount
    // return function cleanup() {
    //   clearInterval(intervalId);
    // };
  }, [drivers, error, users]);

  // 讀取車機清單
  // eslint-disable-next-line camelcase
  const Vehicle_management = async () => {
    // const result = fetchTrucks({"username": users.username, "token": users.token});
    // console.log(users);
    // console.log(1);
    dispatch(fetchDriverList({ "userName": users.userName, "token": users.token }));


  };

  // 關閉彈跳釋出
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



  // tab切換
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [driverID, setDriverID] = useState(0);
  const [teamID, setTeamID] = useState(0);
  const [identification, setIdentification] = useState();
  const [name, setName] = useState();
  const [gender, setGender] = useState();
  const [age, setAge] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [specialMark, setSpecialMark] = useState();
  const [specialMarkInfoID, setspecialMarkInfoID] = useState();
  const [description, setDescription] = useState();
  const [updateTime, setUpdateTime] = useState();
  const [enable, setEnable] = useState();
  const [type, setType] = useState();

  const setInfo = (driverID, teamID, identification, name, gender, age, phone, email, specialMark, specialMarkInfoID, description, updateTime, enable) => {
    setDriverID(driverID);
    setTeamID(teamID);
    setIdentification(identification);
    setGender(gender);
    setAge(age);
    setName(name);
    setPhone(phone);
    setEmail(email);
    setSpecialMark(specialMark);
    setspecialMarkInfoID(specialMarkInfoID);
    setDescription(description);
    setUpdateTime(updateTime);
    setEnable(enable);
    setType('update');
    setValue(1);
  };


  const handleDialogClose = (value) => {
    setDialogOpen(false);
    setType(value);
    if (value !== '')
      setValue(1);
  };


  const insetDriverForm = () => {
    setDriverID('');
    setTeamID('');
    setIdentification('');
    setGender('');
    setAge('');
    setName('');
    setPhone('');
    setEmail('');
    setSpecialMark('');
    setspecialMarkInfoID([]);
    setDescription('');
    setUpdateTime('');
    setEnable(1);
    setType('insert');
    setValue(1);
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  return (
    <Page title="智慧管理識別平台">
      <Container maxWidth="xl">
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {reason}
          </Alert>
        </Snackbar>

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="駕駛資訊" {...a11yProps(0)} />
          <Tab label="詳細資訊" {...a11yProps(1)} />
          <Button variant="contained" size="large" style={{ marginLeft: "auto", marginRight: "24px" }}
            onClick={insetDriverForm}
          >新增</Button>
        </Tabs>

        <TabPanel value={value} index={0}>



          <Grid item xs={12}
            sx={{
              '& th': {
                fontSize: config.tableTitleFontSize
              },
              '& td': {
                fontSize: config.tableContentFontSize
              },
              '& span': {
                fontSize: config.tableContentFontSize,
                height: config.tableSpanHeight
              }
            }}>
            <TTable Vehiclelist={Vehiclelist} headCells={headCells} />
          </Grid>



        </TabPanel>

        <TabPanel value={value} index={1}>

          <DriverForm
            driverID={driverID}
            teamID={teamID}
            identification={identification}
            name={name}
            gender={gender}
            age={age}
            email={email}
            phone={phone}
            specialMark={specialMark}
            specialMarkInfoID={specialMarkInfoID}
            description={description}
            updateTime={updateTime}
            enable={enable}
            type={type}
            setValue={setValue}
          />

          {/* {              
            (() => {

                if(type === "insert"){
                  return (
                    <DriverInsertForm 
                      driverID={driverID}
                      teamID={teamID}
                      identification={ identification }
                      name={ name }
                      email={email }
                      phone={ phone}      
                      specialMark={ specialMark}     
                      description={ description}     
                      updateTime={updateTime}
                      enable={enable}     
                    />
                  );
                }
                if(type === "edit"){
                  return (
                    <DriverForm 
                      driverID={driverID}
                      teamID={teamID}
                      identification={ identification }
                      name={ name }
                      email={email }
                      phone={ phone}      
                      specialMark={ specialMark}     
                      description={ description}     
                      updateTime={updateTime}
                      enable={enable}     
                      />
                  );
                }
            })()
          } */}
        </TabPanel>
        <SimpleDialog
          open={dialogOpen}
          onClose={handleDialogClose}
        />
      </Container>
    </Page>
  );
}
