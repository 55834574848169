import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from './charts';

// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    name: 'Team A',
    type: 'column',
    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
  },
  {
    name: 'Team B',
    type: 'line',
    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
  },
  {
    name: 'Team C',
    type: 'area',
    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
  },
];

export default function AAppWebsiteVisits(props) {
  const chartOptions = merge(BaseOptionChart(), props.chartOptions);

  return (
    <Card>
      <CardHeader title={props.title} subheader={props.subtitle}
      // subheader="(+43%) than last year" 
      />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={props.data} options={chartOptions} height={419} />
      </Box>
    </Card>
  );
}
