import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  DialogTitle,
  Dialog, DialogContent
} from '@mui/material';
import sha256 from 'crypto-js/sha256';
import { LoadingButton } from '@mui/lab';

import { useDispatch, useSelector } from "react-redux";
import { fetchUserChangePassword, setAlertMessage, userLogout } from "../../../actions";
// ----------------------------------------------------------------------


export default function ChangePasswordForm(props) {
  const [first, setFirst] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [nackbarVHState, setnackbarVHState] = useState({
    vertical: 'top',
    horizontal: 'center'
  });

  const LoginSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Password is required'),
    newPassword: Yup.string().required('Password is required'),
    newPassword1: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPassword1: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => onSubmit(values, actions)
  });

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const { users } = usersStore;
  const usersStore1 = useSelector((state) => state.userChangePassword);
  const { error, errorText, loading } = usersStore1;

  // 刷新数据
  useEffect(() => {
    if (first === false) {

      if (!loading) {
        if (errorText === '' && error === null) {
          dispatch(setAlertMessage({ "open": true, "severity": "success", "content": "修改密碼成功，請重新登入" }));
          dispatch(userLogout({}));
          window.setTimeout(() => {
            window.location.href = '/login';
          }, 2000);

        }
        else if (errorText === '舊密碼錯誤' && error === 'FAILED') {
          dispatch(setAlertMessage({ "open": true, "severity": "error", "content": "舊密碼錯誤" }));
        }
        else if (error === 'FAILED') {
          dispatch(setAlertMessage({ "open": true, "severity": "error", "content": errorText }));
        }

      }
    }


  }, [error, errorText])
  const onSubmit = async (values, actions) => {

    actions.setSubmitting(false)

    setFirst(false);

    try {
      const regrexPwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/
      if (!regrexPwd.test(values.newPassword)) {
        dispatch(setAlertMessage({ "open": true, "severity": "error", "content": "新密碼設定長度為8~16個，且要混合大小寫英文字母與數字" }));
        return
      }
      if (values.newPassword === values.oldPassword) {
        dispatch(setAlertMessage({ "open": true, "severity": "error", "content": "舊密碼與新密碼相同" }));
        return
      }
      if (values.newPassword !== values.newPassword1) {
        dispatch(setAlertMessage({ "open": true, "severity": "error", "content": "新密碼與確認密碼不同" }));
        return
      }
      const ciphertext = sha256(values.oldPassword, 'IIIsecretkeyLEON').toString();
      const ciphertext1 = sha256(values.newPassword, 'IIIsecretkeyLEON').toString();
      dispatch(fetchUserChangePassword({
        "userName": users.userName,
        "oldPassword": ciphertext,
        "newPassword": ciphertext1,
        "token": users.token
      }));


    } catch (e) {
      console.error(e)
    }

  }

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleClose = () => {
    props.onClose(false)
  };

  return (

    <Dialog open={props.open} onClose={handleClose} >
      <DialogTitle >修改密碼</DialogTitle>
      <DialogContent sx={{ width: 400, height: 320 }} style={{ 'padding-top': 10 }} >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Old Password"
                {...getFieldProps('oldPassword')}
                InputProps={{
                  // style: { color: "#000000" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />

              <TextField
                fullWidth
                autoComplete="new-password"
                type={showPassword ? 'text' : 'password'}
                label="New Password"
                {...getFieldProps('newPassword')}
                InputProps={{
                  // style: { color: "#000000" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />

              <TextField
                fullWidth
                autoComplete="again-new-password"
                type={showPassword ? 'text' : 'password'}
                label="Confirm New Password"
                {...getFieldProps('newPassword1')}
                InputProps={{
                  // style: { color: "#000000" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>
            <Stack spacing={3} style={{ 'position': 'relative' }} sx={{ mt: 3 }}>
              <LoadingButton style={{ 'position': 'absolute', 'right': 0, }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                確認送出
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>

      </DialogContent>
    </Dialog>
  );
}
