import { call, put, takeEvery } from "redux-saga/effects";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../config/types";
import * as ipconfig from "../config/ipconfig";


const apiUrl = `https://${ipconfig.apiIP}/api/v1/truck/list1`;

function getApi(parse) {
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },    
    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
};

function* fetchTruckList(action) {
  try {
    // // console.log('truck');
    const trucks = yield call(getApi, action.payload.parse); 
    yield put({type: "CHANGE_TOKEN_STATUS", payload: trucks.resultCode});    
    if(trucks.resultCode === 'Failed')
      yield put(getTrucksError());
    else
      yield put(getTrucksSuccess(trucks));
  } catch (error) {
    yield put(getTrucksError(error.message));
  }
}

export const getTrucksSuccess = (trucks) => {
  // console.log("request trucks success");
  return {
    type: types.GET_TRUCK_LIST_SUCCESS,
    payload: {
      loading: false,
      trucks: trucks
    }
  };
};

export const getTrucksError = () => {
  // console.log("request trucks error");
  return {
    type: types.GET_TRUCK_LIST_FAILED,
    error: "FAILED"
  };
};

function* truckSaga() {
  yield takeEvery(types.GET_TRUCK_LIST_REQUEST, fetchTruckList);
}

export default truckSaga;
