
import { set, sub, formatDistanceToNow } from 'date-fns';
import moment from 'moment';
const initialState = {
  notificationsData: [],
  readTime: '',
};
export default function notifications(state = initialState, action) {
  switch (action.type) {
    case 'ADD_ITEM': {
      const menuItemCopy = state.notificationsData.slice();
      return {
        notificationsData: [{
          "data": action.payload,
          "date": sub(new Date(), { hours: 0, minutes: 0 }),
          "isUnRead": true
        }].concat(menuItemCopy)
      };
    }
    case 'INIT_ITEM': {
      const menuItemCopy = state.notificationsData.slice();
      const notificationsData = []
      const tempData = action.payload
      tempData.forEach((item) => {
        // item.receiveTime -> utc+8
        const time = new Date(`${moment(item.receiveTime)}`)
        const time1 = new Date(`${moment(localStorage.readTime1).add(8,'hours')}`)
        const date = sub(time, { hours: 0, minutes: 0 })
        if(time1 > time){
          notificationsData.push({
            "data": item,
            "date": sub(time, { hours: 0, minutes: 0 }),
            "isUnRead": false
          })
        }
        else {
          notificationsData.push({
            "data": item,
            "date": sub(time, { hours: 0, minutes: 0 }),
            "isUnRead": true
          })
        }
      });
      notificationsData.concat(menuItemCopy)
      return { notificationsData };
    }
    case 'CLEAN_ITEM': {
      return { notificationsData: [] };
    }
    case "SET_USER_READTIME": {
      return {
        ...state,
        readTime: action.payload
      };
    }
    case 'isRead': {
      return {
        notificationsData: state.notificationsData.map((notification) => ({
          ...notification,
          isUnRead: false
        }))
      };

    }
    default:
      return state;
  }
};

