import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Snackbar from '@mui/material/Snackbar';

import { useDispatch, useSelector } from "react-redux";
import SelectMenuItem from '../SelectMenuItem'
import { fetchLeonTagList, setAlertMessage } from "../../actions";
import { getOperationEvent, setFormikObjectRequired, setFormikObjectInitialValues } from "./index"
import TTextField from "../TTextField"
import * as ipconfig from "../../config/ipconfig";
// ----------------------------------------------------------------------


export default function LeonTagForm(props) {
  const { leonTagID, truckID, teamID, leonTagProductNumber, description, updateTime, enable, type } = props;
  const { setValue } = props;

  const [nackbarVHState, setnackbarVHState] = useState({
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal } = nackbarVHState;

  // 表單不輸入內容相關之訊初始化
  const [formikRequired, setFormikRequired] = useState({});
  const LoginSchema = Yup.object().shape(formikRequired);

  // 表單資料來源
  const textFieldLabel = ["識別裝置", "描述"];
  const textFieldProps = ["leonTagProductNumber", "description"];
  const textFieldValue = [leonTagProductNumber, description];
  const textFieldValuerequired = ['請輸入識別裝置', ''];

  // 表單內容初始化
  const [formikInitialValues, setFormikInitialValues] = useState({});
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => onSubmit(values, actions)
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const { users } = usersStore;
  const leonTagsStore = useSelector((state) => state.leonTagManage);
  const { leonTags } = leonTagsStore;
  const trucksStore = useSelector((state) => state.truckManage);
  const { trucks } = trucksStore;
  const teamsStore = useSelector((state) => state.teamsInfo);
  const { teams } = teamsStore;

  // const [teamID, setTeamID] = useState();
  const [truckList, setTruckList] = useState([]);
  const [truckSelect, setTruckSelect] = useState(0);
  const [teamList, setTeamList] = useState([]);
  const [teamSelect, setTeamSelect] = useState(0);
  const [truckValue, setTruckValue] = useState(0);
  const [tagEnable, setTagEnable] = useState(1);
  const eventContext = useRef(null);
  const labelName = "選擇綁定車輛"
  const labelTeamName = "選擇綁定車隊"
  const [truckSelectDisabled, setTruckSelectDisabled] = useState(false);
  const [truckRadioDisabled, setTruckRadioDisabled] = useState(false);
  const [oldTeamName, setOldTeamName] = useState(0);
  const teamNameSelect = useRef('');


  const [initialState, setInitialState] = useState('first');
  /* ******************************
            initialState
   *********** 資料清除 ***********
        first   第一次進入   
        success 成功        
   ********* 送出前的資料 *********
        error   輸入資料有誤
  ****************************** */
  useEffect(() => {
    localStorage.setItem('mode', '1');
    if (initialState === 'first' || initialState === 'success') {
      setTeamSelect(users.team_id);
      if (type === 'insert')
        setTagEnable(1)
      else
        setTagEnable(enable)
      // 設定不輸入會顯示紅字錯誤
      const objRequired = setFormikObjectRequired(textFieldProps, textFieldValuerequired);
      setFormikRequired(objRequired);

      // 設定表單內容的初始值
      const objInitialValues = setFormikObjectInitialValues(textFieldProps, textFieldValue)
      setFormikInitialValues(objInitialValues);

      for (let i = 0; i < textFieldProps.length; i += 1)
        setFieldValue(textFieldProps[i], textFieldValue[i]);
    }
  }, [props])



  useEffect(() => {
    // console.log(users);
    // console.log(leonTags);
    // console.log(trucks);
    setTagEnable(enable);
    getTeamList();
    getTruckList();
  }, [])
  useEffect(() => {
    setTruckSelect(null);
    getTruckList();
  }, [teamSelect])

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false)
    const today = new Date().toISOString();
    getTeamNameSelect();
    const leonTagJson = createLeonTagJson(values, today);
    const leonTagRecord = createLeonTagReacordJson(today);
    const userOperationRecordJson = createUserOperationRecordJson();

    const recordJson = {
      "action": type,
      "LeonTagRecord": leonTagRecord,
    };
    // console.log(userOperationRecordJson);
    // console.log(leonTagJson);
    // console.log(recordJson);   
    // console.log();   

    // // console.log(recordJson);   
    // // console.log(truckSelect);   
    // // console.log(truckValue);   
    // // console.log(eventContext.current );   

    if ((truckSelect === null || truckSelect === 0) && truckValue !== 0) {
      setInitialState('error');
      dispatch(setAlertMessage({ "open": true, "severity": "error", "content": "請選擇綁定車輛" }));
    }
    else if (eventContext.current !== '' && eventContext.current !== null)
      updateLeonTag(userOperationRecordJson, leonTagJson, recordJson);


  }

  /* ***************************************
      操作紀錄-事件描述
      1.啟用tag或停用tag (停用也順勢解綁車輛)
      2.解綁車輛
      3.綁定車輛
      4.更換車輛
      5.更改Tag資訊(編號、敘述)
      6.更改Tag所屬車隊
  *************************************** */
  const getUserOperationEvent = (values) => {
    // 1.啟用或停用
    // 選擇的enable與原本狀態不一樣
    eventContext.current = null;
    // console.log(eventContext.current);

    // 解綁
    // 選擇解除綁定 && 原本狀態是綁定
    const unbindTruck = (!truckValue && (truckID !== null && truckID !== 0));
    // 綁定
    // 舊資料未綁定truck 
    const bindTrucktemp = ((truckID === null || truckID === 0) && (truckSelect !== truckID && truckValue));
    const bindTruck = !!bindTrucktemp;
    // 更換駕駛
    // 選擇駕駛與原本綁定的不一樣
    const changeTruck = (truckSelect !== truckID) && (truckSelect !== null && truckSelect !== 0);

    const eventContextList = ["車輛所屬車隊", "綁定狀態(解除綁定)", "綁定狀態(綁定車輛)", "綁定車輛", "識別裝置", "描述"];
    const objectNameList = [teamSelect,
      false,
      false,
      false,
      values.leonTagProductNumber,
      values.description,];

    const oldValuesList = [teamID,
      unbindTruck,
      bindTruck,
      changeTruck,
      leonTagProductNumber,
      description,]

    const eventContextListOfInsert = ["綁定狀態(綁定車輛)", "識別裝置", "描述"];
    const objectNameListOfInsert = [false, values.leonTagProductNumber, values.description];
    const oldValuesListOfInsert = [bindTruck, leonTagProductNumber, description,]

    eventContext.current = getOperationEvent(tagEnable, enable, type, eventContextList, objectNameList, oldValuesList, eventContextListOfInsert, objectNameListOfInsert, oldValuesListOfInsert);

    // console.log(eventContext.current);

    // if(tagEnable !== enable){
    //   const event = tagEnable ? "啟用Tag" : "停用Tag";
    //   eventContext.current = event;
    // }
    // // 2.解綁
    // // 選擇解除綁定 && 原本狀態是未綁定
    // else if(!truckValue && (truckID !== null || truckID !== 0)){
    //   const event = "解除綁定車輛"
    //   eventContext.current = event;
    // }
    // // 3.綁定
    // // 舊資料未綁定truck 
    // else if(truckID === null || truckID === 0){
    //   // 新選擇的truck != 舊資料truck && 綁定為TRUE
    //   if(truckSelect !== truckID && truckValue){      
    //     const event = "綁定車輛"
    //     eventContext.current = event;
    //   }
    // }
    // // 4.更換車輛
    // // 選擇車輛與原本綁定的不一樣
    // else if(truckSelect !== truckID){      
    //   if(truckSelect !== null && truckSelect !== 0){
    //     const event = "更換綁定車輛"
    //     eventContext.current = event;
    //   }
    // }
    // // 5.更改Tag資訊(編號、敘述)
    // if(values.leonTag !== leonTagProductNumber || (values.description !== description && values.description !== '')){
    //   if(eventContext.current !== null){
    //       const event = "、更改Tag資訊"
    //       eventContext.current += event;
    //   }
    //   else{
    //     const event = "更改Tag資訊"
    //     eventContext.current = event;
    //   }
    // }    
    // // 6.更改Tag所屬車隊
    // if( teamID !== teamSelect){
    //   if(eventContext.current !== null){
    //       const event = "更改Tag所屬車隊、"
    //       eventContext.current = event + eventContext.current;
    //   }
    //   else{
    //     const event = "更改Tag所屬車隊"
    //     eventContext.current = event;
    //   }
    // }

  }
  // 操作紀錄的JSON
  const createUserOperationRecordJson = () => {
    const userOperationRecordJson = {
      "action": type,
      "type": "LeonTag",
      "description": eventContext.current,
    };
    // // console.log(userOperationRecordJson);
    return userOperationRecordJson;
  }
  // 最新leonTag資料的JSON
  const createLeonTagJson = (values, date) => {
    getUserOperationEvent(values);

    const jsonObj = {
      "leonTag_id": leonTagID,
      "truck_id": truckSelect,
      "team_id": teamSelect,
      "leonTagProductNumber": values.leonTagProductNumber,
      "description": values.description,
      "enable": tagEnable,
      "updateTime": date
    }
    // console.log(type)
    if (type === 'insert')
      delete jsonObj.leonTag_id
    // 選擇停用(要解除綁定)
    // Select
    if (truckValue === 0)
      jsonObj.truck_id = 0;

    return jsonObj
  }
  // 舊leonTag資料的JSON
  const createLeonTagReacordJson = (date) => {
    const leonTagRecord = [];

    const oldJsonObj = {
      "leonTag_id": leonTagID,
      "truck_id": truckID,
      "team_id": teamID,
      "team_name": oldTeamName,
      "leonTagProductNumber": leonTagProductNumber,
      "description": description,
      "enable": enable,
    }
    if (updateTime !== '')
      oldJsonObj.updateTime = new Date(updateTime).toISOString()

    if (type === 'update')
      leonTagRecord.push(oldJsonObj)

    const jsonObj = createLeonTagJson(values, date);
    jsonObj.team_name = teamNameSelect.current;

    if (type === 'insert')
      delete jsonObj.truck_id

    leonTagRecord.push(jsonObj)
    // // console.log(oldLeonTagJson);
    return leonTagRecord;
  }


  const getTeamNameSelect = () => {
    for (let i = 0; i < teamList.length; i += 1) {
      if (teamList[i].id === teamSelect) {
        teamNameSelect.current = teamList[i].description;
        break;
      }
    }
  }

  const getTeamList = () => {
    const list = []
    for (let i = 0; i < teams.length; i += 1) {
      list.push({ "id": teams[i].id, "description": teams[i].name });
      if (teams[i].id === teamID)
        setOldTeamName(teams[i].name);
    }
    setTeamList(list);

    for (let i = 0; i < leonTags.length; i += 1) {
      if (leonTags[i].leonTagProductNumber === leonTagProductNumber) {
        setTeamSelect(leonTags[i].team_id)
        break;
      }
    }
  };

  // const getTeamList = () => {
  //   const list = []
  //   for (let i = 0; i < leonTags.length; i += 1){
  //     if(leonTags[i].leonTagProductNumber === leonTagProductNumber){
  //       setTeamSelect(leonTags[i].team_id)
  //     }      
  //   }    
  //   for (let i = 0; i < teams.length; i += 1){
  //     list.push({"id": teams[i].id , "description": teams[i].name});
  //   }
  //   setTeamList(list);
  // };



  const getTruckList = async () => {
    const list = []
    if (truckID !== null || truckID !== 0) {
      for (let i = 0; i < trucks.length; i += 1) {
        if (trucks[i].team_id === teamSelect && trucks[i].id === truckID) {
          setTruckSelect(truckID);
          setTruckValue(1);
          const description = `${trucks[i].licensePlateNumber} (目前綁定之車輛)`;
          list.push({ "id": trucks[i].id, "description": description });

          break;
        }
      }
    }
    for (let i = 0; i < trucks.length; i += 1) {
      for (let j = 0; j < leonTags.length; j += 1) {
        if (leonTags[j].id === trucks[i].id) {
          break;
        }
        if (j === leonTags.length - 1 && trucks[i].team_id === teamSelect && trucks[i].id !== truckID) {
          const description = `${trucks[i].licensePlateNumber} (未綁定)`;
          list.push({ "id": trucks[i].id, "description": description });
        }
      }
    }
    setTruckList(list);
  };

  // 更新資料
  const updateLeonTag = async (userOperationRecordJson, leonTagJson, recordJson) => {
    // console.log(values)
    await axios
      .post(`https://${ipconfig.apiIP}/api/v1/${type}/leonTagInfo`,
        {
          "token": users.token,
          "userOperationRecordJson": userOperationRecordJson,
          "leonTagJson": leonTagJson,
          "recordJson": recordJson
        },
        {
          header: {
            Authorization: users.token,
            "Content-Type": "application/json",
          }
        })
      .then(async (response) => {
        dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
        // eslint-disable-next-line eqeqeq
        if (response.data.resultCode == "success") {

          dispatch(setAlertMessage({ "open": true, "severity": "success", "content": "成功" }));
          setValue(0)
          // // console.log("Success");    
          // setSnackbarStateSuccess(true);         
          dispatch(fetchLeonTagList({ "userName": users.userName, "token": users.token }));
          localStorage.setItem('mode', '');
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      })
      .then(() => {
        // always executed
      });
  };

  const handleChangeTagEnable = (event) => {
    setTagEnable(parseInt(event.target.value, 10));

    // 選停用tag => 解除綁定  
    // 車輛disable
    if (parseInt(event.target.value, 10) === 0) {
      setTruckValue(parseInt(event.target.value, 10));
      setTruckSelectDisabled(!parseInt(event.target.value, 10));
    }

    // 選啟用 = enable 選解綁 = disabled 
    setTruckRadioDisabled(!parseInt(event.target.value, 10));
  };

  const handleChangeRadioValue = (event) => {
    setTruckValue(parseInt(event.target.value, 10));
    // 選綁定 = enable 選解綁 = disabled 
    setTruckSelectDisabled(!parseInt(event.target.value, 10));
  };

  return (

    <div>
      <FormikProvider value={formik}>
        <div>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

            <Stack spacing={3}>

              {
                (() => {
                  if (users.permission === "admin") {
                    return (<SelectMenuItem Option={teamList} select={teamSelect} setselect={setTeamSelect} labelName={labelTeamName} />);
                  }
                })()
              }
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={tagEnable}
                onChange={handleChangeTagEnable}
              >
                <FormControlLabel value={1} control={<Radio />} label="啟用識別裝置" />
                <FormControlLabel value={0} control={<Radio />} label="停用識別裝置" />
              </RadioGroup>
              {
                (() => {
                  const TextFieldlist = []
                  for (let i = 0; i < textFieldLabel.length; i += 1) {
                    TextFieldlist.push(
                      <TTextField
                        name={textFieldProps[i]}
                        // type={}
                        label={textFieldLabel[i]}
                        getFieldProps={getFieldProps(textFieldProps[i])}
                        error={Boolean(touched[textFieldProps[i]] && errors[textFieldProps[i]])}
                        helperText={touched[textFieldProps[i]] && errors[textFieldProps[i]]}
                      />
                    )
                  }
                  return TextFieldlist
                })()
              }
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label-1"
                name="row-radio-buttons-group-1"
                value={truckValue}
                onChange={handleChangeRadioValue}
              >
                <FormControlLabel
                  disabled={truckRadioDisabled} value={1} control={<Radio />} label="綁定車輛" />
                <FormControlLabel
                  disabled={truckRadioDisabled} value={0} control={<Radio />} label="解除綁定車輛" />
              </RadioGroup>
              <SelectMenuItem
                disabled={truckSelectDisabled} Option={truckList} select={truckSelect} setselect={setTruckSelect} labelName={labelName} />
            </Stack>


            <Stack spacing={3} style={{ 'position': 'relative' }} sx={{ mt: 3 }}>
              <LoadingButton style={{ 'position': 'absolute', 'right': 0, }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                確認送出
              </LoadingButton>
            </Stack>
          </Form>
        </div>
      </FormikProvider></div>
  );
}
