import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
// material
import {Container, Typography,Tabs, Tab, } from '@mui/material';
// components
import Page from '../components/Page';
import ReaderCountSafe from './ReaderCountSafe';
import TruckLimitMap from './TruckLimitMap';
import ReaderCountMap2 from './ReaderCountMap2';

// 定義切換頁
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,    
        'style': { 'font-size': '24px' },
    };
}

export default function Truckservice() {
 
    const [tabValue, settabValue] = React.useState(0);

    // tab切換
    const handleChange = (event, newValue) => {       
        settabValue(newValue);
    };
    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl">                 
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="大型車輛管制地圖" {...a11yProps(0)} />
                    <Tab label="大型車輛安全路徑" {...a11yProps(1)} />
                </Tabs>                
                <TabPanel value={tabValue} index={0}>
                    <TruckLimitMap />                     
                </TabPanel>                
                {/* <TabPanel value={tabValue} index={1}>
                <ReaderCountSafe />
                </TabPanel> */}
                <TabPanel value={tabValue} index={1}>
                    <ReaderCountMap2 />
                </TabPanel>
                
            </Container>
        </Page>
    );
}
