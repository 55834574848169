
import * as types from "../config/types";

const initialState = {
  loading: false,
  userOperationRecord: [],
  parse:null,
  resultStatus:false,
  error: null
};

export default function userOperationRecord (state = initialState, action)  {
  switch (action.type) {
    case types.GET_USERS_OPERATION_RECORD_REQUEST: {
      return {
        ...state,
        loading: true,   
        resultStatus:false,
        error: null,
      };
    }
    case types.GET_USERS_OPERATION_RECORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        resultStatus:true,
        userOperationRecord: action.payload.userOperationRecord.rows
      };
    }
    case types.GET_USERS_OPERATION_RECORD_FAILED: {
      return {
        ...state,
        loading: false,
        error: "FAILED",      
        resultStatus:false,  
      };
    }
    default:
      return state;
  }
};

