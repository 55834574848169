
import * as types from "../config/types";

const initialState = {
  loading: false,
  teams: [],
  teamReciverStatus: false,
  error: null
};

export default function teamsInfo (state = initialState, action)  {
  switch (action.type) {
    case types.GET_TEAM_REQUEST: {
      return {
        ...state,
        loading: true,   
      };
    }
    case types.GET_TEAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        teamReciverStatus:true,
        teams: action.payload.teams.data
      };
    }
    case types.GET_TEAM_FAILED: {
      return {
        ...state,
        loading: false,
        error: "FAILED",       
      };
    }
    default:
      return state;
  }
};

