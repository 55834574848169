import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SelectMenuItem from '../components/SelectMenuItem'
import AAppWebsiteVisits from '../components/AAppWebsiteVisits';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import ToggleButtons from '../components/ToggleButton'
import ReaderCountDataTable from './ReaderCountDataTable'

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";

export default function TestMap() {
    const [leonReaderlist, setleonReaderlist] = useState([]);
    const [specialMarkData, setspecialMarkData] = useState([]);
    const [tableData, settableData] = useState([]);
    const [popupTableData, setpopupTableData] = useState([]);

    const [mapcenter, setmapcenter] = useState([24, 121]);
    const [openmap, setopenmap] = useState(null);
    const [ToggleButtonselect, setToggleButtonselect] = useState(60);
    const ToggleButtonsOptions = [
        { value: 5, Name: '5分鐘' },
        { value: 15, Name: '15分鐘' },
        { value: 30, Name: '30分鐘' },
        { value: 60, Name: '60分鐘' },
    ]

    const flymap = (point) => {
        openmap.flyTo(point, 15)
    }
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;
    const dispatch = useDispatch();


    const specialMarklist = [
        { "id": 0, "description": "全部", "type": "all" },
        { "id": 1, "description": "贓車", "type": "truckSpecialMark" },
        { "id": 2, "description": "欠稅車", "type": "truckSpecialMark" },
        { "id": 3, "description": "特殊車輛", "type": "truckSpecialMark" },
        { "id": 4, "description": "高風險駕駛", "type": "driverSpecialMark" },
    ]
    const [specialMarkSelect, setspecialMarkSelect] = useState(0);
    useEffect(() => {
        getData(ToggleButtonselect);
    }, [users]);


    useEffect(() => {
        // console.dir('leonReaderlist useEffect')
        // console.dir(leonReaderlist)

        if (openmap != null) {
            const bounds = [[25.112698, 121.311192], [25.080091, 121.24397499999999]
            ]
            for (let i = 0; i < leonReaderlist.length; i += 1) {
                if (leonReaderlist[i].latitude !== null || leonReaderlist[i].longitude !== null) {
                    if (i === 0) {
                        bounds[0][0] = leonReaderlist[i].latitude
                        bounds[1][0] = leonReaderlist[i].latitude
                        bounds[0][1] = leonReaderlist[i].longitude
                        bounds[1][1] = leonReaderlist[i].longitude
                    } else if (leonReaderlist[i].latitude !== 0) {
                        if (leonReaderlist[i].latitude > bounds[0][0])
                            bounds[0][0] = leonReaderlist[i].latitude
                        else if (leonReaderlist[i].latitude < bounds[1][0])
                            bounds[1][0] = leonReaderlist[i].latitude
                        if (leonReaderlist[i].longitude > bounds[0][1])
                            bounds[0][1] = leonReaderlist[i].longitude
                        else if (leonReaderlist[i].longitude < bounds[1][1])
                            bounds[1][1] = leonReaderlist[i].longitude
                    }
                }
            }
            bounds[0][0] += 0.001
            bounds[0][1] += 0.001
            bounds[1][0] -= 0.001
            bounds[1][1] -= 0.001
            openmap.fitBounds(bounds)
        }

    }, [leonReaderlist]);

    useEffect(() => {
        getData(ToggleButtonselect);
    }, [ToggleButtonselect]);

    useEffect(() => {
        setTableData();
    }, [specialMarkData, specialMarkSelect]);


    // 取德標格資料
    const getData = async (minutes) => {
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/specialmark/getSpecialmarkEvent`, { "token": users.token, "minutes": minutes })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                if (response.data.resultCode === "Success") {
                    const list = [];
                    const list1 = [];
                    let index = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj1 = {};
                        const data = response.data.rows[i];
                        const values = "reader_id";
                        if (!list1.find(a => a.id === data[values])) {
                            obj1 = data;
                            obj1.id = data[values]
                            obj1.status = 1
                            list1.push(obj1)
                        }
                    }
                    setleonReaderlist(list1)
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;
                        obj.receiveTime = new Date(obj.receiveTime).toLocaleString()
                        obj.rownum = index
                        obj.id = index
                        list.push(obj)
                        index += 1;
                    }
                    setspecialMarkData(list)
                    // setDate(list)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
            });
        // setOpen(true);
    };

    // 圖標顯示內容
    const popupcontent = (row, textColor) => {
        const r = row
        return (
            <div>
                <div className={Tooltip.text_root}>

                    <div className='pb-4'>
                        <span className={Tooltip.text_name}>{row.address}</span>
                    </div>
                    <div className='pb-4'>
                        <span className={Tooltip.text_address}>智慧感知讀取器名稱 : {row.leonReaderName}</span>
                    </div>
                    <div className='pb-4'>
                        <span className={Tooltip.text_address}>設備編號 : {row.readerName}</span>
                    </div>
                    {/* <div className='pb-4'>
                        <span className={Tooltip.text_address} style={{color:'red'}} >禁行事件 : {row.limitDescription}</span>
                    </div> */}
                    {/* <div className='pb-4'>
                        <span className={Tooltip.text_address}>禁行車輛車牌 : {row.licensePlateNumber}</span>
                    </div> */}
                    {/* <div className='pb-4'>
                        <span className={Tooltip.text_address}>時間 : {row.receiveTime}</span>
                    </div> */}

                    <button className={Tooltip.popup_button}
                        onClick={() => {
                            setPopupTableData(row.leonReaderMACAddress);
                            handleClickOpen();
                        }}
                    >查詢</button>
                </div>
            </div>

        )

    }

    const setPopupTableData = (leonReaderMACAddress) => {
        const specialMarkID = specialMarklist[specialMarkSelect].id
        const list = [];
        let index = 1;
        for (let i = 0; i < specialMarkData.length; i += 1) {
            const data = specialMarkData[i];
            let obj = {};
            if (data.leonReaderMACAddress === leonReaderMACAddress) {
                if (data.specialMark_info_id.includes(specialMarkID) || specialMarkSelect === 0) {
                    obj = data;
                    obj.rownum = index
                    list.push(obj)
                    index += 1;
                }
            }
        }
        setpopupTableData(list)
    };


    const setTableData = () => {
        const specialMarkID = specialMarklist[specialMarkSelect].id
        const list = [];
        let index = 1;
        for (let i = 0; i < specialMarkData.length; i += 1) {
            const data = specialMarkData[i];
            let obj = {};
            if (data.specialMark_info_id.includes(specialMarkID) || specialMarkSelect === 0) {
                obj = data;
                obj.rownum = index
                list.push(obj)
                index += 1;
            }
        }
        settableData(list)
    };



    const columns = [
        { field: "id", headerName: "id", width: 70, hideable: true },
        { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
        {
            field: "leonReaderName", headerName: "智慧感知讀取器名稱", flex: 0.35, sortable: true, headerAlign: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value === 'string')
                    return (params.value);
                const children = params.value.map((val) => (
                    <div >{val}</div>
                ));
                return (
                    <div style={{ fontSize: '20px' }}>
                        {children}
                    </div>
                );
            }
        },
        { field: "driverName", headerName: "駕駛姓名", width: 120, flex: 0.2, headerAlign: 'center', align: 'center', },
        { field: "licensePlateNumber", headerName: "車牌", width: 120, flex: 0.2, headerAlign: 'center', align: 'center', },
        { field: "specialMark", headerName: "特殊事件", width: 120, flex: 0.4, headerAlign: 'center', align: 'center', },
        { field: "receiveTime", headerName: "時間", width: 100, flex: 0.3, sortable: true, headerAlign: 'center', align: 'center', },
    ];


    const TableOnClick = (params, event) => {
        if (!event.ctrlKey) {
            event.defaultMuiPrevented = true;
            // alert(JSON.stringify(params));
            openmap.flyTo([params.row.latitude, params.row.longitude], 23);

        }
    }
    // const searchOnClick = () => {
    //     getData(ToggleButtonselect);
    // }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        <Stack direction="row" spacing={2}>
                            {/* <MultipleSelectCheckmarks Option={roadArealist} select={roadAreaselect} setselect={setroadAreaselect} /> */}
                            <ToggleButtons Options={ToggleButtonsOptions} setSelect={setToggleButtonselect} Select={ToggleButtonselect} />
                            <SelectMenuItem Option={specialMarklist} select={specialMarkSelect} setselect={setspecialMarkSelect} labelName="選擇事件種類" />
                        </Stack>

                    </Typography>
                    {/* <Button variant="contained" size="large" onClick={searchOnClick} >查詢</Button> */}
                </Stack>


                <Grid container spacing={3} >
                    <Grid item xs={12} style={{ position: 'relative' }}>                        
                        <BMap MarkerArray={leonReaderlist} popupcontent={popupcontent} 
                        mapcenter={mapcenter} setopenmap={setopenmap} openmap={openmap} />

                        {/* <div className={Styles.readerCountTips_red}>車流多</div>
                        <div className={Styles.readerCountTips_orange}>車流中等</div>
                        <div className={Styles.readerCountTips_green}>車流少</div>
                        <div className={Styles.readerCountTips_grey}>未更新</div> */}

                    </Grid>

                    <Grid item xs={12} style={{ height: "100%" }}
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontSize: '22px'
                            },
                            '& .MuiDataGrid-cellContent': {
                                fontSize: '20px'
                            }
                        }}>
                        <BDataGrid data={tableData} columns={columns}
                            TableOnClick={TableOnClick}
                        />
                    </Grid>

                    <Grid >
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <Grid style={{ width: '80vw' }}>
                                    <Box
                                        sx={{
                                            height: '100%',
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                                fontSize: '22px'
                                            },
                                            '& .MuiDataGrid-cellContent': {
                                                fontSize: '20px'
                                            }
                                        }}
                                    >
                                        <BDataGrid data={popupTableData} columns={columns}
                                        />
                                    </Box>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>關閉</Button>
                            </DialogActions>
                        </Dialog>

                    </Grid>

                </Grid>
            </Container>
        </Page>
    );
}
