import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
//
import { BaseOptionChart } from './charts';

// ----------------------------------------------------------------------

const CHART_HEIGHT = '68.5vh';
const LEGEND_HEIGHT = '7vh';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(0),
  'background-color': '#00000000 !important',
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'flex-start',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${viewportToPixels(CHART_HEIGHT) - viewportToPixels(LEGEND_HEIGHT)}px) !important`
  },
  '& .apexcharts-legend-text': {
    color: '#fff !important',
  },
  '& .apexcharts-svg .apexcharts-legend-series': {
    'margin-top': '2vh !important',
  },

}));

/* eslint-disable */
const viewportToPixels = (value) => {
  const parts = value.match(/([0-9\.]+)(vh|vw)/)
  const q = Number(parts[1])
  const side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
  return side * (q / 100)
}

// ----------------------------------------------------------------------

const CHART_DATA = [4344, 5435, 1443, 4443];

export default function AppCurrentVisits(props) {
  const chartOptions = merge(BaseOptionChart(), props.chartOptions);

  return (

    <Card style={{
      height: '98%', 'background-color': '#00000000',
      'box-shadow': '0 0 2px 0 rgb(255 255 255 / 33%), 0 16px 32px -4px rgb(145 158 171 / 0%)'
    }} sx={{ pb: 3.6 }}>
      <ChartWrapperStyle id="radialBar1" dir="ltr" >
        <ReactApexChart type="radialBar" series={props.data} options={chartOptions} height='100%' />
      </ChartWrapperStyle>
    </Card >
  );
}
