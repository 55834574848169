
// material
import {
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  Dialog
} from '@mui/material';

export default function SimpleDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose('');
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set backup account</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem button onClick={() => handleListItemClick("LeonTag")}>
          <ListItemText primary="Tag" />
        </ListItem>
        <ListItem button onClick={() => handleListItemClick("Driver")}>
          <ListItemText primary="駕駛" />
        </ListItem>
        <ListItem button onClick={() => handleListItemClick("Truck")}>
          <ListItemText primary="大型車輛" />
        </ListItem>

      </List>
    </Dialog>
  );
}
