import * as Yup from 'yup';
import { useState , useEffect , useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Snackbar from '@mui/material/Snackbar';

import { useDispatch, useSelector } from "react-redux";
import SelectMenuItem from '../SelectMenuItem'
import { fetchReaderList , setAlertMessage} from "../../actions";
import { getOperationEvent , setFormikObjectRequired , setFormikObjectInitialValues} from "./index"
import  TTextField  from "../TTextField"
import * as ipconfig from "../../config/ipconfig";

// ----------------------------------------------------------------------


export default function ReaderForm(props) {
  
  const { readerID , leonReaderMACAddress,leonReaderName, name, latitude, longitude, address, description , updateTime, createTime , enable , type } = props;
  const { setValue } = props;

  // 表單資料來源
  const textFieldLabel = ["智慧感知讀取器名稱","設備編號","ReaderMACAddress","緯度","經度","Reader設置地址","描述"];
  const textFieldProps = ["leonReaderName","name","leonReaderMACAddress","latitude","longitude","address","description"];  
  const textFieldValue = [leonReaderName,name,leonReaderMACAddress,latitude,longitude,address,description];
  const textFieldValuerequired = ['請輸入智慧感知讀取器名稱',
                                  '請輸入設備編號',
                                  '請輸入ReaderMACAddress',
                                  '請輸入緯度',
                                  '請輸入經度',
                                  '請輸入Rader設置的地址',
                                  ''];

  const navigate = useNavigate();
  const [open, setSnackbarStateFailed] = useState(false);
  const [open1, setSnackbarStateSuccess] = useState(false);
  
  const [nackbarVHState, setnackbarVHState] = useState({
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal } = nackbarVHState;

  // 表單不輸入內容相關之訊初始化
  const [formikRequired, setFormikRequired] = useState({});
  const LoginSchema = Yup.object().shape(formikRequired);
  
  // 表單內容初始化
  const [formikInitialValues, setFormikInitialValues] = useState({});
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: LoginSchema,    
    onSubmit:(values,actions)=> onSubmit(values,actions)
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps , setFieldValue } = formik;

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const { users } = usersStore;    
  
  const [readerEnable, setReaderEnable] = useState(1);
  const eventContext = useRef(null);


  const [initialState, setInitialState] = useState('first');

  /* ******************************
            initialState
   *********** 資料清除 ***********
        first   第一次進入   
        success 成功        
   ********* 送出前的資料 *********
        error   輸入資料有誤
  ****************************** */   
  useEffect(() => {
    if(initialState === 'first' || initialState === 'success'){
      if(type === 'insert')
        setReaderEnable(1)
      else
        setReaderEnable(enable)
      // 設定不輸入會顯示紅字錯誤
      const objRequired = setFormikObjectRequired(textFieldProps,textFieldValuerequired);
      setFormikRequired(objRequired);

      // 設定表單內容的初始值
      const objInitialValues = setFormikObjectInitialValues(textFieldProps,textFieldValue)
      setFormikInitialValues(objInitialValues);

      for(let i = 0; i<textFieldProps.length;i+=1)
        setFieldValue(textFieldProps[i],textFieldValue[i]);
    }
  }, [props])
  

  const onSubmit = async (values,actions)=>{
    actions.setSubmitting(false)
    const today =  new Date().toISOString();
    const readerJson = createReaderJson(values,today);
    const readerRecord = createReaderReacordJson(values,today);
    const userOperationRecordJson = createUserOperationRecordJson();

    const recordJson = {
      "action":  type ,
      "ReaderRecord": readerRecord,
    };
    // console.log(userOperationRecordJson);
    // console.log(readerJson);
    // console.log(recordJson);   
    // console.log();   
    
    if(eventContext.current !== '' && eventContext.current !==null){
      updateReader(userOperationRecordJson,readerJson,recordJson);
    }

    // // console.log(eventContext.current );   
    
    // if(eventContext.current !== null)
    //   updateReader(userOperationRecordJson,readerJson,recordJson);

  }
  
  /* ***************************************
      操作紀錄-事件描述
      1.啟用reader或停用reader
      2.更改MACAddress
      3.更改名稱
      4.更改緯度
      5.更改經度
      6.更改裝置地址
      7.更改描述
  *************************************** */
  const getUserOperationEvent = (values) => {   

    eventContext.current = null;
    // console.log(eventContext.current);

    const eventContextList = ["智慧感知讀取器名稱","MACAddress","名稱","緯度","經度","裝置地址","描述"];
    const objectNameList = [values.leonReaderName,values.leonReaderMACAddress,values.name,parseFloat(values.latitude),parseFloat(values.longitude),values.address,values.description];
    const oldValuesList = [leonReaderName,leonReaderMACAddress,name,latitude,longitude,address,description]

    const eventContextListOfInsert = ["Reader資訊","描述"];
    const objectNameListOfInsert = [values.leonReaderMACAddress,values.description];
    const oldValuesListOfInsert = [leonReaderMACAddress,description]
    
    eventContext.current = getOperationEvent(readerEnable,enable,type,eventContextList,objectNameList,oldValuesList,eventContextListOfInsert,objectNameListOfInsert,oldValuesListOfInsert);

    // console.log(eventContext.current);
    
  }
  // 操作紀錄的JSON
  const createUserOperationRecordJson = () => {
    const userOperationRecordJson = {       
      "action":  type,
      "type": "Reader" , 
      "description": eventContext.current ,
    };    
    // // console.log(userOperationRecordJson);
    return userOperationRecordJson;
  }
  // 最新reader資料的JSON
  const createReaderJson = (values,date) => {   
    getUserOperationEvent(values);
    // // console.log(eventContext);
    const jsonList = [];
    // 選擇停用(要解除綁定)
    // Select
    // if(readerEnable === 0){
    //   return {       
    //     "reader_id":readerID,
    //     "leonReaderMACAddress":values.leonReaderMACAddress,
    //     "name":values.name,
    //     "latitude":values.latitude,
    //     "longitude":values.longitude, 
    //     "address":values.address,
    //     "description": values.description ,
    //     "enable": readerEnable,
    //     "updateTime": date 
    //   }
    // }
    if(type === 'insert')
      return {       
        "leonReaderName":values.leonReaderName,
        "leonReaderMACAddress":values.leonReaderMACAddress,
        "name":values.name,
        "latitude": parseFloat(values.latitude),
        "longitude": parseFloat(values.longitude), 
        "address":values.address,
        "description": values.description ,
        "enable": readerEnable,
        "updateTime": date 
      }
    return {       
      "reader_id":readerID,
      "leonReaderName":values.leonReaderName,
      "leonReaderMACAddress":values.leonReaderMACAddress,
      "name":values.name,
      "latitude":parseFloat(values.latitude),
      "longitude":parseFloat(values.longitude), 
      "address":values.address,
      "description": values.description ,
      "enable": readerEnable,
      "updateTime": date 
    }
    
    // // console.log(readerJson);
    // return jsonList[0];
  }
  // 舊reader資料的JSON
  const createReaderReacordJson = (values,date) => {   
    const readerRecord = [];
    if(type === 'update')
      readerRecord.push({       
        "reader_id":readerID,
        "leonReaderName":leonReaderName,
        "leonReaderMACAddress":leonReaderMACAddress,
        "name":name,
        "latitude":parseFloat(latitude),
        "longitude":parseFloat(longitude), 
        "address":address,
        "description": description ,
        "updateTime": updateTime ,
        "enable": enable
      }) 
    
    if(type === 'insert')
      readerRecord.push({     
        "leonReaderName":values.leonReaderName,
        "leonReaderMACAddress":values.leonReaderMACAddress,
        "name":values.name,
        "latitude":parseFloat(values.latitude),
        "longitude":parseFloat(values.longitude), 
        "address":values.address,
        "description": values.description ,
        "enable": readerEnable,
        "updateTime": date 
      })
    else
      readerRecord.push({     
        "reader_id":readerID,
        "leonReaderName":values.leonReaderName,
        "leonReaderMACAddress":values.leonReaderMACAddress,
        "name":values.name,
        "latitude":parseFloat(values.latitude),
        "longitude":parseFloat(values.longitude), 
        "address":values.address,
        "description": values.description ,
        "enable": readerEnable,
        "updateTime": date 
      })
    // if(readerEnable === 0){
    //   readerRecord.push({       
    //     "description": values.description ,
    //     "enable": readerEnable,
    //     "updateTime": date 
    //   })
    // }
    // else{
    //   readerRecord.push({       
    //     "description": values.description ,
    //     "enable": readerEnable,
    //     "updateTime": date 
    //   }) 
    // }
    
    // // console.log(oldReaderJson);
    return readerRecord;
  }
  

  // 更新資料
  const updateReader = async (userOperationRecordJson,readerJson,recordJson) => {
    // console.log(values)
    await axios
        .post(`https://${ipconfig.apiIP}/api/v1/${type}/readerInfo`, 
        {  
          "token" :users.token,
          "userOperationRecordJson": userOperationRecordJson , 
          "readerJson":readerJson , 
          "recordJson":recordJson 
        },
        {
          header:{
            // Authorization: users.token,
            "Content-Type": "application/json",
          }
        })
        .then(async (response) => {            
          dispatch({type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode});    
            // eslint-disable-next-line eqeqeq
            if (response.data.resultCode == "success") {              
              dispatch(setAlertMessage({ "open": true, "severity": "success" , "content": "成功"}));
              dispatch(fetchReaderList({ "userName": users.userName, "token": users.token }));
              setValue(0)
                // // console.log("Success");    
                // setSnackbarStateSuccess(true);         
            }
        })
        .catch((error) => {
            // handle error
            // console.log(error);
        })
        .then(() => {
            // always executed
        });
  };


  const handleChangeReaderEnable = (event) => {
    // 選停用reader  
    setReaderEnable(parseInt(event.target.value,10));
  };
  
  const handleClose = () => {
    setSnackbarStateFailed( false );
    setSnackbarStateSuccess(  false );
  };

  return (
    
    <div>
    <FormikProvider value={formik}>
    <div>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        
      <Stack spacing={3}>
        
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={readerEnable}
            onChange={handleChangeReaderEnable}
          >
            <FormControlLabel value={1} control={<Radio />} label="啟用" />
            <FormControlLabel value={0} control={<Radio />} label="停用" />
          </RadioGroup>          

          {
            (() => {
              const TextFieldlist = []
              for(let i=0;i< textFieldLabel.length;i+=1){                
                TextFieldlist.push(                     
                  <TTextField
                    name={textFieldProps[i]}
                    // type={}
                    label={textFieldLabel[i]} 
                    getFieldProps={getFieldProps(textFieldProps[i])}
                    error={Boolean(touched[textFieldProps[i]] && errors[textFieldProps[i]])}
                    helperText={touched[textFieldProps[i]] && errors[textFieldProps[i]]}                  
                  />
                )
              }
              return TextFieldlist
            })()
          }

        </Stack>


        <Stack spacing={3} style={{'position': 'relative'}} sx={{ mt: 3 }}>
          <LoadingButton style={{'position': 'absolute', 'right': 0,}}
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
            確認送出
          </LoadingButton>
        </Stack>
      </Form>      
      </div>
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message="輸入資料有誤，請重新輸入"
          key={vertical + horizontal}
        />
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open1}
          onClose={handleClose}
          message="修改完成"
          key1={vertical + horizontal}
        />
      </div>
    </FormikProvider></div>
  );
}


// function TextFieldCcomponents(props) {
//   return (

//     <TextField
//       fullWidth
//       autoComplete={props.name}
//       type="text"
//       label={props.label}
//       {...props.getFieldProps}
//       error={props.error}
//       helperText={props.helperText}

//     // {...getFieldProps(props.name)}
//     // error={Boolean(touched.address && errors.address)}
//     // helperText={touched.address && errors.address}
//   />

//   )
// }