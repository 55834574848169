import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Snackbar from '@mui/material/Snackbar';

import { useDispatch, useSelector } from "react-redux";
import SelectMenuItem from '../SelectMenuItem'
import MultipleCheckboxes from '../MultipleCheckboxes';
import { fetchDriverList, setAlertMessage } from "../../actions";
import { getOperationEvent, setFormikObjectRequired, setFormikObjectInitialValues } from "./index"
import TTextField from "../TTextField"
import * as ipconfig from "../../config/ipconfig";
// ----------------------------------------------------------------------


export default function DriverForm(props) {


  const { driverID, teamID, identification, name, gender, age, phone, email, specialMark, specialMarkInfoID, description, updateTime, enable, type } = props;
  const { setValue } = props;

  // 表單資料來源
  // const textFieldLabel = ["姓名","性別","年齡","身份證","電話","email","特殊註記","描述"];
  // const textFieldProps = ["name","gender","age","identification","phone","email","specialMark","description"];  
  // const textFieldValue = [name,gender,age,identification,phone,email,specialMark , description];
  // const textFieldValuerequired = ['請輸入姓名',
  //                                 '請輸入性別',
  //                                 '請輸入年齡',
  //                                 '請輸入身份證',
  //                                 '請輸入電話',
  //                                 '',
  //                                 '',
  //                                 ''];

  // 表單資料來源(無身分證表單)
  const textFieldLabel = ["姓名", "性別", "年齡", "電話", "email", "描述"];
  const textFieldProps = ["name", "gender", "age", "phone", "email", "description"];
  const textFieldValue = [name, gender, age, phone, email, description];
  const textFieldValuerequired = ['請輸入姓名',
    '',
    '',
    '',
    '',
    ''];


  const navigate = useNavigate();
  const [open, setSnackbarStateFailed] = useState(false);
  const [open1, setSnackbarStateSuccess] = useState(false);

  const [nackbarVHState, setnackbarVHState] = useState({
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal } = nackbarVHState;

  // 表單不輸入內容相關之訊初始化
  const [formikRequired, setFormikRequired] = useState({});
  const LoginSchema = Yup.object().shape(formikRequired);

  // 表單內容初始化
  const [formikInitialValues, setFormikInitialValues] = useState({});
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => onSubmit(values, actions)
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const { users } = usersStore;
  const teamsStore = useSelector((state) => state.teamsInfo);
  const { teams } = teamsStore;
  const driversStore = useSelector((state) => state.driverManage);
  const { drivers } = driversStore;
  const specialmarkInfoStore = useSelector((state) => state.specialmarkInfo);
  const { specialmarkInfos } = specialmarkInfoStore;

  const [teamList, setTeamList] = useState([]);
  const [teamSelect, setTeamSelect] = useState(0);
  const [driverEnable, setDriverEnable] = useState(0);
  const [oldTeamName, setOldTeamName] = useState(0);
  const teamNameSelect = useRef('');
  const eventContext = useRef(null);
  const labelTeamName = "選擇綁定車隊"
  const [specialOptions, setspecialOptions] = useState([]);
  const [specialSelectID, setspecialSelectID] = useState([]);
  const [specialSelectContent, setspecialSelectContent] = useState('');


  const [initialState, setInitialState] = useState('first');

  /* ******************************
            initialState
   *********** 資料清除 ***********
        first   第一次進入   
        success 成功        
   ********* 送出前的資料 *********
        error   輸入資料有誤
  ****************************** */
  useEffect(() => {
    localStorage.setItem('mode', '1');
    if (initialState === 'first' || initialState === 'success') {
      setTeamSelect(users.team_id);
      if (type === 'insert')
        setDriverEnable(1)
      else
        setDriverEnable(enable)
      // 設定不輸入會顯示紅字錯誤
      const objRequired = setFormikObjectRequired(textFieldProps, textFieldValuerequired);
      setFormikRequired(objRequired);

      // 設定表單內容的初始值
      const objInitialValues = setFormikObjectInitialValues(textFieldProps, textFieldValue)
      setFormikInitialValues(objInitialValues);

      for (let i = 0; i < textFieldProps.length; i += 1)
        setFieldValue(textFieldProps[i], textFieldValue[i]);
    }
  }, [props])

  useEffect(() => {
    setDriverEnable(enable);
    getTeamList();
    getSpecialOptions();
  }, [])



  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false)
    const today = new Date().toISOString();
    getTeamNameSelect();
    const driverJson = createDriverJson(values, today);
    const driverRecord = createDriverReacordJson(today);
    const userOperationRecordJson = createUserOperationRecordJson();

    const recordJson = {
      "action": type,
      "DriverRecord": driverRecord,
    };
    // console.log(userOperationRecordJson);
    // console.log(driverJson);
    // console.log(recordJson);   
    // console.log();   

    // // console.log(eventContext.current );   

    if (eventContext.current !== '' && eventContext.current !== null) {
      updateDriver(userOperationRecordJson, driverJson, recordJson);
    }
    // if(eventContext.current !== null)
    //   updateDriver(userOperationRecordJson,driverJson,recordJson);

  }

  /* ***************************************
      操作紀錄-事件描述
      1.啟用tag或停用tag (停用也順勢解綁車輛)
      2.更改Tag所屬車隊
      3.身份證
      4.駕駛姓名
      5.email
      6.電話
      7.特別註記
      8.描述
  *************************************** */
  const getUserOperationEvent = (values) => {

    // const eventContextList = ["駕駛所屬車隊","身份證","駕駛姓名","性別","年齡","email","電話","特別註記","描述"];
    // const objectNameList = [teamSelect , values.identification , values.name ,values.gender,values.age, values.email , values.phone , values.specialMark , values.description ];
    // const oldValuesList = [teamID,identification,name,gender,age,email,phone,specialMark,description];

    // 無身分證
    const eventContextList = ["駕駛所屬車隊", "駕駛姓名", "性別", "年齡", "email", "電話", "特別註記", "描述"];
    const objectNameList = [teamSelect, values.name, values.gender, values.age, values.email, values.phone, specialSelectContent, values.description];
    const oldValuesList = [teamID, name, gender, age, email, phone, specialMark, description];

    const eventContextListOfInsert = ["駕駛資訊", "性別", "年齡", "電話", "email", "特別註記", "描述"];
    const objectNameListOfInsert = [values.name, values.gender, values.age, values.phone, values.email, specialSelectContent, values.description];
    const oldValuesListOfInsert = [name, gender, age, phone, email, specialMark, description]

    eventContext.current = getOperationEvent(driverEnable, enable, type, eventContextList, objectNameList, oldValuesList, eventContextListOfInsert, objectNameListOfInsert, oldValuesListOfInsert);


  }
  // 操作紀錄的JSON
  const createUserOperationRecordJson = () => {
    const userOperationRecordJson = {
      "action": type,
      "type": "Driver",
      "description": eventContext.current,
    };
    // // console.log(userOperationRecordJson);
    return userOperationRecordJson;
  }
  // 最新driver資料的JSON
  const createDriverJson = (values, date) => {
    getUserOperationEvent(values);
    // // console.log(eventContext);
    const jsonList = [];
    // 選擇停用(要解除綁定)
    // Select

    const age0 = (values.age === null || values.age === undefined) ? '' : values.age;
    const age1 = !Number.isNaN(age0) ? age0 : parseInt(age0, 10);

    const jsonObj = {
      "driver_id": driverID,
      "team_id": teamSelect,
      // "identification":values.identification, 
      "name": values.name,
      "gender": values.gender,
      "age": age1,
      "email": values.email,
      "phone": values.phone,
      "specialMark": specialSelectContent,
      "specialMark_info_id": specialSelectID,
      "description": values.description,
      "enable": driverEnable,
      "updateTime": date
    }

    if (type === 'insert')
      delete jsonObj.driver_id
    if (age1 === '')
      delete jsonObj.age

    return jsonObj

  }
  // 舊driver資料的JSON
  const createDriverReacordJson = (date) => {
    const driverRecord = [];

    const age00 = (age === null || age === undefined) ? '' : age;
    const age11 = !Number.isNaN(age00) ? age00 : parseInt(age00, 10);

    const age0 = (values.age === null || values.age === undefined) ? '' : values.age;
    const age1 = !Number.isNaN(age0) ? age0 : parseInt(age0, 10);


    const oldJsonObj = {
      "driver_id": driverID,
      "team_id": teamID,
      "team_name": oldTeamName,
      // "identification":identification, 
      "gender": gender,
      "age": age11,
      "name": name,
      "email": email,
      "phone": phone,
      "specialMark": specialMark,
      "description": description,
      "enable": enable,
      "updateTime": updateTime
    }
    if (age11 === '')
      delete oldJsonObj.age
    if (updateTime !== '')
      oldJsonObj.updateTime = new Date(updateTime).toISOString()


    if (type === 'update')
      driverRecord.push(oldJsonObj)

    const jsonObj = createDriverJson(values, date);
    jsonObj.team_name = teamNameSelect.current;

    driverRecord.push(jsonObj)
    return driverRecord;



  }

  const getSpecialOptions = () => {
    const list = [];
    for (let i = 0; i < specialmarkInfos.length; i += 1) {
      if (specialmarkInfos[i].type === 'driver') {
        list.push(specialmarkInfos[i]);
      }
    }
    setspecialOptions(list);
  }
  const getTeamNameSelect = () => {
    for (let i = 0; i < teamList.length; i += 1) {
      if (teamList[i].id === teamSelect) {
        teamNameSelect.current = teamList[i].description;
        break;
      }
    }
  }

  const getTeamList = () => {
    // console.log(teams);
    const list = []
    for (let i = 0; i < teams.length; i += 1) {
      list.push({ "id": teams[i].id, "description": teams[i].name });
      if (teams[i].id === teamID)
        setOldTeamName(teams[i].name);
    }
    setTeamList(list);

    for (let i = 0; i < drivers.length; i += 1) {
      if (drivers[i].id === driverID) {
        setTeamSelect(drivers[i].team_id);
        break;
      }
    }
  };



  // 更新資料
  const updateDriver = async (userOperationRecordJson, driverJson, recordJson) => {
    // console.log(values)
    await axios
      .post(`https://${ipconfig.apiIP}/api/v1/${type}/driverInfo`,
        {
          "token": users.token,
          "userOperationRecordJson": userOperationRecordJson,
          "driverJson": driverJson,
          "recordJson": recordJson
        },
        {
          header: {
            Authorization: users.token,
            "Content-Type": "application/json",
          }
        })
      .then(async (response) => {
        dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
        // eslint-disable-next-line eqeqeq
        if (response.data.resultCode == "success") {
          dispatch(setAlertMessage({ "open": true, "severity": "success", "content": "成功" }));
          setValue(0)
          // // console.log("Success");    
          // setSnackbarStateSuccess(true);         
          dispatch(fetchDriverList({ "userName": users.userName, "token": users.token }));
          localStorage.setItem('mode', '');
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      })
      .then(() => {
        // always executed
      });
  };


  const handleChangeDriverEnable = (event) => {
    // 選停用driver  
    setDriverEnable(parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    setSnackbarStateFailed(false);
    setSnackbarStateSuccess(false);
  };

  return (

    <div>
      <FormikProvider value={formik}>
        <div>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

            <Stack spacing={3}>

              {
                (() => {
                  if (users.permission === "admin") {
                    return (<SelectMenuItem Option={teamList} select={teamSelect} setselect={setTeamSelect} labelName={labelTeamName} />);
                  }
                })()
              }
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={driverEnable}
                onChange={handleChangeDriverEnable}
              >
                <FormControlLabel value={1} control={<Radio />} label="啟用" />
                <FormControlLabel value={0} control={<Radio />} label="停用" />
              </RadioGroup>
              {/* <TextField
            fullWidth
            autoComplete="name"
            type="text"
            label="駕駛名稱"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            fullWidth
            autoComplete="identification"
            type="text"
            label="身份證"
            {...getFieldProps('identification')}
            error={Boolean(touched.identification && errors.identification)}
            helperText={touched.identification && errors.identification}
          />
          <TextField
            fullWidth
            autoComplete="phone"
            // type="text"
            label="手機"
            {...getFieldProps('phone')}
            type="number"
            InputProps={{ inputProps: { min: 0, max: 10 } }}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />
          <TextField
            fullWidth
            autoComplete="email"
            type="text"
            label="email"
            {...getFieldProps('email')}
          />

          <TextField
            fullWidth
            autoComplete="specialMark"
            type="text"
            label="特殊註記"
            {...getFieldProps('specialMark')}
          />

          <TextField 
            fullWidth
            autoComplete="description"
            type="text"
            label="描述"
            {...getFieldProps('description')}
          /> */}



              {
                (() => {
                  const TextFieldlist = []
                  for (let i = 0; i < textFieldLabel.length; i += 1) {
                    TextFieldlist.push(
                      <TTextField
                        name={textFieldProps[i]}
                        // type={}
                        label={textFieldLabel[i]}
                        getFieldProps={getFieldProps(textFieldProps[i])}
                        error={Boolean(touched[textFieldProps[i]] && errors[textFieldProps[i]])}
                        helperText={touched[textFieldProps[i]] && errors[textFieldProps[i]]}
                      />
                    )
                  }
                  return TextFieldlist
                })()
              }

              <MultipleCheckboxes Option={specialOptions} initSelect={specialMarkInfoID}
                selectID={specialSelectID} setselectID={setspecialSelectID}
                selectContent={specialSelectContent} setselectContent={setspecialSelectContent}
                label="請選擇特殊註記" />
            </Stack>

            <Stack spacing={3} style={{ 'position': 'relative' }} sx={{ mt: 3 }}>
              <LoadingButton style={{ 'position': 'absolute', 'right': 0, }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                確認送出
              </LoadingButton>
            </Stack>
          </Form>
        </div>
        <div>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="輸入資料有誤，請重新輸入"
            key={vertical + horizontal}
          />
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open1}
            onClose={handleClose}
            message="修改完成"
            key1={vertical + horizontal}
          />
        </div>
      </FormikProvider></div>
  );
}
