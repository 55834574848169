import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment, { months } from 'moment';
import PropTypes from 'prop-types';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

import SelectMenuItem from '../SelectMenuItem'
import TrafficAnalyzeChart from './TrafficAnalyzeChart copy'

import CompareAnalyzeChart from './CompareAnalyzeChart'

import Styles from '../../css/compareChart.module.css'
import * as config from '../../config/config'

export default function TrafficAnalyzeOfConversionRates(props) {
    const { sessionlist, sessionselect, setsessionselect } = props
    const { unitAmountSelect, setunitAmountSelect, unitAmountList } = props
    const { sessionselect1, setsessionselect1 } = props
    const { readerSectionlist } = props
    const { dayDate, setdayDate } = props
    const tagNam = 'isTruck';
    const tagNam1 = 'total';

    const chartTitle = React.useRef();
    const sqlText = React.useRef();
    const chartUnit = React.useRef();

    // const [dayDate, setdayDate] = React.useState('2022/5/19');
    const [date1, setdate1] = React.useState('');
    const [date2, setdate2] = React.useState('');
    const [dayDateStart, setdayDateStart] = React.useState('');
    const [dayDateEnd, setdayDateEnd] = React.useState('');
    const [optionSelect, setoptionSelect] = React.useState(0);
    const [optionSelect1, setoptionSelect1] = React.useState(0);
    const [optionReaderSelect, setoptionReaderSelect] = React.useState(0);
    const [optionReaderSelect1, setoptionReaderSelect1] = React.useState(0);
    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);
    const { loading } = progressStore;
    const [first, setfirst] = React.useState(true);

    const [year, setyear] = React.useState('');
    const [weeklist, setweeklist] = useState([]);
    const [weekSelect, setweekSelect] = useState(0);

    useEffect(() => {
        setyear(moment().format('YYYY'));
    }, []);

    const monthlist = [
        { "id": 0, "description": "不選擇月份" },
        { "id": 1, "description": "1" },
        { "id": 2, "description": "2" },
        { "id": 3, "description": "3" },
        { "id": 4, "description": "4" },
        { "id": 5, "description": "5" },
        { "id": 6, "description": "6" },
        { "id": 7, "description": "7" },
        { "id": 8, "description": "8" },
        { "id": 9, "description": "9" },
        { "id": 10, "description": "10" },
        { "id": 11, "description": "11" },
        { "id": 12, "description": "12" },
    ]
    const [monthSelect, setmonthSelect] = useState(0);
    const [daySelect, setdaySelect] = useState(0);
    const [daylist, setdaylist] = useState([{ "id": 0, "description": "不選擇日" }]);

    useEffect(() => {
        // console.log(1234)
        const y1 = year
        // 選擇全年度
        if (monthSelect !== 0) {
            const yymm = `${y1}/${monthSelect}`;
            const dd = moment(`${yymm}`, "YYYY-MM").daysInMonth()
            const list = [];
            list.push({ "id": 0, "description": "不選擇日" })
            for (let i = 0; i < dd; i += 1) {
                list.push({ "id": i + 1, "description": `${i + 1}` })
            }
            setdaylist(list)
        }
        else {
            setdaylist([{ "id": 0, "description": "不選擇日" }])
        }

    }, [monthSelect]);

    useEffect(() => {
        let utc01;
        let utc02;
        const y1 = year
        // 選擇全年度
        if (y1 !== '') {
            if (daySelect === 0) {
                if (monthSelect === 0) {
                    utc01 = new Date(`${y1}/01/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
                    utc02 = new Date(`${y1}/12/31 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
                }
                // 選擇某年某月
                else {
                    const yymm = `${y1}/${monthSelect}`;
                    const dd = moment(`${yymm}`, "YYYY-MM").daysInMonth()
                    utc01 = new Date(`${yymm}/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
                    utc02 = new Date(`${moment(`${yymm}`).format('YYYY/MM')}/${dd} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
                }
                // console.log(utc01)
                // console.log(utc02)

                setdate1(utc01)
                setdate2(utc02)
            }
            // 選擇某年某月
            else {
                const yymmdd = `${y1}/${monthSelect}/${daySelect}`;
                utc01 = new Date(`${yymmdd} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
                utc02 = new Date(`${yymmdd} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
            }
            // console.log(utc01)
            // console.log(utc02)
            setdate1(utc01)
            setdate2(utc02)
        }
    }, [daySelect]);

    useEffect(() => {
        let utc01;
        let utc02;
        const y1 = year
        if (y1 !== '') {
            // 選擇全年度
            if (monthSelect === 0) {
                utc01 = new Date(`${y1}/01/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
                utc02 = new Date(`${y1}/12/31 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
                // utc01 = new Date(`${y1}/01/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
                // utc02 = new Date(`${y1}/12/31 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
            }
            // 選擇某年某月
            else {
                const yymm = `${y1}/${monthSelect}`;
                const dd = moment(`${yymm}`, "YYYY-MM").daysInMonth()
                utc01 = new Date(`${yymm}/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
                utc02 = new Date(`${moment(`${yymm}`).format('YYYY/MM')}/${dd} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
            }
            // console.log(utc01)
            // console.log(utc02)

            if (first) {
                // console.log(utc01)
                // console.log(utc02)
                chartTitle.current = '車流統計(單位:輛/月)'
                sqlText.current = 'month ORDER BY month'
                chartUnit.current = 'month'
                setdate1(utc01)
                setdate2(utc02)
                setdayDateStart(utc01)
                setdayDateEnd(utc02)
                setoptionSelect(sessionselect)
                setoptionSelect1(sessionselect1)
                setoptionReaderSelect(readerSectionlistselect)
                setoptionReaderSelect1(readerSectionlistselect1)
                dispatch({ type: "START" });
                setfirst(false)
            } else {
                setdate1(utc01)
                setdate2(utc02)
            }
        }

    }, [year, monthSelect]);

    const searchOnClick = () => {
        if ((date1 !== dayDateStart && date2 !== dayDateEnd) || (sessionselect !== optionSelect)
            || (sessionselect1 !== optionSelect1) || (optionReaderSelect !== readerSectionlistselect)
            || (optionReaderSelect1 !== readerSectionlistselect1)) {
            if (daySelect !== 0) {
                chartTitle.current = '車流統計(單位:輛/時)'
                sqlText.current = 'DateHHMM ORDER BY DateHHMM'
                chartUnit.current = 'Hours'
            }
            else if (monthSelect !== 0) {
                chartTitle.current = '車流統計(單位:輛/日)'
                sqlText.current = 'Date ORDER BY Date'
                chartUnit.current = 'day'
            }
            else {
                chartTitle.current = '車流統計(單位:輛/月)'
                sqlText.current = 'month ORDER BY month'
                chartUnit.current = 'month'
            }
            setdayDateStart(date1)
            setdayDateEnd(date2)
            setoptionSelect(sessionselect)
            setoptionSelect1(sessionselect1)
            setoptionReaderSelect(readerSectionlistselect)
            setoptionReaderSelect1(readerSectionlistselect1)
            dispatch({ type: "START" });

        }
    }


    const [readerlist, setreaderlist] = useState([]);
    const [readerlist1, setreaderlist1] = useState([]);
    const [readerSectionlistselect, setreaderSectionlistselect] = useState(0);
    const [readerSectionlistselect1, setreaderSectionlistselect1] = useState(0);

    useEffect(() => {
        if (readerSectionlist.length > 0 && sessionlist.length > 0) {
            const list = [];
            let index = 1;
            const data = readerSectionlist;
            list.push({ "id": 0, "description": '全部', "description1": '全部' })
            for (let i = 0; i < data.length; i += 1) {
                if (sessionlist[sessionselect].description === data[i].reader_group_info_name) {
                    list.push({
                        "id": index,
                        "description": `${data[i].reader_info_name} - ${data[i].reader_info_leonReaderName}`,
                        "description1": data[i].reader_info_leonReaderName
                    })
                    index += 1
                }
            }
            setreaderlist(list);
        }
    }, [readerSectionlist, sessionlist, sessionselect]);

    useEffect(() => {
        if (readerSectionlist.length > 0 && sessionlist.length > 0) {
            const list = [];
            let index = 1;
            const data = readerSectionlist;
            list.push({ "id": 0, "description": '全部', "description1": '全部' })
            for (let i = 0; i < data.length; i += 1) {
                if (sessionlist[sessionselect1].description === data[i].reader_group_info_name) {
                    list.push({
                        "id": index,
                        "description": `${data[i].reader_info_name} - ${data[i].reader_info_leonReaderName}`,
                        "description1": data[i].reader_info_leonReaderName
                    })
                    index += 1
                }
            }
            setreaderlist1(list);
        }
    }, [readerSectionlist, sessionlist, sessionselect1]);

    return (
        <Grid>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} >
                <Typography variant="h4" gutterBottom  >
                    <Stack direction="row" spacing={2}
                        sx={{
                            '& label ': {
                                fontSize: config.selectLabelFontSize,
                                top: config.selectLabelTop
                            },
                            '& input': {
                                fontSize: config.selectItemFontSize,
                            },
                        }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}  >
                            <DatePicker
                                label="選擇年份"
                                views={['year']}
                                value={year}
                                onChange={(newValue) => {
                                    setyear(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <SelectMenuItem Option={monthlist} select={monthSelect} setselect={setmonthSelect} labelName="選擇月份" width={200} />
                        <SelectMenuItem Option={daylist} select={daySelect} setselect={setdaySelect} labelName="選擇日" width={200} />
                        {/* <SelectMenuItem Option={unitAmountList} select={unitAmountSelect} setselect={setunitAmountSelect} labelName="選擇計數單位" hidden="1" width={200} /> */}

                    </Stack>
                </Typography>
                <Button variant="contained" size="large" item xs={2}
                    disabled={loading}
                    onClick={searchOnClick} >查詢
                </Button>
                {loading && (
                    <CircularProgress
                        size={150}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            "z-index": 5000
                        }}
                    />
                )}
            </Stack>
            <Stack direction="row" spacing={2} mb={3} className={Styles.box} title="資料1" >
                <Stack style={{ marginLeft: 24, marginTop: 30, marginBottom: 20, }} >
                    <SelectMenuItem Option={sessionlist} select={sessionselect} setselect={setsessionselect} labelName="選擇路段" width={400} />
                </Stack>
                <Stack style={{ marginLeft: 24, marginTop: 30, marginBottom: 20, }} >
                    <SelectMenuItem Option={readerlist} select={readerSectionlistselect} setselect={setreaderSectionlistselect} labelName="選擇智慧感知讀取器" width={450} />
                </Stack>
            </Stack>
            <Stack direction="row" spacing={2} mb={3} className={Styles.box} title="資料2" >
                <Stack style={{ marginLeft: 24, marginTop: 30, marginBottom: 20, }} >
                    <SelectMenuItem Option={sessionlist} select={sessionselect1} setselect={setsessionselect1} labelName="選擇路段" width={400} />
                </Stack>
                <Stack style={{ marginLeft: 24, marginTop: 30, marginBottom: 20, }} >
                    <SelectMenuItem Option={readerlist1} select={readerSectionlistselect1} setselect={setreaderSectionlistselect1} labelName="選擇智慧感知讀取器" width={450} />
                </Stack>
            </Stack>


            <Grid item xs={12} mb={2}  >
                <CompareAnalyzeChart
                    chartTitle={chartTitle.current} tagNam={tagNam}
                    sessionlist={sessionlist} select={optionSelect} select1={optionSelect1}
                    readerlist={readerlist} readerlist1={readerlist1} readerselect={optionReaderSelect} readerselect1={optionReaderSelect1}
                    startDate={dayDateStart} endDate={dayDateEnd} sqlText={sqlText.current} chartUnit={chartUnit.current} time="morning"
                    unitAmountValue={unitAmountList[unitAmountSelect].value}
                    hour1={0} hour2={23}
                />
            </Grid>
        </Grid>

    );
}
