import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';

// material
import {
    Grid, Button, Container, Stack, Typography,
    Tabs,
    Tab,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';


import AAppWebsiteVisits from '../components/AAppWebsiteVisits';
import AAppConversionRates from '../components/AAppConversionRates';

import {
    AppTasks,
    AppNewUsers,
    AppItemOrders,
    AppNewsUpdate,
    AppWeeklySales,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppCurrentSubject,
    AppConversionRates
} from '../components/_dashboard/app';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import SelectMenuItem from '../components/SelectMenuItem'
import ToggleButtons from '../components/ToggleButton'
import TrafficAnalyzeOfConversionRates from '../components/trafficAnalyz2/TrafficAnalyzeOfConversionRates'
import TrafficAnalyze2 from '../components/trafficAnalyz2/TrafficAnalyze2-1'
import TrafficAnalyzeChart from '../components/trafficAnalyz2/TrafficAnalyzeChart copy'
import DayChart from '../components/trafficAnalyz2/DayChart'
import WeekChart from '../components/trafficAnalyz2/WeekChart'
import MonthChart from '../components/trafficAnalyz2/MonthChart'
import QuarterChart from '../components/trafficAnalyz2/QuarterChart'
import YearChart from '../components/trafficAnalyz2/YearChart'
import TrafficChart from '../components/trafficAnalyz2/TrafficChart'
import CompareChart from '../components/trafficAnalyz2/CompareChart'

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";

// 定義切換頁
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        'style': { 'font-size': '24px' },
    };
}
export default function TestMap() {
    const [trafficFlowHourData, settrafficFlowHourData] = useState([]);
    const [sessionlist, setsessionlist] = useState([]);
    const [readerSectionlist, setreaderSectionlist] = useState([]);
    const [sessionTrafficFlow, setsessionTrafficFlow] = useState([]);
    const [sessionDataOfTrafficFlow, setsessionDataOfTrafficFlow] = useState([]);
    const [readerGroupInfoNameMap, setreaderGroupInfoNameMap] = useState();
    // const [roadArealist, setroadArealist] = useState([]);
    // const [roadAreaselect, setroadAreaselect] = useState([]);
    // 1.isLeon 
    // 2.notLeon 
    // 3.isTruck 
    // 4.total 
    // const [tagNam, settagNam] = useState('isTruck');
    // const [tagNam1, settagNam1] = useState('total');
    const [chartTitle1, setchartTitle1] = useState('大型車輛車流計數前十排名');
    const [chartTitle2, setchartTitle2] = useState('總車流計數前十排名');

    const [tabValue, settabValue] = React.useState(0);
    const [year, setyear] = React.useState('2022');
    const [value, setValue] = React.useState('2022/4/1');
    const [value1, setValue1] = React.useState('2022/5/1');

    const [dayDateStart, setdayDateStart] = React.useState('2022/5/1');
    const [dayDateEnd, setdayDateEnd] = React.useState('2022/5/1');

    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;

    const [dayDate, setdayDate] = React.useState('');

    const [unitAmountSelect, setunitAmountSelect] = useState(0);
    const unitAmountList = [
        { id: 0, description: '車輛計數', value: 1, },
        { id: 1, description: 'PCU', value: 2, },
    ]

    useEffect(() => {
        const date = moment(new Date()).format('YYYY/MM/DD')
        setdayDate(date)
    }, []);



    useEffect(() => {
        getSessionDataOfTrafficFlow();
        getReaderSection();
    }, [users]);


    // 取得路段資料
    const getSessionDataOfTrafficFlow = async () => {
        await axios
            .get(`https://${ipconfig.apiIP}/api/v1/trafficFlow/getSessionInfoTrafficFlow`)
            .then(async (response) => {
                if (response.data.resultCode === "Success") {
                    // // console.dir(response.data.rows)        
                    // clearObjData();
                    const list = [];
                    const list1 = [];
                    const data = response.data.rows;
                    const rGroupInfoNameMap = new Map();
                    let abc = ''
                    for (let i = 0; i < data.length; i += 1) {
                        const obj = {};
                        obj.id = i;
                        obj.sessionID = data[i].id;
                        obj.readerGroupInfoName = data[i].reader_group_info_name;
                        rGroupInfoNameMap.set(data[i].reader_group_info_name, i)

                        obj.total = 0;
                        obj.isLeon = 0;
                        obj.notLeon = 0;
                        obj.isTruckNotLeon = 0;
                        obj.isTruck = 0;
                        list.push(obj);
                        list1.push({ "id": i, "description": response.data.rows[i].reader_group_info_name })
                        abc += `reader_group_info_name = '${response.data.rows[i].reader_group_info_name}' or `
                    }
                    
                    // console.log(abc)
                    setreaderGroupInfoNameMap(rGroupInfoNameMap);
                    setsessionDataOfTrafficFlow(list);
                    setsessionlist(list1)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                // // console.dir('leonReaderlist api then')
            });
        // setOpen(true);
    };

    // 取得路段資料
    const getReaderSection = async () => {
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/readerSection/list`, { "token": users.token })
            .then(async (response) => {
                if (response.data.resultCode === "Success") {
                    setreaderSectionlist(response.data.rows)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                // // console.dir('leonReaderlist api then')
            });
        // setOpen(true);
    };

    const [startDate, setstartDate] = React.useState('2022/4/1');
    const [endDate, setendDate] = React.useState('2022/5/1');
    const timer = React.useRef();

    const [loading, setLoading] = React.useState(false);

    // tab切換
    const handleChange = (event, newValue) => {
        settabValue(newValue);        
        // if(newValue === 0)
        //   setchartSelect(5)
        // if(newValue === 1)
        //   setchartSelect(4)
        // if(newValue === 2)
        //   setchartSelect(3)
    };

    const [sessionselect, setsessionselect] = useState(0);
    const [sessionselect1, setsessionselect1] = useState(1);
    const tagNam = 'isTruck';
    const tagNam1 = 'total';

    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl">
                {/* <SelectMenuItem  Option={sessionlist} select={sessionselect} setselect={setsessionselect} labelName="選擇路段"/> */}

                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="日車流統計" {...a11yProps(0)} />
                    <Tab label="週車流統計" {...a11yProps(1)} />
                    <Tab label="月車流統計" {...a11yProps(2)} />
                    <Tab label="季車流統計" {...a11yProps(3)} />
                    <Tab label="年車流統計" {...a11yProps(4)} />
                    <Tab label="車流排名" {...a11yProps(5)} />
                    <Tab label="比較分析" {...a11yProps(6)} />
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                    <DayChart
                        dayDate={dayDate} setdayDate={setdayDate}
                        sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                        readerSectionlist={readerSectionlist}
                        unitAmountSelect={unitAmountSelect} setunitAmountSelect={setunitAmountSelect} unitAmountList={unitAmountList}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <WeekChart
                        dayDate={dayDate} setdayDate={setdayDate}
                        sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                        readerSectionlist={readerSectionlist}
                        unitAmountSelect={unitAmountSelect} setunitAmountSelect={setunitAmountSelect} unitAmountList={unitAmountList}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <MonthChart
                        dayDate={dayDate} setdayDate={setdayDate}
                        sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                        readerSectionlist={readerSectionlist}
                        unitAmountSelect={unitAmountSelect} setunitAmountSelect={setunitAmountSelect} unitAmountList={unitAmountList}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <QuarterChart
                        dayDate={dayDate} setdayDate={setdayDate}
                        sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                        readerSectionlist={readerSectionlist}
                        unitAmountSelect={unitAmountSelect} setunitAmountSelect={setunitAmountSelect} unitAmountList={unitAmountList}
                    />
                </TabPanel>

                <TabPanel value={tabValue} index={4}>
                    <YearChart
                        dayDate={dayDate} setdayDate={setdayDate}
                        sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                        readerSectionlist={readerSectionlist}
                        unitAmountSelect={unitAmountSelect} setunitAmountSelect={setunitAmountSelect} unitAmountList={unitAmountList}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                    <TrafficChart
                        dayDate={dayDate} setdayDate={setdayDate}
                        unitAmountSelect={unitAmountSelect} setunitAmountSelect={setunitAmountSelect} unitAmountList={unitAmountList}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={6}>
                    <CompareChart
                        dayDate={dayDate} setdayDate={setdayDate}
                        sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                        sessionselect1={sessionselect1} setsessionselect1={setsessionselect1}
                        unitAmountSelect={unitAmountSelect} setunitAmountSelect={setunitAmountSelect} unitAmountList={unitAmountList}
                        readerSectionlist={readerSectionlist}
                    />
                </TabPanel>


            </Container>
        </Page>
    );
}
