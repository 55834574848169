import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AAppWebsiteVisits from '../components/AAppWebsiteVisits';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import ToggleButtons from '../components/ToggleButton'
import ReaderCountDataTable from './ReaderCountDataTable'

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'

import { convertGeoline } from '../utils/mapfun'
import * as ipconfig from "../config/ipconfig";
import * as config from "../config/config";


export default function TestMap() {
    const [truckLimitlist, settruckLimitlist] = useState([]);
    const [leonReaderlist, setleonReaderlist] = useState([]);
    const [truckLimitData, settruckLimitData] = useState([]);
    const [popupTableData, setpopupTableData] = useState([]);
    const [Geolinelist, setGeolinelist] = useState([]);

    const [mapcenter, setmapcenter] = useState([24, 121]);
    const [openmap, setopenmap] = useState(null);
    const [ToggleButtonselect, setToggleButtonselect] = useState(60);
    const ToggleButtonsOptions = [
        { value: 5, Name: '5分鐘' },
        { value: 15, Name: '15分鐘' },
        { value: 30, Name: '30分鐘' },
        { value: 60, Name: '60分鐘' },
    ]
    const flymap = (point) => {
        openmap.flyTo(point, 15)
    }
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;
    const dispatch = useDispatch();

    useEffect(() => {
        getMarkerData();
        getData(ToggleButtonselect);
    }, [users]);

    useEffect(() => {
        // // console.dir('leonReaderlist useEffect')
        if (openmap != null) {
            const bounds = [
                [25.112698, 121.311192], [25.080091, 121.24397499999999]
            ]
            for (let i = 0; i < leonReaderlist.length; i += 1) {
                if (leonReaderlist[i].latitude !== null || leonReaderlist[i].longitude !== null) {
                    if (i === 0) {
                        bounds[0][0] = leonReaderlist[i].latitude
                        bounds[1][0] = leonReaderlist[i].latitude
                        bounds[0][1] = leonReaderlist[i].longitude
                        bounds[1][1] = leonReaderlist[i].longitude
                    } else if (leonReaderlist[i].latitude !== 0) {
                        if (leonReaderlist[i].latitude > bounds[0][0])
                            bounds[0][0] = leonReaderlist[i].latitude
                        else if (leonReaderlist[i].latitude < bounds[1][0])
                            bounds[1][0] = leonReaderlist[i].latitude
                        if (leonReaderlist[i].longitude > bounds[0][1])
                            bounds[0][1] = leonReaderlist[i].longitude
                        else if (leonReaderlist[i].longitude < bounds[1][1])
                            bounds[1][1] = leonReaderlist[i].longitude
                    }
                }
            }
            bounds[0][0] += 0.001
            bounds[0][1] = 121.33183542633057
            bounds[1][0] -= 0.001
            // bounds[1][1] -= 0.001
            openmap.fitBounds(bounds)
        }

    }, [leonReaderlist]);

    useEffect(() => {
        if (leonReaderlist.length !== 0) {
            getData(ToggleButtonselect);
            let list = []
            const array = leonReaderlist.filter(a => a.linejson !== null && a.linejson !== '')
            for (let i = 0; i < array.length; i += 1) {
                list = list.concat(convertGeoline(JSON.parse(array[i].linejson), 'red'))
            }
            setGeolinelist(list)

        }
    }, [leonReaderlist, ToggleButtonselect]);


    // 取得路段資料
    const getMarkerData = async () => {
        await axios
            .get(`https://${ipconfig.apiIP}/api/v1/truckLimit/getTruckLimitOfReaderInfo`)
            .then(async (response) => {
                // eslint-disable-next-line eqeqeq
                if (response.data.resultCode === "Success") {
                    setleonReaderlist(response.data.rows)
                    // console.log(response.data.rows);
                    const data = response.data.rows
                    // const [truckLimitlist, settruckLimitlist] = useState([]);
                    const list = []
                    for (let i = 0; i < data.length; i += 1) {
                        if (data[i].limitPathName !== null && !list.includes(data[i].limitPathName))
                            list.push(data[i].limitPathName)
                    }
                    const list1 = []
                    const list2 = []
                    for (let i = 0; i < list.length; i += 1) {
                        let readerlist = "";
                        let limitContent = "";
                        for (let j = 0; j < data.length; j += 1) {
                            if (list[i] === data[j].limitPathName) {
                                readerlist += readerlist === "" ? data[j].name : `,${data[j].name}`;
                                limitContent = data[j].limitDescription;
                            }
                        }
                        list1.push(readerlist)
                        list2.push(limitContent)
                    }
                    // console.log(list)
                    // console.log(list1)
                    // console.log(list2)
                    const list3 = []
                    for (let i = 0; i < list.length; i += 1) {
                        const obj = {};
                        obj.id = i + 1
                        obj.rownum = i + 1
                        obj.pathName = list[i]
                        obj.name = list1[i]
                        obj.description = list2[i]
                        list3.push(obj)
                    }
                    // console.log(list3)
                    settruckLimitlist(list3)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                // // console.dir('leonReaderlist api then')
            });
        // setOpen(true);
    };

    // 取德標格資料
    const getData = async (minutes) => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/truckLimit/getTruckLimit`, { "token": users.token, "minutes": minutes, "utc": difference })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                if (response.data.resultCode === "Success") {
                    const list = [];
                    let index = 1;

                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        // let flag = false
                        // for (let k = 0; k < config.taglist.length; k += 1) {
                        //     if (data.EPC === config.taglist[k])
                        //         flag = true
                        // }
                        // /* eslint-disable */
                        // if (flag)
                        //     continue;
                        obj = data;
                        // const limitCondition = JSON.parse(data.condition);         
                        // const limitTypeTag = data.limitType;       
                        // if(limitTypeTag === "tonnage" || limitTypeTag === "height"){
                        //     const limitConditionValue = limitCondition[limitTypeTag];
                        //     if(data[limitTypeTag] > limitConditionValue){
                        //         obj = data;
                        //     }
                        // }
                        // else if(limitTypeTag === "truckType"){
                        //     const limitConditionValue = limitCondition[limitTypeTag];
                        //     if(data[limitTypeTag] === limitConditionValue)
                        //         obj = data;
                        // }
                        // else if(limitTypeTag === "time"){
                        //     const values = ["startTime","endTime","receiveTime2"];
                        //     // 任意假設一個日期
                        //     const startTime = new Date(`2022/1/1 ${limitCondition[values[0]]}`);
                        //     const endTime = new Date(`2022/1/1 ${limitCondition[values[1]]}`);
                        //     const receiveTime2 = new Date(`2022/1/1 ${data[values[2]]}`);
                        //     if(receiveTime2 >= startTime && receiveTime2 <= endTime)
                        //         obj = data;
                        // }
                        if (Object.keys(obj).length !== 0) {
                            obj.receiveTime = new Date(obj.localTime1).toLocaleString()
                            obj.rownum = index
                            obj.id = index
                            list.push(obj)
                            index += 1;
                        }
                    }
                    settruckLimitData(list)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
            });
        // setOpen(true);
    };


    // 圖標顯示內容
    const popupcontent = (row, textColor) => {
        const r = row
        return (
            <div>
                <div className={Tooltip.text_root}>

                    <div className='pb-4'>
                        <span className={Tooltip.text_name}>{row.address}</span>
                    </div>
                    <div className='pb-4'>
                        <span className={Tooltip.text_address}>智慧感知讀取器名稱 : {row.leonReaderName}</span>
                    </div>
                    <div className='pb-4'>
                        <span className={Tooltip.text_address}>設備編號 : {row.name}</span>
                    </div>
                    <div className='pb-4'>
                        <span className={Tooltip.text_address} style={{ color: 'red' }} >禁行事件 : {row.limitDescription}</span>
                    </div>
                    {/* <div className='pb-4'>
                        <span className={Tooltip.text_address}>禁行車輛車牌 : {row.licensePlateNumber}</span>
                    </div> */}
                    {/* <div className='pb-4'>
                        <span className={Tooltip.text_address}>時間 : {row.receiveTime}</span>
                    </div> */}

                    <button className={Tooltip.popup_button}
                        onClick={() => {
                            setPopupTableData(row.leonReaderMACAddress);
                            handleClickOpen();
                        }}
                    >查詢</button>
                </div>
            </div>

        )

    }

    const setPopupTableData = (leonReaderMACAddress) => {
        const list = [];
        let index = 1;
        for (let i = 0; i < truckLimitData.length; i += 1) {
            const data = truckLimitData[i];
            let obj = {};
            if (data.leonReaderMACAddress === leonReaderMACAddress) {
                obj = data;
                obj.rownum = index
                list.push(obj)
                index += 1;
            }
        }
        setpopupTableData(list)
    };



    const columns = [
        { field: "id", headerName: "id", width: 70, hideable: true },
        { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
        {
            field: "leonReaderName", headerName: "智慧感知讀取器名稱", flex: 0.25, sortable: true, headerAlign: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value === 'string')
                    return (params.value);
                const children = params.value.map((val) => (
                    <div >{val}</div>
                ));
                return (
                    <div style={{ fontSize: '20px' }}>
                        {children}
                    </div>
                );
            }
        },
        { field: "name", headerName: "設備編號", flex: 0.13, sortable: true, headerAlign: 'center', align: 'center', },
        { field: "description", headerName: "禁行事件", flex: 0.2, width: 100, headerAlign: 'center', align: 'center', },
        { field: "licensePlateNumber", headerName: "禁行車輛車牌",flex: 0.15, width: 120, headerAlign: 'center', align: 'center', },
        { field: "receiveTime", headerName: "時間", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center', },
    ];

    const columnsLimit = [
        { field: "id", headerName: "id", width: 70, hideable: true },
        { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
        {
            field: "pathName", headerName: "管制路段名稱", flex: 0.3, sortable: true, headerAlign: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value === 'string')
                    return (params.value);
                const children = params.value.map((val) => (
                    <div >{val}</div>
                ));
                return (
                    <div style={{ fontSize: '20px' }}>
                        {children}
                    </div>
                );
            }
        },
        {
            field: "name", headerName: "管制路段設備編號", flex: 0.3, sortable: true, headerAlign: 'center', align: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value !== 'string')
                    return (params.value);

                const content = params.value.split(',');
                const contentTempList = [];
                let contentTemp = '';
                for (let i = 0; i < content.length; i += 1) {
                    if (i === 0 && content.length === 1) {
                        contentTemp += `${content[i]}`;
                        contentTempList.push(contentTemp);
                    }
                    else if (i === 0) {
                        contentTemp += `${content[i]}`;
                    }
                    else if (i % 5 === 0) {
                        contentTempList.push(contentTemp);
                        contentTemp = '';
                    }
                    else if (i === content.length - 1) {
                        contentTempList.push(contentTemp);
                    }
                    else if (i % 5 === 1) {
                        contentTemp += `${content[i]}`;
                    }
                    else {
                        contentTemp += `,${content[i]}`;
                    }
                }
                // content.map((val, index) => {
                //     if (index === 0) {
                //         contentTemp += `${val}`;
                //     }
                //     else if (index % 5 === 0) {
                //         contentTempList.push(contentTemp);
                //         contentTemp = '';
                //     }
                //     else if (index === content.length - 1) {
                //         contentTempList.push(contentTemp);
                //     }
                //     else {
                //         contentTemp += `,${val}`;
                //     }
                // })

                const children = contentTempList.map((val, index) => (
                    <div >{val}</div>
                ));

                return (
                    <div style={{ fontSize: '20px' }}>
                        {children}
                    </div>
                );
                // return (
                //     <div style={{ fontSize: '20px' }}>
                //         {contentTempList}
                //     </div>
                // );
            }
        },
        {
            field: "description", headerName: "禁行內容", flex: 0.3, width: 100, headerAlign: 'center', align: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value !== 'string')
                    return (params.value);

                const content = params.value.split(',');
                const children = content.map((val, index) => (
                    <div >{index + 1}.{val}</div>
                ));
                return (
                    <div style={{ fontSize: '20px' }}>
                        {children}
                    </div>
                );
            }
        },
    ];

    const TableOnClick = (params, event) => {
        if (!event.ctrlKey) {
            event.defaultMuiPrevented = true;
            // alert(JSON.stringify(params));
            openmap.flyTo([params.row.latitude, params.row.longitude], 23);

        }
    }
    // const searchOnClick = () => {
    //     getData(ToggleButtonselect);
    // }

    const [open, setOpen] = React.useState(false);
    const [openLimitList, setopenLimitList] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setopenLimitList(false);
    };

    return (
        <Grid >
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    <Stack direction="row" spacing={2}>
                        {/* <MultipleSelectCheckmarks Option={roadArealist} select={roadAreaselect} setselect={setroadAreaselect} /> */}
                        <ToggleButtons Options={ToggleButtonsOptions} setSelect={setToggleButtonselect} Select={ToggleButtonselect} />
                    </Stack>

                </Typography>
                {/* <Button variant="contained" size="large" onClick={searchOnClick} >查詢</Button> */}
            </Stack>


            <Grid container spacing={3} >
                <Grid item xs={12} style={{ position: 'relative' }}>
                    <BMap Geoarray={Geolinelist} MarkerArray={leonReaderlist} popupcontent={popupcontent} mapcenter={mapcenter} setopenmap={setopenmap} openmap={openmap} />

                    <div className={Styles.readerCountTips_red}>紅</div>
                    <div className={Styles.readerCountTips_red1}>管制路段</div>

                    <Button className={Styles.limitList} onClick={
                        () => (setopenLimitList(true))
                    }>
                        管制路段列表
                    </Button>

                    {/* <div className={Styles.readerCountTips_red}>車流多</div>
                        <div className={Styles.readerCountTips_orange}>車流中等</div>
                        <div className={Styles.readerCountTips_green}>車流少</div>
                        <div className={Styles.readerCountTips_grey}>未更新</div> */}

                </Grid>

                <Grid item xs={12} style={{ height: "100%" }}>

                    <Box
                        sx={{
                            height: '100%',
                            width: 1,
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontSize: '22px'
                            },
                            '& .MuiDataGrid-cellContent': {
                                fontSize: '20px'
                            }
                        }}
                    >
                        <BDataGrid data={truckLimitData} columns={columns}
                            TableOnClick={TableOnClick}
                        />
                    </Box>
                </Grid>

                <Grid >
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <Grid style={{ width: '80vw' }}>
                                <Box
                                    sx={{
                                        height: '100%',
                                        width: 1,
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                            fontSize: '22px'
                                        },
                                        '& .MuiDataGrid-cellContent': {
                                            fontSize: '20px'
                                        }
                                    }}
                                >
                                    <BDataGrid data={popupTableData} columns={columns}
                                    />
                                </Box>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>關閉</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openLimitList}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <Grid style={{ width: '80vw' }}>
                                <Box
                                    sx={{
                                        height: '100%',
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                            fontSize: '22px'
                                        },
                                        '& .MuiDataGrid-cellContent': {
                                            fontSize: '20px'
                                        }
                                    }}
                                >
                                    <BDataGrid data={truckLimitlist} columns={columnsLimit}
                                    />
                                </Box>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>關閉</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>

            </Grid>
        </Grid>
    );
}
