import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';

// material
import { Grid, Button, Container, Stack, Typography, TextField, Tabs, Tab, } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AAppWebsiteVisits from '../components/AAppWebsiteVisits';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import MapTruckLimit from '../components/MapTruckLimit'
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import ToggleButtons from '../components/ToggleButton'
import ReaderCountDataTable from './ReaderCountDataTable'
import TruckLimitComponent from './TruckLimitAnalyzeComponent'

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";
import * as config from "../config/config";


// 定義切換頁
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        'style': { 'font-size': '24px' },
    };
}

export default function TestMap() {
    const [tabValue, settabValue] = React.useState(0);
    const [leonReaderlist, setleonReaderlist] = useState([]);
    const [truckLimitData, settruckLimitData] = useState([]);
    const [popupTableData, setpopupTableData] = useState([]);

    const [mapcenter, setmapcenter] = useState([24, 121]);
    const [openmap, setopenmap] = useState(null);
    const flymap = (point) => {
        openmap.flyTo(point, 15)
    }
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;

    const [dayDateStart, setdayDateStart] = React.useState('');
    const [dayDateEnd, setdayDateEnd] = React.useState('');

    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);
    const { loading } = progressStore;
    const [first, setfirst] = React.useState(true);

    useEffect(() => {
        const date = moment(new Date()).format('YYYY/MM/DD')
        setdayDateStart(date)
        setdayDateEnd(date)
    }, []);

    useEffect(() => {
        if (first && dayDateStart !== '' && dayDateEnd !== '') {
            setfirst(false)
            dispatch({ type: "START" });
            getMarkerData();
        }
    }, [users, dayDateStart, dayDateEnd]);


    useEffect(() => {
        if (leonReaderlist.length !== 0) {
            getData();
        }
    }, [leonReaderlist]);


    // 取得路段資料
    const getMarkerData = async () => {
        await axios
            .get(`https://${ipconfig.apiIP}/api/v1/truckLimit/getTruckLimitOfReaderInfo`)
            .then(async (response) => {
                // eslint-disable-next-line eqeqeq
                if (response.data.resultCode === "Success") {
                    setleonReaderlist(response.data.rows)
                }
                else {
                    dispatch({ type: "END" });
                }
            })
            .catch((error) => {
                // handle error
                dispatch({ type: "END" });
                // console.log(error);
            })
            .then(() => {
                // always executed
                // // console.dir('leonReaderlist api then')
            });
        // setOpen(true);
    };
    // 取德標格資料
    const getData = async () => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));

        const utc01 = new Date(`${moment(dayDateStart).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
        const utc02 = new Date(`${moment(dayDateEnd).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
        const date1 = utc01
        const date2 = utc02
        // const difference = 8
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/truckLimit/getTruckLimitAnalyze`, { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                // console.log(response.data);
                if (response.data.resultCode === "Success") {
                    const list = [];
                    let index = 1;
                    // console.log(response.data.rows);

                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        // let flag = false
                        // for (let k = 0; k < config.taglist.length; k += 1) {
                        //     if (data.EPC === config.taglist[k])
                        //         flag = true
                        // }
                        // /* eslint-disable */
                        // if (flag)
                        //     continue;
                        obj = data;
                        // const limitCondition = JSON.parse(data.condition);         
                        // const limitTypeTag = data.limitType;       
                        // if(limitTypeTag === "tonnage" || limitTypeTag === "height"){
                        //     const limitConditionValue = limitCondition[limitTypeTag];
                        //     if(data[limitTypeTag] > limitConditionValue){
                        //         obj = data;
                        //     }
                        // }
                        // else if(limitTypeTag === "truckType"){
                        //     const limitConditionValue = limitCondition[limitTypeTag];
                        //     if(data[limitTypeTag] === limitConditionValue)
                        //         obj = data;
                        // }
                        // else if(limitTypeTag === "time"){
                        //     const values = ["startTime","endTime","receiveTime2"];
                        //     // 任意假設一個日期
                        //     const startTime = new Date(`2022/1/1 ${limitCondition[values[0]]}`);
                        //     const endTime = new Date(`2022/1/1 ${limitCondition[values[1]]}`);
                        //     const receiveTime2 = new Date(`2022/1/1 ${data[values[2]]}`);
                        //     if(receiveTime2 >= startTime && receiveTime2 <= endTime)
                        //         obj = data;
                        // }
                        if (Object.keys(obj).length !== 0) {
                            obj.receiveTime = new Date(obj.localTime1).toLocaleString()
                            obj.rownum = index
                            obj.id = index
                            list.push(obj)
                            index += 1;
                        }
                    }
                    settruckLimitData(list)
                }
                else {
                    dispatch({ type: "END" });
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                dispatch({ type: "END" });
            });
        // setOpen(true);
    };


    const columns = [
        { field: "id", headerName: "id", width: 70, hideable: true },
        { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
        {
            field: "leonReaderName", headerName: "智慧感知讀取器名稱", flex: 0.4, sortable: true, headerAlign: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value === 'string')
                    return (params.value);
                const children = params.value.map((val) => (
                    <div >{val}</div>
                ));
                return (
                    <div style={{ fontSize: config.tableContentFontSize }}>
                        {children}
                    </div>
                );
            }
        },
        { field: "name", headerName: "設備編號", flex: 0.15, sortable: true, headerAlign: 'center', align: 'center', },
        { field: "description", headerName: "禁行事件", flex: 0.3, width: 100, headerAlign: 'center', align: 'center', },
        { field: "licensePlateNumber", headerName: "禁行車輛車牌", flex: 0.2, width: 120, headerAlign: 'center', align: 'center', },
        { field: "receiveTime", headerName: "時間", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center', },
    ];


    const searchOnClick = () => {
        dispatch({ type: "START" });
        getData();
    }

    const rowsPerPageOptions = [10, 20, 50, 100]

    // tab切換
    const handleChange = (event, newValue) => {
        settabValue(newValue);
    };
    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl">

                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="禁行事件" {...a11yProps(0)} />
                    <Tab label="管制路段分析" {...a11yProps(1)} />
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                    <Stack style={{ "margin": '10px' }} >
                        <MapTruckLimit leonReaderlist={leonReaderlist} />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3} mt={5}>

                        <Typography variant="h4" gutterBottom>
                            <Stack direction="row" spacing={2}
                                sx={{
                                    '& label ': {
                                        fontSize: config.selectLabelFontSize,
                                        top: config.selectLabelTop
                                    },
                                    '& input': {
                                        fontSize: config.selectItemFontSize,
                                    },
                                }}>

                                <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                    <DatePicker
                                        label="起始時間"
                                        value={dayDateStart}
                                        onChange={(newValue) => {
                                            setdayDateStart(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                    <DatePicker
                                        label="結束時間"
                                        value={dayDateEnd}
                                        onChange={(newValue) => {
                                            setdayDateEnd(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Stack>

                        </Typography>

                        <Button variant="contained" size="large" item xs={2}
                            disabled={loading}
                            onClick={searchOnClick} >查詢</Button>
                        {loading && (
                            <CircularProgress
                                size={150}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    "z-index": 50000,
                                }}
                            />
                        )}

                    </Stack>


                    <Grid spacing={3} >

                        <Grid item xs={12} style={{ height: "100%" }}
                            sx={{
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontSize: config.tableTitleFontSize
                                },
                                '& .MuiDataGrid-cellContent': {
                                    fontSize: config.tableContentFontSize
                                }
                            }}>
                            <BDataGrid data={truckLimitData} columns={columns}
                                rowsPerPageOptions={rowsPerPageOptions} export="true"
                            />
                        </Grid>


                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <TruckLimitComponent
                        dayDateStart={dayDateStart} setdayDateStart={setdayDateStart}
                        dayDateEnd={dayDateEnd} setdayDateEnd={setdayDateEnd}
                    />
                </TabPanel>
            </Container>
        </Page>
    );
}
