import React, { useState, useEffect , useRef } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
// material
import {
  Button,
  Grid,
  TextField,
  Tabs,
  Tab,
  Typography,
  Stack,
  Card,
  Table,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Snackbar, List, ListItem, ListItemAvatar, ListItemText, DialogTitle, Dialog
} from '@mui/material';
import L from 'leaflet';
import MuiAlert from '@mui/material/Alert';
import BDataGrid from '../components/BDataGrid'
import Page from '../components/Page';
import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
// eslint-disable-next-line import/named
import { CarListToolbar, abnormalCarListToolbar } from '../components/_dashboard/user';
import { SimpleDialog } from '../components/dialog';

import { fetchDriverList } from "../actions";
import TTable from '../components/TTable'

import { LeonTagRecord, TruckRecord, DriverRecord, ReaderRecord } from '../components/record/index';



import { DriverForm } from '../components/editData/index';
import * as ipconfig from "../config/ipconfig";

// import { LeonTagInsertForm } from '../components/insertData/index';


const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));


// 定義切換頁
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,    
    'style': { 'font-size': '24px' },
  };
}

export default function EnhancedTable() {
  

const columns = [
  { field: "id", headerName: "更新時間", width: 70, hideable: true },
  { field: "MACAddredd", headerName: "MACAddredd", width: 70, hideable: true },
  { field: "EPC", headerName: "EPC", width: 100, sortable: true, },
  { field: "TID", headerName: "TID", width: 70 },
  { field: "date", headerName: "更新時間", width: 200 },
];
const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    fillter: false,
    label: '流水號'
  },
  {
    id: 'MACAddredd',
    numeric: false,
    disablePadding: false,
    fillter: true,
    label: 'Reader編號'
  },
  {
    id: 'EPC',
    numeric: false,
    disablePadding: false,
    fillter: true,
    label: 'EPC'
  },
  {
    id: 'TID',
    numeric: false,
    disablePadding: false,
    fillter: true,
    label: 'TID'
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    fillter: false,
    label: '更新時間',
    type: 'format',
    fn: (data) => {
      const time = new Date(data).toLocaleString()
      return (
        time
      )
    }

  }
];
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:
  const Region = "彰化縣";
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('status');
  const [selected, setSelected] = React.useState([]);
  // 車機清單
  const [Vehiclelist, setVehiclelist] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterName, setFilterName] = React.useState('');
  const [reason, setreason] = React.useState(' ');
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [marker, setMarker] = useState(null);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const driversStore = useSelector((state) => state.driverManage);
  const { users } = usersStore;
  const { drivers, error } = driversStore;
  // const { loading, drivers, error } = driversStore;    
  // eslint-disable-next-line prefer-const
  let intervalId = null;
  let map;
  const v = React.useRef(0);

  useEffect(() => {
    
    // Vehicle_management();
    // 定時讀取車機清單
    // eslint-disable-next-line no-const-assign
    intervalId = setInterval(() => {
      Vehicle_management();
    }, 2000);
    return function cleanup() {
      clearInterval(intervalId);
    };
  }, []);

  // 讀取車機清單
  // eslint-disable-next-line camelcase
  const Vehicle_management = async () => {

    await axios
        .get(`https://${ipconfig.apiIP}/api/v1/getLastData`)
        .then(async (response) => {                
            // eslint-disable-next-line eqeqeq
            const tempData = response.data;
            const tempVehiclelist = Vehiclelist;
            let flag = false;
            if(!Vehiclelist.length)
              flag = true;
            else if(Vehiclelist[Vehiclelist.length-1].date !== tempData.date)
              flag = true;
            if(flag){
              v.current =  parseInt(v.current,10) +  1;
              const lastData = {        
                "id":v.current,
                "MACAddredd":tempData.MACAddredd,
                "date":tempData.date,
                "tagType":tempData.tagType,
                "EPC":tempData.EPC,
                "TID":tempData.TID
              }          
              tempVehiclelist.push(lastData);
              setVehiclelist([]);
              setVehiclelist(tempVehiclelist);
              // console.log(Vehiclelist);
            }
        })
        .catch((error) => {
            // handle error
            // console.log(error);
        })
        .then(() => {
            // always executed
        });
        
  };

  // 關閉彈跳釋出
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



  // tab切換
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [driverID, setDriverID] = useState(0);
  const [teamID, setTeamID] = useState(0);
  const [identification, setIdentification] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [specialMark, setSpecialMark] = useState();
  const [description, setDescription] = useState();
  const [updateTime, setUpdateTime] = useState();
  const [enable, setEnable] = useState();
  const [type, setType] = useState();
             
  const setInfo = (driverID , teamID , identification , name , phone , email , specialMark , description , updateTime , enable) => {    
    setDriverID(driverID);
    setTeamID(teamID);
    setIdentification(identification);
    setName(name);
    setPhone(phone);
    setEmail(email);
    setSpecialMark(specialMark);
    setDescription(description);
    setUpdateTime(updateTime);
    setEnable(enable);    
    setType('edit');
    setValue(1);
  };


  const handleDialogClose = (value) => {
    setDialogOpen(false);
    setType(value);
    if (value !== '')
      setValue(1);
  };


  // Avoid a layout jump when reaching the last page with empty rows.

  return (
    <Page title="智慧管理識別平台">
      <Container maxWidth="xl">
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {reason}
          </Alert>
        </Snackbar>

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="操作紀錄" {...a11yProps(0)} />
          <Tab label="詳細資訊" {...a11yProps(1)} />
          <Button variant="contained" size="large"  style={{ marginLeft: "auto" , marginRight: "24px" }}
          // onClick={insetLeonTagForm} 
          >新增</Button>
        </Tabs>

        <TabPanel value={value} index={0}>


          {/* <BDataGrid data={Vehiclelist} columns={columns}  /> */}
          <TTable Vehiclelist={Vehiclelist} headCells={headCells} />




        </TabPanel>

        <TabPanel value={value} index={1}>
          {              
            (() => {

                // if(type === "insert"){
                //   return (
                //     <LeonTagInsertForm 
                //       driverID={driverID}
                //       identification={ identification }
                //       name={ name }
                //       email={email }
                //       phone={ phone}      
                //       specialMark={ specialMark}     
                //       description={ description}      
                //       enable={enable}     
                //     />
                //   );
                // }
                if(type === "edit"){
                  return (
                    <DriverForm 
                      driverID={driverID}
                      teamID={teamID}
                      identification={ identification }
                      name={ name }
                      email={email }
                      phone={ phone}      
                      specialMark={ specialMark}     
                      description={ description}     
                      updateTime={updateTime}
                      enable={enable}     
                      />
                  );
                }
            })()
          }
        </TabPanel>
        <SimpleDialog
          open={dialogOpen}
          onClose={handleDialogClose}
        />
      </Container>
    </Page>
  );
}
