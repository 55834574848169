
import { set, sub, formatDistanceToNow } from 'date-fns';
const initialState = {
  loading: false,
};
export default function progressStatus (state = initialState, action)  {
  switch (action.type) {
    case 'START': {
      return {
        ...state,
        loading: true
      };
    }
    case 'END': {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
};

