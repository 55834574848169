import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// material
import {
  Button,
  Grid,
  TextField,
  Tabs,
  Tab,
  Typography,
  Stack,
  Card,
  Table,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Snackbar, List, ListItem, ListItemAvatar, ListItemText, DialogTitle, Dialog
} from '@mui/material';
import L from 'leaflet';
import MuiAlert from '@mui/material/Alert';
import Page from '../components/Page';
import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
// eslint-disable-next-line import/named
import { CarListToolbar, abnormalCarListToolbar } from '../components/_dashboard/user';
import { SimpleDialog } from '../components/dialog';

import { fetchOverviewList } from "../actions";
import TTable from '../components/TTable'
import * as config from "../config/config";

import { LeonTagRecord, TruckRecord, DriverRecord, ReaderRecord } from '../components/record/index';
const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));


// 定義切換頁
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};




const headCells = [
  {
    // 排序id要跟資料欄一樣名稱
    id: 'rownum',
    numeric: false,
    disablePadding: true,
    fillter: false,
    label: '項目'
  },
  {
    // 排序id要跟資料欄一樣名稱
    id: 'leonTagProductNumber',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: '識別裝置標號'
  },
  {
    id: 'licensePlateNumber',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: '車牌'
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: '駕駛姓名',
    type: 'format',
    fn: (data) => {
      if (data !== null && data !== '') {
        const nameString = data
        let name = "";
        for (let i = 0; i < nameString.length; i += 1)
          if (i === 0)
            name += `${nameString[i]}`
          else if (i === nameString.length - 1 && i !== 1)
            name += `${nameString[i]}`
          else
            name += `*`
        return (
          name
        )
      }
    }
  },
  // {
  //   id: 'name',
  //   numeric: false,
  //   disablePadding: true,
  //   fillter: true,
  //   label: '駕駛姓名'
  // },
  {
    id: 'truckType',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: '車輛種類'
  },
  {
    id: 'tonnage',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: '車輛噸數'
  },
  {
    id: 'specialMark',
    numeric: false,
    disablePadding: false,
    fillter: true,
    label: '特殊註記',
    type: 'format',
    fn: (data) => {
      // console.log('test')
      if (data !== '') {
        return (
          <Label
            variant="ghost"
            color="error"            
          >
            {data}
          </Label>
        )
      }
      return (
        data
      )
    }
  },
  {
    id: 'updateTime',
    numeric: false,
    disablePadding: false,
    fillter: false,
    label: '更新時間',
    type: 'format',
    fn: (data) => {
      const time = new Date(data).toLocaleString()
      return (
        time
      )
    }
  },
  // {
  //   type: 'render',
  //   fn: (row) => {
  //     const { licensePlateNumber } = row
  //     return (
  //       <Button
  //         color="inherit"
  //         variant="text"
  //         onClick={() => {
  //           // 儲存carid
  //           localStorage.setItem('carid', licensePlateNumber);
  //           localStorage.setItem('CAR_NO', licensePlateNumber);
  //           // 導到下個頁面
  //           window.location = '/dashboard/cartrack';
  //         }}
  //       >
  //         <Typography variant="subtitle2" noWrap>
  //           查詢xxx(未做)
  //         </Typography>
  //       </Button>
  //     )
  //   }
  // },
  // {
  //   type: 'render',
  //   fn: (row) => {
  //     const { updateTime } = row      
  //     return (        
  //         <Button variant="outlined" onClick={
  //           () => alert(JSON.stringify(updateTime))
  //         }>
  //           Open simple dialog
  //         </Button>        
  //     )
  //   }
  // }
];




function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    'style': { 'font-size': '24px' },
  };
}

export default function EnhancedTable() {
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:
  const Region = "彰化縣";
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('status');
  const [selected, setSelected] = React.useState([]);
  // 車機清單
  const [Vehiclelist, setVehiclelist] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterName, setFilterName] = React.useState('');
  const [reason, setreason] = React.useState(' ');
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [marker, setMarker] = useState(null);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const overviewsStore = useSelector((state) => state.overviewManage);
  const { users } = usersStore;
  const { overviews, error } = overviewsStore;
  // const { loading, overviews, error } = overviewsStore;    
  // eslint-disable-next-line prefer-const
  let intervalId = null;
  let map;
  useEffect(() => {
    // console.log(1);
    // if (error === "FAILED")
    //   window.location.href = '/login';
    // if (users.resultCode !== "Success")
    //   window.location.href = '/login';
    if (overviews.length === 0) {
      Vehicle_management();
      setVehiclelist([]);
      setVehiclelist(overviews);
      // console.log(overviews);
    }
    else {
      setVehiclelist([]);
      setVehiclelist(overviews);
      // console.log(overviews);
    }
    // 定時讀取車機清單
    // eslint-disable-next-line no-const-assign
    // intervalId = setInterval(() => {
    //   Vehicle_management();
    // }, 50000);
    // 使用瀏覽器 API 更新文件標題 建立地圖
    const center = [24.050666, 120.483982];
    try {
      const container = L.DomUtil.get('mapid');
      if (container != null) {
        container._leaflet_id = null;
      }
      map = L.map('mapid').setView(center, 12);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        id: 'mapbox/streets-v11'
      }).addTo(map);
      // eslint-disable-next-line no-empty
    } catch (e) { }
    // componentWillUnmount
    // return function cleanup() {
    //   clearInterval(intervalId);
    // };
  }, [overviews, error]);

  // 讀取車機清單
  // eslint-disable-next-line camelcase
  const Vehicle_management = async () => {
    // const result = fetchTrucks({"username": users.username, "token": users.token});
    // console.log(users);
    // console.log(1);
    dispatch(fetchOverviewList({ "userName": users.userName, "token": users.token }));

    //   const ApiUrl = axios.create({
    //     baseURL: `https://${ipconfig.apiIP}/api/v1/truck`,
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Accept": "application/json",     

    //         'Access-Control-Allow-Origin' : '*',
    //         'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',   
    //     }
    //   });
    //   ApiUrl.post('/list', {"username": users.username, "token": users.token} )
    //   // ApiUrl.post('/list', {"username": localStorage.UserName, "token": localStorage.token} )
    //   .then((response) => {       
    //       // console.dir(typeof response.data);
    //       if (response.data.data !== []) {
    //           setVehiclelist([]);
    //           setVehiclelist(response.data.data);
    //           // console.log(response.data.data)
    //         }else
    //         {
    //           setVehiclelist([]);
    //         }
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //   })

  };

  // 關閉彈跳釋出
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



  // tab切換
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [leonTagID, setLeonTagID] = useState(0);
  const [driverID, setDriverID] = useState(0);
  const [truckID, setTruckID] = useState(0);
  const [truckDynamicID, setTruckDynamicID] = useState(0);
  const [type, setType] = useState();


  const handleDialogClickOpen = (leonTagID, driverID, truckID, truckDynamicID) => {
    setDialogOpen(true);
    setLeonTagID(leonTagID);
    setDriverID(driverID);
    setTruckID(truckID);
    setTruckDynamicID(truckDynamicID);
  };

  const handleDialogClose = (value) => {
    setDialogOpen(false);
    setType(value);
    if (value !== '')
      setValue(1);
  };


  // Avoid a layout jump when reaching the last page with empty rows.

  return (
    <Page title="智慧管理識別平台">
      <Container maxWidth="xl">
        {/* <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {reason}
          </Alert>
        </Snackbar>

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="總覽" {...a11yProps(0)} />
          <Tab label="詳細資訊" {...a11yProps(1)} />
        </Tabs> */}

        {/* <TabPanel value={value} index={0}> */}

        <Grid item xs={12}
          sx={{
            '& th': {
              fontSize: config.tableTitleFontSize
            },
            '& td': {
              fontSize: config.tableContentFontSize
            },            
            '& span': {
              fontSize: config.tableContentFontSize,
              height: config.tableSpanHeight
            }
          }}>
          <TTable Vehiclelist={Vehiclelist} headCells={headCells} />
        </Grid>
        {/* </TabPanel> */}

      </Container>
    </Page>
  );
}
