
import * as types from "../config/types";

const initialState = {
  loading: false,
  usersChangePassword: [],
  parse:null,
  resultStatus:false,
  error: null,
  errorText:null,
};

export default function userChangePassword (state = initialState, action)  {
  switch (action.type) {
    case types.GET_USERS_CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,   
        resultStatus:false,
        error: null,
        errorText:null
      };
    }
    case types.GET_USERS_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        resultStatus:true,
        usersChangePassword: action.payload.usersChangePassword,
        error: null,   
        errorText:''

      };
    }
    case types.GET_USERS_CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
        error: "FAILED",      
        resultStatus:false,  
        errorText:action.errorText
      };
    }
    default:
      return state;
  }
};

