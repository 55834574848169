import React, { useReducer, useEffect } from "react";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialState = { selectedOptions: [] };

function reducer(state, action) {
    switch (action.type) {
        case "SET_SELECTED_OPTIONS":
            return { selectedOptions: action.payload.options };

        case "REMOVE_OPTION":
            return {
                selectedOptions: state.selectedOptions.filter(
                    (option) => option.id !== action.payload.id
                )
            };
        default:
            throw new Error();
    }
}

function compare(a, b) {
    if (a.id < b.id) {
        return -1;
    }
    if (a.id > b.id) {
        return 1;
    }
    return 0;
}

export default function App(props) {
    const { Option, initSelect, label } = props
    const { specialSelectID, setselectID } = props
    const { selectContent, setselectContent } = props

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const list = [];
        let specialContent = '';
        if (!Array.isArray(initSelect))
            return
        for (let i = 0; i < Option.length; i += 1) {
            const isIncludes = initSelect.includes(Option[i].id)
            if (isIncludes) {
                const obj = Option[i];
                obj.selected = true
                list.push(obj)
                if (specialContent === '')
                    specialContent += obj.content
                else
                    specialContent += `,${obj.content}`
            }
        }
        dispatch({ type: "SET_SELECTED_OPTIONS", payload: { options: list } });
        setselectID(initSelect)
        setselectContent(specialContent)
    }, [Option]);

    const init = () => {
        const list = [];
        for (let i = 0; i < Option.length; i += 1) {
            list.push(Option[i])
        }
        dispatch({ type: "SET_SELECTED_OPTIONS", payload: { options: list } });
    };


    const handleChange = (event, values) => {
        const list = [];
        const idlist = [];
        const idlist1 = [];
        const repeatlist = [];
        let specialContent = '';
        for (let i = 0; i < values.length; i += 1) {
            // includes(obj) 無法正確辨識
            const notIncludes = !idlist1.includes(values[i].id)
            if (notIncludes) {
                idlist1.push(values[i].id);
            }
            else {
                // 紀錄重複過的id，之後要清除掉
                repeatlist.push(values[i].id)
            }
        }
        values.sort(compare)
        for (let i = 0; i < values.length; i += 1) {
            // 未重複的id
            const notIncludes = !repeatlist.includes(values[i].id)

            if (notIncludes) {
                const obj = values[i];
                obj.selected = true
                list.push(obj);
                idlist.push(obj.id);
                if (specialContent === '')
                    specialContent += obj.content
                else
                    specialContent += `,${obj.content}`
            }

        }
        dispatch({ type: "SET_SELECTED_OPTIONS", payload: { options: list } });
        setselectID(idlist)
        setselectContent(specialContent)
        // setselect(list)
        // console.log(list)
    };

    const removeOption = (id) => {
        dispatch({ type: "REMOVE_OPTION", payload: { id: id } });
    };

    return (
        <div>
            <Autocomplete
                multiple
                options={Option}
                disableCloseOnSelect
                getOptionLabel={(option) => option.content}
                value={state.selectedOptions}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={
                                (() => {
                                    for (let i = 0; i < state.selectedOptions.length; i += 1) {
                                        if (state.selectedOptions[i].id === option.id)
                                            return state.selectedOptions[i].selected
                                    }
                                    return false
                                })()
                            }
                        />
                        {option.content}
                    </li>
                )}
                style={{ width: '100%' }}
                onChange={handleChange}

                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label}
                        placeholder=""
                    />
                )}
            // renderInput={(params) => (
            //   <TextField
            //     {...params}
            //     variant="outlined"
            //     label={label}
            //     placeholder=""
            //   />
            // )}
            />
        </div>
    );
}