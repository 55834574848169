import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AAppWebsiteVisits from '../AAppWebsiteVisits';


export default function TrafficAnalyzeOfConversionRates(props) {
    const {sessionlist , sessionselect , tagNam ,chartTitle } = props    
    const {sessionTurningTrafficList } = props
    const {startDate , endDate } = props
    const { data ,  dataTime} = props
    const {chartTimeUnit ,chartUnit, time} = props

    const dispatch = useDispatch();
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;
    
    useEffect(() => {    
        if(sessionlist.length > 0 && startDate !== '' ){
            setTableData();            
            dispatch({type: "END"}); 
        }
    }, [data,dataTime,sessionlist]);
    
    
    // const [chartTitle, setchartTitle] = useState("");
    const [chartSubtitle, setchartSubtitle] = useState("");
    
    const [chartOptions, setchartOptions] = useState({});
    const [chartData, setchartData] = useState([]);

    const setTableData = async () => {
        // setreaderGroupInfoNameMap(rGroupInfoNameMap);
        // setsessionDataOfTrafficFlow(list);      
        // const list = sessionDataOfTrafficFlow;

        // // console.log(data)
        // const dataListTemp = [];
        // for (let i = 0; i < data.length; i += 1){
        //     if(sessionlist[select].description === data[i].reader_group_info_name){
        //         dataListTemp.push(data[i].Hours);
        //     }
        // }
        // const dataList = [...new Set(dataListTemp)]; 
        // 5 , 15 , 30 , 60
        const chartTimeUnitValue = chartTimeUnit
        const dataList = []
        const loopLength = 60 / chartTimeUnitValue ;
        const hourStart = time === 'morning' ? 7:17;
        const hourEnd = time === 'morning' ? 9:19;

        for (let i = hourStart; i < hourEnd; i += 1){
            for (let j = 0; j < loopLength; j += 1){
                    dataList.push(`${i}:${j*chartTimeUnitValue}`);
            }
        }

        setChartData(dataList);
        
    }  


    // 統計顯示相關
    const setChartData = (dataList) =>  {
        // A : Leon
        // B : notLeon
        // C : total

        // A : 本計畫之大型車輛
        // B : 大車(非力歐計畫的)
        // C : 非本計畫車(包含大車) 
        // D : 其他車輛(不包含大車) 
        // E : total = A + B + D 或 A + C
        const dataA = [];
        const dataB = [];
        const dataC = [];        
        const dataD = [];  
        const dataE = [];
        const dataAA = [];
        const dataBB = [];
        const dataCC = [];  
        const dataDD = [];  
        const dataEE = [];  

        // 先建立arraySize避免沒資料，會導致錯位
        for (let i = 0; i < dataList.length; i += 1){    
            dataA.push(0);
            dataB.push(0);
            dataC.push(0);
            dataD.push(0);
            dataE.push(0);
            dataAA[i] = [];
            dataBB[i] = [];
            dataCC[i] = [];
            dataDD[i] = [];
            dataEE[i] = [];
        }
        for (let i = 0; i < dataList.length; i += 1){            
            for (let j = 0; j < data.length; j += 1){
                if(sessionlist[sessionselect].description === data[j].pathName && dataList[i] === data[j][chartUnit]){
                    dataEE[i].push(data[j].speed)   
                }
            }
        }
        for (let i = 0; i < dataList.length; i += 1){            
            for (let j = 0; j < dataTime.length; j += 1){
                if(sessionlist[sessionselect].description === dataTime[j].pathName && dataList[i] === dataTime[j][chartUnit]){
                    dataDD[i].push(dataTime[j].travelTime)   
                }
            }
        }
        
        // // console.log(dataAA)
        // // console.log(dataBB)
        for (let i = 0; i < dataList.length; i += 1){    
            for (let j = 0; j < dataEE[i].length; j += 1){                
                dataE[i] += dataEE[i][j];
            }
            dataE[i] = dataEE[i].length > 0 ? parseInt(dataE[i]/dataEE[i].length,10):0;

            for (let j = 0; j < dataDD[i].length; j += 1){                
                dataD[i] += dataDD[i][j];
            }
            dataD[i] = dataDD[i].length > 0 ? parseFloat(dataD[i]/dataDD[i].length,10):0;
        }

        const data1 = [
            {
                type: 'line',
                name: '旅行速度',
                data: dataE,
                color: '#00AB55'
            },
            {
                type: 'line',
                name: '旅行時間',
                data: dataD,
                color: '#2D99FF'
            },
        ];
        setchartData(data1);
        setChartOption(dataList);
    }

    // 統計顯示相關
    const setChartOption = (chartLabels) =>  {        
        // const currentdate = new Date(); 
        // const datetime = `${currentdate.getMonth()+1}/${currentdate.getDate()}`
        // const subTitle = `統計日期 ${currentdate.getFullYear()}/${currentdate.getMonth()+1}/${currentdate.getDate()}`
        // setchartSubtitle(subTitle);
        const chartLabels1 = []
        const chartTimeUnitValue = chartTimeUnit
        // const dataList = []
        const loopLength = 60 / chartTimeUnitValue ;
        const hourStart = time === 'morning' ? 7:17;
        const hourEnd = time === 'morning' ? 10:20;

        for (let i = hourStart; i < hourEnd; i += 1){
            for (let j = 0; j < loopLength; j += 1){
                if(j*chartTimeUnitValue < 10)
                    chartLabels1.push(`${i}:0${j*chartTimeUnitValue}`);
                else
                    chartLabels1.push(`${i}:${j*chartTimeUnitValue}`);
            }
        }

        const chartOption = {
            chart: {
                offsetX: -20,
                toolbar: {
                  show: true,
                  offsetY: -50,
                  export: {
                      csv: {
                          filename: undefined,
                      },
                      png: {
                          filename: undefined,
                      }
                  }
                },                  
            },
            plotOptions: { bar: { columnWidth: '50%',barHeight: '100%', borderRadius: 4 } },
            fill: { type: ['solid','solid',] },
            
            labels: chartLabels1,
            xaxis: { type: 'category' ,       
                datetimeUTC: false,
            },
            yaxis: [{
                seriesName: '旅行速度',
                title: {                  
                  text: ["","時速(公里/小時)"],
                  rotate: 0,
                  style: {
                    fontSize: '16px',
                    fontWeight: 600,
                  }
                },
                labels: {
                  formatter: function(val) {
                    return val.toFixed(0)
                  }
                }
            },
            { 
                seriesName: '旅行時間',
                opposite: true,    
                title: {           
                    text: ["　　　　時間(分)"],
                    rotate: 0,
                    style: {
                    fontSize: '16px',
                    fontWeight: 600,
                    }
                },
                labels: {
                  formatter: function(val) {
                    return val.toFixed(3)
                  }
                }
            }],
            tooltip: {
                shared: true,
                intersect: false,                
                x: {
                    show: true,
                    formatter: function(x,opts) {
                        if (typeof x !== 'undefined') {
                            return `${opts.w.globals.categoryLabels[opts.dataPointIndex]}`
                        }
                        // return x;
                      }
                  },
                y: {
                  formatter: (y,opts) => {
                    if (typeof y !== 'undefined') {
                        if(opts.seriesIndex === 0)
                            return `${y.toFixed(0)} Km/h`;
                        return `${y.toFixed(3)} 分鐘`;
                    }
                    // return y;
                  }
                }
            }
        };
        setchartOptions(chartOption);
    }          
    // const columns = [
    //     { field: "id", headerName: "id", width: 70, hideable: true },
    //     { field: "readerGroupInfoName", headerName: "路段名稱(起)", flex: 0.6, width: 330, sortable: true, headerAlign: 'center',align: 'center',},
    //     // { field: "total", headerName:  "總共車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center', },
    //     // { field: "isLeon", headerName:  "本計畫車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center',},
    //     // { field: "notLeon", headerName:  "非本計畫車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center',},
    // ];

    // if(tagNam === 'total')
    //     columns.push({ field: "total", headerName:  "總車流數",  flex: 0.4,width: 200, sortable: true, headerAlign: 'center', align: 'center', })
    // else
    //     columns.push({ field: "isLeon", headerName:  "本計畫車輛之車流數",  flex: 0.4,width: 200, sortable: true, headerAlign: 'center', align: 'center', })

    return (
        
        <div>
            <AAppWebsiteVisits title={`${sessionlist[sessionselect].description} - ${chartTitle}`} subtitle="" chartOptions={chartOptions} data={chartData}/>                        
        </div>
        
    );
}
