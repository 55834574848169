import { combineReducers } from 'redux';
import userLogin from './userLogin';
import teamsInfo from './teamsInfo';
import truckManage from './truckManage';
import driverManage from './driverManage';
import readerManage from './readerManage';
import leonTagManage from './leonTagManage';
import cmsManage from './cmsManage';
import overviewManage from './overviewManage';
import userChangePassword from './userChangePassword';
import userOperationRecord from './userOperationRecord';
import detailRecord from "./detailRecord";
import alertMessage from "./alertMessage";
import userManage from "./userManage";
import notifications from "./notifications";
import notificationsReaderStstus from "./notificationsReaderStstus";
import progressStatus from "./progressStatus";
import serverIP from "./serverIP";
import specialmarkInfo from "./specialmarkInfo";


const rootReducer = combineReducers({
  userLogin:userLogin,
  teamsInfo:teamsInfo,
  truckManage:truckManage,
  driverManage:driverManage,
  readerManage:readerManage,
  cmsManage:cmsManage,
  leonTagManage:leonTagManage,
  overviewManage:overviewManage,
  userChangePassword:userChangePassword,
  userOperationRecord:userOperationRecord,
  detailRecord:detailRecord,
  alertMessage:alertMessage,
  userManage:userManage,
  notifications:notifications,
  notificationsReaderStstus:notificationsReaderStstus,
  progressStatus:progressStatus,
  serverIP:serverIP,
  specialmarkInfo:specialmarkInfo,
});

export default rootReducer;
