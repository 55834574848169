import faker from 'faker';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { formatDistance } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
// material
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';
// utils
import { mockImgCover } from '../../utils/mockImages';
//
import Scrollbar from '../Scrollbar';

// ----------------------------------------------------------------------

const NEWS = [...Array(10)].map((_, index) => {
  const setIndex = index + 1;
  return {
    title: faker.name.title(),
    description: faker.lorem.paragraphs(),
    image: mockImgCover(setIndex),
    postedAt: faker.date.soon()
  };
});

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.object.isRequired
};

function NewsItem({ news }) {
  const { image, name, description, createdAt } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box sx={{ minWidth: 240, }}>
        {/* <Link to="#" color="inherit" underline="hover" component={RouterLink}> */}

        <Typography variant="h4" sx={{ color: '#fff', fontSize: '2.6vh !important' }} noWrap>
          {description}
        </Typography>
        <Typography variant="body1" sx={{ color: '#fff', fontSize: '2vh' }} noWrap>
          設備編號：{name}
          &nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;
          時間：{createdAt}
        </Typography>
        {/* </Link> */}
      </Box>
      {/* <Typography variant="caption" sx={{ pl: 10, flexShrink: 0, color: 'text.secondary' }}>
        
        {createdAt}
      </Typography> */}
    </Stack>
  );
}

export default function AppNewsUpdate(props) {
  const { data } = props
  return (

    <Card sx={{
      backgroundColor: '#ffffff00'
    }}>
      <Box
        sx={{
          height: `64.5vh`,
          '& .simplebar-wrapper': {
            backgroundColor: '#ffffff00',
          }
        }}>
        <CardHeader title="即時事件" sx={{ color: '#fff', fontSize: '2vh !important' }}/>

        <Scrollbar>
          <Stack spacing={3} sx={{
            p: 3, pr: 0, height: '57vh',
          }}>
            {data.map((news) => (
              <NewsItem key={news.title} news={news} />
            ))}
          </Stack>
        </Scrollbar>
      </Box>
    </Card>
  );
}
