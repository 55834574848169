import { call, put, takeEvery } from "redux-saga/effects";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../config/types";
import * as ipconfig from "../config/ipconfig";

const apiUrl = `https://${ipconfig.apiIP}/api/v1/auth/detailRecord`;

function getApi(parse) {
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    
    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
};

function* fetchDetailRecord(action) {
  try {
    // console.log('2');
    const detailRecord = yield call(getApi, action.payload.parse);
    
    yield put({type: "CHANGE_TOKEN_STATUS", payload: detailRecord.resultCode});
    if(detailRecord.resultCode === 'Failed')
      yield put(getDetailRecordError('FAILED'));
    else{
      yield put(getDetailRecordSuccess(detailRecord));     
    }
  } catch (error) {
    yield put(getDetailRecordError(error.message));
  }
}

export const getDetailRecordSuccess = (detailRecord) => {
  // console.log("request getDetailRecord success");
  return {
    type: types.GET_DETAIL_RECORD_SUCCESS,
    payload: {
      loading: false,
      detailRecord: detailRecord
    }
  };
};

export const getDetailRecordError = (error) => {
  // console.log("request users error");
  return {
    type: types.GET_DETAIL_RECORD_FAILED,
    error: error
  };
};

function* detailRecordSaga() {
  yield takeEvery(types.GET_DETAIL_RECORD_REQUEST, fetchDetailRecord);
}

export default detailRecordSaga;
