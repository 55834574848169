import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import moment from 'moment';
import * as ipconfig from "../config/ipconfig";

import { fetchCmsList , fetchLeonTagList ,fetchReaderList,fetchTruckList,fetchDriverList} from "../actions";
export default function Notice() {
  const dispatch = useDispatch();

  // 定時撈推播資料
  const isActive = true;
  useEffect(() => {
    localStorage.setItem('mode', '');
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        if (localStorage.mode !== '1') {
          getCmsEvent()
          getReaderStatus()
          dispatch(fetchCmsList({ "userName": localStorage.userName, "token": localStorage.token }));
          dispatch(fetchReaderList({ "userName": localStorage.userName, "token": localStorage.token }));          
          dispatch(fetchLeonTagList({ "userName": localStorage.userName, "token": localStorage.token }));
          dispatch(fetchTruckList({ "userName": localStorage.userName, "token": localStorage.token }));
          dispatch(fetchDriverList({ "userName": localStorage.userName, "token": localStorage.token }));
        }
        // console.log(123)  
      }, 60000);
    } else if (!isActive) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);

  }, []);


  // 取得路段資料
  const getCmsEvent = async () => {
    const currentdate = new Date();
    const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
    const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
    const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
    const utc01 = new Date(`${moment(localStorage.readTime).add(8, 'hours').format('YYYY/MM/DD HH:mm:ss')}`).toISOString().slice(0, 19).replace('T', ' ')
    const utc02 = new Date(`${moment(currentdate).add(1, 'days').format('YYYY/MM/DD  HH:mm:ss')}`).toISOString().slice(0, 19).replace('T', ' ')
    const date1 = utc01
    const date2 = utc02
    await axios
      .post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getNotification`,
        { "token": localStorage.token, "date1": date1, "date2": date2, "utc": difference })
      .then(async (response) => {
        dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
        if (response.data.resultCode === "Success") {
          // console.log(response.data)

          dispatch({
            type: "INIT_ITEM",
            payload: response.data.rows
          });
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      })
      .then(() => {
        // always executed
        // // console.dir('leonReaderlist api then')
      });
    // setOpen(true);
  };
  
  // 取得Reader狀態
  const getReaderStatus = async () => {
    await axios
      .post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getLatestReaderStatus`,
        { "token": localStorage.token })
      .then(async (response) => {
        dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
        if (response.data.resultCode === "Success") {
          // console.log(response.data)

          dispatch({
            type: "READER_STATUS_INIT_ITEM",
            payload: response.data.rows
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      })
      .then(() => {
        // always executed
        // // console.dir('leonReaderlist api then')
      });
    // setOpen(true);
  };
  return(null)
}
