import React, { useState, useEffect } from 'react';
import { Typography, Paper, Box, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid,
    Tooltip, Legend, ResponsiveContainer, Label
} from 'recharts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { zhTW } from 'date-fns/locale';
import { getRouteStats } from '../services/readerService';

const colorMap = {
    'avg_speed': '#82ca9d',
    'avg_travel_time_seconds': '#8884d8'
};

const DailyRouteStatsChart = ({ routeData, date, handleDateChange }) => {
    const [chartData, setChartData] = useState([]);
    const [fetchTimeout, setFetchTimeout] = useState(null);
    const [visibleMetrics, setVisibleMetrics] = useState({
        'avg_speed': true,
        'avg_travel_time_seconds': true
    });
    const [selectedRoute, setSelectedRoute] = useState(
        routeData && routeData.length > 0 ? routeData[0].id : 1
    );
    const fetchData = async () => {
        try {
            const stats = await getRouteStats(date, selectedRoute);
            const formattedData = stats.map(item => ({
                time: item.timeTag,
                avg_speed: item.avg_speed,
                avg_travel_time_seconds: item.avg_travel_time_seconds
            }));
            setChartData(formattedData);
        } catch (error) {
            console.error('獲取路段統計數據時發生錯誤:', error);
        }
    };

    useEffect(() => {

        // 第一次載入執行
        fetchData();

        // 設定定時器，每2分鐘執行一次
        const timer = setTimeout(async function runFetch() {
            await fetchData();
            // 設定下一次執行
            setFetchTimeout(setTimeout(runFetch, 120000)); // 120000ms = 2分鐘
        }, 120000);

        setFetchTimeout(timer);

        // 清理函式
        return () => {
            if (fetchTimeout) {
                clearTimeout(fetchTimeout);
            }
        };
    }, [date, selectedRoute]);

    const handleRouteChange = (event) => {
        setSelectedRoute(event.target.value);
    };
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{
                    backgroundColor: 'white',
                    padding: '10px',
                    border: '1px solid #ccc',
                    position: 'relative',
                    top: '-10px',
                    left: '10px'
                }}>
                    <p>{`時間: ${label}`}</p>
                    {payload.map((entry, index) => (
                        <p key={index} style={{ color: entry.color }}>
                            {`${entry.dataKey === 'avg_speed' ? '平均速度' : '平均旅行時間'}: ${entry.value} ${entry.dataKey === 'avg_speed' ? 'km/h' : '秒'}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const CustomLegend = ({ payload }) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 8 }}>
            {payload.map((entry, index) => (
                <Box
                    key={`item-${index}`}
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        m: 0.5,
                        cursor: 'pointer',
                        position: 'relative',
                    }}
                    onClick={() => toggleMetric(entry.dataKey)}
                >
                    <Box sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: entry.color,
                        mr: 0.5
                    }} />
                    <Typography
                        variant="body2"
                        sx={{
                            position: 'relative',
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: '50%',
                                borderTop: visibleMetrics[entry.dataKey] ? 'none' : '1px solid black',
                            }
                        }}
                    >
                        {entry.dataKey === 'avg_speed' ? '平均速度' : '平均旅行時間'}
                    </Typography>
                </Box>
            ))}
        </Box>
    );

    const toggleMetric = (metric) => {
        setVisibleMetrics(prev => ({
            ...prev,
            [metric]: !prev[metric]
        }));
    };

    return (
        <Paper sx={{ p: 2, height: '100%' }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 2
            }}>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>選擇路段</InputLabel>
                    <Select
                        value={selectedRoute}
                        onChange={handleRouteChange}
                        label="選擇路段"
                        size="small"
                    >
                        {routeData.map((route) => (
                            <MenuItem key={route.id} value={route.id}>
                                {route.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
                    <DatePicker
                        label="選擇日期"
                        value={date}
                        onChange={handleDateChange}
                        renderInput={(params) =>
                            <TextField {...params} variant="standard" />
                        }
                    />
                </LocalizationProvider>
            </Box>

            <ResponsiveContainer width="100%" height={450}>
                <LineChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="time"
                        angle={-45}
                        textAnchor="end"
                        interval={0}
                        dy={10}
                    >
                        <Label value="時間(小時)" position="insideBottom" offset={-60} />
                    </XAxis>
                    <YAxis
                        yAxisId="left"
                        orientation="left"
                    >
                        <Label value="平均速度(km/h)" angle={-90} position="insideLeft" dx={-10} />
                    </YAxis>
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                    >
                        <Label value="平均旅行時間(秒)" angle={90} position="insideRight" dx={10} />
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={<CustomLegend />} />
                    <Line
                        yAxisId="left"
                        type="monotone"
                        dataKey="avg_speed"
                        stroke={colorMap.avg_speed}
                        strokeWidth={3}  // 增加線條粗細
                        dot={false}      // 移除數據點
                        hide={!visibleMetrics.avg_speed}
                    />
                    <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey="avg_travel_time_seconds"
                        stroke={colorMap.avg_travel_time_seconds}
                        strokeWidth={3}  // 增加線條粗細
                        dot={false}      // 移除數據點
                        hide={!visibleMetrics.avg_travel_time_seconds}
                    />
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
};

export default DailyRouteStatsChart;