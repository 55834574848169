import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';

// material
import {
    Grid, Button, Container, Stack, Typography,
    Tabs,
    Tab,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';



import SelectMenuItem from '../SelectMenuItem'
import TrafficAnalyzeOfConversionRates from './TrafficAnalyzeOfConversionRates'
import * as ipconfig from "../../config/ipconfig";
import * as config from "../../config/config";

export default function TestMap(props) {
    const { dayDate, setdayDate } = props
    const { unitAmountSelect, setunitAmountSelect, unitAmountList } = props
    const [trafficFlowHourData, settrafficFlowHourData] = useState([]);
    const [sessionlist, setsessionlist] = useState([]);
    const [sessionTrafficFlow, setsessionTrafficFlow] = useState([]);
    const [sessionDataOfTrafficFlow, setsessionDataOfTrafficFlow] = useState([]);
    const [readerGroupInfoNameMap, setreaderGroupInfoNameMap] = useState();
    // const [roadArealist, setroadArealist] = useState([]);
    // const [roadAreaselect, setroadAreaselect] = useState([]);
    // 1.isLeon 
    // 2.notLeon 
    // 3.isTruck 
    // 4.total 
    const [tagNam, settagNam] = useState('isTruck');
    const [tagNam1, settagNam1] = useState('total');
    const [chartTitle1, setchartTitle1] = useState('大型車輛車流計數前十排名');
    const [chartTitle2, setchartTitle2] = useState('總車流計數前十排名');

    const [tabValue, settabValue] = React.useState(0);
    const [year, setyear] = React.useState('');
    const [value, setValue] = React.useState('');
    const [value1, setValue1] = React.useState('');

    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;



    const chartlist = [
        { "id": 1, "description": "年" },
        { "id": 2, "description": "季" },
        { "id": 3, "description": "月" },
        { "id": 4, "description": "週" },
        { "id": 5, "description": "日" },
    ]
    const [chartSelect, setchartSelect] = useState(5);



    const monthlist = [
        { "id": 0, "description": "不選擇月份" },
        { "id": 1, "description": "1" },
        { "id": 2, "description": "2" },
        { "id": 3, "description": "3" },
        { "id": 4, "description": "4" },
        { "id": 5, "description": "5" },
        { "id": 6, "description": "6" },
        { "id": 7, "description": "7" },
        { "id": 8, "description": "8" },
        { "id": 9, "description": "9" },
        { "id": 10, "description": "10" },
        { "id": 11, "description": "11" },
        { "id": 12, "description": "12" },
    ]
    const [monthSelect, setmonthSelect] = useState(0);
    const [daySelect, setdaySelect] = useState(0);
    const [daylist, setdaylist] = useState([{ "id": 0, "description": "不選擇日" }]);

    useEffect(() => {
        // console.log(1234)
        const y1 = year
        // 選擇全年度
        if (monthSelect !== 0) {
            const yymm = `${y1}/${monthSelect}`;
            const dd = moment(`${yymm}`, "YYYY-MM").daysInMonth()
            const list = [];
            list.push({ "id": 0, "description": "不選擇日" })
            for (let i = 0; i < dd; i += 1) {
                list.push({ "id": i + 1, "description": `${i + 1}` })
            }
            setdaylist(list)
        }
        else {
            setdaylist([{ "id": 0, "description": "不選擇日" }])
        }

    }, [monthSelect]);

    useEffect(() => {
        let utc01;
        let utc02;
        const y1 = year
        // 選擇全年度
        if (y1 !== '') {
            if (daySelect === 0) {
                if (monthSelect === 0) {
                    utc01 = new Date(`${y1}/01/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
                    utc02 = new Date(`${y1}/12/31 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
                }
                // 選擇某年某月
                else {
                    const yymm = `${y1}/${monthSelect}`;
                    const dd = moment(`${yymm}`, "YYYY-MM").daysInMonth()
                    utc01 = new Date(`${yymm}/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
                    utc02 = new Date(`${moment(`${yymm}`).format('YYYY/MM')}/${dd} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
                }
                // console.log(utc01)
                // console.log(utc02)
                setValue(utc01)
                setValue1(utc02)
            }
            // 選擇某年某月
            else {
                const yymmdd = `${y1}/${monthSelect}/${daySelect}`;
                utc01 = new Date(`${yymmdd} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
                utc02 = new Date(`${yymmdd} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
            }
            // console.log(utc01)
            // console.log(utc02)
            setValue(utc01)
            setValue1(utc02)
        }
    }, [daySelect]);

    useEffect(() => {
        let utc01;
        let utc02;
        const y1 = year
        if (y1 !== '') {
            // 選擇全年度
            if (monthSelect === 0) {
                utc01 = new Date(`${y1}/01/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
                utc02 = new Date(`${y1}/12/31 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
                // utc01 = new Date(`${y1}/01/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
                // utc02 = new Date(`${y1}/12/31 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
            }
            // 選擇某年某月
            else {
                const yymm = `${y1}/${monthSelect}`;
                const dd = moment(`${yymm}`, "YYYY-MM").daysInMonth()
                utc01 = new Date(`${yymm}/01 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
                utc02 = new Date(`${moment(`${yymm}`).format('YYYY/MM')}/${dd} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
            }
            // console.log(utc01)
            // console.log(utc02)
            setValue(utc01)
            setValue1(utc02)
        }

    }, [year, monthSelect]);

    useEffect(() => {
        if (first && value !== '' && value1 !== '') {
            dispatch({ type: "START" });
            getTrafficFlowData();
            setfirst(false)
        }
    }, [value, value1, sessionDataOfTrafficFlow]);

    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);
    const { loading } = progressStore;
    const [first, setfirst] = useState(true);

    useEffect(() => {
        if (first && dayDate !== '') {
            const utc01 = `${moment(dayDate).format('YYYY')}`
            setyear(utc01)
        }
    }, [dayDate]);

    useEffect(() => {
        // console.log(props)
        getSessionDataOfTrafficFlow();
    }, [users]);



    /* ******    取得全部路口車流資料  ********
        假設 datetime = 2022/04/26 00:00:00
        如果本地端為UTC+8 
        utc0 = 2022/04/25 16:00:00
        difference = 8
    *************************************** */
    const getTrafficFlowData = async () => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));

        // const utc01 = new Date(`${moment(value).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
        // const utc02 = new Date(`${moment(value1).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
        const date1 = value
        const date2 = value1
        // const difference = 8
        // console.log(date1);
        // console.log(date2);
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/trafficFlow/getSessionTrafficFlowOfAnalyze`, { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq   
                // console.log(response.data);
                if (response.data.resultCode === "Success") {
                    setsessionTrafficFlow(response.data.rows)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                dispatch({ type: "END" });
            });
        // setOpen(true);
    };


    // 取得路段資料
    const getSessionDataOfTrafficFlow = async () => {
        await axios
            .get(`https://${ipconfig.apiIP}/api/v1/trafficFlow/getSessionInfoTrafficFlow`)
            .then(async (response) => {
                if (response.data.resultCode === "Success") {
                    // // console.dir(response.data.rows)        
                    // clearObjData();
                    const list = [];
                    const list1 = [];
                    const data = response.data.rows;
                    const rGroupInfoNameMap = new Map();
                    for (let i = 0; i < data.length; i += 1) {
                        const obj = {};
                        obj.id = i;
                        obj.sessionID = data[i].id;
                        obj.readerGroupInfoName = data[i].reader_group_info_name;
                        rGroupInfoNameMap.set(data[i].reader_group_info_name, i)

                        obj.total = 0;
                        obj.isLeon = 0;
                        obj.notLeon = 0;
                        obj.isTruckNotLeon = 0;
                        obj.isTruck = 0;
                        list.push(obj);
                        list1.push({ "id": i, "description": response.data.rows[i].reader_group_info_name })
                    }
                    setreaderGroupInfoNameMap(rGroupInfoNameMap);
                    setsessionDataOfTrafficFlow(list);
                    setsessionlist(list1)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                // // console.dir('leonReaderlist api then')
            });
        // setOpen(true);
    };

    const timer = React.useRef();
    const searchOnClick = () => {
        dispatch({ type: "START" });
        getTrafficFlowData();
    }




    return (

        <div>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} >


                <Typography variant="h4" gutterBottom  >
                    <Stack direction="row" spacing={2}
                        sx={{
                            '& label ': {
                                fontSize: config.selectLabelFontSize,
                                top: config.selectLabelTop
                            },
                            '& input': {
                                fontSize: config.selectItemFontSize,
                            },
                        }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}  >
                            <DatePicker
                                label="選擇年份"
                                views={['year']}
                                value={year}
                                onChange={(newValue) => {
                                    setyear(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>




                        <SelectMenuItem Option={monthlist} select={monthSelect} setselect={setmonthSelect} labelName="選擇月份" width={200} />
                        <SelectMenuItem Option={daylist} select={daySelect} setselect={setdaySelect} labelName="選擇日" width={200} />

                        {/* <SelectMenuItem Option={unitAmountList} select={unitAmountSelect} setselect={setunitAmountSelect} labelName="選擇計數單位" hidden="1" width={200} /> */}

                    </Stack>
                </Typography>
                <Button variant="contained" size="large" item xs={2}
                    disabled={loading}
                    onClick={searchOnClick} >查詢</Button>
                {loading && (
                    <CircularProgress
                        size={150}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            "z-index": 5000,
                        }}
                    />
                )}

            </Stack>

            <Grid container spacing={3} >
                <Grid item xs={6}>
                    <TrafficAnalyzeOfConversionRates
                        sessionDataOfTrafficFlow={sessionDataOfTrafficFlow} setsessionDataOfTrafficFlow={setsessionDataOfTrafficFlow}
                        readerGroupInfoNameMap={readerGroupInfoNameMap}
                        unitAmountValue={unitAmountList[unitAmountSelect].value}
                        chartTitle={chartTitle1} tagNam={tagNam} data={sessionTrafficFlow} />
                </Grid>

                <Grid item xs={6}>
                    <TrafficAnalyzeOfConversionRates
                        sessionDataOfTrafficFlow={sessionDataOfTrafficFlow} setsessionDataOfTrafficFlow={setsessionDataOfTrafficFlow}
                        readerGroupInfoNameMap={readerGroupInfoNameMap}
                        unitAmountValue={unitAmountList[unitAmountSelect].value}
                        chartTitle={chartTitle2} tagNam={tagNam1} data={sessionTrafficFlow} />
                </Grid>
            </Grid>

        </div>
    );
}
