import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopoverReaderStatus from './NotificationsPopoverReaderStatus';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme, maxWidth }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up(maxWidth)]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme, maxWidth }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up(maxWidth)]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));


// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { pathname } = useLocation();
  const [widthStyle, setwidthStyle] = useState('lg');
  const [menuIconColor, setmenuIconColor] = useState('#000');

  useEffect(() => {
    if (pathname.includes('/Dashboard/Dashboard123')) {
      setwidthStyle('xxl')
      // 修改上方toolbar的底圖(與下面內容相近)
      // document.getElementsByTagName('header')[0].style.backgroundImage =  `url(${'/static/login.jpg'})`;
      document.getElementsByTagName('header')[0].style.backgroundColor = '#2B2D3E';
      document.getElementsByTagName('header')[0].style['background-size'] = `cover`;
      document.getElementsByTagName('header')[0].style.minHeight = '10px !important';
      document.getElementsByTagName('header')[0].id = 'header1';
      document.getElementsByTagName('header')[0].style.display = 'none'
      // document.getElementsByClassName('MuiToolbar-regular')[0].style.minHeight = '4px !important';
      // document.getElementsByClassName('MuiToolbar-regular')[0].style.height = '4px !important';
      
      // document.getElementsByClassName('MuiIconButton-root')[0].style.display = 'none'
      // document.getElementsByClassName('MuiIconButton-root')[1].style.display = 'none'
      // document.getElementsByClassName('MuiIconButton-root')[2].style.display = 'none'
      // document.getElementsByTagName('header')[0].style.display = 'none'

      document.getElementsByTagName('header')[0].addEventListener('mouseover', changeDefOver);
      document.getElementsByTagName('header')[0].addEventListener('mouseout', changeDefOut);
      document.getElementById('main').style.paddingTop = '0px';
      // 修改menuIcon的顏色
      setmenuIconColor('#fff')
    }
    else {
      // 移除上方toolbar的底圖(與下面內容相近) 在別頁要還原原始的CSS
      document.getElementsByTagName('header')[0].removeAttribute("style");
      document.getElementById('main').style.paddingTop = '64px';
      
      // document.getElementsByClassName('MuiIconButton-root')[0].style.display = ''
      // document.getElementsByClassName('MuiIconButton-root')[1].style.display = ''
      // document.getElementsByClassName('MuiIconButton-root')[2].style.display = ''
      // 修改menuIcon的顏色
      setmenuIconColor('#000')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function changeDefOver(e) {
    document.getElementsByClassName('MuiIconButton-root')[0].click();

    e.target.classList.toggle('opacity-toggle');
  }

  function changeDefOut(e) {
    e.target.classList.toggle('opacity-toggle');
  }
  return (
    <RootStyle maxWidth={widthStyle}>
      <ToolbarStyle>
        <MHidden width={`${widthStyle}Up`}>
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon style={{ color: menuIconColor }} id="menuicon" icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <NotificationsPopoverReaderStatus /> */}
          <NotificationsPopover />
        </Stack>
        {/* <AccountPopover onClick={onOpenSidebar} /> */}
      </ToolbarStyle>
    </RootStyle>
  );
}
