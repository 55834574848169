import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';


// material
import { Grid, Button, Container, Stack, Typography, TextField } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

import AAppWebsiteVisits from '../components/AAppWebsiteVisits';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import ToggleButtons from '../components/ToggleButton'
import ReaderCountDataTable from './ReaderCountDataTable'
import SelectMenuItem from '../components/SelectMenuItem'

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";
import * as config from "../config/config";

export default function TestMap() {
    const [truckLimitData, settruckLimitData] = useState([]);
    const timer = React.useRef();

    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;


    const [dayDateStart, setdayDateStart] = React.useState('');
    const [dayDateEnd, setdayDateEnd] = React.useState('');
    const [columns, setcolumns] = React.useState([]);

    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);
    const { loading } = progressStore;
    const [first, setfirst] = React.useState(true);

    const warnNotificationlist = [
        { "id": 1, "description": "資訊可變標誌提醒", "type": "cms" },
        { "id": 2, "description": "車速過快", "type": "speed" },
        { "id": 3, "description": "車流過多", "type": "trafficFlow" },
    ]
    const [warnNotificationSelect, setwarnNotificationSelect] = useState(1);

    useEffect(() => {
        const date = moment(new Date()).format('YYYY/MM/DD')
        setdayDateStart(date)
        setdayDateEnd(date)
    }, []);


    useEffect(() => {
        if (first && dayDateStart !== '' && dayDateEnd !== '') {
            setfirst(false)
            dispatch({ type: "START" });
            getData();
        }
    }, [users, dayDateStart, dayDateEnd]);

    // useEffect(() => {
    //     console.log(warnNotificationSelect)
    //     if (warnNotificationSelect === 1)
    //         getData();
    //     else if (warnNotificationSelect === 2)
    //         getData1();
    //     else
    //         getData2();
    // }, [warnNotificationSelect]);



    // CMS推播資料
    const getData = async () => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        const utc01 = new Date(`${moment(dayDateStart).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
        const utc02 = new Date(`${moment(dayDateEnd).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
        const date1 = utc01
        const date2 = utc02
        // const difference = 8
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getCmsEventsAnalyze`,
                { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                // console.log(response.data);
                if (response.data.resultCode === "Success") {
                    const list = [];
                    let index = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;
                        if (Object.keys(obj).length !== 0) {
                            obj.receiveTime = new Date(obj.endTime).toLocaleString()
                            obj.notification = obj.content
                            obj.rownum = index
                            obj.id = index
                            list.push(obj)
                            index += 1;
                        }
                    }
                    settruckLimitData(list)
                    // getData1(date1,date2,difference,list,index)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                const columns = [
                    { field: "id", headerName: "id", width: 70, hideable: true },
                    { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
                    {
                        field: "leonReaderName", headerName: "智慧感知讀取器名稱", flex: 0.4, sortable: true, headerAlign: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: config.tableContentFontSize }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    {
                        field: "cmsName", headerName: "資訊可變標誌名稱", flex: 0.4, sortable: true, headerAlign: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: config.tableContentFontSize }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    {
                        field: "notification", headerName: "警示提醒內容", flex: 0.2, width: 100, headerAlign: 'center', align: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: config.tableContentFontSize }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    { field: "licensePlateNumber", headerName: "車牌", width: 120, headerAlign: 'center', align: 'center', },
                    { field: "receiveTime", headerName: "時間", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center', },
                ];
                setcolumns(columns)
                dispatch({ type: "END" });
                // always executed
            });
        // setOpen(true);
    };

    // 車流過多資料
    // const getData1 = async (date1,date2,difference,list,index) 
    const getData1 = async () => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        const utc01 = new Date(`${moment(dayDateStart).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
        const utc02 = new Date(`${moment(dayDateEnd).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
        const date1 = utc01
        const date2 = utc02
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getTrafficExceedEventAnalyze`, { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                // console.log(response.data);
                if (response.data.resultCode === "Success") {
                    const list = [];
                    let index = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;
                        if (Object.keys(obj).length !== 0) {
                            obj.receiveTime = new Date(obj.localTime1).toLocaleString()
                            obj.notification = `車流過多(300輛/hr)`
                            obj.rownum = index
                            obj.id = index
                            list.push(obj)
                            index += 1;
                        }
                    }

                    settruckLimitData(list)
                    // getData2(date1,date2,difference,list,index)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                const columns = [
                    { field: "id", headerName: "id", width: 70, hideable: true },
                    { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
                    {
                        field: "leonReaderName", headerName: "智慧感知讀取器名稱", flex: 0.4, sortable: true, headerAlign: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: config.tableContentFontSize }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    { field: "name", headerName: "設備編號", flex: 0.2, sortable: true, headerAlign: 'center', align: 'center', },
                    {
                        field: "notification", headerName: "警示提醒內容", flex: 0.3, width: 100, headerAlign: 'center', align: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: config.tableContentFontSize }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    { field: "receiveTime", headerName: "時間", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center', },
                ];
                setcolumns(columns)
                dispatch({ type: "END" });
                // always executed
            });
        // setOpen(true);
    };


    // 車速過快資料
    const getData2 = async () => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        const utc01 = new Date(`${moment(dayDateStart).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
        const utc02 = new Date(`${moment(dayDateEnd).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
        const date1 = utc01
        const date2 = utc02
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getSpeedExceedEventAnalyze`, { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                // console.log(response.data);
                if (response.data.resultCode === "Success") {
                    const list = [];
                    let index = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;
                        if (Object.keys(obj).length !== 0) {
                            obj.receiveTime = new Date(obj.localTime1).toLocaleString()
                            obj.notification = `車速過快(60 Km/h)`
                            obj.rownum = index
                            obj.id = index
                            obj.name = `${data.reader_info_name1},${data.reader_info_name2}`
                            list.push(obj)
                            index += 1;
                        }
                    }
                    settruckLimitData(list)
                    // setspeedExceedData(list)   
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                const columns = [
                    { field: "id", headerName: "id", width: 70, hideable: true },
                    { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
                    {
                        field: "pathName", headerName: "路段名稱", flex: 0.4, sortable: true, headerAlign: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: config.tableContentFontSize }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    { field: "name", headerName: "設備編號", flex: 0.2, sortable: true, headerAlign: 'center', align: 'center', },
                    {
                        field: "notification", headerName: "警示提醒內容", flex: 0.3, width: 100, headerAlign: 'center', align: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: config.tableContentFontSize }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    { field: "receiveTime", headerName: "時間", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center', },
                ];
                setcolumns(columns)
                dispatch({ type: "END" });
                // always executed
            });
        // setOpen(true);
    };





    const searchOnClick = () => {
        dispatch({ type: "START" });
        if (warnNotificationSelect === 1)
            getData();
        else if (warnNotificationSelect === 2)
            getData2();
        else
            getData1();
    }


    const rowsPerPageOptions = [10, 20, 50, 100]


    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" >

                        <Stack direction="row" spacing={2}
                            sx={{
                                '& label ': {
                                    fontSize: config.selectLabelFontSize,
                                    top: config.selectLabelTop
                                },
                                '& input': {
                                    fontSize: config.selectItemFontSize,
                                },
                            }}>

                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <DatePicker
                                    label="起始時間"
                                    value={dayDateStart}
                                    onChange={(newValue) => {
                                        setdayDateStart(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <DatePicker
                                    label="結束時間"
                                    value={dayDateEnd}
                                    onChange={(newValue) => {
                                        setdayDateEnd(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>

                        </Stack>

                    </Typography>
                    <SelectMenuItem Option={warnNotificationlist} select={warnNotificationSelect} setselect={setwarnNotificationSelect} labelName="選擇警示種類" />

                    <Button variant="contained" size="large" item xs={2}
                        disabled={loading}
                        onClick={searchOnClick} >查詢</Button>
                    {loading && (
                        <CircularProgress
                            size={150}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                "z-index": 5000
                            }}
                        />
                    )}
                    {/* <Button variant="contained" size="large" onClick={searchOnClick} >查詢</Button> */}
                </Stack>


                <Grid container spacing={3} >

                    <Grid item xs={12} style={{ height: "100%" }}
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontSize: config.tableTitleFontSize
                            },
                            '& .MuiDataGrid-cellContent': {
                                fontSize: config.tableContentFontSize
                            },
                            '& .MuiDataGrid-cell': {
                                fontSize: config.tableContentFontSize
                            }
                        }}>
                        <BDataGrid data={truckLimitData} columns={columns} rowsPerPageOptions={[10, 20, 50]}
                        // TableOnClick={TableOnClick}
                        />
                    </Grid>


                </Grid>
            </Container>
        </Page>
    );
}
