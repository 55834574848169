import * as React from "react";
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
// material
import { Typography } from '@mui/material';

const columns = [
    { field: "id", headerName: "id", width: 70, hideable: true },
    { field: "address", headerName: "地址", width: 200 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
        field: "age",
        headerName: "Age",
        type: "number",
        width: 90
    }
    //   ,  {
    //     field: "fullName",
    //     headerName: "Full name",
    //     description: "This column has a value getter and is not sortable.",
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params) =>
    //       `${params.row.firstName || ""} ${params.row.lastName || ""}`
    //   }
];
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export default function DataTable(props) {

    const rowsPerPageOptions = props.rowsPerPageOptions !== undefined ? props.rowsPerPageOptions : [5, 10, 20, 50]
    const [pageSize, setpageSize] = useState(rowsPerPageOptions[0]);
    const heightFix = rowsPerPageOptions[0] !== 5 ? 20 : 0
    // if(props.pageSize !== undefined )
    //     setpageSize(props.pageSize)

    const obj = { "Toolbar": CustomToolbar };
    const obj1 = {};
    const export1 = props.export ? obj : obj1;

    useEffect(() => {
        for (let i = 0; i < props.data.length; i += 1) {
            const data = props.data[i];
            Object.keys(data).forEach(key => {
                const text = data[key] || '';
                if (typeof text === 'string' && text.includes("(")) {
                    const words = data[key].split('(');
                    if (Array.isArray(words)) {
                        if (words.length > 1)
                            words[1] = `(${words[1]}`
                    }
                    data[key] = words
                }
            })
        }
        // console.log(props.data)
    }, [props]);

    if (props.mode === undefined)
        return (
            <DataGrid
                autoHeight {...props.data}
                getRowHeight={() => 'auto'}
                disableColumnMenu
                onCellClick={props.TableOnClick}
                rows={props.data}
                columns={props.columns}
                pageSize={pageSize}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns status and traderName, the other columns will remain visible
                            id: false,
                        },
                    },
                }}
                components={export1}
                getCellClassName={props.TableCellClassName}
                Selection
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 2.5,
                    },
                }}
            />

            // <Box style={{ 'height': 132 + (pageSize * 52) + heightFix , width: "100%" }}>
            // <Box sx={{ height: 400, width: '100%' }}>
            // <DataGrid
            //     autoHeight {...props.data}
            //     rows={props.data}
            //     columns={props.columns}
            //     getRowHeight={() => 'auto'}
            //     sx={{
            //       [`& .${gridClasses.cell}`]: {
            //         py: 1,
            //       },
            //     }}
            // />

            // </Box>
        );
    return (
        // <Box style={{ 'height': 132 + (pageSize * 52) + heightFix, width: "100%" }}>
            <Box sx={{ height: 400, width: '100%' }}>

            <DataGrid
                getRowHeight={() => 'auto'}
                disableColumnMenu
                onCellClick={props.TableOnClick}
                rows={props.data}
                columns={props.columns}
                pageSize={pageSize}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns status and traderName, the other columns will remain visible
                            id: false,
                        },
                    },
                }}
                components={export1}
                getCellClassName={props.TableCellClassName}
                Selection
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 2.5,
                    },
                }}
            />

        </Box>
    )

}
