import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Box, Grid } from '@mui/material';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { PieChart, PieSeriesOption } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
  TitleComponent,
  DatasetComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
//
import { BaseOptionChart } from '../charts';

// ----------------------------------------------------------------------
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
]);

const CHART_DATA = [
  {
    name: 'Team A',
    type: 'column',
    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
  },
  {
    name: 'Team B',
    type: 'line',
    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
  },
  {
    name: 'Team C',
    type: 'area',
    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
  },
];

const CHART_HEIGHT = '70vh';
const LEGEND_HEIGHT = '25vh';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  'background-color': '#00000000 !important',
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .echarts-for-react  ': {
    height: `${CHART_HEIGHT} !important`,
    width: `100%`
  },

  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'flex-start',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${viewportToPixels(CHART_HEIGHT) - viewportToPixels(LEGEND_HEIGHT)}px) !important`
  },

  '& .apexcharts-legend-text': {
    color: '#fff !important',
  },
  '& .apexcharts-svg .apexcharts-legend-series': {
    'margin-top': '2vh !important',
  },


}));


/* eslint-disable */
const viewportToPixels = (value) => {
  const parts = value.match(/([0-9\.]+)(vh|vw)/)
  const q = Number(parts[1])
  const side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
  return side * (q / 100)
}


export default function AAppWebsiteVisits(props) {
  const chartOptions = merge(BaseOptionChart(), props.chartOptions);
  const chartOptions1 = merge(BaseOptionChart(), props.chartOptions1);

  return (
    <Card style={{
      height: '98%', 'background-color': '#00000000',
      'box-shadow': '0 0 2px 0 rgb(255 255 255 / 33%), 0 16px 32px -4px rgb(145 158 171 / 0%)'
    }} sx={{ pb: 3.6 }}>

      {/* <CardHeader  style={{ color: '#fff', }} sx={{ mt: 2.6 }} subheader={props.subtitle}/> */}

      <Grid container item xs={12} >
        <Grid item xs={6} >
          <ChartWrapperStyle sx={{ p: 3, pb: 1, }} dir="ltr" >
            {/* <Grid sx={{ p: 3, pb: 1, mt: 5 }}>
          <ReactApexChart type="bar" series={props.data} options={chartOptions} height="30%" />
        </Grid> */}

            <Grid >
              <ReactEChartsCore
                height="100%"
                echarts={echarts}
                option={chartOptions}
                notMerge={true}
                lazyUpdate={true}
                theme={'theme_name'}
                opts={{}}
              />
            </Grid>

          </ChartWrapperStyle>
        </Grid>
        <Grid item xs={6} >
          <ChartWrapperStyle sx={{ p: 3, pb: 1, }} dir="ltr" >
            {/* <Grid sx={{ p: 3, pb: 1, mt: 5 }}>
          <ReactApexChart type="bar" series={props.data} options={chartOptions} height="30%" />
        </Grid> */}
            <Grid >
              <ReactEChartsCore
                height="100%"
                echarts={echarts}
                option={chartOptions1}
                notMerge={true}
                lazyUpdate={true}
                theme={'theme_name'}
                opts={{}}
                outerRadius={50}

                options={{ maintainAspectRatio: false }}
              />
            </Grid>

          </ChartWrapperStyle>
        </Grid>
      </Grid>

    </Card >

  );
}
