import {
  TextField,
} from '@mui/material';


export default function TextFieldCcomponents(props) {
  return (
    <TextField
      fullWidth
      autoComplete={props.name}
      type="text"
      InputProps={props.InputProps}
      label={props.label}
      {...props.getFieldProps}
      error={props.error}
      // value={props.value}
      // onChange={props.onChange}
      helperText={props.helperText}
    />
  )
}