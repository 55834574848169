import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

import SelectMenuItem from '../SelectMenuItem'

import TrafficAnalyze2 from './TrafficAnalyze2-1'
import * as config from "../../config/config";


export default function TrafficAnalyzeOfConversionRates(props) {
    const {sessionTurningTrafficList,sessionlist , sessionselect , setsessionselect } = props   
    const tagNam = 'isTruck';
    const tagNam1 = 'total';
    const chartTitle0 = '晨峰時段07:00-09:00平均旅行速度與旅行時間統計(統計單位:1分鐘)';
    const chartTitle1 = '晨峰時段07:00-09:00平均旅行速度與旅行時間統計(統計單位:5分鐘)';
    const chartTitle2 = '晨峰時段07:00-09:00平均旅行速度與旅行時間統計(統計單位:15分鐘)';
    const chartTitle3 = '晨峰時段07:00-09:00平均旅行速度與旅行時間統計(統計單位:30分鐘)';
    const chartTitle4 = '晨峰時段07:00-09:00平均旅行速度與旅行時間統計(統計單位:1小時)';
    const chartTitle6 = '昏峰時17:00-19:00平均旅行速度與旅行時間統計(單位:輛/時)';
    
    const sqlText5 = 'DateHHMM,Hours having Hours >= 7 and Hours < 9  ORDER BY DateHHMM';    
    const sqlText6 = 'DateHHMM,Hours having Hours >= 17 and Hours < 19  ORDER BY DateHHMM';  
    // const [dayDate, setdayDate] = React.useState('2022/5/30');
    const [dayDateStart, setdayDateStart] = React.useState('');
    const [dayDateEnd, setdayDateEnd] = React.useState('');
    const {dayDate, setdayDate } = props   
    
    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);    
    const { loading } = progressStore;   
    const [first, setfirst] = React.useState(true);
   
    useEffect(() => {
        if(first && dayDate !== ''){
            const utc01 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
            const utc02 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')                
            setdayDateStart(utc01)
            setdayDateEnd(utc02)  
            dispatch({type: "START"}); 
            setfirst(false)
        }
    }, [dayDate]);


    
    const searchOnClick = () => {
        const utc01 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
        const utc02 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')        
        // console.log(utc01)
        // console.log(utc02)
        setdayDateStart(utc01)
        setdayDateEnd(utc02)
        if(utc01 !== dayDateStart)
            dispatch({type: "START"}); 
    }

    
    return (
        <Grid>
            
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" mb={5} >     
                <Stack direction="row" spacing={2}
                    sx={{
                        '& label ': {
                            fontSize: config.selectLabelFontSize,
                            top: config.selectLabelTop
                        },
                        '& input': {
                            fontSize: config.selectItemFontSize,
                        },
                    }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}  >
                        <DatePicker
                            label="選擇日期"
                            // views={['year']}
                            value={dayDate}
                            onChange={(newValue) => {
                                setdayDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>                    
                    <SelectMenuItem  Option={sessionlist} select={sessionselect} setselect={setsessionselect} labelName="選擇路段"  width={400}/>
                    
                </Stack>
                <Button variant="contained" size="large"  item xs={2}
                disabled={loading}
                onClick={searchOnClick} >查詢</Button>
                {loading && (
                <CircularProgress
                    size={80}
                    sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    "z-index":50,
                    }}
                />
                )}
            </Stack>
            
            <Grid  item xs={12}>                
                <TrafficAnalyze2 
                    startDate={dayDateStart} endDate={dayDateEnd} 
                    chartTitle={chartTitle0} sqlText="DateHHMM_1 ORDER BY DateHHMM_1"
                    sessionlist={sessionlist} sessionselect={sessionselect} 
                    chartTimeUnit={1} 
                    hour1={7} hour2={8}
                    chartUnit="DateHHMM_1" time="morning" />
            </Grid>

            <Grid  item xs={12}>                
                <TrafficAnalyze2 
                    startDate={dayDateStart} endDate={dayDateEnd} 
                    chartTitle={chartTitle1} sqlText="DateHHMM_5 ORDER BY DateHHMM_5"
                    sessionlist={sessionlist} sessionselect={sessionselect} 
                    chartTimeUnit={5} 
                    hour1={7} hour2={8}
                    chartUnit="DateHHMM_5" time="morning" />
            </Grid>

            <Grid  item xs={12}>                
                <TrafficAnalyze2 
                    startDate={dayDateStart} endDate={dayDateEnd} 
                    chartTitle={chartTitle2} sqlText="DateHHMM_15 ORDER BY DateHHMM_15"
                    sessionlist={sessionlist} sessionselect={sessionselect} 
                    chartTimeUnit={15} 
                    hour1={7} hour2={8}
                    chartUnit="DateHHMM_15" time="morning" />
            </Grid>
            <Grid  item xs={12}>                
                <TrafficAnalyze2 
                    startDate={dayDateStart} endDate={dayDateEnd} 
                    chartTitle={chartTitle3} sqlText="DateHHMM_30 ORDER BY DateHHMM_30"
                    sessionlist={sessionlist} sessionselect={sessionselect} 
                    chartTimeUnit={30} 
                    hour1={7} hour2={8}
                    chartUnit="DateHHMM_30" time="morning" />
            </Grid>
            <Grid  item xs={12}>                
                <TrafficAnalyze2 
                    startDate={dayDateStart} endDate={dayDateEnd} 
                    chartTitle={chartTitle4}  sqlText="DateHHMM_60 ORDER BY DateHHMM_60"
                    sessionlist={sessionlist} sessionselect={sessionselect} 
                    chartTimeUnit={60} 
                    hour1={7} hour2={8}
                    chartUnit="DateHHMM_60" time="morning" />
            </Grid>
        </Grid>

    );
}
