import React, { useState, useEffect , useRef } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// material
import {
  Grid,
  Card,
  Table,
  Typography,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  TableSortLabel
} from '@mui/material';

import TTable from '../TTable'

import SearchNotFound from '../SearchNotFound';

// eslint-disable-next-line import/named
import { CarListToolbar } from '../_dashboard/user';

import { DataGridCustom } from '../tableContainer';

import { fetchDetailRecord } from "../../actions";



export default function EnhancedTable(props) {
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:  
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('eventnum');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterName, setFilterName] = useState('');
  const [detailRecordList, setDetailRecordList] = useState([]);
  const [operationRecordID, setOperationRecordID] = useState(0);
  
  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const detailRecordStore = useSelector((state) => state.detailRecord);
  const { users } = usersStore;    
  const { detailRecord } = detailRecordStore;   


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const headCell = [    
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: '姓名'
  },
  {
    id: 'gender',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: '性別'
  },
  {
    id: 'age',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: '年齡'
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: '手機'
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: 'Email'
  },
  {
    id: 'specialMark',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: '特殊註記',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    fillter: true,
    label: '描述',
  },
  {
    id: 'updateTime',
    numeric: false,
    disablePadding: false,
    fillter: false,
    label: '更新時間',
    type: 'format',
    fn: (updateTime) => {
      const time = new Date(updateTime).toLocaleString()
      return (
        time
      )
    }
  }
];

  useEffect(() => {
    // 當API呼叫時，Store的detailRecord會改變狀態
    // 外部傳入 operationRecordID
    if(operationRecordID !== props.operationRecord_id)
      getDetailRecord(props.operationRecord_id);

    if(detailRecord.length === 0){
      setDetailRecordList([]); 
    }
    else if((JSON.parse(detailRecord[0].record)).DriverRecord !== undefined){    
      // const driverRecord = (JSON.parse(detailRecord[0].record)).driverRecord;
      // if((JSON.parse(detailRecord[0].record)).driverRecord !== undefined){
        // setDetailRecordList((JSON.parse(detailRecord[0].record)).DriverRecord); 
        
      setDetailRecordHeadCell(props.action);
      setDetailRecordData(props.action,(JSON.parse(detailRecord[0].record)).DriverRecord)
      // }
    }
    // else
    // setDetailRecordList([]); 
    // console.log(detailRecordList);
    // 設定 operationRecordID
    setOperationRecordID(props.operationRecord_id);
    // // console.log(userOperationRecordToLeonTagRecord);
    // // console.log(props.operationRecord_id);
    // // console.log(resultStatus);
    
  }, [props.operationRecord_id,detailRecord]);

  useEffect(() => {
    setDetailRecordList(detailRecordList); 
}, [detailRecordList]);

const [headCells, setHeadCells] = useState([]);
const setDetailRecordHeadCell = (action) => {   
  if(action === 'update'){
    const list = [];
    list.push({
      id: 'action',
      numeric: false,
      disablePadding: false,
      fillter: false,
      label: '操作'
    })

    for(let i=0;i<headCell.length;i+=1){
      list.push(headCell[i])
    }
    setHeadCells(list);
    // console.log(list);
  }
  else{
    setHeadCells(headCell);
  }
}

const actionContext = useRef(null);
const enableContext = useRef(null);
const truckContext = useRef(null);

const setDetailRecordData = async (action,dataRecordList) => { 
  const list = []; 
  for(let i=0;i<dataRecordList.length;i+=1){
    if(action === 'update' && i === 0)
      actionContext.current = '修改前'    
    else if(action === 'update' && i === 1)
      actionContext.current = '修改後'
      
    if(dataRecordList[i].enable === 0)
      enableContext.current = '停用'
    else
      enableContext.current = '啟用'

    list.push({
        "action" : actionContext.current,
        "enable" : enableContext.current,
        "team_name" : dataRecordList[i].team_name,
        "name" : dataRecordList[i].name,
        "gender" : dataRecordList[i].gender,
        "age" : dataRecordList[i].age,
        "phone" : dataRecordList[i].phone,
        "email" : dataRecordList[i].email,
        "specialMark" : dataRecordList[i].specialMark,
        "description" : dataRecordList[i].description,        
        "updateTime" :dataRecordList[i].updateTime ,
    })
  }    
  setDetailRecordList(list);
  // console.log(list);
}
  const getDetailRecord = async (id) => {    
    dispatch(fetchDetailRecord({"type":"Driver","token": users.token,"operationRecord_id":id}));
  };

  const columns = [
    { field: "name", headerName: "id", width: 70, hideable: true },
    { field: "name", headerName: "名稱", width: 70, hideable: true },
    { field: "email", headerName: "Email", width: 100, sortable: true, },
    { field: "phone", headerName: "電話", width: 70 },
    { field: "specialMark", headerName: "特別註記", width: 200 },
    { field: "updateTime", headerName: "更新時間", width: 200 },
  ];

  return (
      <Container maxWidth="xl">        
      
      <Box
          sx={{
            width: 300,
            height: 130
          }}
        >
          <div>
            <Typography variant="h4"  >使用者ID： {users.userName}</Typography>
          </div>
          <div>
            <Typography variant="h4"  >事件： {              
              (() => {
                if(detailRecord.length){                  
                  if((JSON.parse(detailRecord[0].record)).action === "insert"){
                    return (<a>新增</a>);
                  }
                return (<a>修改</a>);
                }
              })()
            }</Typography>
          </div>
          {/* <div>
            <Typography variant="h4"  >筆數： {detailRecordList.length}</Typography>
          </div> */}
          
      </Box>
            
        <TTable Vehiclelist={detailRecordList} headCells={headCells} />

      </Container>
  );
}
