export const taglist = [
    "D4D72D504F43323230323437", "D4D72D504F43323230313839", "D4D72D504F43323230303730", "D4D72D504F43323230313338", "D4D72D504F43323230323135",
    "D4D72D504F43323230313037", "D4D72D504F43323230323038", "D4D72D504F43323230313837", "D4D72D504F43323230303934", "D4D72D504F43323230313534",
    "D4D72D504F43323230303433", "D4D72D504F43323230313230", "D4D72D504F43323230303535", "D4D72D504F43323230303734", "D4D72D504F43323230303436",
    "D4D72D504F43323230303833", "D4D72D504F43323230313739", "D4D72D504F43323230313439", "D4D72D504F43323230313133", "D4D72D504F43323230313136",
    "D4D72D504F43323230313135", "D4D72D504F43323230313134", "D4D72D504F43323230303638", "D4D72D504F43323230303930", "D4D72D504F43323230303830",
    "D4D72D504F43323230323230", "D4D72D504F43323230303330", "D4D72D504F43323230313737", "D4D72D504F43323230323231", "D4D72D504F43323230323431",
    "D4D72D504F43323230313535", "D4D72D504F43323230303837", "D4D72D504F43323230313431", "D4D72D504F43323230303533", "D4D72D504F43323230303336",
    "D4D72D504F43323230313532", "D4D72D504F43323230323037", "D4D72D504F43323230313937", "D4D72D504F43323230313231", "D4D72D504F43323230313433",
    "D4D72D504F43323230313835", "D4D72D504F43323230313934", "D4D72D504F43323230303339", "D4D72D504F43323230303739", "D4D72D504F43323230313339",
    "D4D72D504F43323230323336", "D4D72D504F43323230303836", "D4D72D504F43323230303135", "D4D72D504F43323230313938", "D4D72D504F43323230313936",
]

// -------------------- 表格專用 --------------------
export const tableTitleFontSize = "22px";
export const tableContentFontSize = "20px";
// 特殊註記的方塊大小
export const tableSpanHeight = "30px";


// -------------------- 選擇項目(Select)專用 --------------------
export const selectLabelFontSize = "26px";
export const selectLabelTop = "-8px";
export const selectItemFontSize = "20px";
// -------------------- 地圖專用 --------------------
export const mapCenter = {
    lat: 23.028353245273976, // 中心點的緯度
    lng: 120.21525069403704 // 中心點的經度
};
export const mapBounds = [
    [23.2, 120.5], // 台南範圍的北東角
    [22.8, 120.0]  // 台南範圍的南西角
];