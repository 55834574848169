import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from '@mui/material';
import sha256 from 'crypto-js/sha256';
import { LoadingButton } from '@mui/lab';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, fetchTeams, fetchSpecialmarkInfo } from "../../../actions";
import SystemInfoStore from '../../../Store/useSystemInfoStore';

export default function LoginForm() {
  // 擴充功能
  const { useDashboardSidebar } = SystemInfoStore();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [showPassword, setShowPassword] = useState(false);
  const [open, setSnackbarState] = useState(false);
  const [nackbarVHState, setnackbarVHState] = useState({
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal } = nackbarVHState;

  const usersStore = useSelector((state) => state.userLogin);
  const { users, userReciverStatus, nackbarState, resultLog } = usersStore;
  const teamsStore = useSelector((state) => state.teamsInfo);
  const { teamReciverStatus, teams } = teamsStore;


  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('UserName is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => onSubmit(values, actions)
  });

  useEffect(() => {
    if (localStorage.userName !== undefined) {
      dispatch(fetchUsers({ "userName": localStorage.userName, "token": localStorage.token }));
      dispatch(fetchTeams({ "token": localStorage.token }));
      dispatch(fetchSpecialmarkInfo({ "token": localStorage.token }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (users.resultCode === 'Success') {
      dispatch(fetchTeams({ "token": users.token }));
      dispatch(fetchSpecialmarkInfo({ "token": users.token }));
      localStorage.setItem("userName", users.userName);
      localStorage.setItem('token', users.token);
      localStorage.setItem('useDashboardSidebar', useDashboardSidebar);
    }
  }, [userReciverStatus, users, dispatch]);

  useEffect(() => {
    console.log(teams);
    // navigate('/Dashboard/TrafficOverview');
    // 登入成功跳轉頁面
    if (teams.length !== 0) {
      navigate('/dashboard');
    }
  }, [teamReciverStatus, teams, navigate]);

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    try {
      const ciphertext = sha256(values.password, 'IIIsecretkeyLEON').toString();
      dispatch(fetchUsers({ "userName": values.name, "password": ciphertext }));
    } catch (e) {
      console.error(e);
    }
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleClose = () => {
    setSnackbarState({ open: false });
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="帳號"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="密碼"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="記住我"
          />
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          登入
        </LoadingButton>
      </Form>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={nackbarState}
        onClose={handleClose}
        message={resultLog === null ? "帳號密碼錯誤" : resultLog}
        key={vertical + horizontal}
      />
    </FormikProvider>
  );
}