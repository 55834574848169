import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

// components

import BMap from './BMap';


// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'

import { convertGeoline } from '../utils/mapfun'
import * as ipconfig from "../config/ipconfig";

export default function MapTruckLimit(props) {
    const { leonReaderlist } = props
    // const [leonReaderlist, setleonReaderlist] = useState([]);
    // const [truckLimitData, settruckLimitData] = useState([]);
    const [popupTableData, setpopupTableData] = useState([]);
    const [Geolinelist, setGeolinelist] = useState([]);

    const [mapcenter, setmapcenter] = useState([24, 121]);
    const [openmap, setopenmap] = useState(null);
    

    useEffect(() => {
        // // console.dir('leonReaderlist useEffect')
        if (openmap != null) {
            const bounds = [
                [25.112698, 121.311192], [25.080091, 121.24397499999999]
            ]
            for (let i = 0; i < leonReaderlist.length; i += 1) {
                if (leonReaderlist[i].latitude !== null || leonReaderlist[i].longitude !== null) {
                    if (i === 0) {
                        bounds[0][0] = leonReaderlist[i].latitude
                        bounds[1][0] = leonReaderlist[i].latitude
                        bounds[0][1] = leonReaderlist[i].longitude
                        bounds[1][1] = leonReaderlist[i].longitude
                    } else if (leonReaderlist[i].latitude !== 0) {
                        if (leonReaderlist[i].latitude > bounds[0][0])
                            bounds[0][0] = leonReaderlist[i].latitude
                        else if (leonReaderlist[i].latitude < bounds[1][0])
                            bounds[1][0] = leonReaderlist[i].latitude
                        if (leonReaderlist[i].longitude > bounds[0][1])
                            bounds[0][1] = leonReaderlist[i].longitude
                        else if (leonReaderlist[i].longitude < bounds[1][1])
                            bounds[1][1] = leonReaderlist[i].longitude
                    }
                }
            }
            bounds[0][0] += 0.001
            bounds[0][1] = 121.33183542633057
            bounds[1][0] -= 0.001
            // bounds[1][1] -= 0.001
            openmap.fitBounds(bounds)
        }

    }, [leonReaderlist]);

    useEffect(() => {
        if (leonReaderlist.length !== 0) {
            let list = []
            const array = leonReaderlist.filter(a => a.linejson !== null && a.linejson !== '')
            for (let i = 0; i < array.length; i += 1) {
                list = list.concat(convertGeoline(JSON.parse(array[i].linejson), 'red'))
            }
            setGeolinelist(list)

        }
    }, [leonReaderlist]);



    // 圖標顯示內容
    const popupcontent = (row, textColor) => {
        const r = row
        return (
            <div>
                <div className={Tooltip.text_root}>

                    <div className='pb-4'>
                        <span className={Tooltip.text_name}>{row.address}</span>
                    </div>
                    <div className='pb-4'>
                        <span className={Tooltip.text_address}>智慧感知讀取器名稱 : {row.leonReaderName}</span>
                    </div>
                    <div className='pb-4'>
                        <span className={Tooltip.text_address}>設備編號 : {row.name}</span>
                    </div>
                    <div className='pb-4'>
                        <span className={Tooltip.text_address} style={{ color: 'red' }} >禁行事件 : {row.limitDescription}</span>
                    </div>
                    {/* <div className='pb-4'>
                        <span className={Tooltip.text_address}>禁行車輛車牌 : {row.licensePlateNumber}</span>
                    </div> */}
                    {/* <div className='pb-4'>
                        <span className={Tooltip.text_address}>時間 : {row.receiveTime}</span>
                    </div> */}

                 
                </div>
            </div>

        )

    }
    

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid item xs={12} style={{ position: 'relative' }}>
            <BMap Geoarray={Geolinelist} MarkerArray={leonReaderlist} popupcontent={popupcontent} mapcenter={mapcenter} setopenmap={setopenmap} openmap={openmap} />
            <div className={Styles.readerCountTips_red}>紅</div>
            <div className={Styles.readerCountTips_red1}>管制路段</div>
        </Grid>


    );
}
