/* eslint-disable no-restricted-globals */

import React, { useState, useEffect } from 'react';
import { ExternalLink } from 'lucide-react';
import {
  Typography,
  Box,
  Container,
  Snackbar,
  Link,
  Chip,
} from '@mui/material';

import MuiAlert from '@mui/material/Alert';
import BMap from '../components/BMap';
import TTable from '../components/TTable';
import { getCctvList } from '../services/cctvService';
import Tooltip from '../css/Tooltip.module.css';
import * as config from "../config/config";
const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function CCTVManagement() {
  const [cctvList, setCctvList] = useState([]);
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [mapcenter] = useState([config.mapCenter.lat, config.mapCenter.lng]);
  const [openmap, setOpenmap] = useState(null);

  const formatDateTime = (dateStr) => {
    if (!dateStr) return '---';
    return new Date(dateStr).toLocaleString('zh-TW', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '編號'
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '位置名稱',
      type: 'render',
      fn: (row) => {
        const { name, latitude, longitude } = row;
        return (
          <div
            role="button"
            tabIndex={0}
            style={{ color: '#00AB55', cursor: "pointer" }}
            onClick={() => {
              if (openmap) {
                openmap.flyTo([latitude, longitude], 20);
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                if (openmap) {
                  openmap.flyTo([latitude, longitude], 20);
                }
              }
            }}
          >
            {name}
          </div>
        );
      }
    },
    {
      id: 'code',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: 'CCTV代碼'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '連線狀態',
      type: 'render',
      fn: (row) => {
        const isOnline = row.status === 'online';
        return (
          <Chip
            label={isOnline ? '連線' : '斷線'}
            color={isOnline ? 'success' : 'error'}
            size="small"
          />
        );
      }
    },
    {
      id: 'dns_url',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '串流網址',
      type: 'render',
      fn: (row) => {
        return (
          <div className="flex items-center">
            <Link
              href={row.dns_url}
              target="_blank"
              rel="noopener"
              className="flex items-center text-blue-600 hover:text-blue-800"
            >
              {row.dns_url}
              <ExternalLink className="ml-1" size={16} />
            </Link>
          </div>
        );
      }
    },
    {
      id: 'event_count',
      numeric: true,
      disablePadding: true,
      fillter: false,
      label: '事件計數',
      type: 'format',
      fn: (count) => count.toLocaleString()
    },
    {
      id: 'last_heartbeat',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '最後心跳時間',
      type: 'format',
      fn: formatDateTime
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '建立時間',
      type: 'format',
      fn: formatDateTime
    }
  ];

  useEffect(() => {
    const fetchCCTVData = async () => {
      try {
        const data = await getCctvList();
        setCctvList(data);
      } catch (error) {
        console.error('Failed to fetch CCTV list:', error);
        setOpen(true);
        setReason('讀取CCTV資料失敗');
      }
    };

    fetchCCTVData();

    // 設定定時更新
    const intervalId = setInterval(fetchCCTVData, 60000); // 每分鐘更新一次

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (openmap && cctvList.length > 0) {
      const validCctvs = cctvList.filter(cctv =>
        cctv.latitude != null &&
        cctv.longitude != null &&
        !isNaN(cctv.latitude) &&
        !isNaN(cctv.longitude)
      );

      if (validCctvs.length > 0) {
        const bounds = [
          [
            Math.min(...validCctvs.map(cctv => cctv.latitude)) - 0.001,
            Math.min(...validCctvs.map(cctv => cctv.longitude)) - 0.001
          ],
          [
            Math.max(...validCctvs.map(cctv => cctv.latitude)) + 0.001,
            Math.max(...validCctvs.map(cctv => cctv.longitude)) + 0.001
          ]
        ];

        openmap.fitBounds(bounds);
      }
    }
  }, [cctvList, openmap]);

  const popupcontent = (cctv) => (
    <div>
      <div className={Tooltip.text_root}>
        <div className="pb-4">
          <span className={Tooltip.text_name}>{cctv.name}</span>
        </div>
        <div className="pb-2">
          <span className={Tooltip.text_address}>CCTV代碼: {cctv.code}</span>
        </div>
        <div className="pb-2">
          <span className={Tooltip.text_address}>
            狀態: {cctv.status === 'online' ?
              <span style={{ color: 'green' }}>連線</span> :
              <span style={{ color: 'red' }}>斷線</span>
            }
          </span>
        </div>
        <div className="pb-2">
          <span className={Tooltip.text_address}>事件數: {cctv.event_count.toLocaleString()}</span>
        </div>
        <div className="pb-2">
          <span className={Tooltip.text_address}>
            最後心跳: {formatDateTime(cctv.last_heartbeat)}
          </span>
        </div>
        {cctv.status === 'online' && (
          <div className="pt-2">
            <a
              href={cctv.dns_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800"
            >
              開啟串流
            </a>
          </div>
        )}
      </div>
    </div>
  );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {reason}
        </Alert>
      </Snackbar>

      <Box sx={{ pb: 3 }}>
        <Typography variant="h4">CCTV監控管理</Typography>
      </Box>

      {/* 地圖容器 */}
      <Box sx={{
        height: '500px',
        width: '100%',
        marginBottom: '24px',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        overflow: 'hidden'
      }}>
        <BMap
          mapcenter={mapcenter}
          setopenmap={setOpenmap}
          openmap={openmap}
          MarkerArray={cctvList}
          popupcontent={popupcontent}
        />
      </Box>
      <Box sx={{ height: '30px', mb: 3 }} />

      {/* 表格容器 */}
      <Box sx={{
        width: '100%',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        '& th': {
          fontSize: config.tableTitleFontSize,
          backgroundColor: '#f5f5f5'
        },
        '& td': {
          fontSize: config.tableContentFontSize
        }
      }}>
        <TTable
          Vehiclelist={cctvList}
          headCells={headCells}
          defaultSort="id"
          defaultOrder="asc"
        />
      </Box>
    </Container>
  );
}