import React, { useState, useEffect , useRef } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
// material
import {
  Button,
  Grid,
  TextField,
  Tabs,
  Tab,
  Typography,
  Stack,
  Card,
  Table,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Snackbar, List, ListItem, ListItemAvatar, ListItemText, DialogTitle, Dialog
} from '@mui/material';
import L from 'leaflet';
import MuiAlert from '@mui/material/Alert';
import BDataGrid from '../components/BDataGrid'
import Page from '../components/Page';
import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
// eslint-disable-next-line import/named
import { CarListToolbar, abnormalCarListToolbar } from '../components/_dashboard/user';
import { SimpleDialog } from '../components/dialog';

import { fetchDriverList } from "../actions";
import TTable from '../components/TTable'

import { LeonTagRecord, TruckRecord, DriverRecord, ReaderRecord } from '../components/record/index';



import { DriverForm } from '../components/editData/index';
import * as ipconfig from "../config/ipconfig";

// import { LeonTagInsertForm } from '../components/insertData/index';


export default function EnhancedTable() {
  

const columns = [
  { field: "id", headerName: "更新時間", width: 70, hideable: true },
  { field: "MACAddredd", headerName: "MACAddredd", width: 70, hideable: true },
  { field: "EPC", headerName: "EPC", width: 100, sortable: true, },
  { field: "TID", headerName: "TID", width: 70 },
  { field: "date", headerName: "更新時間", width: 200 },
];
const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    fillter: false,
    label: '流水號'
  },
  {
    id: 'leonReaderMACAddress',
    numeric: false,
    disablePadding: false,
    fillter: true,
    label: 'Reader編號'
  },
  {
    id: 'EPC',
    numeric: false,
    disablePadding: false,
    fillter: true,
    label: 'EPC'
  },
  {
    id: 'TID',
    numeric: false,
    disablePadding: false,
    fillter: true,
    label: 'TID'
  },
  {
    id: 'receiveTime',
    numeric: false,
    disablePadding: false,
    fillter: false,
    label: '更新時間',
    type: 'format',
    fn: (data) => {
      const time = new Date(data).toLocaleString()
      return (
        time
      )
    }
  },
  {
    id: 'sqlTime',
    numeric: false,
    disablePadding: false,
    fillter: false,
    label: '系統時間',
    type: 'format',
    fn: (data) => {
      const time = new Date(data).toLocaleString()
      return (
        time
      )
    }
  }
];
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:
  const Region = "彰化縣";
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('status');
  const [selected, setSelected] = React.useState([]);
  // 車機清單
  const [Vehiclelist, setVehiclelist] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterName, setFilterName] = React.useState('');
  const [reason, setreason] = React.useState(' ');
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [marker, setMarker] = useState(null);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const driversStore = useSelector((state) => state.driverManage);
  const { users } = usersStore;
  const { drivers, error } = driversStore;
  // const { loading, drivers, error } = driversStore;    
  // eslint-disable-next-line prefer-const
  let intervalId = null;
  let map;
  const v = React.useRef(0);

  useEffect(() => {
    // console.log(123456);
    // Vehicle_management();
    // 定時讀取車機清單
    // eslint-disable-next-line no-const-assign
    // intervalId = setInterval(() => {
    //   Vehicle_management();
    // }, 2000);
    // return function cleanup() {
    //   clearInterval(intervalId);
    // };
  }, [users]);

  const searchOnClick = () => {
    Vehicle_management();
}

  // 讀取車機清單
  // eslint-disable-next-line camelcase
  const Vehicle_management = async () => {

    await axios
        .get(`https://${ipconfig.apiIP}/api/v1/getRawData1`)
        .then(async (response) => {                
            // eslint-disable-next-line eqeqeq
            const tempData = response.data.rows;            
            setVehiclelist(tempData); 
            // console.log(Vehiclelist);
            // const tempVehiclelist = Vehiclelist;
            // let flag = false;
            // if(!Vehiclelist.length)
            //   flag = true;
            // else if(Vehiclelist[Vehiclelist.length-1].date !== tempData.date)
            //   flag = true;
            // if(flag){
            //   v.current =  parseInt(v.current,10) +  1;
            //   const lastData = {        
            //     "id":v.current,
            //     "MACAddredd":tempData.MACAddredd,
            //     "date":tempData.date,
            //     "tagType":tempData.tagType,
            //     "EPC":tempData.EPC,
            //     "TID":tempData.TID
            //   }          
            //   tempVehiclelist.push(lastData);
            //   setVehiclelist([]);
            //   setVehiclelist(tempVehiclelist);
            //   // console.log(Vehiclelist);
            // }
        })
        .catch((error) => {
            // handle error
            // console.log(error);
        })
        .then(() => {
            // always executed
        });
        
  };
             




  // Avoid a layout jump when reaching the last page with empty rows.

  return (
    
    <Page title="智慧管理">
    <Container maxWidth="xl"> 
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Button variant="contained" size="large" onClick={searchOnClick} >查詢近10分鐘內資料</Button>
        </Stack>
        <Grid container spacing={2}>
          <TTable Vehiclelist={Vehiclelist} headCells={headCells} />
        </Grid>
    </Container>
</Page>
  );
}
