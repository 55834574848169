import { call, put, takeEvery } from "redux-saga/effects";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../config/types";
import * as ipconfig from "../config/ipconfig";

const apiUrl = `https://${ipconfig.apiIP}/api/v1/getTeam`;

function getApi(parse) {
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    
    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
};

function* fetchTeams(action) {
  try {
    // console.log('2');
    const teams = yield call(getApi, action.payload.parse);
    yield put({type: "CHANGE_TOKEN_STATUS", payload: teams.resultCode}); 
    
    if(teams.resultCode === 'Failed')
      yield put(getTeamsError('Failed'));
    else{
      yield put(getTeamsSuccess(teams));     
    }
  } catch (error) {
    yield put(getTeamsError(error.message));
  }
}


export const getTeamsSuccess = (teams) => {
  // console.log("request users success");
  return {
    type: types.GET_TEAM_SUCCESS,
    payload: {
      loading: false,
      teams: teams
    }
  };
};

export const getTeamsError = (error) => {
  // console.log("request users error");
  return {
    type: types.GET_TEAM_FAILED,
    error: error
  };
};

function* teamSaga() {
  yield takeEvery(types.GET_TEAM_REQUEST, fetchTeams);
}

export default teamSaga;
