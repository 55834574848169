// routes
import { useIdleTimer } from 'react-idle-timer'
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import moment from 'moment';
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import './App.css';
import { userLogout, fetchUsers, fetchTeams, setAlertMessage, fetchSpecialmarkInfo } from "./actions";

import CustomizedSnackbars from './components/CustomizedSnackbars';
import Notice from './components/Notice';
import * as ipconfig from "./config/ipconfig";
// ----------------------------------------------------------------------

export default function App() {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const { loading, users, tokenStatus, error, nackbarState } = usersStore;
  const teamsStore = useSelector((state) => state.teamsInfo);
  const { teams } = teamsStore;
  const messageStore = useSelector((state) => state.alertMessage);
  const { message } = messageStore;
  const [first, setfirst] = useState(true);


  // 網頁閒置多久時間後登出
  const onIdle = () => {
    // showLogout();    
    // console.log(users);  
    if (window.location.pathname !== '/login') {
      dispatch(userLogout({}));
      window.location.href = '/login';
    }
  }
  useIdleTimer({
    // 10分鐘
    timeout: 1000 * 60 * 10 * 99,
    onIdle: onIdle,
    debounce: 500
  })

  useEffect(() => {

    if (localStorage.userName !== undefined) {
      dispatch(fetchUsers({ "userName": localStorage.userName, "token": localStorage.token }));
      dispatch(fetchTeams({ "token": localStorage.token }));
      dispatch(fetchSpecialmarkInfo({ "token": localStorage.token }));
    }
    else if (window.location.pathname !== '/login')
      window.location.href = '/login';
  }, [])

  useEffect(() => {
    // alert 訊息
    setOpen(message.open);
    setSeverity(message.severity);
    setContent(message.content);
  }, [message])


  useEffect(() => {
    setOpen(false);
    if (!pathname.includes('/manage') && !pathname.includes('/account/userManage')) {
      localStorage.setItem('mode', '');
    }
  }, [pathname]);

  useEffect(() => {
    if (Array.isArray(users))
      return
    if (typeof users === 'object') {
      if (first) {
        if (users.updateTime !== undefined) {
          const now = moment();
          const updateTime = moment(users.updateTime);
          const dayDiff = now.diff(updateTime, 'day');
          if (dayDiff >= 90)
            window.setTimeout(() => {
              dispatch(setAlertMessage({ "open": true, "severity": "warning", "content": "已超過三個月未更換密碼，請至帳號管理更換新密碼" }));
            }, 2000);
        }
        localStorage.setItem('readTime', users.readTime);
        localStorage.setItem('readTime1', users.readTime);
        setfirst(false)
        getCmsEvent()
        getReaderStatus()
      }
    }
  }, [users])

  // 定時撈推播資料
  // const isActive = true;
  // useEffect(() => {
  //   localStorage.setItem('mode', '');
  //   let interval = null;
  //   if (isActive) {
  //     interval = setInterval(() => {
  //       if (typeof users === 'object') {
  //         if (localStorage.mode !== '1') {
  //           getCmsEvent()
  //           setOpen(false);
  //         }
  //         // console.log(123)  
  //       }
  //     }, 60000);
  //   } else if (!isActive) {
  //     clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);

  // }, []);


  useEffect(() => {
    // console.log(`tokenStatus : ${tokenStatus}`)
    if (tokenStatus.includes('token')) {
      dispatch(userLogout({}));
      window.location.href = '/login';
    }
  }, [tokenStatus])


  // 取得路段資料
  const getCmsEvent = async () => {
    const currentdate = new Date();
    const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
    const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
    const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
    const utc01 = new Date(`${moment(localStorage.readTime).add(8, 'hours').format('YYYY/MM/DD HH:mm:ss')}`).toISOString().slice(0, 19).replace('T', ' ')
    const utc02 = new Date(`${moment(currentdate).add(1, 'days').format('YYYY/MM/DD  HH:mm:ss')}`).toISOString().slice(0, 19).replace('T', ' ')
    const date1 = utc01
    const date2 = utc02
    await axios
      .post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getNotification`,
        { "token": localStorage.token, "date1": date1, "date2": date2, "utc": difference })
      .then(async (response) => {
        dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
        if (response.data.resultCode === "Success") {
          // console.log(response.data)

          dispatch({
            type: "INIT_ITEM",
            payload: response.data.rows
          });
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      })
      .then(() => {
        // always executed
        // // console.dir('leonReaderlist api then')
      });
    // setOpen(true);
  };

  // 取得Reader狀態
  const getReaderStatus = async () => {
    await axios
      .post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getLatestReaderStatus`,
        { "token": localStorage.token })
      .then(async (response) => {
        dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
        if (response.data.resultCode === "Success") {
          // console.log(response.data)

          dispatch({
            type: "READER_STATUS_INIT_ITEM",
            payload: response.data.rows
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      })
      .then(() => {
        // always executed
        // // console.dir('leonReaderlist api then')
      });
    // setOpen(true);
  };

  // // 使用 WebSocket 的網址向 Server 開啟連結
  // const ws = new WebSocket(`ws://${ipconfig.webSocketIP}`)

  // ws.onopen = () => {
  //   console.log('open connection')
  // }

  // ws.onclose = () => {
  //   console.log('close connection')
  // }

  // ws.onmessage = event => {
  //   // console.log('推播資訊')
  //   dispatch({
  //     type: "ADD_ITEM",
  //     payload: JSON.parse(event.data)
  //   });
  // }

  /* eslint-enable global-require */

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
      <CustomizedSnackbars
        open={open}
        severity={severity}
        content={content}
      />
    </ThemeConfig>
  );
}
