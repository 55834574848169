import * as React from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({    
    'font-size':'18px',
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: selectedColor,
    },
}));

const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});

export default function ToggleButtons(props) {
    const { Options, setSelect, Select } = props

    const handleSelect = (event, newSelect) => {
        setSelect(newSelect);
    };

    return (
        <ThemeProvider theme={theme}>
            <ToggleButtonGroup value={Select} exclusive onChange={handleSelect}>
                {Options.map((Option) => (
                    <ToggleButton value={Option.value} selectedColor="#3b82f6" >
                        {Option.Name}
                    </ToggleButton>))}
            </ToggleButtonGroup>
        </ThemeProvider>
    );
}
