import React, { useEffect, useState } from 'react';
import { startOfMonth } from 'date-fns';
import { zhCN } from 'date-fns/locale';
import {
    Typography,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Button,
    TextField,
    Grid,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getCctvImages } from '../services/cctvService';
import { apiIP } from '../config/ipconfig';

const CctvImageList = ({ selectedCctv, onImageSelect }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [totalImages, setTotalImages] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const rowsPerPage = 10;

    useEffect(() => {
        const fetchImages = async () => {
            if (selectedCctv) {
                setLoading(true);
                try {
                    const data = await getCctvImages(selectedCctv.code, page + 1, rowsPerPage, selectedDate);
                    setImages(data.images);
                    setTotalImages(data.pagination.totalImages);
                } catch (error) {
                    console.error('Error fetching CCTV images:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchImages();
    }, [selectedCctv, page, selectedDate]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleSearch = () => {
        setPage(0); // 重置頁面為第一頁
        // 觸發 useEffect 重新加載圖片
    };

    if (!selectedCctv) {
        return <Typography>請選擇一個 CCTV</Typography>;
    }

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
                <Grid item>
                    <Typography variant="h6" gutterBottom>{selectedCctv.name} 資料列表</Typography>
                </Grid>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhCN}>
                        <DatePicker
                            views={['year', 'month']}
                            label="選擇月份"
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} variant="standard" />}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <TableContainer sx={{ flexGrow: 1 }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>時間</TableCell>
                            <TableCell>檔案名稱</TableCell>
                            <TableCell>縮圖</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            images.map((image) => (
                                <TableRow
                                    key={image.id}
                                    onClick={() => onImageSelect(image)}
                                    hover
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>{new Date(image.timestamp).toLocaleString('zh-TW', { timeZone: 'Asia/Taipei' })}</TableCell>
                                    <TableCell>{image.filename}</TableCell>
                                    <TableCell>
                                        <img
                                            src={`https://${apiIP}/api/v1/cctv/images/${image.location}/${image.camera}/${image.date}/thumbnails/${image.filename}`}
                                            alt="CCTV"
                                            style={{ width: '100px', height: 'auto' }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalImages}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[10]}
            />
        </Box>
    );
};

export default CctvImageList;