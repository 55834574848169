
import * as types from "../config/types";

const initialState = {
  message: [],
};
export default function alertMessage (state = initialState, action)  {
  switch (action.type) {
    case types.SET_ALERT_MESSAGE_REQUEST: {
      return {
        ...state,
      };
    }
    case types.SET_ALERT_MESSAGE_SUCCESS: {
      return {
        ...state,
        message: action.payload.message
      };
    }
    default:
      return state;
  }
};

