import { call, put, takeEvery } from "redux-saga/effects";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../config/types";
import * as ipconfig from "../config/ipconfig";

const apiUrl = `https://${ipconfig.apiIP}/api/v1/getSpecialmarkInfoList`;

function getApi(parse) {
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    
    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
};

function* fetchSpecialmarkInfo(action) {
  try {
    // console.log('2');
    if(action.payload.parse === undefined)
      return
    const specialmarkInfos = yield call(getApi, action.payload.parse);
    yield put({type: "CHANGE_TOKEN_STATUS", payload: specialmarkInfos.resultCode}); 
    
    if(specialmarkInfos.resultCode === 'Failed')
      yield put(getSpecialmarkInfoError('Failed'));
    else{
      yield put(getSpecialmarkInfoSuccess(specialmarkInfos));     
    }
  } catch (error) {
    yield put(getSpecialmarkInfoError(error.message));
  }
}


export const getSpecialmarkInfoSuccess = (specialmarkInfos) => {
  // console.log("request users success");
  return {
    type: types.GET_SPECIALMARK_INFO_LIST_SUCCESS,
    payload: {
      loading: false,
      specialmarkInfos: specialmarkInfos
    }
  };
};

export const getSpecialmarkInfoError = (error) => {
  // console.log("request users error");
  return {
    type: types.GET_SPECIALMARK_INFO_LIST_FAILED,
    error: error
  };
};

function* specialmarkInfoSaga() {
  yield takeEvery(types.GET_SPECIALMARK_INFO_LIST_REQUEST, fetchSpecialmarkInfo);
}

export default specialmarkInfoSaga;
