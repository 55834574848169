import { call, put, takeEvery } from "redux-saga/effects";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../config/types";
import * as ipconfig from "../config/ipconfig";


const apiUrl = `https://${ipconfig.apiIP}/api/v1/user/list`;

function getApi(parse) {
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },    
    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
};

function* fetchUserList(action) {
  try {
    
    // console.log('driver');
    const userList = yield call(getApi, action.payload.parse);    
    yield put({type: "CHANGE_TOKEN_STATUS", payload: userList.resultCode});   
    if(userList.resultCode === 'Failed')
      yield put(getUserListError());
    else
      yield put(getUserListSuccess(userList));
  } catch (error) {
    yield put(getUserListError(error.message));
  }
}

export const getUserListSuccess = (userList) => {
  // console.log("request drivers success");
  return {
    type: types.GET_USER_LIST_SUCCESS,
    payload: {
      loading: false,
      userList: userList
    }
  };
};

export const getUserListError = () => {
  // console.log("request drivers error");
  return {
    type: types.GET_USER_LIST_FAILED,
    error: "FAILED"
  };
};

function* userManageSaga() {
  yield takeEvery(types.GET_USER_LIST_REQUEST, fetchUserList);
}

export default userManageSaga;
