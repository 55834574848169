import React, { useState, useEffect , useRef } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// material
import {
  Grid,
  Card,
  Table,
  Typography,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  TableSortLabel
} from '@mui/material';

import TTable from '../TTable'


import SearchNotFound from '../SearchNotFound';

// eslint-disable-next-line import/named
import { CarListToolbar } from '../_dashboard/user';

import { fetchDetailRecord } from "../../actions";

// 定義切換頁
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};




export default function EnhancedTable(props) {
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:  
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('eventnum');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterName, setFilterName] = useState('');
  const [detailRecordList, setDetailRecordList] = useState([]);
  const [operationRecordID, setOperationRecordID] = useState(0);
  
  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const detailRecordStore = useSelector((state) => state.detailRecord);
  const { users } = usersStore;    
  const { detailRecord } = detailRecordStore;   



  const headCell = [
    {
      id: 'leonReaderName',
      numeric: false,
      disablePadding: true,
      label: '智慧感知讀取器名稱'
    },
    {
      id: 'enable',
      numeric: true,
      disablePadding: true,
      fillter: true,
      label: '狀態'
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: '設備編號'
    },
    {
      id: 'leonReaderMACAddress',
      numeric: false,
      disablePadding: false,
      label: 'MACAddress'
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: '設置地址'
    },
    {
      id: 'latitude',
      numeric: false,
      disablePadding: false,
      label: '緯度'
    },
    {
      id: 'longitude',
      numeric: false,
      disablePadding: false,
      label: '經度'
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: '描述'
    },
    {
      id: 'updateTime',
      numeric: false,
      disablePadding: true,
      label: '更新時間',
      type: 'format',
      fn: (updateTime) => {
        const time = new Date(updateTime).toLocaleString()
        return (
          time
        )
      }
    }
  ];


  useEffect(() => {
    // 當API呼叫時，Store的detailRecord會改變狀態
    // 外部傳入 operationRecordID
    if(operationRecordID !== props.operationRecord_id)
      getDetailRecord(props.operationRecord_id);

    if(detailRecord.length === 0){
      setDetailRecordList([]); 
    }
    else if((JSON.parse(detailRecord[0].record)).ReaderRecord !== undefined){    
      // const driverRecord = (JSON.parse(detailRecord[0].record)).driverRecord;
      // if((JSON.parse(detailRecord[0].record)).driverRecord !== undefined){
        // setDetailRecordList((JSON.parse(detailRecord[0].record)).ReaderRecord); 
        setDetailRecordHeadCell(props.action);
        setDetailRecordData(props.action,(JSON.parse(detailRecord[0].record)).ReaderRecord)
      // }
    }
    // else
    // setDetailRecordList([]); 
    
    // 設定 operationRecordID
    setOperationRecordID(props.operationRecord_id);
    // console.log(1);
    // // console.log(props.operationRecord_id);
    // // console.log(resultStatus);
    
  }, [props.operationRecord_id,detailRecord]);

  useEffect(() => {
    setDetailRecordList(detailRecordList); 
}, [detailRecordList]);

const [headCells, setHeadCells] = useState([]);
const setDetailRecordHeadCell = (action) => {   
  if(action === 'update'){
    const list = [];
    list.push({
      id: 'action',
      numeric: false,
      disablePadding: false,
      fillter: false,
      label: '操作'
    })

    for(let i=0;i<headCell.length;i+=1){
      list.push(headCell[i])
    }
    setHeadCells(list);
    // console.log(list);
  }
  else{
    setHeadCells(headCell);
  }
}

const actionContext = useRef(null);
const enableContext = useRef(null);
const truckContext = useRef(null);



const setDetailRecordData = async (action,dataRecordList) => { 
  const list = []; 
  for(let i=0;i<dataRecordList.length;i+=1){
    if(action === 'update' && i === 0)
      actionContext.current = '修改前'    
    else if(action === 'update' && i === 1)
      actionContext.current = '修改後'
      
    if(dataRecordList[i].enable === 0)
      enableContext.current = '停用'
    else
      enableContext.current = '啟用'

    const textFieldProps = ["leonReaderName","name","leonReaderMACAddress","latitude","longitude","address","description","updateTime"];  
    const textFieldValue = [dataRecordList[i].leonReaderName,
    dataRecordList[i].name,
    dataRecordList[i].leonReaderMACAddress,
    dataRecordList[i].latitude,
    dataRecordList[i].longitude,
    dataRecordList[i].address,
    dataRecordList[i].description,
    dataRecordList[i].updateTime];

    const obj = {}
    for(let j =0;j<textFieldProps.length;j+=1){
      obj[textFieldProps[j]] = textFieldValue[j];
    }    
    obj.action = actionContext.current;
    obj.enable = enableContext.current;

    list.push(obj)
  }    
  setDetailRecordList(list);
  // console.log(list);
}

  const getDetailRecord = async (id) => {    
    dispatch(fetchDetailRecord({"type":"Reader","token": users.token,"operationRecord_id":id}));
  };

  return (
      <Container maxWidth="xl">        
      
      <Box
          sx={{
            width: 300,
            height: 130
          }}
        >
          <div>
            <Typography variant="h4"  >使用者ID： {users.userName}</Typography>
          </div>
          <div>
            <Typography variant="h4"  >事件： {              
              (() => {
                if(detailRecord.length){                  
                  if((JSON.parse(detailRecord[0].record)).action === "insert"){
                    return (<a>新增</a>);
                  }
                return (<a>修改</a>);
                }
              })()
            }</Typography>
          </div>
          
      </Box>

        <TTable Vehiclelist={detailRecordList} headCells={headCells} />

      </Container>
  );
}
