// src/components/PopupContents.js
import React from 'react';
import { Typography, Button } from '@mui/material';

// 路線 Popup
export const RoutePopup = ({ name, travelTime, travelSpeed }) => (
    <div>
        <h4>{name}</h4>
        <p>旅行速度: {travelSpeed} km/h</p>
        <p>旅行時間: {travelTime}</p>
    </div>
);

// CCTV Popup
export const CCTVPopup = ({ cctv, onStreamSelect }) => (
    <>
        <Typography variant="subtitle1">{cctv.name}</Typography>
        <Typography variant="body2">CCTV代碼: {cctv.code}</Typography>
        <Typography variant="body2">偵測類別: {cctv.object_type}</Typography>
        <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={() => onStreamSelect({
                id: cctv.id,
                name: cctv.name,
                dns_url: cctv.dns_url,
                code: cctv.code
            })}
        >
            選擇此攝影機
        </Button>
    </>
);

// Reader Popup
export const ReaderPopup = ({ reader }) => (
    <>
        <Typography variant="subtitle1">{reader.address}</Typography>
        <Typography variant="body2">設備編號: {reader.name}</Typography>
        <Typography variant="body2">偵測數量: {reader.detection_count}</Typography>
        <Typography variant="body2">最後更新時間: {new Date(reader.updateTime).toLocaleString()}</Typography>
        <Typography variant="body2">
            連線狀態: {reader.status === 1 ? (
                <span style={{ color: 'green' }}>連線</span>
            ) : (
                <span style={{ color: 'red' }}>斷線</span>
            )}
        </Typography>
    </>
);