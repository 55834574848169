import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import $ from 'jquery';

// material
import { Grid, Button, Container, Stack, Typography, Tabs, Tab, } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AAppWebsiteVisits from '../components/AAppWebsiteVisits';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import SelectMenuItem from '../components/SelectMenuItem'
import ToggleButtons from '../components/ToggleButton'

import ReaderCountComponent2 from './ReaderCountComponent2'
// import TurningTrafficChart from './TurningTrafficChart'
import TurningTrafficSvg from './TurningTrafficSvg'

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";

// 定義切換頁
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        'style': { 'font-size': '24px' },
    };
}

export default function SessionspeedAnalyze() {
    const [mapZoom, setmapZoom] = useState(12);
    const [leonReaderlist, setleonReaderlist] = useState([]);
    const [trafficFlowData, settrafficFlowData] = useState([]);
    const [sessionTrafficFlow, setsessionTrafficFlow] = useState([]);
    const [speedlist, setspeedlist] = useState([]);
    const [Geolinelist, setGeolinelist] = useState([]);
    const [routeTrafficList, setrouteTrafficList] = useState([]);
    const [routeTrafficSafeData, setrouteTrafficSafeData] = useState([]);
    const [routeSelectList, setrouteSelectList] = useState([]);
    const [routeList, setrouteList] = useState([]);
    const [routeSelect, setrouteSelect] = useState([]);
    const [mapcenter, setmapcenter] = useState([24, 121]);
    const [openmap, setopenmap] = useState(null);
    const [ToggleButtonselect, setToggleButtonselect] = useState(60);
    const [ToggleButtonValue, setToggleButtonValue] = useState(60);

    const ToggleButtonsOptions = [
        { value: 5, Name: '5分鐘' },
        { value: 15, Name: '15分鐘' },
        { value: 30, Name: '30分鐘' },
        { value: 60, Name: '60分鐘' },
    ]
    const flymap = (point) => {
        openmap.flyTo(point, 15)
    }
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;
    const dispatch = useDispatch();

    // useEffect(() => {
    //     // getspeedData(ToggleButtonselect);
    //     // getData(ToggleButtonselect);
    // });

    useEffect(() => {
        getrouteData();
        // getData(ToggleButtonselect);
        if (openmap != null) {
            openmap.fitBounds([[25.112698, 121.311192], [25.080091, 121.24397499999999]])
        }
    }, [users]);

    useEffect(() => {

        if (openmap != null) {
            openmap.on("zoomend", () => {

                const zoom = openmap.getZoom();
                let lineWidth = 10
                if (zoom < 16)
                    lineWidth -= 3
                if (zoom < 17)
                    lineWidth -= 3
                if (zoom < 18)
                    lineWidth -= 3
                // // console.log(lineWidth)
                const pathElement = document.getElementsByClassName('leaflet-interactive');
                // const parentElement = document.getElementsByClassName('leaflet-interactive')[0].parentElement.nodeName;
                for (let i = 0; i < pathElement.length; i += 1)
                    pathElement[i].style.strokeWidth = `${4}px`
                // const pathlist = document.getElementsByClassName('leaflet-interactive');
                // try{
                //     // console.log(openmap.getZoom())
                //     // console.log(document.getElementsByClassName("leaflet-interactive")[2].attributes[7].value)}
                // catch(e){
                //     // console.log('e')
                // }

                // const path = document.getElementsByClassName('leaflet-interactive')[0];
                // path.style.strokeWidth = `${50}px`
                // // console.log(linewidth[0])
                // // console.log(path)
                // $(parentElement).append(path);
            });
            // // console.dir(openmap.getZoom())
            // // console.dir(openmap._layers)
            // // console.dir(openmap)
        }

    }, [openmap]);

    useEffect(() => {
        // console.dir('setGeolinelist')
        let list = []
        for (let i = 0; i < routeTrafficSafeData.length; i += 1) {
            let color = 'grey'
            if (routeTrafficSafeData[i].travelSpeed > 0)
                color = 'red'
            if (routeTrafficSafeData[i].travelSpeed >= 15)
                color = 'orange'
            if (routeTrafficSafeData[i].travelSpeed >= 35)
                color = 'green'
            list = list.concat(convertGeoline(JSON.parse(routeTrafficSafeData[i].linejosn), color, routeTrafficSafeData[i]))
        }
        // console.dir(Geolinelist)
        // console.dir(list)
        setGeolinelist(list)
    }, [routeTrafficSafeData]);

    useEffect(() => {
        if (routeSelectList.length !== 0) {
            getspeedData(ToggleButtonselect);
        }
    }, [routeSelectList]);

    useEffect(() => {
        if (routeSelectList.length !== 0) {
            getData(ToggleButtonselect);
        }
    }, [speedlist]);

    // 取得路段資料
    const getrouteData = async () => {
        await axios
            .get(`https://${ipconfig.apiIP}/api/v1/trafficFlow/getTrafficFlowSafeInfo`)
            .then(async (response) => {
                // eslint-disable-next-line eqeqeq
                // console.log(response.data)
                if (response.data.resultCode === "Success") {
                    setrouteList(response.data.rows)
                    const list = [];
                    const list1 = [];
                    const obj = {};
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        list.push(response.data.rows[i].pathName);
                        list1.push({ "id": response.data.rows[i].id, "address": response.data.rows[i].pathName })
                        // list.push(response.data.rows[i].pathName);  

                        const routeID = response.data.rows[i].id;
                        const isLeon = "is_leon".toString();
                        const notLeon = "not_leon".toString();
                        const isTruckNotLeon = "isTruckNotLeon".toString();
                        if (obj[routeID] === undefined)
                            obj[routeID] = {};
                        if (obj[routeID][isLeon] === undefined) {
                            obj[routeID][isLeon] = [];
                            obj[routeID][notLeon] = [];
                            obj[routeID][isTruckNotLeon] = [];
                        }

                    }
                    setrouteTrafficList(obj);
                    setrouteSelect(list);
                    setrouteSelectList(list1);

                    // // console.dir(response.data.rows)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                // // console.dir('leonReaderlist api then')
            });
        // setOpen(true);
    };

    useEffect(() => {
        // console.dir(speedlist)
        if (speedlist.length > 0) {
            const list = [];
            for (let i = 0; i < speedlist.length; i += 1) {
                const l = speedlist[i];
                if (l.pathName !== null) {
                    const line = {
                        data: [[l.G1lon, l.G1lat], [l.G2lon, l.G2lat]],
                        color: 'grey'
                    }
                    if (l.travelSpeed > 0)
                        line.color = 'red'
                    if (l.travelSpeed >= 15)
                        line.color = 'orange'
                    if (l.travelSpeed >= 35)
                        line.color = 'green'

                    list.push(line)
                }
            }
            // setGeolinelist(list)
        }
    }, [speedlist]);

    // 清除之前車流安全的資料
    const clearObjData = async () => {
        const obj = routeTrafficList;
        Object.keys(obj).forEach(key => {
            Object.keys(obj[key]).forEach(key1 => {
                obj[key][key1] = [];
            })
        })
        setrouteTrafficList(obj);
    }
    // 取得數度資料
    const getspeedData = async (minutes) => {
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/map/getTruckTravelInfo`, { "minutes": minutes, token: users.token })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq           
                // console.log(response.data)
                if (response.data.resultCode === "Success") {
                    const list = []
                    // console.log(routeTrafficSafeData);         
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        if (routeTrafficSafeData.findIndex((r) => r.pathName === response.data.rows[i].pathName) > -1)
                            list.push(response.data.rows[i])
                    }

                    setspeedlist(response.data.rows)
                    // setspeedlist(list)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
            });
        // setOpen(true);
    };
    // 取德車流安全資料(XX分鐘內)
    const getData = async (minutes) => {
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/trafficFlow/getTrafficFlowSafe`, { "token": users.token, "minutes": minutes })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq                
                const obj = routeTrafficList;
                // console.dir(obj)
                // console.dir(response.data)
                if (response.data.resultCode === "Success") {
                    clearObjData();
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        const routeID = response.data.rows[i].route_id;
                        let planType;
                        if (response.data.rows[i].is_leonTag >= 0)
                            planType = "is_leon";
                        else
                            planType = "not_leon";
                        if (response.data.rows[i].is_leonTag === 0 && response.data.rows[i].isTruckNotLeon)
                            planType = "isTruckNotLeon";
                        obj[routeID][planType].push(response.data.rows[i])
                    }
                    // // console.dir(obj)                    
                    // // console.log(response.data.rows);
                }
                setTableData();
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
            });
        // setOpen(true);
    };


    // 設定資料(DataTable呈現)
    const setTableData = () => {
        const obj = routeTrafficList;
        const list = [];
        let index = 1;
        // console.dir(routeList)
        // const selectidarray = routeSelect.map(r => routeSelectList.find(a => a.address === r).id)
        const selectidarray = routeSelectList.map(r => r.id)
        for (let i = 0; i < routeList.length; i += 1) {
            const routeID = routeList[i].id;
            const isLeon = "is_leon".toString();
            const notLeon = "not_leon".toString();
            const isTruckNotLeon = "isTruckNotLeon".toString();
            const speedIndex = speedlist.findIndex(a => a.route_id === routeList[i].id)
            // // console.log(speedlist[speedIndex])
            // // console.log(speedIndex)
            let serviceLevel = 'F';

            if (selectidarray.findIndex(a => a === routeList[i].id) > -1) {

                if (speedIndex === -1) {
                    list.push({
                        "id": routeList[i].id,
                        "rownum": index,
                        "pathName": routeList[i].pathName,
                        "travelSpeed": 0,
                        "serviceLevel": '無資料',
                        "travelTime": 0,
                        "distance": routeList[i].distance,
                        "routeReader": `${routeList[i].reader_name_1},${routeList[i].reader_name_2}`,
                        "linejosn": routeList[i].linejosn
                    })
                }
                else {
                    const speed = parseInt(speedlist[speedIndex].travelSpeed, 10)
                    if (speed >= 35)
                        serviceLevel = 'A';
                    else if (speed < 35 && speed >= 30)
                        serviceLevel = 'B';
                    else if (speed < 30 && speed >= 25)
                        serviceLevel = 'C';
                    else if (speed < 25 && speed >= 20)
                        serviceLevel = 'D';
                    else if (speed < 20 && speed >= 15)
                        serviceLevel = 'E';
                    else
                        serviceLevel = 'F';
                    list.push({
                        "id": routeList[i].id,
                        "rownum": index,
                        "pathName": routeList[i].pathName,
                        "serviceLevel":serviceLevel,
                        "travelSpeed": parseInt(speedlist[speedIndex].travelSpeed, 10),
                        "travelTime": speedlist[speedIndex].travelTime.toFixed(2),
                        "distance": routeList[i].distance,
                        "routeReader": `${routeList[i].reader_name_1},${routeList[i].reader_name_2}`,
                        "linejosn": routeList[i].linejosn
                    })
                }
                index += 1;
            }
        }
        setrouteTrafficSafeData(list)
    }


    // 圖標顯示內容
    const linepopupcontent = (pathName, travelSpeed, travelTime, color) => {
        const r = pathName

        return (
            <div>
                <div className={Tooltip.text_root}>

                    <div className='pb-4'>
                        <span id="lineTitle" className={Tooltip.text_name}>{pathName}</span>
                    </div>
                    <div className='pb-4'>
                        {/* <span className={Tooltip.text_address} style={{color:color}}>旅行速度 : {travelSpeed} Km/h</span> */}
                        <span id="lineContent1" className={Tooltip.text_address} >旅行速度 : {travelSpeed} Km/h</span>
                    </div>
                    <div className='pb-4'>
                        <span id="lineContent2" className={Tooltip.text_address} >旅行時間 : {travelTime} 分鐘</span>
                    </div>
                </div>
            </div>
        )
    }
    // 圖標顯示內容
    const popupcontent = (row, textColor) => {
        const r = row
        // // console.log(r)
        return (
            <div>
                <div className={Tooltip.text_root}>

                    <div className='pb-4'>
                        <span className={Tooltip.text_name}>{row.reader_info_address}</span>
                    </div>
                    <div className='pb-4'>
                        <span className={Tooltip.text_address}>智慧感知讀取器名稱 : {row.reader_info_leonReaderName}</span>
                    </div>
                    <div className='pb-4'>
                        <span className={Tooltip.text_address}>設備編號 : {row.reader_info_name}</span>
                    </div>
                    <div className='pb-4'>
                        <span className={Tooltip.text_address} style={{ color: textColor }}>偵測之數量 : {row.ReaderCount}</span>
                    </div>
                </div>
            </div>
        )
    }


    const columns = [
        { field: "id", headerName: "id", width: 70, hideable: true },
        { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
        {
            field: "pathName", headerName: "路段名稱", flex: 0.4, sortable: true, headerAlign: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value === 'string')
                    return (params.value);
                const children = params.value.map((val) => (
                    <div >{val}</div>
                ));
                return (
                    <div style={{ fontSize: '20px' }}>
                        {children}
                    </div>
                );
            }
        },
        { field: "serviceLevel", headerName: "服務水準", flex: 0.2, sortable: true, headerAlign: 'center', align: 'center', },
        { field: "travelSpeed", headerName: "旅行速度(Km/h)", flex: 0.3, sortable: true, headerAlign: 'center', align: 'center', },
        { field: "travelTime", headerName: "旅行時間(分鐘)", flex: 0.28, sortable: true, headerAlign: 'center', align: 'center', },
        { field: "distance", headerName: "路段長度(公尺)", width: 100, flex: 0.28, sortable: true, headerAlign: 'center', align: 'center', },
        { field: "routeReader", headerName: "相關設備編號(起,訖)", flex: 0.35, width: 200, headerAlign: 'center', align: 'center', },
    ];

    const TableCellClassName = (params) => {
        const value = params.row.travelSpeed;
        if (value >= 35)
            return 'green';
        if (value >= 15)
            return 'orange';
        if (value > 0)
            return 'red';
        return 'grey';
    }

    useEffect(() => {
        setToggleButtonValue(ToggleButtonselect)
        getspeedData(ToggleButtonselect);
        getData(ToggleButtonselect);
    }, [ToggleButtonselect]);

    const searchOnClick = () => {
        setToggleButtonValue(ToggleButtonselect)
        getspeedData(ToggleButtonselect);
        getData(ToggleButtonselect);

    }

    const convertGeoline = (array, color, routeData) => {
        // // console.log(routeData)
        if (color === 'red')
            color = '#f24a40'
        if (color === 'orange')
            color = '#fd7000'
        if (color === 'green')
            color = '#43c548'
        if (color === 'grey')
            color = '#666666'
        const list = []
        for (let z = 0; z < array.length - 1; z += 1) {
            list.push({
                data: [array[z], array[z + 1]],
                color: color,
                id: routeData.id,
                pathName: routeData.pathName,
                travelSpeed: routeData.travelSpeed,
                travelTime: routeData.travelTime,
            })
        }
        return list
    }

    const [tablePopup, settablePopup] = React.useState(0);

    const TableOnClick = (params, event) => {
        // console.log(params)
        // console.log(event)
        // console.log(JSON.parse(params.row.linejosn))
        if (!event.ctrlKey) {
            event.defaultMuiPrevented = true;
            // alert(JSON.stringify(params));
            // openmap.flyTo([params.row.latitude, params.row.longitude], 23);
            const lineJson = JSON.parse(params.row.linejosn)
            const arrayLength = lineJson.length - 1;
            const latlon = [lineJson[0][1], lineJson[0][0]];
            // const latlon1 = [lineJson[arrayLength][1],lineJson[arrayLength][0]];


            const bounds = [[0, 0], latlon]
            for (let i = 0; i < lineJson.length; i += 1) {
                const lat = lineJson[i][1]
                const lon = lineJson[i][0]
                if (lat > bounds[0][0])
                    bounds[0][0] = lat
                else if (lat < bounds[1][0])
                    bounds[1][0] = lat

                if (lon > bounds[0][1])
                    bounds[0][1] = lon
                else if (lon < bounds[1][1])
                    bounds[1][1] = lon
            }
            bounds[0][0] += 0.001
            bounds[0][1] += 0.001
            bounds[1][0] -= 0.001
            bounds[1][1] -= 0.001
            // console.log(bounds)

            Object.keys(openmap._layers).forEach(key => {
                // console.log(key)
                // console.log(openmap._layers[key] )

                if (openmap._layers[key] !== undefined)
                    if (params.row.id === openmap._layers[key].options.id) {
                        openmap._layers[key].openPopup()
                    }
            })
            openmap.fitBounds(bounds);

            // 圖標顯示內容
            // const view = linepopupcontent(params.row.pathName,params.row.travelSpeed,params.row.travelTime,"")
            // settablePopup({"id":params.row.id,"view":view})
            // // openmap.setPopupContent(view)
            // $("#lineTitle").textContent = params.row.pathName
            // $("#lineContent1").textContent = `旅行速度 : {travelSpeed} Km/h`
            // $("#lineContent2").textContent = `旅行時間 : {travelTime} 分鐘`
            // // console.log(params.row.pathName,params.row.travelSpeed,params.row.travelTime,"")
            // // console.log(openmap)
        }
    }


    const [tabValue, settabValue] = React.useState(0);

    // tab切換
    const handleChange = (event, newValue) => {
        setopenmap(null)
        settabValue(newValue);
    };



    // 0-> show ; 1-> hidden
    const show = 0;
    const hidden = 1;
    const [speedTable, setspeedTable] = React.useState(show);
    const [TrafficTable, setTrafficTable] = React.useState(hidden);
    const [tableTabValue, settableTabValue] = React.useState(0);

    // tab切換 
    // 選擇顯示哪個表格，只可以用隱藏的，其他的方法數值會無法更新
    const tableHandleChange = (event, newValue) => {
        // 旅行速度資訊
        if (newValue === 0) {
            setTrafficTable(hidden)
            setspeedTable(show)
        }
        // 車流資訊
        else if (newValue === 1) {
            setspeedTable(hidden)
            setTrafficTable(show)
        }
        settableTabValue(newValue);
    };

    return (
        <Grid >


            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    <Stack direction="row" spacing={2}>
                        {/* <MultipleSelectCheckmarks Option={routeSelectList} select={routeSelect} setselect={setrouteSelect} /> */}
                        <ToggleButtons Options={ToggleButtonsOptions} setSelect={setToggleButtonselect} Select={ToggleButtonselect} />
                    </Stack>

                </Typography>
                {/* <Button variant="contained" size="large" onClick={searchOnClick} >查詢</Button> */}
            </Stack>


            <Grid container spacing={3}>
                <Grid item xs={12} style={{ position: 'relative' }} mb={3}>
                    <BMap Geoarray={Geolinelist} mapcenter={mapcenter} setopenmap={setopenmap} openmap={openmap}
                        MarkerArray={leonReaderlist} popupcontent={popupcontent}
                        ispopupline='true' linepopupcontent={linepopupcontent}
                        tablePopup={tablePopup}
                    />
                    <div className={Styles.readerCountTips_red}>紅</div>
                    <div className={Styles.readerCountTips_red1}>不安全</div>
                    <div className={Styles.readerCountTips_orange}>橘</div>
                    <div className={Styles.readerCountTips_orange1}>稍不安全</div>
                    <div className={Styles.readerCountTips_green}>綠</div>
                    <div className={Styles.readerCountTips_green1}>安全</div>
                    <div className={Styles.readerCountTips_grey}>灰</div>
                    <div className={Styles.readerCountTips_grey1}>更新中</div>

                </Grid>

                <Container maxWidth="xl">
                    <Grid mb={3} >
                        <Tabs value={tableTabValue} onChange={tableHandleChange} aria-label="basic tabs example">
                            <Tab label="旅行速度與旅行時間資訊" {...a11yProps(0)} />
                            <Tab label="車流資訊" {...a11yProps(1)} />
                        </Tabs>
                    </Grid>



                    <Grid item xs={12} hidden={speedTable}>
                        <Box
                            sx={{
                                height: '100%',
                                width: 1,
                                '& .select': {
                                    backgroundColor: 'rgba(157, 255, 118, 0.49)',
                                    // color: '#ca2d40',
                                    // fontWeight: '600',
                                },
                                '& .red': {
                                    // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                                    color: '#ca2d40',
                                    // fontWeight: '600',
                                },
                                '& .orange': {
                                    // backgroundColor: '#d47483',
                                    color: '#fd7000',
                                    // fontWeight: '600',
                                },
                                '& .green': {
                                    // backgroundColor: '#d47483',
                                    color: '#26ac23',
                                    // fontWeight: '600',
                                },
                                '& .grey': {
                                    // backgroundColor: '#d47483',
                                    color: '#666666',
                                    // fontWeight: '600',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontSize: '22px'
                                },
                                '& .MuiDataGrid-cellContent': {
                                    fontSize: '20px'
                                }
                            }}
                        >
                            <BDataGrid data={routeTrafficSafeData} columns={columns}
                                TableOnClick={TableOnClick}
                                TableCellClassName={TableCellClassName}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} hidden={TrafficTable} >
                        <ReaderCountComponent2 openmap={openmap} ToggleButtonValue={ToggleButtonValue}
                            leonReaderlist={leonReaderlist} setleonReaderlist={setleonReaderlist} />
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}
