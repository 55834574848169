import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";


import AAppConversionRates from '../AAppConversionRates';
import * as ipconfig from "../../config/ipconfig";


export default function TrafficAnalyzeOfConversionRates(props) {
    const { sessionlist, select, tagNam, chartTitle, sqlText } = props
    const { readerlist, readerselect } = props
    const { startDate, endDate } = props
    const { hour1, hour2 } = props
    const { chartUnit , chartUnit1, time } = props
    const { unitAmountValue } = props
    const [data, setdata] = useState([]);

    const dispatch = useDispatch();
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;
    // useEffect(() => {
    //     getTrafficFlowData(); 
    // }, [users,startDate,endDate]);

    const timer = React.useRef();


    useEffect(() => {
        // console.log(data)
        if (sessionlist.length > 0) {
            setTableData();
            // let lodingTime = 1000;
            // if (chartUnit === 'day')
            //     lodingTime = 3000
            // else if (chartUnit === 'month')
            //     lodingTime = 5000
            // timer.current = window.setTimeout(() => {
            //     dispatch({ type: "END" });
            // }, lodingTime);
            // dispatch({type: "END"}); 
        }
    }, [data, select, readerselect,unitAmountValue]);

    useEffect(() => {
        try {
            if (users.id !== undefined)
                if (sessionlist.length > 0 && startDate !== '') {
                    // console.log(users)
                    // console.log(startDate)
                    // console.log(endDate)
                    // console.log(select)
                    // dispatch({ type: "START" });
                    getTrafficFlowData();
                    // dispatch({type: "END"}); 

                }
        }
        catch (e) {
            console.log(e.toString)
        }
    }, [sessionlist, readerselect, select, users, endDate]);

    /* ******    取得全部路口車流資料  ********
        假設 datetime = 2022/04/26 00:00:00
        如果本地端為UTC+8 
        utc0 = 2022/04/25 16:00:00
        difference = 8
    *************************************** */
    const getTrafficFlowData = async () => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        // const utc01 = new Date(`${moment(startDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
        // const utc02 = new Date(`${moment(endDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
        const date1 = startDate
        const date2 = endDate
        const readerGroupName = sessionlist[select].description
        const readerName = readerlist.length === 0 ? '全部' : readerlist[readerselect].description1
        // const readerGroupName
        // const difference = 8
        // const sqlText= 'week,Hours  having Hours >= 17 and Hours <= 19  ORDER BY week'
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/trafficFlow/getSessionTrafficFlowOfAnalyzeFilter1`, {
                "token": users.token, "date1": date1, "date2": date2, "sqlText": sqlText, "readerGroupName": readerGroupName, "readerName": readerName,
                "hour1": hour1, "hour2": hour2, "utc": difference
            })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq   
                if (response.data.resultCode === "Success") {
                    setdata(response.data.rows)
                    // // console.log(response.data.rows);
                    // const obj = {};        
                    // const objWeek = {};        
                    // const objMonth = {};        
                    // for(let i = 0;i<roadArealist.length;i+=1){
                    //     obj[roadArealist[i].id] = {}
                    //     for(let j = 0;j<24;j+=1){
                    //         obj[roadArealist[i].id][j] = []
                    //     }
                    // }               
                    // const data = response.data.rows;                    
                    // for (let i = 0; i < data.length; i += 1){
                    //     if(data[i].Hours !== null){
                    //         obj[data[i].session_info_id][data[i].Hours].push(data[i]);       

                    //     }                        
                    // }
                    // settrafficFlowHourData(obj)
                }
            })
            .catch((error) => {
                dispatch({ type: "END" });
                // handle error
                // // console.log(error);
            })
            .then(() => {
                // dispatch({type: "END"}); 
                // always executed
            });
        // setOpen(true);
    };

    // const [chartTitle, setchartTitle] = useState("");
    const [chartSubtitle, setchartSubtitle] = useState("");

    const [chartOptions, setchartOptions] = useState({});
    const [chartData, setchartData] = useState([]);

    const setTableData = async () => {

        // setreaderGroupInfoNameMap(rGroupInfoNameMap);
        // setsessionDataOfTrafficFlow(list);      

        // // console.log(data)
        const dataListTemp = [];
        // for (let i = 0; i < data.length; i += 1){
        //     if(sessionlist[select].description === data[i].reader_group_info_name){
        //         dataListTemp.push(data[i].Date);
        //     }
        // }
        const dateListTemp = [];
        if (chartUnit === 'Hours') {
            for (let i = 0; i < 24; i += 1) {
                dateListTemp.push(i);
            }
        }
        else if (chartUnit === 'DateHHMM') {
            if (time === 'morning') {
                dateListTemp.push('7:0');
                dateListTemp.push('7:15');
                dateListTemp.push('7:30');
                dateListTemp.push('7:45');
                dateListTemp.push('8:0');
                dateListTemp.push('8:15');
                dateListTemp.push('8:30');
                dateListTemp.push('8:45');
            }
            else {
                dateListTemp.push('17:0');
                dateListTemp.push('17:15');
                dateListTemp.push('17:30');
                dateListTemp.push('17:45');
                dateListTemp.push('18:0');
                dateListTemp.push('18:15');
                dateListTemp.push('18:30');
                dateListTemp.push('18:45');
            }
        }
        else if (chartUnit === 'day' && chartUnit1 === 'week') {
            // 當週天數
            const dd = moment(endDate).diff(startDate, 'day')
            const list = []
            for (let i = 0; i <= dd; i += 1) {
                const d = moment(endDate).subtract(i, 'days').format('DD');
                list.push(d);
            }
            for (let i = list.length - 1; i >= 0; i -= 1) {
                dateListTemp.push(parseInt(list[i],10));
            }

        }
        else if (chartUnit === 'day') {
            // 當月天數
            const dd = moment(endDate, "YYYY-MM").daysInMonth()
            for (let i = 1; i <= dd; i += 1) {
                dateListTemp.push(i);
            }
        }
        else if (chartUnit === 'month' && chartUnit1 === 'quarter') {            
            // 當季幾個月
            const dd = moment(endDate).diff(startDate, 'month')
            const list = []
            for (let i = 0; i <= dd; i += 1) {
                const d = moment(endDate).subtract(i, 'months').format('MM');
                list.push(d);
            }
            for (let i = list.length - 1; i >= 0; i -= 1) {
                dateListTemp.push(parseInt(list[i],10));
            }
        }
        else if (chartUnit === 'month') {
            for (let i = 1; i <= 12; i += 1) {
                dateListTemp.push(i);
            }
        }
        else {
            for (let i = 0; i < data.length; i += 1) {
                if (sessionlist[select].description === data[i].reader_group_info_name) {
                    dateListTemp.push(data[i][chartUnit]);
                }
            }
        }
        // const dataList = [...new Set(dateListTemp)];
        const dataList = dateListTemp;

        // // console.log(dataList);

        if (chartUnit === 'month')
            setChartData1(dataList);
        else
            setChartData(dataList);
        // // console.dir(list)
        // // console.dir(sessionSelect)
        // const list2 = [];
        // for (let i = 0; i < list.length; i += 1){        
        //     for (let j = 0; j < sessionSelect.length; j += 1){
        //         if(list[i].sessionID === sessionSelect[j])
        //         list2.push(list[i])
        //     }
        // }
        // settabledata(list);
        // // // console.log(list2)
        // if(list2.length > 0){
        //     setchartTitle(list2[0].readerGroupInfoName);
        //     setChartData(list2);
        // }

    }


    // 統計顯示相關
    const setChartData = (dateList) => {
        // // console.log(data)
        // A : Leon
        // B : truckNotLeon
        // C : notLeon
        // D : total

        // A : 本計畫之大型車輛
        // B : etag大車
        // C : etag小客車
        // D : 總和 = A+C
        const dataA = [];
        const dataB = [];
        const dataC = [];
        const dataD = [];
        // 先建立arraySize避免沒資料，會導致錯位
        for (let i = 0; i < dateList.length; i += 1) {
            dataA.push(0);
            dataB.push(0);
            dataC.push(0);
            dataD.push(0);
        }

        if (chartUnit === 'DateHHMM') {
            for (let i = 0; i < dateList.length; i += 1) {
                for (let j = 0; j < data.length; j += 1) {
                    if (sessionlist[select].description === data[j].reader_group_info_name && dateList[i] === data[j][chartUnit]) {
                        const isetagData = !Number.isNaN(data[j].iseTag)
                        const etagData = isetagData ? parseInt(data[j].iseTag, 10) : 9999
                        if (data[j].isLeonTag !== 0)
                            dataA[i] += data[j].count*unitAmountValue;
                        else if (isetagData && etagData >= 4 && etagData <= 5)
                            dataB[i] += data[j].count*unitAmountValue;
                        else if (isetagData && etagData === 3)
                            dataC[i] += data[j].count;
                    }
                }
            }
        }
        else {
            for (let k = 0; k < dateList.length; k += 1) {
                data.forEach((d) => {
                    if (sessionlist[select].description === d.reader_group_info_name && dateList[k] === d[chartUnit]) {
                        const isetagData = !Number.isNaN(d.iseTag)
                        const etagData = isetagData ? parseInt(d.iseTag, 10) : 9999
                        if (d.isLeonTag !== 0)
                            dataA[k] += parseInt(d.count*unitAmountValue / 2, 10);
                        else if (isetagData && etagData >= 4 && etagData <= 5)
                            dataB[k] += parseInt(d.count*unitAmountValue / 2, 10);
                        else if (isetagData && etagData === 3)
                            dataC[k] += parseInt(d.count / 2, 10);
                    }
                })
                // for (let j = 0; j < data.length; j += 1){
                //     // console.log(j)
                //     if(sessionlist[select].description === data[j].reader_group_info_name && dateList[i] === data[j][chartUnit]){   
                //         const isetagData = !Number.isNaN(data[j].iseTag)
                //         const etagData = isetagData ? parseInt(data[j].iseTag, 10):9999
                //         if(data[j].isLeonTag !== 0)
                //             dataA[i] += parseInt(data[j].count/2,10);
                //         else if(isetagData && etagData >= 4 &&  etagData <= 5)
                //             dataB[i] += parseInt(data[j].count/2,10);          
                //         else if(isetagData && etagData === 3)
                //             dataC[i] += parseInt(data[j].count/2,10);
                //     }
                // }
            }
        }
        for (let i = 0; i < dateList.length; i += 1) {
            dataD[i] = dataA[i] + dataC[i] + dataB[i];
        }


        const data1 = [
            {
                name: '本計畫之大型車輛',
                data: dataA,
                color: '#00AB55'
            },
            {
                name: 'etag大型車輛',
                data: dataB,
                color: '#2D99FF'
            },
            {
                name: 'etag小客車',
                data: dataC,
                color: '#CA8EFF'
            },
            // {
            //   name: '總數',
            //   type: 'area',
            //   data: dataD,
            //   color: '#FF9700'
            // },
        ];
        setchartData(data1);
        setChartOption(dateList);
    }

    // 統計顯示相關
    const setChartData1 = (dateList) => {
        // A : 本計畫之大型車輛
        // B : etag大車
        // C : etag小客車
        // D : 總和 = A+C
        const dataA = [];
        const dataB = [];
        const dataC = [];
        const dataD = [];
        const dataAA = [];
        const dataBB = [];
        const dataCC = [];
        const dataDD = [];
        // 先建立arraySize避免沒資料，會導致錯位
        for (let i = 0; i < dateList.length; i += 1) {
            dataA.push(0);
            dataB.push(0);
            dataC.push(0);
            dataD.push(0);
            dataAA[i] = [];
            dataBB[i] = [];
            dataCC[i] = [];
            dataDD[i] = [];
        }
        for (let i = 0; i < dateList.length; i += 1) {
            for (let j = 0; j < data.length; j += 1) {
                if (sessionlist[select].description === data[j].reader_group_info_name && dateList[i] === data[j][chartUnit]) {
                    const isetagData = !Number.isNaN(data[j].iseTag)
                    const etagData = isetagData ? parseInt(data[j].iseTag, 10) : 9999
                    if (data[j].isLeonTag !== 0)
                        dataAA[i].push(parseInt(data[j].count*unitAmountValue / 2, 10))
                    else if (isetagData && etagData >= 4 && etagData <= 5)
                        dataBB[i].push(parseInt(data[j].count*unitAmountValue / 2, 10))
                    else if (isetagData && etagData === 3)
                        dataCC[i].push(parseInt(data[j].count / 2, 10))
                }
            }
        }

        for (let i = 0; i < dateList.length; i += 1) {
            for (let j = 0; j < dataAA[i].length; j += 1) {
                dataA[i] += dataAA[i][j];
            }
            dataA[i] = dataAA[i].length > 0 ? parseInt(dataA[i] / dataAA[i].length, 10) : 0;

            for (let j = 0; j < dataBB[i].length; j += 1) {
                dataB[i] += dataBB[i][j];
            }
            dataB[i] = dataBB[i].length > 0 ? parseInt(dataB[i] / dataBB[i].length, 10) : 0;

            for (let j = 0; j < dataCC[i].length; j += 1) {
                dataC[i] += dataCC[i][j];
            }
            dataC[i] = dataCC[i].length > 0 ? parseInt(dataC[i] / dataCC[i].length, 10) : 0;


        }

        const data1 = [
            {
                name: '本計畫之大型車輛',
                data: dataA,
                color: '#00AB55'
            },
            {
                name: 'etag大型車輛',
                data: dataB,
                color: '#2D99FF'
            },
            {
                name: 'etag小客車',
                data: dataC,
                color: '#CA8EFF'
            },
            // {
            //   name: '總數',
            //   type: 'area',
            //   data: dataD,
            //   color: '#FF9700'
            // },
        ];
        setchartData(data1);
        setChartOption(dateList);
    }

    // 統計顯示相關
    const setChartOption = (chartLabels) => {

        const dateListTemp = [];
        const dateListTemp1 = [];
        if (chartUnit === 'Hours') {
            for (let i = 0; i < 24; i += 1) {
                dateListTemp.push(`${i}:00`);
            }
        }
        else if (chartUnit === 'DateHHMM') {
            if (time === 'morning') {
                dateListTemp.push('7:00');
                dateListTemp.push('7:15');
                dateListTemp.push('7:30');
                dateListTemp.push('7:45');
                dateListTemp.push('8:00');
                dateListTemp.push('8:15');
                dateListTemp.push('8:30');
                dateListTemp.push('8:45');
            }
            else {
                dateListTemp.push('17:00');
                dateListTemp.push('17:15');
                dateListTemp.push('17:30');
                dateListTemp.push('17:45');
                dateListTemp.push('18:00');
                dateListTemp.push('18:15');
                dateListTemp.push('18:30');
                dateListTemp.push('18:45');
            }
        }
        else if (chartUnit === 'day' && chartUnit1 === 'week') {
            // 當週天數
            const dd = moment(endDate).diff(startDate, 'day')
            const list = []
            for (let i = 0; i <= dd; i += 1) {
                const d = moment(endDate).subtract(i, 'days').format('YYYY/MM/DD');
                list.push(d);
            }            
            for (let i = list.length - 1; i >= 0; i -= 1) {
                const list1 = []
                dateListTemp.push(`${list[i]}`);
                list1.push(list[i])
                const mapping = ['一','二','三','四','五','六','日',]
                list1.push(`星期${mapping[moment(list[i]).isoWeekday()-1]}`)
                dateListTemp1.push(list1);
            }
            // console.log(`相差天數 : ${dd}` )
            // console.log(`相差天數 : ${dateListTemp}` )
        }
        else if (chartUnit === 'day') {
            // 當月天數
            const dd = moment(endDate, "YYYY-MM").daysInMonth()
            for (let i = 1; i <= dd; i += 1) {
                dateListTemp.push(`${moment(endDate).format('YYYY/MM')}/${i}`);
            }
        }
        else if (chartUnit === 'month' && chartUnit1 === 'quarter') {            
            // 當季幾個月
            const dd = moment(endDate).diff(startDate, 'month')
            const list = []
            for (let i = 0; i <= dd; i += 1) {
                const d = moment(endDate).subtract(i, 'months').format('YYYY/MM');
                list.push(d);
            }
            for (let i = list.length - 1; i >= 0; i -= 1) {
                dateListTemp.push(list[i]);
            }
        }
        else if (chartUnit === 'month') {
            for (let i = 1; i <= 12; i += 1) {
                dateListTemp.push(`${moment(endDate).format('YYYY')}/${i}`);
            }
        }
        else {
            for (let i = 0; i < data.length; i += 1) {
                if (sessionlist[select].description === data[i].reader_group_info_name) {
                    if (chartUnit === 'year')
                        dateListTemp.push(`${data[i][chartUnit]}年`);
                    else if (chartUnit === 'quarter')
                        dateListTemp.push(`${data[i].year}第${data[i][chartUnit]}季`);
                    else if (chartUnit === 'month')
                        dateListTemp.push(`${data[i].year}/${data[i][chartUnit]}`);
                    else if (chartUnit === 'week')
                        dateListTemp.push(`${data[i].year} 第${data[i][chartUnit]}週`);
                    else
                        dateListTemp.push(`${data[i][chartUnit]}`);
                }
            }

        }

        // const chartLabels1 = [...new Set(dateListTemp)];
        const chartLabels1 = dateListTemp;
        const chartLabels2 = dateListTemp1.length === 0 ?dateListTemp : dateListTemp1;

        const chartOption = {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                zoom: {
                    enabled: true
                },
                toolbar: {
                    show: true,
                    offsetY: -30,
                    export: {
                        csv: {
                            filename: undefined,
                        },
                        png: {
                            filename: undefined,
                        }
                    }
                },
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%',
                    barHeight: '100%',
                    horizontal: false,
                }
            },
            stroke: {
                show: true,
            },
            yaxis: {
                title: {
                    text: ["數量　　", "　"],
                    rotate: 0,
                    style: {
                        fontSize: '16px',
                        fontWeight: 600,
                    }
                }
            },
            labels: chartLabels1,
            xaxis: {
                type: 'category',
                categories: chartLabels2
            },
            tooltip: {
                shared: true,
                intersect: false,
                x: {
                    show: true,
                    formatter: function (x, opts) {
                        if (typeof x !== 'undefined') {
                            return opts.w.globals.labels[opts.dataPointIndex]
                        }
                        return x;
                    }
                },
                y: {
                    formatter: (y, opts) => {
                        let sum = 0;
                        for (let i = 0; i < opts.series.length; i += 1)
                            sum += opts.series[i][opts.dataPointIndex]
                        if (typeof y !== 'undefined') {
                            const percent = y / sum * 100;
                            if (sum === 0)
                                return `${y.toFixed(0)} (0%)`;
                            return `${y.toFixed(0)} (${percent.toFixed(0)}%)`;
                        }
                        return y;
                    }
                }
            }, legend: {
                show: true,
                // customLegendItems: ['本計畫之大型車輛','etag大型車輛','etag小客車'],

                formatter: function (seriesName, opts) {
                    // // console.log(seriesName)
                    const t1 = seriesName.toLowerCase()
                    return [t1]
                }
            },

        };
        setchartOptions(chartOption);
    }
    // const columns = [
    //     { field: "id", headerName: "id", width: 70, hideable: true },
    //     { field: "readerGroupInfoName", headerName: "路段名稱(起)", flex: 0.6, width: 330, sortable: true, headerAlign: 'center',align: 'center',},
    //     // { field: "total", headerName:  "總共車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center', },
    //     // { field: "isLeon", headerName:  "本計畫車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center',},
    //     // { field: "notLeon", headerName:  "非本計畫車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center',},
    // ];

    // if(tagNam === 'total')
    //     columns.push({ field: "total", headerName:  "總車流數",  flex: 0.4,width: 200, sortable: true, headerAlign: 'center', align: 'center', })
    // else
    //     columns.push({ field: "isLeon", headerName:  "本計畫車輛之車流數",  flex: 0.4,width: 200, sortable: true, headerAlign: 'center', align: 'center', })

    return (

        <div>
            <AAppConversionRates title={chartTitle} subtitle={chartSubtitle} chartOptions={chartOptions} data={chartData} />
        </div>

    );
}
