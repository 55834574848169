import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import * as config from "../config/config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};




export default function SelectMenuItem(props) {
    const { Option, select, setselect, labelName, disabled, width, optkey } = props
    // const isAllSelected = Option.length > 0 && select.length === Option.length;
    let maxLength = 0;
    Option.map((name) => (
        maxLength = name.description.length > maxLength ? name.description.length : maxLength
    ))    
    maxLength = labelName.length > maxLength ? labelName.length : maxLength
    // const w = 21*maxLength;
    const w = (35 - maxLength * 0.5) * maxLength;
    // const w = width !== undefined ? width : 300;
    const handleChange = (event) => {
        // console.log(event);
        const {
            target: { value },
        } = event;

        // if (value[value.length - 1] === "全選") {
        //     setselect(select.length === Option.length ? [] : Option.map(a=>a.address));
        //     return;
        //   }
        // console.log(value)
        setselect(
            // On autofill we get a stringified value.
            parseInt(value, 10)
            // typeof value === 'string' ? value.split(',') : value,
        );
    };

    // // console.dir(select)
    // // console.dir(setselect)    
    // console.dir(maxLength)
    return (
        <div>
            <FormControl sx={{ m: 0, width: w }}>
                <InputLabel id="demo-simple-select-label" sx={{ fontSize: config.selectLabelFontSize, top: config.selectLabelTop }}>{labelName}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={select}
                    onChange={handleChange}
                    // 設定title長度
                    input={<OutlinedInput label={`${labelName}${labelName.substring(0, 1 + Math.round(labelName.length / 2))}`} />}
                    MenuProps={MenuProps}
                    disabled={disabled}
                >
                    {/* <MenuItem key="0" value="0">
                        <ListItemText primary="請選擇"/>
                    </MenuItem> */}
                    {Option.map((name) => (
                        <MenuItem value={name.id} >
                            <ListItemText primaryTypographyProps={{ style: { 'font-size': config.selectItemFontSize } }} primary={name[!optkey ? "description" : optkey]} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
