import { startOfMonth, endOfMonth } from 'date-fns';
import httpClient from '../utils/httpClient';

export const getCctvList = async () => {
    try {
        const response = await httpClient.get('/cctv/list');
        return response.data;
    } catch (error) {
        console.error('Error fetching CCTV data:', error);
        throw error;
    }
};
export const getCctvImages = async (cctvCode, page, limit = 10, selectedDate = new Date()) => {
    try {
        const startDate = startOfMonth(selectedDate);
        const endDate = endOfMonth(selectedDate);
        const response = await httpClient.get(`/cctv/images/${cctvCode}`, {
            params: {
                startDate: startDate.toISOString().split('T')[0],
                endDate: endDate.toISOString().split('T')[0],
                page,
                limit
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching CCTV images:', error);
        throw error;
    }
};
export const getDailyEventAggregation = async (date, cameras) => {
    try {
        const url = `/cctv/daily-aggregation/${date}`;
        const params = {};
        if (cameras && cameras.length > 0) {
            params.cameras = cameras.join(',');
        }
        const response = await httpClient.get(url, { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching daily event aggregation:', error);
        throw error;
    }
};