import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AAppWebsiteVisits from '../components/AAppWebsiteVisits';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import ToggleButtons from '../components/ToggleButton'
import ReaderCountDataTable from './ReaderCountDataTable'
import SelectMenuItem from '../components/SelectMenuItem'

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";

export default function TestMap() {
    const [leonReaderlist, setleonReaderlist] = useState([]);
    const [truckLimitData, settruckLimitData] = useState([]);
    const [speedExceedData, setspeedExceedData] = useState([]);
    const [popupTableData, setpopupTableData] = useState([]);
    const [columns, setcolumns] = React.useState([]);
    const [tableData, settableData] = useState([]);

    const [mapcenter, setmapcenter] = useState([24, 121]);
    const [openmap, setopenmap] = useState(null);
    const [ToggleButtonselect, setToggleButtonselect] = useState(60);
    const ToggleButtonsOptions = [
        { value: 5, Name: '5分鐘' },
        { value: 15, Name: '15分鐘' },
        { value: 30, Name: '30分鐘' },
        { value: 60, Name: '60分鐘' },
    ]

    const warnNotificationlist = [
        { "id": 1, "description": "資訊可變標誌提醒", "type": "cms" },
        { "id": 2, "description": "車速過快", "type": "speed" },
        { "id": 3, "description": "車流過多", "type": "trafficFlow" },
    ]
    const [warnNotificationSelect, setwarnNotificationSelect] = useState(1);

    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;
    const dispatch = useDispatch();

    useEffect(() => {

        if (warnNotificationSelect === 1)
            getData(ToggleButtonselect);
        else if (warnNotificationSelect === 2)
            getData2(ToggleButtonselect);
        else
            getData1(ToggleButtonselect);
        // getData(ToggleButtonselect);
    }, [users, ToggleButtonselect, warnNotificationSelect]);

    // useEffect(() => {
    //     setTableData();
    // }, [truckLimitData, warnNotificationSelect]);

    // const setTableData = () => {
    //     const specialMarkID = specialMarklist[specialMarkSelect].id
    //     const list = [];
    //     let index = 1;
    //     for (let i = 0; i < specialMarkData.length; i += 1) {
    //         const data = specialMarkData[i];
    //         let obj = {};
    //         if (data.specialMark_info_id.includes(specialMarkID) || specialMarkSelect === 0) {
    //             obj = data;
    //             obj.rownum = index
    //             list.push(obj)
    //             index += 1;
    //         }
    //     }
    //     settableData(list)
    // };


    // 取德標格資料
    const getData = async (minutes) => {
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getCmsEvents`, { "token": users.token, "minutes": minutes })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                // console.log(response.data);
                if (response.data.resultCode === "Success") {
                    const list = [];
                    let index = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;
                        if (Object.keys(obj).length !== 0) {
                            obj.receiveTime = new Date(obj.endTime).toLocaleString()
                            obj.notification = obj.content
                            obj.rownum = index
                            obj.id = index
                            list.push(obj)
                            index += 1;
                        }
                    }
                    settruckLimitData(list)
                    // getData1(minutes,list,index)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                const columns = [
                    { field: "id", headerName: "id", width: 70, hideable: true },
                    { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
                    {
                        field: "leonReaderName", headerName: "智慧感知讀取器名稱", flex: 0.4, sortable: true, headerAlign: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: '20px' }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    {
                        field: "cmsName", headerName: "資訊可變標誌名稱", flex: 0.4, sortable: true, headerAlign: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: '20px' }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    { field: "notification", headerName: "警示提醒內容", flex: 0.2, width: 100, headerAlign: 'center', align: 'center', },
                    { field: "licensePlateNumber", headerName: "車牌", width: 120, headerAlign: 'center', align: 'center', },
                    { field: "receiveTime", headerName: "時間", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center', },
                ];
                setcolumns(columns)
                // always executed
            });
        // setOpen(true);
    };

    // 取德標格資料
    const getData1 = async (minutes) => {
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getTrafficExceedEvent`, { "token": users.token, "minutes": minutes })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                // console.log(response.data);
                if (response.data.resultCode === "Success") {
                    const list = [];
                    let index = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;
                        if (Object.keys(obj).length !== 0) {
                            // obj.receiveTime = moment(obj.createTime).add(8,'hours').toLocaleString()
                            obj.receiveTime = new Date(moment(obj.createTime).add(8, 'hours')).toLocaleString()
                            obj.notification = `車流過多(300輛/hr)`
                            obj.rownum = index
                            obj.id = index
                            list.push(obj)
                            index += 1;
                        }
                    }

                    settruckLimitData(list)
                    // getData2(minutes,list,index)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                const columns = [
                    { field: "id", headerName: "id", width: 70, hideable: true },
                    { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
                    {
                        field: "leonReaderName", headerName: "智慧感知讀取器名稱", flex: 0.4, sortable: true, headerAlign: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: '20px' }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    { field: "name", headerName: "設備編號", flex: 0.2, sortable: true, headerAlign: 'center', align: 'center', },
                    {
                        field: "notification", headerName: "警示提醒內容", flex: 0.3, width: 100, headerAlign: 'center', align: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: '20px' }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    { field: "receiveTime", headerName: "時間", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center', },
                ];
                setcolumns(columns)
                // always executed
            });
        // setOpen(true);
    };


    // 取德標格資料
    const getData2 = async (minutes) => {
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getSpeedExceedEvent`, { "token": users.token, "minutes": minutes })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                // console.log(response.data);
                if (response.data.resultCode === "Success") {
                    const list = [];
                    let index = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;
                        if (Object.keys(obj).length !== 0) {
                            // obj.receiveTime = moment(obj.createTime).add(8,'hours').toLocaleString()
                            obj.receiveTime = new Date(moment(obj.createTime).add(8, 'hours')).toLocaleString()
                            obj.notification = `車速過快(60 Km/h)`
                            obj.rownum = index
                            obj.id = index
                            obj.name = `${data.reader_info_name1},${data.reader_info_name2}`
                            list.push(obj)
                            index += 1;
                        }
                    }
                    settruckLimitData(list)
                    // setspeedExceedData(list)   
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                const columns = [
                    { field: "id", headerName: "id", width: 70, hideable: true },
                    { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
                    {
                        field: "pathName", headerName: "路段名稱", flex: 0.4, sortable: true, headerAlign: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: '20px' }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    { field: "name", headerName: "設備編號", flex: 0.2, sortable: true, headerAlign: 'center', align: 'center', },
                    {
                        field: "notification", headerName: "警示提醒內容", flex: 0.3, width: 100, headerAlign: 'center', align: 'center',
                        renderCell: (params) => {
                            if (params.value === undefined)
                                return (null);
                            if (typeof params.value === 'string')
                                return (params.value);
                            const children = params.value.map((val) => (
                                <div >{val}</div>
                            ));
                            return (
                                <div style={{ fontSize: '20px' }}>
                                    {children}
                                </div>
                            );
                        }
                    },
                    { field: "receiveTime", headerName: "時間", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center', },
                ];
                setcolumns(columns)
                // always executed
            });
        // setOpen(true);
    };





    // const columns = [
    //     { field: "id", headerName: "id", width: 70, hideable: true },
    //     { field: "rownum", headerName: "項目", width: 50, sortable: true, headerAlign: 'center', align: 'center',},
    //     { field: "leonReaderName", headerName: "智慧感知讀取器名稱", flex: 0.4, sortable: true, headerAlign: 'center', },
    //     { field: "name", headerName: "設備編號",  flex: 0.1 , sortable: true, headerAlign: 'center', align: 'center',},
    //     { field: "notification", headerName: "提醒告警條件", flex: 0.3 ,width: 100 , headerAlign: 'center', align: 'center',},
    //     { field: "licensePlateNumber", headerName: "車牌", width: 120 , headerAlign: 'center', align: 'center',},
    //     { field: "receiveTime", headerName: "時間", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center',},
    // ];

    // const searchOnClick = () => {
    //     getData(ToggleButtonselect);
    // }


    return (
        <Page title="智慧管理" sx={{
            '& label': {
                fontSize: '26px',
                top:'-6px'
            },
            '& span ': {
                fontSize: '24px'
            },
            '& .MuiMenu-list ': {
                fontSize: '24px'
            },
        }} >
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        <Stack direction="row" spacing={2}
                            >
                            {/* <MultipleSelectCheckmarks Option={roadArealist} select={roadAreaselect} setselect={setroadAreaselect} /> */}
                            <ToggleButtons Options={ToggleButtonsOptions} setSelect={setToggleButtonselect} Select={ToggleButtonselect} />
                            <SelectMenuItem Option={warnNotificationlist} select={warnNotificationSelect} setselect={setwarnNotificationSelect} labelName="選擇警示種類" />
                        </Stack>

                    </Typography>
                    {/* <Button variant="contained" size="large" onClick={searchOnClick} >查詢</Button> */}
                </Stack>


                <Grid container spacing={3} >

                    <Grid item xs={12} style={{ height: "100%" }}
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontSize: '22px'
                            },
                            '& .MuiDataGrid-cellContent': {
                                fontSize: '20px'
                            }
                        }}>
                        <BDataGrid data={truckLimitData} columns={columns} rowsPerPageOptions={[10, 20, 50]}
                        // TableOnClick={TableOnClick}
                        />
                    </Grid>


                </Grid>
            </Container>
        </Page>
    );
}
