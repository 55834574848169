// src/infrastructure/httpClient.ts
import axios from 'axios';
import { apiIP } from '../config/ipconfig';

const httpClient = axios.create({
    baseURL: `https://${apiIP}/api/v1`,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default httpClient;