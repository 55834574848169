export default function getOperationEvent(Enable,enable,type,eventContextList,objectNameList,oldValuesList,eventContextListOfInsert,objectNameListOfInsert,oldValuesListOfInsert) {  
  
    // 1.啟用或停用
    // 選擇的enable與原本狀態不一樣
    // // console.log(values.fn("leonReaderMACAddress"));
    let eventContext = null;
    if(Enable !== enable){
      const event = Enable ? "啟用" : "停用";
      eventContext = event;
    }
    
    const eventNumberList = [];
    const eventNumberListOfInsert = [];
    
    for(let i = 0 ;i<objectNameList.length;i+=1){
      if(objectNameList[i] !== oldValuesList[i]){
        eventNumberList.push(i);
      }
    }
    
    for(let i = 0 ;i<objectNameListOfInsert.length;i+=1){
      if(objectNameListOfInsert[i] !== oldValuesListOfInsert[i]){
        eventNumberListOfInsert.push(i);
      }
    } 

    const typeString = type === 'insert' ? "新增":"修改";
    const numberList = type === 'insert' ? eventNumberListOfInsert:eventNumberList;
    const list = type === 'insert' ? eventContextListOfInsert:eventContextList;

    for(let i = 0 ;i<numberList.length;i+=1){      
      const index = numberList[i];
      if(eventContext !== null)
        eventContext += `、 ${typeString}`;    
      else    
        eventContext = typeString;    
      eventContext += list[index];
    }
    return eventContext
};
