import React, { useState, useEffect , useRef } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// material
import {
  Grid,
  Card,
  Table,
  Typography,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  TableSortLabel
} from '@mui/material';


import SearchNotFound from '../SearchNotFound';
import TTable from '../TTable'

// eslint-disable-next-line import/named
import { CarListToolbar } from '../_dashboard/user';

import { fetchDetailRecord } from "../../actions";

// 定義切換頁
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function EnhancedTable(props) {
  
  const headCell = [    
    {
      id: 'userName',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '使用者名稱'
    },
    {
      id: 'userGroup',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '權限'
    },    
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      fillter: false,
      label: 'email'
    },
    // {
    //   id: 'updateTime',
    //   numeric: false,
    //   disablePadding: false,
    //   fillter: false,
    //   label: '更新時間'
    // }
  ];
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:  
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('eventnum');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterName, setFilterName] = useState('');
  const [detailRecordList, setDetailRecordList] = useState([]);
  const [operationRecordID, setOperationRecordID] = useState(0);
  const [headCells, setHeadCells] = useState([]);
  
  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const detailRecordStore = useSelector((state) => state.detailRecord);
  const { users } = usersStore;    
  const { detailRecord } = detailRecordStore;   
  const teamsStore = useSelector((state) => state.teamsInfo);
  const { teams } = teamsStore;


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {
    // 當API呼叫時，Store的detailRecord會改變狀態
    // 外部傳入 operationRecordID
    if(operationRecordID !== props.operationRecord_id)
      getDetailRecord(props.operationRecord_id);

    if(detailRecord.length === 0){
      setDetailRecordList([]); 
    }
    else if((JSON.parse(detailRecord[0].record)).UserRecord !== undefined){    
      // const driverRecord = (JSON.parse(detailRecord[0].record)).driverRecord;
      // if((JSON.parse(detailRecord[0].record)).driverRecord !== undefined){
      // setDetailRecordList((JSON.parse(detailRecord[0].record)).LeonTagRecord); 
      
      setDetailRecordHeadCell(props.action);
      setDetailRecordData(props.action,(JSON.parse(detailRecord[0].record)).UserRecord)
      // setDetailRecord(props.action,(JSON.parse(detailRecord[0].record)).LeonTagRecord); 
      // }
    }
    // else{      
    //   setDetailRecordList((JSON.parse(detailRecord[0].record)).LeonTagRecord); 
    // }
    
    // 設定 operationRecordID
    setOperationRecordID(props.operationRecord_id);
    // // console.log(userOperationRecordToLeonTagRecord);
    // // console.log(props.operationRecord_id);
    // console.log(detailRecord);
    
  }, [props.operationRecord_id,detailRecord]);
  

  useEffect(() => {
      setDetailRecordList(detailRecordList); 
  }, [detailRecordList]);

  const setDetailRecordHeadCell = (action) => {   
    if(action === 'update'){
      const list = [];
      list.push({
        id: 'action',
        numeric: false,
        disablePadding: false,
        fillter: false,
        label: '操作'
      })

      for(let i=0;i<headCell.length;i+=1){
        list.push(headCell[i])
      }
      setHeadCells(list);
      // console.log(list);
    }
    else{
      setHeadCells(headCell);
    }
  }
  
  const actionContext = useRef(null);
  const enableContext = useRef(null);
  const userGroupContext = useRef(null);

  const setDetailRecordData = async (action,dataRecordList) => { 
    const list = []; 
    for(let i=0;i<dataRecordList.length;i+=1){
      if(action === 'update' && i === 0)
        actionContext.current = '修改前'    
      else if(action === 'update' && i === 1)
        actionContext.current = '修改後'
        
      if(dataRecordList[i].enable === 0)
        enableContext.current = '停用'
      else
        enableContext.current = '啟用'

      if(dataRecordList[i].user_group_id === 1)
        userGroupContext.current = '系統管理員'
      else if(dataRecordList[i].user_group_id === 2)
        userGroupContext.current = '超級使用者'
      else
        userGroupContext.current = '一般使用者'

        const textFieldProps = ["userName","email"];
        // const textFieldProps = ["cmsName","name","cmsProductNumber","latitude","longitude","address","description","updateTime"];  
        // const textFieldProps = ["leonReaderName","name","leonReaderMACAddress","latitude","longitude","address","description","updateTime"];  
        const textFieldValue = [dataRecordList[i].user_name,
        dataRecordList[i].email];
    
        const obj = {}
        for(let j =0;j<textFieldProps.length;j+=1){
          obj[textFieldProps[j]] = textFieldValue[j];
        }    
        obj.action = actionContext.current;
        obj.enable = enableContext.current;
        obj.userGroup = userGroupContext.current;
    
        list.push(obj)
    }    
    setDetailRecordList(list);
    // console.log(list);
  }

  const getDetailRecord = async (id) => {    
    dispatch(fetchDetailRecord({"type":"User","token": users.token,"operationRecord_id":id}));
  };

  return (
      <Container maxWidth="xl">        
      
      <Box
          sx={{
            width: 300,
            height: 130
          }}
        >
          <div>
            <Typography variant="h4"  >使用者ID： {users.userName}</Typography>
          </div>
          <div>
            <Typography variant="h4"  >事件： {              
              (() => {
                if(detailRecord.length){                  
                  if((JSON.parse(detailRecord[0].record)).action === "insert"){
                    return (<a>新增</a>);
                  }
                return (<a>修改</a>);
                }
              })()
            }</Typography>
          </div>
          {/* <div>
            <Typography variant="h4"  >筆數： {detailRecordList.length}</Typography>
          </div> */}
          
      </Box>
      
        <TTable Vehiclelist={detailRecordList} headCells={headCells} />
          
      </Container>
  );
}
