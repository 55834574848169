import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";


import AAppWebsiteVisits from '../AAppWebsiteVisits';
import * as ipconfig from "../../config/ipconfig";


export default function TrafficAnalyzeOfConversionRates(props) {
    const { sessionlist, select, select1, tagNam, chartTitle, sqlText } = props
    const { unitAmountValue } = props
    const { readerlist, readerlist1, readerselect, readerselect1 } = props
    const { startDate, endDate } = props
    const { hour1, hour2 } = props
    const { chartUnit, chartUnit1, time } = props
    const [chartdata, setchartdata] = useState([]);
    const [data, setdata] = useState([]);
    const [data1, setdata1] = useState([]);

    const dispatch = useDispatch();
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;
    // useEffect(() => {
    //     getTrafficFlowData(); 
    // }, [users,startDate,endDate]);

    const [first, setfirst] = React.useState(true);
    const timer = React.useRef();
    // const list = React.useRef();


    useEffect(() => {
        // console.log(data)
        if (sessionlist.length > 0) {
            const list = []
            // list.current = [];
            const d1 = setTableData(data, select, 1);
            const d2 = setTableData(data1, select1, 2);

            if (first && (data.length === 0 || data1.length === 0)) {
                setfirst(false);
                return
            }
            list.push(d1);
            list.push(d2);
            // console.log(d1)
            // console.log(d2)
            setchartData(list)
            setChartOption();
            let lodingTime = 1000;
            if (chartUnit === 'day')
                lodingTime = 3000
            else if (chartUnit === 'month')
                lodingTime = 5000
            timer.current = window.setTimeout(() => {
                dispatch({ type: "END" });
            }, lodingTime);
            // dispatch({type: "END"}); 
        }
    }, [data, data1, unitAmountValue]);

    useEffect(() => {
        // console.log(data)
        if (sessionlist.length > 0 && startDate !== '') {
            dispatch({ type: "START" });
            getTrafficFlowData(select, readerlist, readerselect, setdata);
            getTrafficFlowData(select1, readerlist1, readerselect1, setdata1);
        }
    }, [select, readerselect, select1, readerselect1, endDate]);

    useEffect(() => {
        try {
            if (users.id !== undefined)
                if (sessionlist.length > 0 && startDate !== '') {
                    // console.log(users)
                    // console.log(startDate)
                    // console.log(endDate)
                    // console.log(select)
                    dispatch({ type: "START" });
                    getTrafficFlowData(select, readerlist, readerselect, setdata);
                    getTrafficFlowData(select1, readerlist1, readerselect1, setdata1);
                    // dispatch({type: "END"}); 

                }
        }
        catch (e) {
            console.log(e.toString)
        }
    }, [sessionlist, readerselect, select, select1, readerselect1, users, endDate]);

    /* ******    取得全部路口車流資料  ********
        假設 datetime = 2022/04/26 00:00:00
        如果本地端為UTC+8 
        utc0 = 2022/04/25 16:00:00
        difference = 8
    *************************************** */
    const getTrafficFlowData = async (select, readerlist, readerselect, setdata) => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        // const utc01 = new Date(`${moment(startDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
        // const utc02 = new Date(`${moment(endDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
        const date1 = startDate
        const date2 = endDate
        const readerGroupName = sessionlist[select].description
        const readerName = readerlist.length === 0 ? '全部' : readerlist[readerselect].description1
        // const readerGroupName
        // const difference = 8
        // const sqlText= 'week,Hours  having Hours >= 17 and Hours <= 19  ORDER BY week'
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/trafficFlow/getSessionTrafficFlowOfAnalyzeFilter1`, {
                "token": users.token, "date1": date1, "date2": date2, "sqlText": sqlText, "readerGroupName": readerGroupName, "readerName": readerName,
                "hour1": hour1, "hour2": hour2, "utc": difference
            })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq   
                if (response.data.resultCode === "Success") {
                    setdata(response.data.rows)
                }
            })
            .catch((error) => {
                dispatch({ type: "END" });
                // handle error
                // // console.log(error);
            })
            .then(() => {
                // dispatch({type: "END"}); 
                // always executed
            });
        // setOpen(true);
    };

    // const [chartTitle, setchartTitle] = useState("");
    const [chartSubtitle, setchartSubtitle] = useState("");

    const [chartOptions, setchartOptions] = useState({});
    const [chartData, setchartData] = useState([]);

    const setTableData = (data, select, index) => {

        const dataListTemp = [];

        const dateListTemp = [];
        if (chartUnit === 'Hours') {
            for (let i = 0; i < 24; i += 1) {
                dateListTemp.push(i);
            }
        }
        else if (chartUnit === 'day') {
            // 當月天數
            const dd = moment(endDate, "YYYY-MM").daysInMonth()
            for (let i = 1; i <= dd; i += 1) {
                dateListTemp.push(i);
            }
        }
        else if (chartUnit === 'month') {
            for (let i = 1; i <= 12; i += 1) {
                dateListTemp.push(i);
            }
        }
        else {
            for (let i = 0; i < data.length; i += 1) {
                if (sessionlist[select].description === data[i].reader_group_info_name) {
                    dateListTemp.push(data[i][chartUnit]);
                }
            }
        }
        // const dataList = [...new Set(dateListTemp)];
        const dataList = dateListTemp;

        // // console.log(dataList);

        return setChartData(dataList, data, select, index);

    }


    // 統計顯示相關
    const setChartData = (dateList, data, select, index) => {
        // // console.log(data)
        // A : Leon
        // B : truckNotLeon
        // C : notLeon
        // D : total

        // A : 本計畫之大型車輛
        // B : etag大車
        // C : etag小客車
        // D : 總和 = A+C
        const dataA = [];
        const dataB = [];
        const dataC = [];
        const dataD = [];
        // 先建立arraySize避免沒資料，會導致錯位
        for (let i = 0; i < dateList.length; i += 1) {
            dataA.push(0);
            dataB.push(0);
            dataC.push(0);
            dataD.push(0);
        }

        for (let i = 0; i < dateList.length; i += 1) {
            for (let j = 0; j < data.length; j += 1) {
                if (sessionlist[select].description === data[j].reader_group_info_name && dateList[i] === data[j][chartUnit]) {
                    const isetagData = !Number.isNaN(data[j].iseTag)
                    const etagData = isetagData ? parseInt(data[j].iseTag, 10) : 9999
                    if (data[j].isLeonTag !== 0)
                        dataA[i] += data[j].count * unitAmountValue;
                    else if (isetagData && etagData >= 4 && etagData <= 5)
                        dataB[i] += data[j].count * unitAmountValue;
                    else if (isetagData && etagData === 3)
                        dataC[i] += data[j].count;
                }
            }
        }

        for (let i = 0; i < dateList.length; i += 1) {
            dataD[i] = dataA[i] + dataC[i] + dataB[i];
        }



        let color = '#00AB55'
        if (index === 2) color = '#2D99FF'
        // list.current.push({
        //     name: `資料${index}`,
        //     data: dataD,
        //     type: 'line',
        //     color: color
        // })
        return {
            name: `資料${index}`,
            data: dataD,
            type: 'line',
            color: color
        }
        // list.current.push({
        //     name: `資料${index}2`,
        //     data: dataA,
        //     color: color
        // })
        // const list = []
        // list.push({
        //     name: `資料${index}`,
        //     data: dataD,
        //     color: color
        // })
        // list.push({
        //     name: `資料${index}1`,
        //     data: dataA,
        //     color: color
        // })
        // const data1 = [
        //     {
        //         name: `資料${index}`,
        //         data: dataD,
        //         type: 'line',
        //         color: '#FF9700'
        //     },
        //     {
        //         name: `資料${index}2`,
        //         data: dataA,
        //         type: 'line',
        //         color: '#FF9700'
        //     },
        // ];

        // setchartdata(data1)
    }


    // 統計顯示相關
    const setChartOption = () => {

        const dateListTemp = [];
        const dateListTemp1 = [];
        if (chartUnit === 'Hours') {
            for (let i = 0; i < 24; i += 1) {
                dateListTemp.push(`${i}:00`);
            }
        }
        else if (chartUnit === 'day') {
            // 當月天數
            const dd = moment(endDate, "YYYY-MM").daysInMonth()
            for (let i = 1; i <= dd; i += 1) {
                dateListTemp.push(`${moment(endDate).format('YYYY/MM')}/${i}`);
            }
        }
        else if (chartUnit === 'month') {
            for (let i = 1; i <= 12; i += 1) {
                dateListTemp.push(`${moment(endDate).format('YYYY')}/${i}`);
            }
        }

        // const chartLabels1 = [...new Set(dateListTemp)];
        const chartLabels1 = dateListTemp;

        const chartOption = {
            chart: {
                offsetX: -20,
                toolbar: {
                    show: true,
                    offsetY: -50,
                    export: {
                        csv: {
                            filename: undefined,
                        },
                        png: {
                            filename: undefined,
                        }
                    }
                },
            },
            plotOptions: { bar: { columnWidth: '50%', barHeight: '100%', borderRadius: 4 } },
            fill: { type: ['solid', 'solid',] },

            labels: chartLabels1,
            xaxis: {
                type: 'category',
                datetimeUTC: false,
            },
            yaxis: {
                title: {
                    text: ["數量　　", "　",],
                    rotate: 0,
                    style: {
                        fontSize: '16px',
                        fontWeight: 600,
                    }
                },
                labels: {
                    formatter: function (val) {
                        return val.toFixed(0)
                    }
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                x: {
                    show: true,
                    formatter: function (x, opts) {
                        if (typeof x !== 'undefined') {
                            return `${opts.w.globals.categoryLabels[opts.dataPointIndex]} `
                        }
                        // return x;
                    }
                },
                y: {
                    formatter: (y, opts) => {
                        let sum = 0;
                        for (let i = 0; i < opts.series.length; i += 1)
                            sum += opts.series[i][opts.dataPointIndex]
                        if (typeof y !== 'undefined') {
                            const percent = y / sum * 100;
                            if (sum === 0)
                                return `${y.toFixed(0)} (0%)`;
                            return `${y.toFixed(0)} (${percent.toFixed(0)}%)`;
                        }
                        return y;
                    }
                }
            }
        };
        setchartOptions(chartOption);
    }

    return (

        <div>
            <AAppWebsiteVisits title={chartTitle} subtitle={chartSubtitle} chartOptions={chartOptions} data={chartData} />
        </div>

    );
}
