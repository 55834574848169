import { Icon } from '@iconify/react';
import driverFilled from '@iconify/icons-fa-solid/user-tie';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

const RootStyle1 = styled(Card)(({ theme }) => ({
  height : 190,
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: '#a00d0d',
  backgroundColor: '#ffd0d0'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppNewUsers(props) {
  if(props.icon !== undefined)
    return (
      <RootStyle >
        <IconWrapperStyle >
          <Icon icon={props.icon} width={25} height={25} />
        </IconWrapperStyle>
        <Typography variant="h3">
          {props.TOTAL}
          {props.unit}
        </Typography>
        <Typography variant="h4" sx={{ opacity: 0.72 }}>
          {props.title}
        </Typography>
      </RootStyle>
    );
    
  return (
    <RootStyle1 >
      <Typography variant="h4" sx={{ opacity: 0.72 }} >
        {props.title} 
      </Typography>
      <Typography variant="h4" sx={{ opacity: 0.72 }} mb={1}>
        {props.subtitle} 
      </Typography>
      <Typography variant="h3">
        {props.TOTAL} {props.unit}        
      </Typography>
    </RootStyle1>
  );
}
