import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Snackbar from '@mui/material/Snackbar';

import { useDispatch, useSelector } from "react-redux";
import SelectMenuItem from '../SelectMenuItem'
import MultipleCheckboxes from '../MultipleCheckboxes';
import { getOperationEvent, setFormikObjectRequired, setFormikObjectInitialValues } from "./index"
import TTextField from "../TTextField"
import { fetchTruckList, setAlertMessage } from "../../actions";

import * as ipconfig from "../../config/ipconfig";
// ----------------------------------------------------------------------


export default function TruckForm(props) {

  // console.log(props)
  const { driverID, truckID, teamID, type, licensePlateNumber, tonnage, height, brand, truckType, truckColor, description, specialMark, specialMarkInfoID, useType, riskEvaluation, dateOfManufacture, updateTime, enable } = props;
  const { setValue } = props;
  // 表單資料來源
  const textFieldLabel = ["車牌", "噸數", "車高", "廠牌", "車種", "顏色", "出廠日期", '描述'];
  const textFieldProps = ["licensePlateNumber", "tonnage", "height", "brand", "truckType", "truckColor", "dateOfManufacture", 'description'];
  const textFieldValue = [licensePlateNumber, tonnage, height, brand, truckType, truckColor, dateOfManufacture, description];
  const textFieldValuerequired = ['請輸入車牌',
    '',
    '',
    '',
    '',
    '',
    '',
    ''];

  const navigate = useNavigate();
  const [first, setFirst] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setSnackbarStateFailed] = useState(false);
  const [open1, setSnackbarStateSuccess] = useState(false);

  const [nackbarVHState, setnackbarVHState] = useState({
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal } = nackbarVHState;

  // 表單不輸入內容相關之訊初始化
  const [formikRequired, setFormikRequired] = useState({});
  const LoginSchema = Yup.object().shape(formikRequired);

  // 表單內容初始化
  const [formikInitialValues, setFormikInitialValues] = useState({});
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => onSubmit(values, actions)
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;


  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const { users } = usersStore;
  const trucksStore = useSelector((state) => state.truckManage);
  const { trucks } = trucksStore;
  const driversStore = useSelector((state) => state.driverManage);
  const { drivers } = driversStore;
  const teamsStore = useSelector((state) => state.teamsInfo);
  const { teams } = teamsStore;
  const specialmarkInfoStore = useSelector((state) => state.specialmarkInfo);
  const { specialmarkInfos } = specialmarkInfoStore;

  // const [teamID, setTeamID] = useState();
  const [driverList, setDriverList] = useState([]);
  const [driverSelect, setDriverSelect] = useState(0);
  const [teamList, setTeamList] = useState([]);
  const [teamSelect, setTeamSelect] = useState(0);
  const [driverValue, setdriverValue] = useState(0);
  const [truckEnable, setTruckEnable] = useState(1);
  const eventContext = useRef(null);
  const labelName = "選擇綁定駕駛"
  const labelTeamName = "選擇綁定車隊"
  const [driverSelectDisabled, setDriverSelectDisabled] = useState(false);
  const [driverRadioDisabled, setDriverRadioDisabled] = useState(false);
  const [oldTeamName, setOldTeamName] = useState(0);
  const teamNameSelect = useRef('');
  const [specialOptions, setspecialOptions] = useState([]);
  const [specialSelectID, setspecialSelectID] = useState([]);
  const [specialSelectContent, setspecialSelectContent] = useState('');

  const [initialState, setInitialState] = useState('first');
  const useTypelist = [
    { "id": 0, "description": "營業用" },
    { "id": 1, "description": "自用" },
  ]
  const [useTypeSelect, setuseTypeSelect] = useState(0);
  /* ******************************
            initialState
   *********** 資料清除 ***********
        first   第一次進入   
        success 成功        
   ********* 送出前的資料 *********
        error   輸入資料有誤
  ****************************** */
  useEffect(() => {
    localStorage.setItem('mode', '1');
    if (initialState === 'first' || initialState === 'success') {
      setTeamSelect(users.team_id);
      if (type === 'insert')
        setTruckEnable(1)
      else
        setTruckEnable(enable)
      // 設定不輸入會顯示紅字錯誤
      const objRequired = setFormikObjectRequired(textFieldProps, textFieldValuerequired);
      setFormikRequired(objRequired);

      // 設定表單內容的初始值
      const objInitialValues = setFormikObjectInitialValues(textFieldProps, textFieldValue)
      setFormikInitialValues(objInitialValues);

      for (let i = 0; i < textFieldProps.length; i += 1)
        setFieldValue(textFieldProps[i], textFieldValue[i]);

    }
  }, [props])

  useEffect(() => {
    // console.log(users);
    // console.log(trucks);
    // console.log(trucks);
    setTruckEnable(enable);
    getTeamList();
    getSpecialOptions();
    getDriverList();
    getUseType();

  }, [])

  useEffect(() => {
    setDriverSelect(null);
    getDriverList();
  }, [teamSelect])

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false)
    const today = new Date().toISOString();
    getTeamNameSelect();
    const truckJson = createTruckJson(values, today);
    const truckRecord = createTruckReacordJson(values, today);
    const userOperationRecordJson = createUserOperationRecordJson();

    const recordJson = {
      "action": type,
      "TruckRecord": truckRecord,
    };

    if ((driverSelect === null || driverSelect === 0) && driverValue !== 0) {
      setInitialState('error');
      dispatch(setAlertMessage({ "open": true, "severity": "error", "content": "請選擇綁定駕駛" }));
    }
    else if (eventContext.current !== '' && eventContext.current !== null)
      updateTruck(userOperationRecordJson, truckJson, recordJson);

  }

  /* ***************************************
      操作紀錄-事件描述
      1.啟用tag或停用tag (停用也順勢解綁駕駛)
      2.解綁駕駛
      3.綁定駕駛
      4.更換駕駛
      5.更改車輛資訊(編號、敘述)
      6.更改車輛所屬車隊
  *************************************** */
  const getUserOperationEvent = (values) => {
    // const specialContent = JSON.parse(localStorage.getItem('specialContent'));
    // 1.啟用或停用
    // 選擇的enable與原本狀態不一樣
    eventContext.current = '';

    // 解綁
    // 選擇解除綁定 && 原本狀態是綁定
    const unbindDriver = !driverValue && (driverID !== null && driverID !== 0);
    // 綁定
    // 舊資料未綁定truck 
    const bindDriver = !!((driverID === null || driverID === 0) && (driverSelect !== driverID && driverValue));
    // 更換駕駛
    // 選擇駕駛與原本綁定的不一樣
    const changeDriver = driverSelect !== driverID && (driverSelect !== null && driverSelect !== 0);
    const tonnage0 = (values.tonnage === null || values.tonnage === undefined) ? '' : values.tonnage;
    const height0 = (values.height === null || values.height === undefined) ? '' : values.height;
    const tonnage1 = !Number.isNaN(tonnage0) ? tonnage0 : parseFloat(tonnage0);
    const height1 = !Number.isNaN(height0) ? height0 : parseFloat(height0);


    const eventContextList = ["車輛所屬車隊", "綁定狀態(解除綁定)", "綁定狀態(綁定駕駛)", "綁定駕駛", "車牌", "噸數", "車高",
      "廠牌", "車種", "顏色", "出廠日期", "描述", "特殊註記", "使用類別"];
    const objectNameList = [teamSelect,
      false,
      false,
      false,
      values.licensePlateNumber,
      tonnage1,
      height1,
      values.brand,
      values.truckType,
      values.truckColor,
      values.dateOfManufacture,
      values.description,
      specialSelectContent,
      useTypelist[useTypeSelect].description
    ];

    const oldValuesList = [teamID,
      unbindDriver,
      bindDriver,
      changeDriver,
      licensePlateNumber,
      tonnage,
      height,
      brand,
      truckType,
      truckColor,
      dateOfManufacture,
      description,
      specialMark,
      useType
    ]


    const eventContextListOfInsert = ["綁定狀態(綁定駕駛)", "車輛資訊", "噸數", "車高", "廠牌", "車種", "顏色", "出廠日期", "描述", "特殊註記", "使用類別"];
    const objectNameListOfInsert = [false, values.licensePlateNumber, tonnage1, height1,
      values.brand, values.truckType, values.truckColor, values.dateOfManufacture, values.description, specialSelectContent, useTypelist[useTypeSelect].description];
    const oldValuesListOfInsert = [bindDriver, licensePlateNumber, tonnage, height,
      brand, truckType, truckColor, dateOfManufacture, description, specialMark, useType]

    eventContext.current = getOperationEvent(truckEnable, enable, type, eventContextList, objectNameList, oldValuesList, eventContextListOfInsert, objectNameListOfInsert, oldValuesListOfInsert);
    // setSaveData(values.licensePlateNumber);
    // console.log(eventContext.current);

    // // 2.解綁
    // // 選擇解除綁定 && 原本狀態是綁定
    // if(!driverValue && (driverID !== null || driverID !== 0)){
    //   const event = "解除綁定駕駛"
    //   eventContext.current += event;
    // }
    // // 3.綁定
    // // 舊資料未綁定truck 
    // if((driverID === null || driverID === 0) && (driverSelect !== driverID && driverValue)){
    //   // 新選擇的truck != 舊資料truck && 綁定為TRUE
    //   // if(driverSelect !== truckID && driverValue){      
    //     const event = "綁定駕駛"
    //     eventContext.current += event;
    //   // }
    // }
    // // 4.更換駕駛
    // // 選擇駕駛與原本綁定的不一樣
    // if(driverSelect !== driverID && (driverSelect !== null && driverSelect !== 0)){      
    //   // if(driverSelect !== null && driverSelect !== 0){
    //     const event = "更換綁定駕駛"
    //     eventContext.current += event;
    //   // }
    // }
    // // 6.更改Tag所屬車隊
    // if( teamID !== teamSelect){
    //   if(eventContext.current !== ''){
    //       const event = "更改車輛所屬車隊、"
    //       eventContext.current = event + eventContext.current;
    //   }
    //   else{
    //     const event = "更改車輛所屬車隊"
    //     eventContext.current = event;
    //   }
    // }

  }
  // 操作紀錄的JSON
  const createUserOperationRecordJson = () => {
    const userOperationRecordJson = {
      "action": type,
      "type": "Truck",
      "description": eventContext.current,
    };
    // // console.log(userOperationRecordJson);
    return userOperationRecordJson;
  }
  // 最新truck資料的JSON
  const createTruckJson = (values, date) => {
    getUserOperationEvent(values);
    // // console.log(eventContext);

    const tonnage1 = (values.tonnage === null || values.tonnage === undefined) ? 0 : parseFloat(values.tonnage)
    const height1 = (values.height === null || values.height === undefined) ? 0 : parseFloat(values.height)

    const jsonObj = {
      "truck_id": truckID,
      "team_id": teamSelect,
      "driver_id": driverSelect,
      "licensePlateNumber": values.licensePlateNumber,
      "tonnage": tonnage1,
      "height": height1,
      "brand": values.brand,
      "truckType": values.truckType,
      "truckColor": values.truckColor,
      "description": values.description,
      "specialMark": specialSelectContent,
      "specialMark_info_id": specialSelectID,
      "useType": useTypelist[useTypeSelect].description,
      "dateOfManufacture": values.dateOfManufacture,
      "enable": truckEnable,
      "updateTime": date
    }
    // 選擇停用(要解除綁定)
    // Select
    if (driverValue === 0)
      jsonObj.driver_id = 0;
    if (values.dateOfManufacture === '')
      delete jsonObj.dateOfManufacture

    if (type === 'insert')
      delete jsonObj.truck_id
    if (tonnage1 === 0)
      delete jsonObj.tonnage
    if (height1 === 0)
      delete jsonObj.height

    return jsonObj
    // return jsonList[0];
  }
  // 舊truck資料的JSON
  const createTruckReacordJson = (values, date) => {
    const truckRecord = [];
    const tempTonnage = tonnage === '' ? 0 : tonnage;
    const tempHight = height === '' ? 0 : height;

    const oldJsonObj = {
      "truck_id": truckID,
      "driver_id": driverID,
      "team_id": teamID,
      "team_name": oldTeamName,
      "licensePlateNumber": licensePlateNumber,
      "tonnage": parseFloat(tempTonnage),
      "height": parseFloat(tempHight),
      "brand": brand,
      "truckType": truckType,
      "truckColor": truckColor,
      "description": description,
      "specialMark": specialMark,
      "useType": useType,
      "dateOfManufacture": dateOfManufacture,
      "enable": enable,
    }

    if (description === null)
      delete oldJsonObj.description
    if (tempTonnage === 0)
      delete oldJsonObj.tonnage
    if (tempHight === 0)
      delete oldJsonObj.height

    if (updateTime !== '')
      oldJsonObj.updateTime = new Date(updateTime).toISOString()
    if (dateOfManufacture === '')
      delete oldJsonObj.dateOfManufacture

    if (type === 'update')
      truckRecord.push(oldJsonObj)

    const jsonObj = createTruckJson(values, date);
    jsonObj.team_name = teamNameSelect.current;


    truckRecord.push(jsonObj)
    // // console.log(oldTruckJson);
    return truckRecord;
  }


  const getSpecialOptions = () => {
    const list = [];
    for (let i = 0; i < specialmarkInfos.length; i += 1) {
      if (specialmarkInfos[i].type === 'car') {
        list.push(specialmarkInfos[i]);
      }
    }
    setspecialOptions(list);
  }
  const getTeamNameSelect = () => {
    for (let i = 0; i < teamList.length; i += 1) {
      if (teamList[i].id === teamSelect) {
        teamNameSelect.current = teamList[i].description;
        break;
      }
    }
  }

  const getTeamList = () => {
    const list = []
    for (let i = 0; i < teams.length; i += 1) {
      list.push({ "id": teams[i].id, "description": teams[i].name });
      if (teams[i].id === teamID)
        setOldTeamName(teams[i].name);
    }
    setTeamList(list);

    for (let i = 0; i < trucks.length; i += 1) {
      if (trucks[i].licensePlateNumber === licensePlateNumber) {
        setTeamSelect(trucks[i].team_id)
        break;
      }
    }
  };

  // const getTeamList = () => {
  //   const list = []
  //   for (let i = 0; i < trucks.length; i += 1){
  //     if(trucks[i].licensePlateNumber === licensePlateNumber){
  //       setTeamSelect(trucks[i].team_id)
  //     }      
  //   }    
  //   for (let i = 0; i < teams.length; i += 1){
  //     list.push({"id": teams[i].id , "description": teams[i].name});
  //   }
  //   setTeamList(list);
  // };



  const getDriverList = async () => {
    const list = []
    if (driverID !== null && driverID !== 0) {
      for (let i = 0; i < drivers.length; i += 1) {
        if (drivers[i].team_id === teamSelect && drivers[i].id === driverID) {
          setDriverSelect(driverID);
          setdriverValue(1);
          const description = `${drivers[i].name} (目前綁定之駕駛)`;
          list.push({ "id": drivers[i].id, "description": description });
          break;
        }
      }
    }
    for (let i = 0; i < drivers.length; i += 1) {
      for (let j = 0; j < trucks.length; j += 1) {
        if (trucks[j].driver_id === drivers[i].id) {
          break;
        }
        if (j === trucks.length - 1 && drivers[i].team_id === teamSelect && drivers[i].id !== driverID) {
          const description = `${drivers[i].name} (未綁定)`;
          list.push({ "id": drivers[i].id, "description": description });
        }
      }
    }
    setDriverList(list);
  };

  const getUseType = async () => {
    for (let i = 0; i < useTypelist.length; i += 1) {
      if (useTypelist[i].description === useType) {
        setuseTypeSelect(i)
        return;
      }
    }
    setuseTypeSelect(0)
  };
  // 更新資料
  const updateTruck = async (userOperationRecordJson, truckJson, recordJson) => {
    // console.log(values)
    await axios
      .post(`https://${ipconfig.apiIP}/api/v1/${type}/truckInfo`,
        {
          "token": users.token,
          "userOperationRecordJson": userOperationRecordJson,
          "truckJson": truckJson,
          "recordJson": recordJson
        },
        {
          header: {
            Authorization: users.token,
            "Content-Type": "application/json",
          }
        })
      .then(async (response) => {
        dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
        // eslint-disable-next-line eqeqeq
        if (response.data.resultCode == "success") {
          dispatch(setAlertMessage({ "open": true, "severity": "success", "content": "成功" }));
          dispatch(fetchTruckList({ "userName": users.userName, "token": users.token }));
          setValue(0)
          localStorage.setItem('mode', '');
          // // console.log("Success");    
          // setSnackbarStateSuccess(true);         
          // dispatch(fetchDriverList({ "userName": users.userName, "token": users.token }));
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      })
      .then(() => {
        // always executed
      });
  };


  const handleChangeTagEnable = (event) => {
    setTruckEnable(parseInt(event.target.value, 10));

    // 選停用tag => 解除綁定  
    // 駕駛disable
    if (parseInt(event.target.value, 10) === 0) {
      setdriverValue(parseInt(event.target.value, 10));
      setDriverSelectDisabled(!parseInt(event.target.value, 10));
    }

    // 選啟用 = enable 選解綁 = disabled 
    setDriverRadioDisabled(!parseInt(event.target.value, 10));
  };

  const handleChangeRadioValue = (event) => {
    setdriverValue(parseInt(event.target.value, 10));
    // 選綁定 = enable 選解綁 = disabled 
    setDriverSelectDisabled(!parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    setSnackbarStateFailed(false);
    setSnackbarStateSuccess(false);
  };

  return (

    <div>
      <FormikProvider value={formik}>
        <div>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

            <Stack spacing={3}>

              {
                (() => {
                  if (users.permission === "admin") {
                    return (<SelectMenuItem Option={teamList} select={teamSelect} setselect={setTeamSelect} labelName="選擇綁定車隊" />);
                  }
                })()
              }
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={truckEnable}
                onChange={handleChangeTagEnable}
              >
                <FormControlLabel value={1} control={<Radio />} label="啟用車輛" />
                <FormControlLabel value={0} control={<Radio />} label="停用車輛" />
              </RadioGroup>
              {
                (() => {
                  const TextFieldlist = []
                  for (let i = 0; i < textFieldLabel.length; i += 1) {
                    TextFieldlist.push(
                      <TTextField
                        name={textFieldProps[i]}
                        // type={}
                        label={textFieldLabel[i]}
                        getFieldProps={getFieldProps(textFieldProps[i])}
                        error={Boolean(touched[textFieldProps[i]] && errors[textFieldProps[i]])}
                        helperText={touched[textFieldProps[i]] && errors[textFieldProps[i]]}
                      />
                    )
                  }
                  return TextFieldlist
                })()
              }

              <MultipleCheckboxes Option={specialOptions} initSelect={specialMarkInfoID}
                selectID={specialSelectID} setselectID={setspecialSelectID}
                selectContent={specialSelectContent} setselectContent={setspecialSelectContent}
                label="選擇特殊註記" />

              <SelectMenuItem
                Option={useTypelist} select={useTypeSelect} setselect={setuseTypeSelect} labelName="選擇車輛使用類別" />

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label-1"
                name="row-radio-buttons-group-1"
                value={driverValue}
                onChange={handleChangeRadioValue}
              >
                <FormControlLabel
                  disabled={driverRadioDisabled} value={1} control={<Radio />} label="綁定駕駛" />
                <FormControlLabel
                  disabled={driverRadioDisabled} value={0} control={<Radio />} label="解除綁定駕駛" />
              </RadioGroup>
              <SelectMenuItem
                disabled={driverSelectDisabled} Option={driverList} select={driverSelect} setselect={setDriverSelect} labelName="選擇綁定駕駛" />
            </Stack>


            <Stack spacing={3} style={{ 'position': 'relative' }} sx={{ mt: 3 }}>
              <LoadingButton style={{ 'position': 'absolute', 'right': 0, }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                確認送出
              </LoadingButton>
            </Stack>
          </Form>
        </div>
        <div>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="輸入資料有誤，請重新輸入"
            key={vertical + horizontal}
          />
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open1}
            onClose={handleClose}
            message="修改完成"
            key1={vertical + horizontal}
          />
        </div>
      </FormikProvider></div>
  );
}
