import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
// material
import { Grid, Button, Container, Stack, Typography, TextField } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

import SelectMenuItem from '../components/SelectMenuItem'

// components
import Page from '../components/Page';
import BDataGrid from '../components/BDataGrid'

// css
import Styles from '../css/readerCountTips.module.css'
import * as ipconfig from "../config/ipconfig";
import * as config from "../config/config";

export default function TestMap() {
    const [specialMarkDatalist, setspecialMarkDatalist] = useState([]);
    const [specialMarkData, setspecialMarkData] = useState([]);
    const [popupTableData, setpopupTableData] = useState([]);

    const [mapcenter, setmapcenter] = useState([24, 121]);
    const [openmap, setopenmap] = useState(null);


    const [searchcarNo, setsearchcarNo] = React.useState('');

    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;


    const specialMarklist = [
        { "id": 1, "description": "贓車", "type": "truckSpecialMark" },
        { "id": 2, "description": "高風險駕駛", "type": "driverSpecialMark" },
        { "id": 3, "description": "欠稅車", "type": "truckSpecialMark" },
        { "id": 4, "description": "特殊車輛", "type": "truckSpecialMark" },
    ]
    const [specialMarkSelect, setspecialMarkSelect] = useState(1);

    const [dayDateStart, setdayDateStart] = React.useState('');
    const [dayDateEnd, setdayDateEnd] = React.useState('');

    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);
    const { loading } = progressStore;
    const [first, setfirst] = React.useState(true);

    useEffect(() => {
        const date = moment(new Date()).format('YYYY/MM/DD')
        setdayDateStart(date)
        setdayDateEnd(date)
    }, []);


    useEffect(() => {
        if (first && dayDateStart !== '' && dayDateEnd !== '') {
            setfirst(false)
            dispatch({ type: "START" });
            getData();
        }
    }, [users, dayDateStart, dayDateEnd]);


    useEffect(() => {
        setData();
    }, [specialMarkDatalist, searchcarNo]);


    // 取德標格資料
    const getData = async () => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        const utc01 = new Date(`${moment(dayDateStart).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
        const utc02 = new Date(`${moment(dayDateEnd).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
        const date1 = utc01
        const date2 = utc02
        // const difference = 8

        // 特殊事件API
        const repSpecialmark = await axios.post(`https://${ipconfig.apiIP}/api/v1/specialmark/getSpecialmarkEventAnalyze`, { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
        let index = 1;
        const list = [];


        if (repSpecialmark.data.resultCode === "Success") {
            // // console.log(response.data.rows);
            for (let i = 0; i < repSpecialmark.data.rows.length; i += 1) {
                const data = repSpecialmark.data.rows[i];
                let obj = {};
                obj = data;
                obj.receiveTime = new Date(obj.receiveTime).toLocaleString()
                obj.rownum = index
                obj.id = index
                // const specialMarkarray =[]
                // if(data.truckSpecialMark)
                //     specialMarkarray.push(data.truckSpecialMark)
                // if(data.driverSpecialMark)
                //     specialMarkarray.push(data.driverSpecialMark)

                obj.MIXMark = data.specialMark
                list.push(obj)
                index += 1;
            }
        }

        // 警示提醒三個API 
        const res = await axios.post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getCmsEventsAnalyze`, { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
        if (res.data.resultCode === "Success") {
            for (let i = 0; i < res.data.rows.length; i += 1) {
                let obj = {};
                const data = res.data.rows[i];
                obj = data;
                if (Object.keys(obj).length !== 0) {
                    obj.receiveTime = new Date(obj.endTime).toLocaleString()
                    obj.MIXMark = '大型車輛靠近'
                    obj.rownum = index
                    obj.id = index
                    list.push(obj)
                    index += 1;
                }
            }
        }
        const res2 = await axios.post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getTrafficExceedEventAnalyze`, { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
        if (res2.data.resultCode === "Success") {
            for (let i = 0; i < res2.data.rows.length; i += 1) {
                let obj = {};
                const data = res2.data.rows[i];
                obj = data;
                if (Object.keys(obj).length !== 0) {
                    obj.receiveTime = new Date(obj.localTime1).toLocaleString()
                    obj.MIXMark = "車流過多(300輛/hr)"
                    obj.rownum = index
                    obj.id = index
                    list.push(obj)
                    index += 1;
                }
            }
        }
        const res3 = await axios.post(`https://${ipconfig.apiIP}/api/v1/warnNotification/getSpeedExceedEventAnalyze`, { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
        if (res3.data.resultCode === "Success") {
            for (let i = 0; i < res3.data.rows.length; i += 1) {
                let obj = {};
                const data = res3.data.rows[i];
                obj = data;
                if (Object.keys(obj).length !== 0) {
                    obj.receiveTime = new Date(obj.localTime1).toLocaleString()
                    obj.MIXMark = `車速過快(60 Km/h)`
                    obj.rownum = index
                    obj.id = index
                    list.push(obj)
                    index += 1;
                }
            }
        }



        // 大車違規
        const res4 = await axios.post(`https://${ipconfig.apiIP}/api/v1/truckLimit/getTruckLimitAnalyze`, { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
        if (res4.data.resultCode === "Success") {
            for (let i = 0; i < res4.data.rows.length; i += 1) {
                let obj = {};
                const data = res4.data.rows[i];
                obj = data;
                if (Object.keys(obj).length !== 0) {
                    obj.receiveTime = new Date(obj.localTime1).toLocaleString()
                    obj.MIXMark = data.description
                    obj.rownum = index
                    obj.id = index
                    list.push(obj)
                    index += 1;
                }
            }
        }

        dispatch({ type: "CHANGE_TOKEN_STATUS", payload: res4.data.resultCode });

        setspecialMarkDatalist(list)
        dispatch({ type: "END" });
    };

    const setData = () => {
        const list = [];
        if (searchcarNo === '')
            setspecialMarkData(specialMarkDatalist)
        else {
            for (let i = 0; i < specialMarkDatalist.length; i += 1) {
                const havecarno = !!specialMarkDatalist[i].licensePlateNumber
                if (havecarno && specialMarkDatalist[i].licensePlateNumber.includes(searchcarNo))
                    list.push(specialMarkDatalist[i])
            }

            setspecialMarkData(list)
        }
    }


    const columns = [
        { field: "id", headerName: "id", width: 70, hideable: true },
        { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
        {
            field: "leonReaderName", headerName: "智慧感知讀取器名稱", flex: 0.35, sortable: true, headerAlign: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value === 'string')
                    return (params.value);
                const children = params.value.map((val) => (
                    <div >{val}</div>
                ));
                return (
                    <div>
                        {children}
                    </div>
                );
            }
        },
        { field: "driverName", headerName: "駕駛姓名", width: 120, flex: 0.2, headerAlign: 'center', align: 'center', },
        { field: "licensePlateNumber", headerName: "車牌", width: 120, flex: 0.2, headerAlign: 'center', align: 'center', },
        {
            field: "MIXMark", headerName: "特殊事件", width: 120, flex: 0.4, headerAlign: 'center', align: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value === 'string')
                    return (params.value);
                const children = params.value.map((val) => (
                    <div >{val}</div>
                ));
                return (
                    <div>
                        {children}
                    </div>
                );
            }
        },
        { field: "receiveTime", headerName: "時間", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center', },
    ];


    const searchOnClick = () => {
        dispatch({ type: "START" });
        getData();
    }

    const rowsPerPageOptions = [10, 20, 50, 100]

    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl">
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" mb={5} >
                    <Typography variant="h4"   >
                        <Stack direction="row" spacing={2}
                            sx={{
                                '& label ': {
                                    fontSize: config.selectLabelFontSize,
                                    top: config.selectLabelTop
                                },
                                '& input': {
                                    fontSize: config.selectItemFontSize,
                                },
                            }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <DatePicker
                                    label="起始時間"
                                    value={dayDateStart}
                                    onChange={(newValue) => {
                                        setdayDateStart(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <DatePicker
                                    label="結束時間"
                                    value={dayDateEnd}
                                    onChange={(newValue) => {
                                        setdayDateEnd(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Typography>
                    <Stack direction="row" spacing={0} item xs={2}>
                        {/* <SelectMenuItem  Option={specialMarklist} select={specialMarkSelect} setselect={setspecialMarkSelect} labelName="選擇事件種類" />   */}
                        <TextField id="outlined-basic" label="車牌號碼" variant="outlined" onChange={(e) => { setsearchcarNo(e.target.value) }} />
                    </Stack>
                    <Button variant="contained" size="large" item xs={2}
                        disabled={loading}
                        onClick={searchOnClick} >查詢</Button>
                    {loading && (
                        <CircularProgress
                            size={80}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                "z-index": 50,
                            }}
                        />
                    )}
                </Stack>
                <Grid container spacing={3} >
                    <Grid item xs={12} style={{ height: "100%" }}
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontSize: config.tableTitleFontSize
                            },
                            '& .MuiDataGrid-cellContent': {
                                fontSize: config.tableContentFontSize
                            },
                            '& .MuiDataGrid-cell': {
                                fontSize: config.tableContentFontSize
                            }
                        }}>
                        <BDataGrid data={specialMarkData} columns={columns} rowsPerPageOptions={rowsPerPageOptions}
                        />
                    </Grid>


                </Grid>
            </Container>
        </Page>
    );
}
