import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../config/types";


function* setAlertMessage(action) {
  try {
    // console.log('alert message');
    yield put(setAlertMessageSuccess(action.payload.parse));
  } catch (error) {
    // console.log('alert message error');
  }
}

export const setAlertMessageSuccess = (message) => {
  // console.log("request message success");
  return {
    type: types.SET_ALERT_MESSAGE_SUCCESS,
    payload: {
      message: message
    }
  };
};

function* alertMessageSaga() {
  yield takeEvery(types.SET_ALERT_MESSAGE_REQUEST, setAlertMessage);
}

export default alertMessageSaga;
