import { call, put, takeEvery } from "redux-saga/effects";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../config/types";
import * as ipconfig from "../config/ipconfig";


const apiUrl = `https://${ipconfig.apiIP}/api/v1/auth/login`;

function getApi(parse) {
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },

    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
};

function* fetchUsers(action) {
  try {

    // console.log('2');
    const users = yield call(getApi, action.payload.parse);
    yield put({ type: "CHANGE_TOKEN_STATUS", payload: users.resultCode });
    if (users.resultCode === 'Failed')
      yield put(getUsersNotFound(false, users));
    else {
      yield put(getUsersSuccess(users));
    }
  } catch (error) {
    yield put(getUserError(error.message));
  }
}

function* userLogout(action) {
  try {
    // console.log('userLogout');    
    yield put(getUsersLogoutSuccess());
    localStorage.removeItem('userName');
    localStorage.removeItem('token');

  } catch (error) {
    yield put(getUserError(error.message));
  }
}

export const getUsersSuccess = (users) => {
  // console.log("request users success");
  return {
    type: types.GET_USERS_SUCCESS,
    payload: {
      loading: false,
      users: users
    }
  };
};

export const getUsersLogoutSuccess = () => {
  // console.log("request users success");
  return {
    type: types.GET_USERS_SUCCESS,
    payload: {
      loading: false,
      users: []
    }
  };
};
export const getUsersNotFound = (status, users) => {
  // console.log("request users NotFound");
  return {
    type: types.GET_USERS_NOT_FOUND,
    payload: {
      loading: false,
      nackbarState: status,
      resultLog: users.resultLog || null
    }
  };
};

export const getUserError = (error) => {
  // console.log("request users error");
  return {
    type: types.GET_USERS_FAILED,
    error: error
  };
};

function* userSaga() {
  yield takeEvery(types.GET_USERS_REQUEST, fetchUsers);
  yield takeEvery(types.GET_USERS_LOGOUT_REQUEST, userLogout);
}

export default userSaga;
