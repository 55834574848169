import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';
import MomentUtils from "@date-io/moment"
import PropTypes from 'prop-types';

// material
import { Grid, Button, Container, Stack, Typography ,
    Tabs, Tab, } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DateRangePicker from '@mui/lab/DateRangePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';


import AAppWebsiteVisits from '../components/AAppWebsiteVisits';
import AAppConversionRates from '../components/AAppConversionRates';

import {
  AppTasks,
  AppNewUsers,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import SelectMenuItem from '../components/SelectMenuItem'
import ToggleButtons from '../components/ToggleButton'
import TrafficAnalyzeOfConversionRates from '../components/trafficAnalyz/TrafficAnalyzeOfConversionRates'
import TrafficAnalyze2 from '../components/turningtrafficAnalyz/TrafficAnalyze2-1'

import DayChart from '../components/turningtrafficAnalyz2/DayChart'
import MorningRushHour from '../components/turningtrafficAnalyz2/MorningRushHour'
import NightRushHour from '../components/turningtrafficAnalyz2/NightRushHour'
import DayChartSvg from '../components/turningtrafficAnalyz2/DayChartSvg'

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";

// 定義切換頁
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,    
        'style': { 'font-size': '24px' },
    };
}

export default function TestMap() {
    const [trafficFlowHourData, settrafficFlowHourData] = useState([]);
    const [sessionTrafficFlow, setsessionTrafficFlow] = useState([]);
    const [sessionDataOfTrafficFlow, setsessionDataOfTrafficFlow] = useState([]);
    const [readerGroupInfoNameMap, setreaderGroupInfoNameMap] = useState();    

    const [sessionTurningTrafficList, setsessionTurningTrafficList] = useState([]);
    const [sessionlist, setsessionlist] = useState([]);
    const [sessionselect, setsessionselect] = useState([]);
    

    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;
    
    
    const [dayDate, setdayDate] = React.useState('');
    useEffect(() => {
        const date = moment(new Date()).format('YYYY/MM/DD')        
        setdayDate(date)
    }, []);
    
    useEffect(() => {
        getrouteData();
        getSessionDataOfTrafficFlow();
    }, [users]);

    // 取得路段資料
    const getrouteData = async () => {
        await axios
            .get(`https://${ipconfig.apiIP}/api/v1/turningTraffic/getSessionIsCalc`)
            .then(async (response) => {
                // // console.dir(response.data.rows.length);
                // eslint-disable-next-line eqeqeq
                if (response.data.resultCode == "Success") {
                    const list = [];
                    const list1 = [];
                    for (let i = 0; i < response.data.rows.length; i += 1){         
                        if(response.data.rows[i].is_calc){
                            if(!list.find(a=>a.id===response.data.rows[i].id)){
                                list.push({"id":response.data.rows[i].id,"description":response.data.rows[i].address})       
                            }
                            const sessionID = response.data.rows[i].id;
                            const readerGroupInfoName = response.data.rows[i].reader_group_info_name.toString();
                            list1.push({"id":sessionID,"description":readerGroupInfoName})    
                            
                        }
                    }
                    setsessionTurningTrafficList(list1);
                    setsessionselect(list[0].id)
                    setsessionlist(list)
                    // // console.dir(obj)

                    // setsessionlist(response.data.rows)
                    // // console.dir(response.data.rows)

                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                // // console.dir('leonReaderlist api then')
            });
        // setOpen(true);
    };

    // 取得路段資料
    const getSessionDataOfTrafficFlow = async () => {
        await axios
            .get(`https://${ipconfig.apiIP}/api/v1/trafficFlow/getSessionInfoTrafficFlow`)
            .then(async (response) => {
                if (response.data.resultCode === "Success") {
                    // // console.dir(response.data.rows)        
                    // clearObjData();
                    const list = [];
                    const data = response.data.rows;          
                    const rGroupInfoNameMap = new Map();          
                    for (let i = 0; i < data.length; i += 1){
                        const obj = {};
                        obj.id = i;
                        obj.sessionID = data[i].id;
                        obj.readerGroupInfoName = data[i].reader_group_info_name;
                        rGroupInfoNameMap.set(data[i].reader_group_info_name,i)

                        obj.total = 0;
                        obj.isLeon = 0;
                        obj.notLeon = 0;
                        obj.isTruckNotLeon = 0;
                        obj.isTruck = 0;
                        list.push(obj);
                    }
                    setreaderGroupInfoNameMap(rGroupInfoNameMap);
                    setsessionDataOfTrafficFlow(list);
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                // // console.dir('leonReaderlist api then')
            });
        // setOpen(true);
    };


    // tab切換
    const handleChange = (event, newValue) => {
        settabValue(newValue);
    };

    
    const [tabValue, settabValue] = React.useState(0);

    const [loading, setLoading] = React.useState(false);
    
    const [unitAmountSelect, setunitAmountSelect] = useState(0);
    const unitAmountList = [
        { id: 0, description: '車輛計數', value: 1, },
        { id: 1, description: 'PCU', value: 2, },
    ]

    const locale = "zh-tw"
    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl"> 
                
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="轉向交通量圖" {...a11yProps(0)} />
                    <Tab label="日轉向量" {...a11yProps(1)} />
                    <Tab label="晨峰時段轉向量" {...a11yProps(2)} />
                    <Tab label="昏峰時段轉向量" {...a11yProps(3)} />
                </Tabs>
                
                <TabPanel value={tabValue} index={0}>                
                      <DayChartSvg                             
                        dayDate={dayDate} setdayDate={setdayDate}                               
                        unitAmountSelect={unitAmountSelect} setunitAmountSelect={setunitAmountSelect} unitAmountList={unitAmountList}
                        sessionTurningTrafficList={sessionTurningTrafficList} sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                      />               
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                      <DayChart                
                        dayDate={dayDate} setdayDate={setdayDate}                   
                        unitAmountSelect={unitAmountSelect} setunitAmountSelect={setunitAmountSelect} unitAmountList={unitAmountList}            
                        sessionTurningTrafficList={sessionTurningTrafficList} sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                      />               
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                      <MorningRushHour                            
                        dayDate={dayDate} setdayDate={setdayDate}   
                        unitAmountSelect={unitAmountSelect} setunitAmountSelect={setunitAmountSelect} unitAmountList={unitAmountList}                             
                        sessionTurningTrafficList={sessionTurningTrafficList} sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                      />               
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                      <NightRushHour                            
                        dayDate={dayDate} setdayDate={setdayDate}    
                        unitAmountSelect={unitAmountSelect} setunitAmountSelect={setunitAmountSelect} unitAmountList={unitAmountList}                            
                        sessionTurningTrafficList={sessionTurningTrafficList} sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                      />               
                </TabPanel>
                
            </Container>
        </Page>
    );
}
