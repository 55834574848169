import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";


import {
    AppTasks,
    AppNewUsers,
    AppItemOrders,
    AppNewsUpdate,
    AppWeeklySales,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppCurrentSubject,
    AppConversionRates
} from '../_dashboard/app';
import SelectMenuItem from '../SelectMenuItem'
// components
import Page from '../Page';
import BMap from '../BMap';
import BDataGrid from '../BDataGrid'
import MultipleSelectCheckmarks from '../MultipleSelectCheckmarks'
import ToggleButtons from '../ToggleButton'
// import TrafficAnalyzeChartDay2 from './TrafficAnalyzeChartDay2'
import TrafficAnalyzeChartDay from './TrafficAnalyzeChartDay'
import RushHourAnalyzeChart from './RushHourAnalyzeChart'
import * as ipconfig from "../../config/ipconfig";



export default function TestMap(props) {
    // 1.isLeon 
    // 2.notLeon 
    // 3.isTruck 
    // 4.total     
    const tagNam = 'isTruck';
    const tagNam1 = 'total';
    // const chartTitle1 = '車流統計';
    const chartTitle2 = '日平均旅行速度統計(時間單位:時 ; 時速單位:公里/小時)';
    const chartTitle3 = '週平均旅行速度統計(時速單位:公里/小時)';
    const chartTitle4 = '月平均旅行速度統計(時間單位:日;時速單位:公里/小時)';
    // const chartTitle5 = '早上尖峰時段 07:00 ~ 9:00(單位:周)';
    // const chartTitle6 = '晚上尖峰時段 17:00 ~ 19:00(單位:周)';
    const sqlText2 = ' Hours';
    const sqlText3 = 'weekDay';
    const sqlText4 = 'day';


    const { chartTitle, sqlText } = props
    const { startDate, endDate, chartUnit } = props
    const { hour1, hour2 } = props
    const { chartTimeUnit, time } = props
    const { sessionlist, sessionselect } = props

    const [dataSpeed, setdataSpeed] = useState([]);
    const [dataTime, setdataTime] = useState([]);
    const [dataMonth, setdataMonth] = useState([]);
    const [dataWeekDay, setdataWeekDay] = useState([]);
    // const [dataDay, setdata] = useState([]); 
    const dispatch = useDispatch();
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;

    const timer = React.useRef();

    useEffect(() => {
        if (sessionlist.length > 0 && startDate !== '') {
            // console.log(1231321321)
            getTravelSpeed(sqlText, setdataSpeed);
            getTravelTime(sqlText, setdataTime);
        }
    }, [users, startDate, endDate, sessionlist]);

    /* ******    取得全部路口車流資料  ********
        假設 datetime = 2022/04/26 00:00:00
        如果本地端為UTC+8 
        utc0 = 2022/04/25 16:00:00
        difference = 8
    *************************************** */
    const getTravelSpeed = async (sqlText, setData) => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        const date1 = startDate
        const date2 = endDate
        // const difference = 8
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/travel/getTravelSpeed`, {
                "token": users.token, "date1": date1, "date2": date2, "sqlText": sqlText,
                "chartTimeUnit": chartTimeUnit, "utc": difference,
                "hour1": hour1, "hour2": hour2
            })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq   
                // console.log(response.data);
                if (response.data.resultCode === "Success") {
                    setData(response.data.rows)
                }
            })
            .catch((error) => {
                // handle error
                dispatch({ type: "END" });
                // console.log(error);
            })
            .then(() => {
                // always executed
            });
        // setOpen(true);
    };
    /* ******    取得全部路口車流資料  ********
        假設 datetime = 2022/04/26 00:00:00
        如果本地端為UTC+8 
        utc0 = 2022/04/25 16:00:00
        difference = 8
    *************************************** */
    const getTravelTime = async (sqlText, setData) => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        const date1 = startDate
        const date2 = endDate
        // const difference = 8
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/travel/getTravelTime`, {
                "token": users.token, "date1": date1, "date2": date2, "sqlText": sqlText,
                "chartTimeUnit": chartTimeUnit, "utc": difference,
                "hour1": hour1, "hour2": hour2
            })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq   
                // console.log(response.data);
                if (response.data.resultCode === "Success") {
                    setData(response.data.rows)
                }
            })
            .catch((error) => {
                // handle error
                dispatch({ type: "END" });
                // console.log(error);
            })
            .then(() => {
                // always executed
            });
        // setOpen(true);
    };


    const [loading, setLoading] = React.useState(false);

    return (
        <Grid container  >
            {sessionlist.map((list, index) => {
                if (list.id === sessionselect) {
                    if (time === 'morning' || time === 'night') {
                        return (
                            <Grid item xs={12} mb={5} >
                                <RushHourAnalyzeChart
                                    chartTitle={chartTitle} sessionselect={index} sessionlist={sessionlist}
                                    startDate={startDate} endDate={endDate}
                                    data={dataSpeed} dataTime={dataTime} time={time} chartUnit={chartUnit} chartTimeUnit={chartTimeUnit}
                                />
                            </Grid>
                        )
                    }
                    return (
                        <Grid item xs={12} mb={5} >
                            <TrafficAnalyzeChartDay
                                chartTitle={chartTitle} sessionselect={index} sessionlist={sessionlist}
                                startDate={startDate} endDate={endDate}
                                data={dataSpeed} dataTime={dataTime}
                            />
                        </Grid>
                    )
                }
                return null
            })}


        </Grid>
    );
}
