import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";


import {
    AppTasks,
    AppNewUsers,
    AppItemOrders,
    AppNewsUpdate,
    AppWeeklySales,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppCurrentSubject,
    AppConversionRates
} from '../_dashboard/app';
import SelectMenuItem from '../SelectMenuItem'
// components
import Page from '../Page';
import BMap from '../BMap';
import BDataGrid from '../BDataGrid'
import MultipleSelectCheckmarks from '../MultipleSelectCheckmarks'
import ToggleButtons from '../ToggleButton'
import TrafficAnalyzeChartDay from './TrafficAnalyzeChartDay'
import RushHourAnalyzeChart from './RushHourAnalyzeChart'
import * as ipconfig from "../../config/ipconfig";


export default function TestMap(props) {
    // 1.isLeon 
    // 2.notLeon 
    // 3.isTruck 
    // 4.total     
    const tagNam = 'isTruck';
    const tagNam1 = 'total';
    // const chartTitle1 = '車流統計';
    const chartTitle2 = '日平均轉向量統計(單位:時)';
    const chartTitle3 = '週平均轉向量統計';
    const chartTitle4 = '月平均轉向量統計(單位:日)';
    // const chartTitle5 = '早上尖峰時段 07:00 ~ 9:00(單位:周)';
    // const chartTitle6 = '晚上尖峰時段 17:00 ~ 19:00(單位:周)';
    const sqlText2 = 'day , Month , Hours';
    const sqlText3 = 'day , Month';
    const sqlText4 = 'day , Month';

    const { chartTitle, sqlText } = props
    const { startDate, endDate, chartUnit } = props
    const { hour1, hour2 } = props
    const { sessionTurningTrafficList, sessionselect } = props
    const { chartlist, chartSelect } = props
    const { vehicleTypelist, vehicleTypeSelect } = props
    const { chartTimeUnitlist, chartTimeUnitSelect, time } = props
    const { unitAmountValue } = props

    const [sessionlist, setsessionlist] = useState([]);
    const [dataDay, setdataDay] = useState([]);
    const [dataMonth, setdataMonth] = useState([]);
    const [dataWeekDay, setdataWeekDay] = useState([]);
    // const [dataDay, setdata] = useState([]); 
    // const [sessionselect, setsessionselect] = useState(0);
    const dispatch = useDispatch();
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;

    useEffect(() => {
        if (sessionTurningTrafficList.length > 0 && startDate !== '' && endDate !== '') {
            // if(chartUnit === 'Hours')
            getTrafficFlowData(sqlText, setdataDay);
        }
    }, [users, startDate, endDate, sessionTurningTrafficList, chartSelect, chartTimeUnitSelect]);

    /* ******    取得全部路口車流資料  ********
        假設 datetime = 2022/04/26 00:00:00
        如果本地端為UTC+8 
        utc0 = 2022/04/25 16:00:00
        difference = 8
    *************************************** */
    const getTrafficFlowData = async (sqlText, setData) => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        // const utc01 = new Date(`${moment(startDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
        // const utc02 = new Date(`${moment(endDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
        const date1 = startDate
        const date2 = endDate
        // const difference = 8
        // console.log(sqlText)
        let chartTimeUnitTemp = 60;
        if (chartTimeUnitlist !== undefined)
            chartTimeUnitTemp = chartTimeUnitlist[chartTimeUnitSelect - 1].value
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/turningTraffic/getTurningTrafficOfAnalyzeFilter`, {
                "token": users.token, "date1": date1, "date2": date2, "sqlText": sqlText, 'chartTimeUnit': chartTimeUnitTemp, "utc": difference
                , "hour1": hour1, "hour2": hour2
            })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq   
                // // console.log(response.data)
                if (response.data.resultCode === "Success") {
                    setData(response.data.rows)
                    // const obj = {};        
                    // const objWeek = {};        
                    // const objMonth = {};        
                    // for(let i = 0;i<roadArealist.length;i+=1){
                    //     obj[roadArealist[i].id] = {}
                    //     for(let j = 0;j<24;j+=1){
                    //         obj[roadArealist[i].id][j] = []
                    //     }
                    // }               
                    // const data = response.data.rows;                    
                    // for (let i = 0; i < data.length; i += 1){
                    //     if(data[i].Hours !== null){
                    //         obj[data[i].session_info_id][data[i].Hours].push(data[i]);       

                    //     }                        
                    // }
                    // settrafficFlowHourData(obj)
                }
            })
            .catch((error) => {
                // handle error
                dispatch({ type: "END" });
                // console.log(error);
            })
            .then(() => {
                // always executed
            });
        // setOpen(true);
    };

    const timer = React.useRef();
    const searchOnClick = () => {
        // getTrafficFlowData();
        // getData(ToggleButtonselect);
        // setChartData();
        if (!loading) {
            setLoading(true);
            timer.current = window.setTimeout(() => {
                setLoading(false);
            }, 2000);
        }

    }

    const labelTeamName = "選擇路段"
    const [loading, setLoading] = React.useState(false);


    // const vehicleTypelist = [
    //     {"id":1,"description":"本計畫之大型車輛","type":"isLeon"},
    //     {"id":2,"description":"非本計畫之大型車輛","type":"isTruckNotLeon"},
    //     {"id":3,"description":"全部車輛","type":"total"},
    // ]
    // const [vehicleTypeSelect, setvehicleTypeSelect] = useState(1);

    return (
        <Grid container  >

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}  >


                {/* <MultipleSelectCheckmarks Option={chartlist} select={chartSelect} setselect={setchartSelect} label="選擇圖表"/>
                    <SelectMenuItem  Option={sessionlist} select={sessionselect} setselect={setsessionselect} labelName={labelTeamName}/> */}

                {/* <Button variant="contained" size="large" 
                    disabled={loading}
                    onClick={searchOnClick} >查詢</Button>
                    {loading && (
                    <CircularProgress
                        size={150}
                        sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        "z-index":5000
                        }}
                    />
                    )} */}
            </Stack>

            {sessionTurningTrafficList.map((list, index) => {
                if (list.id === sessionselect) {
                    if (time === 'morning' || time === 'night') {
                        return (
                            <Grid item xs={12} mb={5} >
                                <RushHourAnalyzeChart
                                    chartTitle={chartTitle} sessionselect={index} sessionTurningTrafficList={sessionTurningTrafficList}
                                    startDate={startDate} endDate={endDate} vehicleList={vehicleTypelist} vehicleselect={vehicleTypeSelect}
                                    chartTimeUnitlist={chartTimeUnitlist} chartTimeUnitSelect={chartTimeUnitSelect}
                                    data={dataDay} time={time} chartUnit={chartUnit}
                                    unitAmountValue={unitAmountValue}
                                />
                            </Grid>
                        )
                    }
                    return (
                        <Grid item xs={12} mb={5} >
                            <TrafficAnalyzeChartDay
                                chartTitle={chartTitle} sessionselect={index} sessionTurningTrafficList={sessionTurningTrafficList}
                                startDate={startDate} endDate={endDate} vehicleList={vehicleTypelist} vehicleselect={vehicleTypeSelect}
                                data={dataDay}
                                unitAmountValue={unitAmountValue}
                            />
                        </Grid>
                    )
                }
                return null
            })}

        </Grid>
    );
}
