import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';

// datatable
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Image } from 'primereact/image';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

// components
import Page from '../Page';
import BMap from '../BMap';
import BDataGrid from '../BDataGrid'
import MultipleSelectCheckmarks from '../MultipleSelectCheckmarks'
import ToggleButtons from '../ToggleButton'
import SelectMenuItem from '../SelectMenuItem'

// import logo from '../turning.svg'
import { ReactComponent as Logo1 } from '../../turning1.svg'
import { ReactComponent as Logo7 } from '../../turning7.svg'
// css
import Styles from '../../css/pic.module.css'
import * as ipconfig from "../../config/ipconfig";
import * as config from "../../config/config";

export default function TestMap(props) {
    const { sessionTurningTrafficList, sessionlist, sessionselect } = props
    const { timeRangelist, timeRangeSelect } = props
    const { startDate, endDate } = props
    const { hour1, hour2 } = props
    const { chartTitle, sqlText } = props
    const { unitAmountValue } = props

    const [sessionImage, setsessionImage] = useState(1);
    const [sessionTurningTrafficData, setsessionTurningTrafficData] = useState([]);
    const [mapcenter, setmapcenter] = useState([24, 121]);
    const [openmap, setopenmap] = useState(null);
    const [tableData, settableData] = useState([]);
    const [ToggleButtonselect, setToggleButtonselect] = useState(60);
    const ToggleButtonsOptions = [
        { value: 5, Name: '5分鐘' },
        { value: 15, Name: '15分鐘' },
        { value: 30, Name: '30分鐘' },
        { value: 60, Name: '60分鐘' },
    ]
    const dt = useRef(null);
    const dispatch = useDispatch();
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;

    useEffect(() => {
        if (sessionTurningTrafficList.length > 0 && startDate !== '' && endDate !== '') {
            getData();
        }
    }, [startDate, endDate, sessionTurningTrafficList]);
    useEffect(() => {
        // // console.log(sessionTurningTrafficData);
        // const imageSrc = `/static/sessionImage/img-${sessionselect}.jpg`
        // setsessionImage(imageSrc)
        setTableData();
        dispatch({ type: "END" });

    }, [sessionselect, sessionTurningTrafficData, unitAmountValue]);

    const getData = async () => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        // const utc01 = new Date(`${moment(startDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
        // const utc02 = new Date(`${moment(endDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ') 
        const date1 = startDate
        const date2 = endDate
        // console.log(startDate)
        // const difference = 8
        const chartTimeUnitTemp = 60;
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/turningTraffic/getTurningTrafficOfAnalyzeFilter`, {
                "token": users.token, "date1": date1, "date2": date2, "sqlText": sqlText,
                'chartTimeUnit': chartTimeUnitTemp, "utc": difference,
                "hour1": hour1, "hour2": hour2

            })
            .then(async (response) => {
                // console.log(`"token": ${users.token}, 
                // "date1": "${date1}", 
                // "date2": "${date2}", 
                // "hour1": "${hour1}", 
                // "hour2": "${hour2}",
                // "sqlText": "${sqlText}", 
                // "chartTimeUnit": "${chartTimeUnitTemp}"`);
                // console.log(response.data)
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq   
                if (response.data.resultCode === "Success") {
                    setsessionTurningTrafficData(response.data.rows)
                    // console.log(response.data.rows)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
                dispatch({ type: "END" });
            })
            .then(() => {
                // always executed
            });
        // setOpen(true);
    };

    const mappingObj = {
        1: {
            '三民路一段東南向往西北': 'B',
            '三民路一段西北向往東南': 'D',
            '建國路往西南': 'A',
            '竹圍街往東北': 'C'
        },
        7: {
            '海山路往東南(南山路三段&海山路路口)': 'C',
            '山林路一段往西北(南山路三段&海山路路口)': 'A',
            '南山路三段北向往南(南山路三段&海山路路口)': 'D',
            '南山路三段南向往北(南山路三段&海山路路口)': 'B'
        }
    }
    const compareValues = (a, b) => {
        // converting to uppercase to have case-insensitive comparison
        const name1 = a.sort.toUpperCase();
        const name2 = b.sort.toUpperCase();

        let comparison = 0;

        if (name1 > name2) {
            comparison = 1;
        } else if (name1 < name2) {
            comparison = -1;
        }
        return comparison;
    }
    // 統計顯示相關
    const setTableData = () => {
        const data = [];
        for (let i = 0; i < sessionTurningTrafficList.length; i += 1) {
            const objdata = {};

            objdata.sort = `${mappingObj[sessionselect][sessionTurningTrafficList[i].description]}`
            objdata.address = `${mappingObj[sessionselect][sessionTurningTrafficList[i].description]}.${sessionTurningTrafficList[i].description}`;
            objdata.address1 = `${sessionTurningTrafficList[i].description}`;
            objdata.timeRange = `前${60}分鐘~現在`;
            objdata.leonLeft = 0;
            objdata.leonRight = 0;
            objdata.leonForward = 0;

            objdata.etagTruckLeft = 0;
            objdata.etagTruckRight = 0;
            objdata.etagTruckForward = 0;

            objdata.etagCarLeft = 0;
            objdata.etagCarRight = 0;
            objdata.etagCarForward = 0;

            objdata.totalLeft = 0;
            objdata.totalRight = 0;
            objdata.totalForward = 0;
            if (sessionTurningTrafficList[i].id === sessionselect) {
                for (let j = 0; j < sessionTurningTrafficData.length; j += 1) {
                    if (sessionTurningTrafficList[i].description === sessionTurningTrafficData[j].reader_group_info_name) {
                        const isetagData = !Number.isNaN(sessionTurningTrafficData[j].iseTag)
                        const etagData = isetagData ? parseInt(sessionTurningTrafficData[j].iseTag, 10) : 9999
                        if (sessionTurningTrafficData[j].is_leonTag !== 0) {
                            if (sessionTurningTrafficData[j].direction === '左轉') {
                                objdata.leonLeft += sessionTurningTrafficData[j].count * unitAmountValue;
                                objdata.totalLeft += sessionTurningTrafficData[j].count * unitAmountValue;
                            }
                            else if (sessionTurningTrafficData[j].direction === '直行') {
                                objdata.leonForward += sessionTurningTrafficData[j].count * unitAmountValue;
                                objdata.totalForward += sessionTurningTrafficData[j].count * unitAmountValue;
                            }
                            else if (sessionTurningTrafficData[j].direction === '右轉') {
                                objdata.leonRight += sessionTurningTrafficData[j].count * unitAmountValue;
                                objdata.totalRight += sessionTurningTrafficData[j].count * unitAmountValue;
                            }
                        }
                        else if (isetagData && etagData >= 3 && etagData <= 5) {
                            if (sessionTurningTrafficData[j].direction === "左轉") {
                                if (etagData === 3) {
                                    objdata.etagCarLeft += sessionTurningTrafficData[j].count;
                                    objdata.totalLeft += sessionTurningTrafficData[j].count;
                                }
                                else if (etagData >= 4) {
                                    objdata.etagTruckLeft += sessionTurningTrafficData[j].count * unitAmountValue;
                                    objdata.totalLeft += sessionTurningTrafficData[j].count * unitAmountValue;
                                }
                            }
                            else if (sessionTurningTrafficData[j].direction === "右轉") {
                                if (etagData === 3) {
                                    objdata.etagCarRight += sessionTurningTrafficData[j].count;
                                    objdata.totalRight += sessionTurningTrafficData[j].count;
                                }
                                else if (etagData >= 4) {
                                    objdata.etagTruckRight += sessionTurningTrafficData[j].count * unitAmountValue;
                                    objdata.totalRight += sessionTurningTrafficData[j].count * unitAmountValue;
                                }
                            }
                            else if (sessionTurningTrafficData[j].direction === "直行") {
                                if (etagData === 3) {
                                    objdata.etagCarForward += sessionTurningTrafficData[j].count;
                                    objdata.totalForward += sessionTurningTrafficData[j].count;
                                }
                                else if (etagData >= 4) {
                                    objdata.etagTruckForward += sessionTurningTrafficData[j].count * unitAmountValue;
                                    objdata.totalForward += sessionTurningTrafficData[j].count * unitAmountValue;
                                }
                            }
                        }
                    }
                }
                data.push(objdata)
            }
        }
        data.sort(compareValues);
        settableData(data);
    }

    useEffect(() => {
        const sectionText = ['A', 'B', 'C', 'D']
        const truningText = ['Right', 'Forward', 'Left']

        const turningMappingObj = {
            'Right': 'totalRight',
            'Forward': 'totalForward',
            'Left': 'totalLeft',
        }
        // console.log(tableData)
        for (let i = 0; i < tableData.length; i += 1) {
            let sum = 0;
            const sectionTextTemp = mappingObj[sessionselect][tableData[i].address1];
            for (let k = 0; k < 2; k += 1) {
                for (let j = 0; j < truningText.length; j += 1) {
                    if (k === 0) {
                        const id1 = `${sectionTextTemp}${truningText[j]}_1`;
                        const id2 = `${sectionTextTemp}${truningText[j]}_1_1`;
                        const element1 = document.getElementById(id1);
                        const element2 = document.getElementById(id2);
                        const turningValue = tableData[i][turningMappingObj[truningText[j]]]
                        element1.innerHTML = turningValue;
                        element2.innerHTML = turningValue;
                        sum += turningValue
                    }
                    else {
                        const turningValue = tableData[i][turningMappingObj[truningText[j]]]
                        const temp = sum !== 0 ? `${parseInt(turningValue * 100 / sum, 10)}%` : '0%'
                        const id2 = `${sectionTextTemp}Total_1`;
                        const id1 = `${sectionTextTemp}${truningText[j]}_2`;
                        const element1 = document.getElementById(id1);
                        const element2 = document.getElementById(id2);
                        element1.innerHTML = temp;
                        element2.innerHTML = sum;
                    }
                }
            }
        }

        for (let i = 0; i < sectionText.length; i += 1) {
            let sum = 0;
            for (let j = 0; j < truningText.length; j += 1) {
                const index = (i + j + 1) % 4;
                const id1 = `${sectionText[index]}${truningText[j]}_1`
                const element1 = document.getElementById(id1);
                sum += parseInt(element1.innerHTML, 10);
            }
            const id2 = `${sectionText[i]}Total_2`;
            const element2 = document.getElementById(id2);
            element2.innerHTML = sum;
        }

        const nowDate = moment(startDate).add(8, 'hours').format('YYYY/MM/DD')
        const element1 = document.getElementById('Date');
        const element2 = document.getElementById('Time');
        element1.innerHTML = `日期:${nowDate}`;
        element2.innerHTML = `時間:${timeRangelist[timeRangeSelect].description}`;


    }, [tableData]);

    function download() {
        /* eslint-disable */
        // get svg element.
        const svg = document.getElementById("svg1");

        // get svg source.
        const serializer = new XMLSerializer();
        let source = serializer.serializeToString(svg);
        // add name spaces.
        if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        // add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        // convert svg source to URI data scheme.
        const url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

        const a = document.createElement('a')
        a.href = url
        let sectionName = '';
        for (let i = 0; i < sessionlist.length; i += 1) {
            if (sessionlist[i].id === sessionselect)
                sectionName = sessionlist[i].description;
        }
        a.download = `${sectionName}.svg`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    }

    const header = (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between"  >
            <Button variant="outlined" size="large" item xs={2}
                onClick={() => exportCSV(false)}
                data-pr-tooltip="CSV">匯出</Button>
        </Stack>
    );

    const headerGroup = <ColumnGroup>
        <Row>
            <Column header="路段名稱(起)" rowSpan={2} style={{ 'text-align': 'center' }} />
            {/* <Column header="統計時段" rowSpan={2} style={{'text-align': 'center'}}/> */}
            <Column header="本計畫之大型車輛" colSpan={3} style={{ 'text-align': 'center' }} />
            <Column header="etag大型車輛" colSpan={3} style={{ 'text-align': 'center' }} />
            <Column header="etag小客車" colSpan={3} style={{ 'text-align': 'center' }} />
            <Column header="全部車輛" colSpan={3} style={{ 'text-align': 'center' }} />
        </Row>
        <Row>
            <Column header="左轉" />
            <Column header="直行" />
            <Column header="右轉" />
            <Column header="左轉" />
            <Column header="直行" />
            <Column header="右轉" />
            <Column header="左轉" />
            <Column header="直行" />
            <Column header="右轉" />
            <Column header="左轉" />
            <Column header="直行" />
            <Column header="右轉" />
        </Row>
    </ColumnGroup>;
    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl">
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} >
                    <Typography variant="h4" gutterBottom  style={{'marginTop':'18px'}}>
                        <Stack direction="row" spacing={2} >                            
                            <SelectMenuItem  Option={sessionlist} select={sessionselect} setselect={setsessionselect} labelName="選擇路段"/>
                            <ToggleButtons Options={ToggleButtonsOptions} setSelect={setToggleButtonselect} Select={ToggleButtonselect} />
                        </Stack>
                    </Typography>
                    
                </Stack> */}

                <Button variant="outlined" size="large" item xs={2}
                    onClick={() => download()} >轉向量圖下載</Button>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} >

                    <div className="App" style={{ width: "100%" }}>
                        {/* <img src={logo} className="App-logo" alt="turning" /> */}
                        {(() => {
                            if (sessionselect === 1) {
                                return (
                                    <Logo1 id='svg1' className="App-logo" alt="turning" stroke="#DB7290" strokeWidth="1rem" />
                                )
                            }
                            return (
                                <Logo7 id='svg1' className="App-logo" alt="turning" stroke="#DB7290" strokeWidth="1rem" />
                            )
                        })()}

                    </div>
                </Stack>


                <Grid container spacing={3} >
                    <Grid item xs={12}
                        sx={{
                            height: '100%',
                            width: 1,
                            '& .p-column-title': {
                                fontSize: config.tableTitleFontSize
                            },
                            '& td': {
                                fontSize: config.tableContentFontSize
                            }
                        }}>
                        <div >
                            <div className="card" style={{ 'text-align': 'center' }} >

                                {/* <Tooltip target=".export-buttons>button" position="bottom" /> */}

                                <DataTable ref={dt} header={header} value={tableData} headerColumnGroup={headerGroup} responsiveLayout="scroll">
                                    <Column field="address" />
                                    {/* <Column field="timeRange" style={{'text-align': 'center'}}/> */}
                                    <Column field="leonLeft" style={{ 'text-align': 'center' }} />
                                    <Column field="leonForward" style={{ 'text-align': 'center' }} />
                                    <Column field="leonRight" style={{ 'text-align': 'center' }} />
                                    <Column field="etagTruckLeft" style={{ 'text-align': 'center' }} />
                                    <Column field="etagTruckForward" style={{ 'text-align': 'center' }} />
                                    <Column field="etagTruckRight" style={{ 'text-align': 'center' }} />
                                    <Column field="etagCarLeft" style={{ 'text-align': 'center' }} />
                                    <Column field="etagCarForward" style={{ 'text-align': 'center' }} />
                                    <Column field="etagCarRight" style={{ 'text-align': 'center' }} />
                                    <Column field="totalLeft" style={{ 'text-align': 'center' }} />
                                    <Column field="totalForward" style={{ 'text-align': 'center' }} />
                                    <Column field="totalRight" style={{ 'text-align': 'center' }} />
                                </DataTable>
                            </div>

                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
