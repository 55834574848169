import * as types from "../config/types";
export function fetchUsers(data) {
  // console.log("request users");
  return {
    type: types.GET_USERS_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}
export function fetchUserList(data) {
  // console.log("request users List");
  return {
    type: types.GET_USER_LIST_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}
export function fetchTeams(data) {
  // console.log("request users");
  return {
    type: types.GET_TEAM_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}
export function fetchSpecialmarkInfo(data) {
  // console.log("request users");
  return {
    type: types.GET_SPECIALMARK_INFO_LIST_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}
export function userLogout(data) {
  // console.log("request logout users");
  return {
    type: types.GET_USERS_LOGOUT_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}

export function fetchUserChangePassword(data) {
  // console.log("request Change Password");
  return {
    type: types.GET_USERS_CHANGE_PASSWORD_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}

export function fetchUserOperationRecord(data) {
  // console.log("request Operation Record");
  return {
    type: types.GET_USERS_OPERATION_RECORD_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}

export function fetchDetailRecord(data) {
  // console.log("request fetchDetailRecord");
  return {
    type: types.GET_DETAIL_RECORD_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}

export function fetchOverviewList(data) {
  // console.log("GET_TRUCK_REQUEST");
  return {
    type: types.GET_OVERVIEW_LIST_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}

export function fetchTruckList(data) {
  // console.log("GET_TRUCK_REQUEST");
  return {
    type: types.GET_TRUCK_LIST_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}

export function fetchDriverList(data) {
  // console.log("GET_DRIVER_LIST_REQUEST");
  return {
    type: types.GET_DRIVER_LIST_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}

export function fetchReaderList(data) {
  // console.log("GET_READER_LIST_REQUEST");
  return {
    type: types.GET_READER_LIST_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}
export function fetchCmsList(data) {
  // console.log("GET_CMS_LIST_REQUEST");
  return {
    type: types.GET_CMS_LIST_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}

export function fetchLeonTagList(data) {
  // console.log("GET_TRUCK_REQUEST");
  return {
    type: types.GET_LEONTAG_LIST_REQUEST,
    payload: {
      loading: true,
      parse:data
    }
  };
}

export function setAlertMessage(data) {
  // console.log("SET_ALERT_MESSAGE_REQUEST");
  return {
    type: types.SET_ALERT_MESSAGE_REQUEST,
    payload: {
      parse:data
    }
  };
}

