import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';

// material
import { Grid, Button, Container, Stack, Typography, TextField } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

import SelectMenuItem from '../components/SelectMenuItem'
import AAppWebsiteVisits from '../components/AAppWebsiteVisits';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import ToggleButtons from '../components/ToggleButton'
import ReaderCountDataTable from './ReaderCountDataTable'

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";
import * as config from "../config/config";


export default function TestMap() {
    const [specialMarkData, setspecialMarkData] = useState([]);
    const [popupTableData, setpopupTableData] = useState([]);
    const [tableData, settableData] = useState([]);

    const [mapcenter, setmapcenter] = useState([24, 121]);
    const [openmap, setopenmap] = useState(null);



    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;


    const specialMarklist = [
        { "id": 0, "description": "全部", "type": "all" },
        { "id": 1, "description": "贓車", "type": "truckSpecialMark" },
        { "id": 2, "description": "欠稅車", "type": "truckSpecialMark" },
        { "id": 3, "description": "特殊車輛", "type": "truckSpecialMark" },
        { "id": 4, "description": "高風險駕駛", "type": "driverSpecialMark" },
    ]
    const [specialMarkSelect, setspecialMarkSelect] = useState(0);



    const [dayDateStart, setdayDateStart] = React.useState('');
    const [dayDateEnd, setdayDateEnd] = React.useState('');

    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);
    const { loading } = progressStore;
    const [first, setfirst] = React.useState(true);

    useEffect(() => {
        const date = moment(new Date()).format('YYYY/MM/DD')
        setdayDateStart(date)
        setdayDateEnd(date)
    }, []);

    useEffect(() => {
        if (first && dayDateStart !== '' && dayDateEnd !== '') {
            setfirst(false)
            dispatch({ type: "START" });
            getData();
        }
    }, [users, dayDateStart, dayDateEnd]);


    useEffect(() => {
        setTableData();
    }, [specialMarkData, specialMarkSelect]);




    // 取德標格資料
    const getData = async () => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        const utc01 = new Date(`${moment(dayDateStart).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
        const utc02 = new Date(`${moment(dayDateEnd).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
        const date1 = utc01
        const date2 = utc02
        // const difference = 8
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/specialmark/getSpecialmarkEventAnalyze`, { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                if (response.data.resultCode === "Success") {
                    const list = [];
                    const list1 = [];
                    let index = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj1 = {};
                        const data = response.data.rows[i];
                        const values = "reader_id";
                        if (!list1.find(a => a.id === data[values])) {
                            obj1 = data;
                            obj1.id = data[values]
                            list1.push(obj1)
                        }

                        let obj = {};
                        obj = data;
                        obj.receiveTime = new Date(obj.receiveTime).toLocaleString()
                        obj.rownum = index
                        obj.id = index
                        list.push(obj)
                        index += 1;
                    }
                    setspecialMarkData(list)
                    // setDate(list)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                dispatch({ type: "END" });
                // always executed
            });
        // setOpen(true);
    };



    const setTableData = () => {
        const specialMarkID = specialMarklist[specialMarkSelect].id
        const list = [];
        let index = 1;
        for (let i = 0; i < specialMarkData.length; i += 1) {
            const data = specialMarkData[i];
            let obj = {};
            if (data.specialMark_info_id.includes(specialMarkID) || specialMarkSelect === 0) {
                obj = data;
                obj.rownum = index
                list.push(obj)
                index += 1;
            }
        }
        settableData(list)
    };



    const columns = [
        { field: "id", headerName: "id", width: 70, hideable: true },
        { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
        {
            field: "leonReaderName", headerName: "智慧感知讀取器名稱", flex: 0.35, sortable: true, headerAlign: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value === 'string')
                    return (params.value);
                const children = params.value.map((val) => (
                    <div >{val}</div>
                ));
                return (
                    <div>
                        {children}
                    </div>
                );
            }
        },
        { field: "driverName", headerName: "駕駛姓名", width: 120, flex: 0.2, headerAlign: 'center', align: 'center', },
        { field: "licensePlateNumber", headerName: "車牌", width: 120, flex: 0.2, headerAlign: 'center', align: 'center', },
        { field: "specialMark", headerName: "特殊事件", width: 120, flex: 0.4, headerAlign: 'center', align: 'center', },
        { field: "receiveTime", headerName: "時間", width: 100, flex: 0.25, sortable: true, headerAlign: 'center', align: 'center', },
    ];


    const searchOnClick = () => {
        dispatch({ type: "START" });
        getData();
    }

    const rowsPerPageOptions = [10, 20, 50, 100]

    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} >
                    <Typography variant="h4"   >
                        <Stack direction="row" spacing={2}
                            sx={{
                                '& label ': {
                                    fontSize: config.selectLabelFontSize,
                                    top: config.selectLabelTop
                                },
                                '& input': {
                                    fontSize: config.selectItemFontSize,
                                },
                            }}>

                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <DatePicker
                                    label="起始時間"
                                    value={dayDateStart}
                                    onChange={(newValue) => {
                                        setdayDateStart(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <DatePicker
                                    label="結束時間"
                                    value={dayDateEnd}
                                    onChange={(newValue) => {
                                        setdayDateEnd(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Typography>
                    <SelectMenuItem Option={specialMarklist} select={specialMarkSelect} setselect={setspecialMarkSelect} labelName="選擇事件種類" />

                    <Button variant="contained" size="large" item xs={2}
                        disabled={loading}
                        onClick={searchOnClick} >查詢</Button>
                    {loading && (
                        <CircularProgress
                            size={150}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                "z-index": 5000
                            }}
                        />
                    )}
                </Stack>
                <Grid container spacing={3} >
                    <Grid item xs={12} style={{ height: "100%" }}
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontSize: config.tableTitleFontSize
                            },
                            '& .MuiDataGrid-cellContent': {
                                fontSize: config.tableContentFontSize
                            },
                            '& .MuiDataGrid-cell': {
                                fontSize: config.tableContentFontSize
                            }
                        }}>
                        <BDataGrid data={tableData} columns={columns} rowsPerPageOptions={rowsPerPageOptions}
                        />
                    </Grid>


                </Grid>
            </Container>
        </Page>
    );
}
