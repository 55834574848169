import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

import SelectMenuItem from '../SelectMenuItem'

import TrafficAnalyze2 from './TrafficAnalyze2-1'
import * as config from "../../config/config";


export default function TrafficAnalyzeOfConversionRates(props) {
    const { sessionTurningTrafficList, sessionlist, sessionselect, setsessionselect } = props
    const { unitAmountSelect, setunitAmountSelect, unitAmountList } = props
    const { dayDate, setdayDate } = props
    const tagNam = 'isTruck';
    const tagNam1 = 'total';
    const chartTitle2 = '日平均轉向量統計(單位:時)';
    const chartTitle5 = '晨峰時段07:00-09:00平均車流統計(單位:輛/時)';
    const chartTitle6 = '昏峰時17:00-19:00平均車流統計(單位:輛/時)';

    const sqlText2 = 'day , Month , Hours';
    const sqlText5 = 'DateHHMM having Hours >= 7 and Hours < 9  ORDER BY DateHHMM';
    const sqlText6 = 'DateHHMM having Hours >= 17 and Hours < 19  ORDER BY DateHHMM';
    // const [dayDate, setdayDate] = React.useState('2022/5/19');
    const [dayDateStart, setdayDateStart] = React.useState('');
    const [dayDateEnd, setdayDateEnd] = React.useState('');


    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);
    const { loading } = progressStore;
    const [first, setfirst] = React.useState(true);

    useEffect(() => {
        if (first && dayDate !== '') {
            const utc01 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
            const utc02 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
            setdayDateStart(utc01)
            setdayDateEnd(utc02)
            dispatch({ type: "START" });
            setfirst(false)
        }
    }, [dayDate]);

    const searchOnClick = () => {
        const utc01 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
        const utc02 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
        // console.log(utc01)
        // console.log(utc02)
        setdayDateStart(utc01)
        setdayDateEnd(utc02)
        if (utc01 !== dayDateStart)
            dispatch({ type: "START" });
    }

    const vehicleTypelist = [
        { "id": 1, "description": "本計畫之大型車輛", "type": "isLeon" },
        { "id": 2, "description": "etag大型車輛", "type": "etagTruck" },
        { "id": 3, "description": "etag小客車", "type": "etagCar" },
        { "id": 4, "description": "全部車輛", "type": "total" },
    ]
    const [vehicleTypeSelect, setvehicleTypeSelect] = useState(1);

    return (
        <Grid>

            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" mb={5} >
                <Stack direction="row" spacing={2}
                    sx={{
                        '& label ': {
                            fontSize: config.selectLabelFontSize,
                            top: config.selectLabelTop
                        },
                        '& input': {
                            fontSize: config.selectItemFontSize,
                        },
                    }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}  >
                        <DatePicker
                            label="選擇日期"
                            // views={['year']}
                            value={dayDate}
                            onChange={(newValue) => {
                                setdayDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    {/* <SelectMenuItem Option={unitAmountList} select={unitAmountSelect} setselect={setunitAmountSelect} labelName="選擇計數單位" hidden="1" width={200} /> */}
                </Stack>
                <Stack direction="row" spacing={0} item xs={2}>
                    {/* <SelectMenuItem  Option={chartlist} select={chartSelect} setselect={setchartSelect} labelName="選擇統計單位"/> */}
                    <SelectMenuItem Option={sessionlist} select={sessionselect} setselect={setsessionselect} labelName="選擇路段" />

                </Stack>
                <Button variant="contained" size="large" item xs={2}
                    disabled={loading}
                    onClick={searchOnClick} >查詢</Button>
                {loading && (
                    <CircularProgress
                        size={80}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            "z-index": 50,
                        }}
                    />
                )}
            </Stack>

            <Stack container spacing={4} direction="row" mb={5} >
                <SelectMenuItem Option={vehicleTypelist} select={vehicleTypeSelect} setselect={setvehicleTypeSelect} labelName="選擇車輛種類" />
            </Stack>

            <Grid item xs={12}>
                <TrafficAnalyze2
                    startDate={dayDateStart} endDate={dayDateEnd}
                    chartTitle={chartTitle2} sqlText={sqlText2}
                    sessionTurningTrafficList={sessionTurningTrafficList} sessionselect={sessionselect}
                    vehicleTypelist={vehicleTypelist} vehicleTypeSelect={vehicleTypeSelect} chartUnit="Hours"
                    hour1={0} hour2={23}
                    unitAmountValue={unitAmountList[unitAmountSelect].value}
                />
            </Grid>

        </Grid>

    );
}
