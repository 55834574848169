import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

import SelectMenuItem from '../SelectMenuItem'
import TrafficAnalyzeChart from './TrafficAnalyzeChart copy'

import TrafficAnalyzeChart2 from './TrafficAnalyzeChart2'
import * as config from '../../config/config'

export default function TrafficAnalyzeOfConversionRates(props) {
    const { sessionlist, sessionselect, setsessionselect } = props
    const { unitAmountSelect, setunitAmountSelect, unitAmountList } = props

    const { readerSectionlist } = props
    const { dayDate, setdayDate } = props
    const tagNam = 'isTruck';
    const tagNam1 = 'total';
    const chartTitle5 = '晨峰時段07:00-09:00平均車流統計(單位:輛/時)';
    const chartTitle6 = '昏峰時17:00-19:00平均車流統計(單位:輛/時)';

    const sqlText5 = 'DateHHMM ORDER BY DateHHMM';
    const sqlText6 = 'DateHHMM ORDER BY DateHHMM';
    // const [dayDate, setdayDate] = React.useState('2022/5/19');
    const [dayDateStart, setdayDateStart] = React.useState('');
    const [dayDateEnd, setdayDateEnd] = React.useState('');
    const [optionSelect, setoptionSelect] = React.useState(0);
    const [optionReaderSelect, setoptionReaderSelect] = React.useState(0);

    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);
    const { loading } = progressStore;
    const [first, setfirst] = React.useState(true);
    useEffect(() => {
        if (first && dayDate !== '') {
            const date = moment().format('YYYY/MM/DD')
            const utc01 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
            const utc02 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
            // console.log(new Date(`${moment(dayDate).format('YYYY/MM/DD')} 00:00:00`))
            // console.log(utc02)             
            setdayDateStart(utc01)
            setdayDateEnd(utc02)
            setoptionSelect(sessionselect)
            dispatch({ type: "START" });
            setfirst(false)
        }
    }, [dayDate]);


    const searchOnClick = () => {
        const utc01 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
        const utc02 = new Date(`${moment(dayDate).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
        // console.log(utc01)
        // console.log(utc02)
        setdayDateStart(utc01)
        setdayDateEnd(utc02)
        setoptionSelect(sessionselect)
        setoptionReaderSelect(readerSectionlistselect)
        if (utc01 !== dayDateStart)
            dispatch({ type: "START" });
    }

    const [readerlist, setreaderlist] = useState([]);
    const [readerSectionlistselect, setreaderSectionlistselect] = useState(0);

    useEffect(() => {
        if (readerSectionlist.length > 0 && sessionlist.length > 0) {
            const list = [];
            let index = 1;
            const data = readerSectionlist;
            const rGroupInfoNameMap = new Map();
            list.push({ "id": 0, "description": '全部', "description1": '全部' })
            for (let i = 0; i < data.length; i += 1) {
                if (sessionlist[sessionselect].description === data[i].reader_group_info_name) {
                    list.push({
                        "id": index,
                        "description": `${data[i].reader_info_name} - ${data[i].reader_info_leonReaderName}`,
                        "description1": data[i].reader_info_leonReaderName
                    })
                    index += 1
                }
            }
            setreaderlist(list);
        }
    }, [readerSectionlist, sessionlist, sessionselect]);

    return (
        <Grid>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} >
                <Typography variant="h4" gutterBottom  >
                    <Stack direction="row" spacing={2}
                        sx={{
                            '& label ': {
                                fontSize: config.selectLabelFontSize,
                                top: config.selectLabelTop
                            },
                            '& input': {
                                fontSize: config.selectItemFontSize,
                            },
                        }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}
                        >
                            <DatePicker
                                label="選擇日期"
                                // views={['year']}
                                value={dayDate}
                                onChange={(newValue) => {
                                    setdayDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                        <Stack >
                            {/* <SelectMenuItem Option={unitAmountList} select={unitAmountSelect} setselect={setunitAmountSelect} labelName="選擇計數單位"  /> */}
                        </Stack>
                    </Stack>
                </Typography>
                <Button variant="contained" size="large" item xs={2}
                    disabled={loading}
                    onClick={searchOnClick} >查詢
                </Button>
                {loading && (
                    <CircularProgress
                        size={150}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            "z-index": 5000
                        }}
                    />
                )}
            </Stack>
            <Stack direction="row" spacing={2} mb={3}  >
                <Stack>
                    <SelectMenuItem Option={sessionlist} select={sessionselect} setselect={setsessionselect} labelName="選擇路段" width={400} />
                </Stack>
                <Stack>
                    <SelectMenuItem Option={readerlist} select={readerSectionlistselect} setselect={setreaderSectionlistselect} labelName="選擇智慧感知讀取器" width={450} />
                </Stack>
            </Stack>

            <Grid item xs={12} mb={2} >
                <TrafficAnalyzeChart
                    chartTitle="車流統計(單位:輛/時)" tagNam={tagNam} sessionlist={sessionlist} select={optionSelect}
                    readerlist={readerlist} readerselect={optionReaderSelect}
                    startDate={dayDateStart} endDate={dayDateEnd} chartUnit="Hours"
                    unitAmountValue={unitAmountList[unitAmountSelect].value}
                    hour1={0} hour2={23}
                />
            </Grid>

            <Grid item xs={12} mb={2}  >
                <TrafficAnalyzeChart2
                    chartTitle={chartTitle5} tagNam={tagNam} sessionlist={sessionlist} select={optionSelect}
                    readerlist={readerlist} readerselect={optionReaderSelect}
                    startDate={dayDateStart} endDate={dayDateEnd} sqlText={sqlText5} chartUnit="DateHHMM" time="morning"
                    unitAmountValue={unitAmountList[unitAmountSelect].value}
                    hour1={7} hour2={8}
                />
            </Grid>
            <Grid item xs={12} mb={2}  >
                <TrafficAnalyzeChart2
                    chartTitle={chartTitle6} tagNam={tagNam} sessionlist={sessionlist} select={optionSelect}
                    readerlist={readerlist} readerselect={optionReaderSelect}
                    startDate={dayDateStart} endDate={dayDateEnd} sqlText={sqlText6} chartUnit="DateHHMM" time="night"
                    unitAmountValue={unitAmountList[unitAmountSelect].value}
                    hour1={17} hour2={18}
                />
            </Grid>
        </Grid>

    );
}
