export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILED = "GET_USER_LIST_FAILED";

export const GET_TEAM_REQUEST = "GET_TEAM_REQUEST";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAILED = "GET_TEAM_FAILED";

export const GET_SPECIALMARK_INFO_LIST_REQUEST = "GET_SPECIALMARK_INFO_LIST_REQUEST";
export const GET_SPECIALMARK_INFO_LIST_SUCCESS = "GET_SPECIALMARK_INFO_LIST_SUCCESS";
export const GET_SPECIALMARK_INFO_LIST_FAILED = "GET_SPECIALMARK_INFO_LIST_FAILED";

export const GET_USERS_LOGOUT_REQUEST = "GET_USERS_LOGOUT_REQUEST";
export const GET_USERS_LOGOUT_SUCCESS = "GET_USERS_LOGOUT_SUCCESS";

export const GET_USERS_FAILED = "GET_USERS_FAILED";
export const GET_USERS_NOT_FOUND = "GET_USERS_NOT_FOUND";

export const GET_USERS_CHANGE_PASSWORD_REQUEST = "GET_USERS_CHANGE_PASSWORD_REQUEST";
export const GET_USERS_CHANGE_PASSWORD_SUCCESS = "GET_USERS_CHANGE_PASSWORD_SUCCESS";
export const GET_USERS_CHANGE_PASSWORD_FAILED = "GET_USERS_CHANGE_PASSWORD_FAILED";

export const GET_USERS_OPERATION_RECORD_REQUEST = "GET_USERS_OPERATION_RECORD_REQUEST";
export const GET_USERS_OPERATION_RECORD_SUCCESS = "GET_USERS_OPERATION_RECORD_SUCCESS";
export const GET_USERS_OPERATION_RECORD_FAILED = "GET_USERS_OPERATION_RECORD_FAILED";

export const GET_DETAIL_RECORD_REQUEST = "GET_DETAIL_RECORD_REQUEST";
export const GET_DETAIL_RECORD_SUCCESS = "GET_DETAIL_RECORD_SUCCESS";
export const GET_DETAIL_RECORD_FAILED = "GET_DETAIL_RECORD_FAILED";

export const GET_TRUCK_LIST_REQUEST = "GET_TRUCK_LIST_REQUEST";
export const GET_TRUCK_LIST_SUCCESS = "GET_TRUCK_LIST_SUCCESS";
export const GET_TRUCK_LIST_FAILED = "GET_TRUCK_LIST_FAILED";

export const GET_OVERVIEW_LIST_REQUEST = "GET_OVERVIEW_LIST_REQUEST";
export const GET_OVERVIEW_LIST_SUCCESS = "GET_OVERVIEW_LIST_SUCCESS";
export const GET_OVERVIEW_LIST_FAILED = "GET_OVERVIEW_LIST_FAILED";

export const GET_LEONTAG_LIST_REQUEST = "GET_LEONTAG_LIST_REQUEST";
export const GET_LEONTAG_LIST_SUCCESS = "GET_LEONTAG_LIST_SUCCESS";
export const GET_LEONTAG_LIST_FAILED = "GET_LEONTAG_LIST_FAILED";

export const GET_DRIVER_LIST_REQUEST = "GET_DRIVER_LIST_REQUEST";
export const GET_DRIVER_LIST_SUCCESS = "GET_DRIVER_LIST_SUCCESS";
export const GET_DRIVER_LIST_FAILED = "GET_DRIVER_LIST_FAILED";

export const GET_READER_LIST_REQUEST = "GET_READER_LIST_REQUEST";
export const GET_READER_LIST_SUCCESS = "GET_READER_LIST_SUCCESS";
export const GET_READER_LIST_FAILED = "GET_READER_LIST_FAILED";

export const GET_CMS_LIST_REQUEST = "GET_CMS_LIST_REQUEST";
export const GET_CMS_LIST_SUCCESS = "GET_CMS_LIST_SUCCESS";
export const GET_CMS_LIST_FAILED =  "GET_CMS_LIST_FAILED";

export const SET_ALERT_MESSAGE_REQUEST = "SET_ALERT_MESSAGE_REQUEST";
export const SET_ALERT_MESSAGE_SUCCESS = "SET_ALERT_MESSAGE_SUCCESS";
