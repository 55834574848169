import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, useMapEvents, Polyline } from 'react-leaflet';
import 'leaflet-arrowheads'
import Leaflet from 'leaflet'
import { mapCenter } from '../config/config';
const defaultcenter = [mapCenter.lat, mapCenter.lng];

const cmsOnIcon = new Leaflet.Icon({
  iconUrl: '/static/icons/img/icon-map-sign.svg',
  popupAnchor: [1, -48], iconSize: [39, 48],
  iconAnchor: [19, 48],
});
const cmsOffIcon = new Leaflet.Icon({
  iconUrl: '/static/icons/img/icon-map-sign-alert.svg',
  popupAnchor: [1, -48], iconSize: [39, 48],
  iconAnchor: [19, 48],
});
const readerOnIcon = new Leaflet.Icon({
  iconUrl: '/static/icons/img/icon-map-rfid-reader.svg',
  popupAnchor: [1, -48], iconSize: [39, 48],
  iconAnchor: [19, 48],
});
const readerOffIcon = new Leaflet.Icon({
  iconUrl: '/static/icons/img/icon-map-rfid-reader-alert.svg',
  popupAnchor: [1, -48], iconSize: [39, 48],
  iconAnchor: [19, 48],
});
const cctvOnIcon = new Leaflet.Icon({
  iconUrl: '/static/icons/img/icon-camera.svg',
  popupAnchor: [1, -48], iconSize: [39, 48],
  iconAnchor: [19, 48],
});
const cctvOffIcon = new Leaflet.Icon({
  iconUrl: '/static/icons/img/icon-camera-alert.svg',
  popupAnchor: [1, -48], iconSize: [39, 48],
  iconAnchor: [19, 48],
});

function BMap(props) {
  const mapHeight = props.mapHeight !== undefined ? props.mapHeight : '65vh';
  const isPopupline = props.ispopupline !== undefined;
  const flymap = (point, id) => {
    // if(props.setChartData !== undefined)
    //   props.setChartData(id)
    props.openmap.flyTo(point)
  }

  function LocationMarker() {
    const [position, setPosition] = useState(null)

    const map = useMapEvents({
      click(e) {
        // console.dir( `[${e.latlng.lng},${e.latlng.lat}],`);
        setPosition(e.latlng)
      },
      locationfound(e) {
        setPosition(e.latlng)
        map.flyTo(e.latlng, map.getZoom())
      },
    })

    return position === null ? null : (
      <Marker position={position}>
        <Popup>{position.lng},{position.lat}</Popup>
      </Marker>
    )
  }


  return (
    <MapContainer id="map"
      center={!props.mapcenter ? defaultcenter : props.mapcenter}
      zoom={12} maxNativeZoom={30}
      whenCreated={props.setopenmap}

      zoomControl={false}
      style={{ height: mapHeight, width: '100%' }}
    >
      {!!props.Geoarray && props.Geoarray.length > 0 && props.Geoarray.map((Goe, index) => {
        const { id, data, color, pathName, travelSpeed, travelTime } = Goe

        const positions = [];
        for (let i = 0; i < data.length; i += 1)
          positions.push([data[i][1], data[i][0]])
        return (

          // <Polyline id={id} positions={positions} color={color} >
          //   <Popup >
          //            {props.linepopupcontent(pathName  , travelSpeed , travelTime,color)}
          //          </Popup>
          // </Polyline>       

          <GeoJSON id={id} smoothFactor={5} data={{
            "type": "FeatureCollection",
            "features": [
              {
                "type": "Feature",
                "properties": {},
                "geometry": {
                  "type": "LineString",
                  "coordinates": data
                }
              }
            ]
          }}
            // arrowheads={{ size: '15px', color: color, }} 
            style={{ color: color }} >
            {(() => {
              if (isPopupline)
                return (
                  <Popup >
                    {props.linepopupcontent(pathName, travelSpeed, travelTime, color)}
                  </Popup>
                )
            })()}
          </GeoJSON>
        )
      })}


      <TileLayer

        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        maxNativeZoom={19}
        maxZoom={23}
        minZoom={12}
        // url="https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=1rOPuT2gcLTbHJyeD4Rv"
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {props.MarkerArray.length > 0 && props.MarkerArray.map((incident) => {
        if (incident.latitude === 0)
          return

        const point = [incident.latitude, incident.longitude];
        let icon = readerOnIcon;
        let textColor = '#848484';
        // 判斷設備類型
        if (incident.id !== null && incident.code !== undefined) {
          // CCTV 設備的判斷
          if (incident.status === "online") {
            icon = cctvOnIcon;
          } else {
            icon = cctvOffIcon;
            textColor = '#848484';
          }
        } else if (incident.ReaderCount !== null && incident.ReaderCount !== undefined) {
          // 既有的 Reader 判斷邏輯
          if (incident.ReaderCount > 0) {
            icon = readerOnIcon;
          } else {
            icon = readerOffIcon;
            textColor = '#848484';
          }
        } else if (incident.cms_id !== null && incident.cms_id !== undefined) {
          // 既有的 CMS 判斷邏輯
          if (incident.status === 1) {
            icon = cmsOnIcon;
          } else {
            icon = cmsOffIcon;
            textColor = '#848484';
          }
        }

        return (
          <Marker position={point} key={incident.id} id={incident.id} icon={icon} >
            <Popup onOpen={() => flymap(point, incident.id)}>
              {props.popupcontent(incident, textColor)}
            </Popup>
          </Marker>
        );
      })}
      {/* <LocationMarker /> */}
    </MapContainer>
  );
}



export default BMap;
