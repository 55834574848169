
import * as types from "../config/types";

const initialState = {
  loading: false,
  specialmarkInfos: [],
  specialmarkInfoReciverStatus: false,
  error: null
};

export default function specialmarkInfo (state = initialState, action)  {
  switch (action.type) {
    case types.GET_SPECIALMARK_INFO_LIST_REQUEST: {
      return {
        ...state,
        loading: true,   
      };
    }
    case types.GET_SPECIALMARK_INFO_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        specialmarkInfoReciverStatus:true,
        specialmarkInfos: action.payload.specialmarkInfos.rows
      };
    }
    case types.GET_SPECIALMARK_INFO_LIST_FAILED: {
      return {
        ...state,
        loading: false,
        error: "FAILED",       
      };
    }
    default:
      return state;
  }
};

