
export function convertGeoline(array,color) {
    const list =[]
        for (let z = 0; z < array.length-1; z += 1){  
            list.push({
                data:[array[z],array[z+1]],
                color:color,
                index:z
            })
        }        
        return list
  }