import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import carFilled from '@iconify/icons-ant-design/car-filled';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';

// material
import {
    Grid, Button, Container, Stack, Typography,
    Tabs, Tab,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import AAppWebsiteVisits from '../components/AAppWebsiteVisits';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import ToggleButtons from '../components/ToggleButton'
import ReaderCountDataTable from './ReaderCountDataTable'
import TTable from '../components/TTable'
import SelectMenuItem from '../components/SelectMenuItem'

import {
    AAppEvent,
    AppEvent,
} from '../components/_dashboard/app';

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";
import * as config from "../config/config";

// 定義切換頁
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        'style': { 'font-size': '24px' },
    };
}
export default function TestMap() {
    const [leonReaderlist, setleonReaderlist] = useState([]);
    const [truckLimitData, settruckLimitData] = useState([]);
    const [speedExceedData, setspeedExceedData] = useState([]);
    const [popupTableData, setpopupTableData] = useState([]);
    const [Vehiclelist, setVehiclelist] = React.useState([]);

    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;


    const [dayDateStart, setdayDateStart] = React.useState('');
    const [dayDateEnd, setdayDateEnd] = React.useState('');

    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);
    const { loading } = progressStore;
    const [first, setfirst] = React.useState(true);

    useEffect(() => {
        const date = moment(new Date()).format('YYYY/MM/DD')
        setdayDateStart(date)
        setdayDateEnd(date)
    }, []);

    useEffect(() => {
        if (first && dayDateStart !== '' && dayDateEnd !== '') {
            setfirst(false)
            dispatch({ type: "START" });
            getData();
        }
    }, [users, dayDateStart, dayDateEnd]);


    const [parkingIn, setparkingIn] = useState([]);
    const [parkingInTotal, setparkingInTotal] = useState(0);
    const [parkingOut, setparkingOut] = useState([]);
    const [parkingOutTotal, setparkingOutTotal] = useState(0);

    /* ******    取得全部路口車流資料  ********
        假設 datetime = 2022/04/26 00:00:00
        如果本地端為UTC+8 
        utc0 = 2022/04/25 16:00:00
        difference = 8
    *************************************** */
    const getData = async () => {
        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        const utc01 = new Date(`${moment(dayDateStart).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
        const utc02 = new Date(`${moment(dayDateEnd).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')
        const date1 = utc01
        const date2 = utc02
        // const difference = 8
        // console.log(date1);
        // console.log(date2);
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/parking/getParkingAnalyze`, { "token": users.token, "date1": date1, "date2": date2, "utc": difference })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq   
                if (response.data.resultCode === "Success") {
                    const list = [];
                    let index = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;

                        if (data.type === 'parking-in') {
                            obj.rownum = index
                            obj.id = index
                            list.push(obj)
                            index += 1;
                        }
                    }

                    setparkingIn(list);
                    setparkingInTotal(index - 1);

                    const outlist = [];
                    let outindex = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;

                        if (data.type === 'parking-out') {
                            obj.rownum = outindex
                            obj.id = outindex
                            outlist.push(obj)
                            outindex += 1;
                        }
                    }
                    setparkingOut(outlist);
                    setparkingOutTotal(outindex - 1);
                    // settruckLimitData(list)   
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                dispatch({ type: "END" });
            });
        // setOpen(true);
    };


    const headCells = [
        {
            id: 'rownum',
            numeric: false,
            disablePadding: true,
            fillter: false,
            label: '項目'
        },
        {
            id: 'leonReaderName',
            numeric: true,
            disablePadding: true,
            fillter: true,
            label: '智慧感知讀取器名稱'
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            fillter: true,
            label: '設備編號'
        },
        {
            id: 'licensePlateNumber',
            numeric: false,
            disablePadding: true,
            fillter: true,
            label: '車牌'
        },
        {
            id: 'receiveTime',
            numeric: false,
            disablePadding: true,
            fillter: false,
            label: '時間',
            type: 'format',
            fn: (data) => {
                const time = new Date(data).toLocaleString()
                return (
                    time
                )
            }
        },
    ];

    const searchOnClick = () => {
        dispatch({ type: "START" });
        getData();
    }

    const [tabValue, settabValue] = React.useState(0);

    // tab切換
    const handleChange = (event, newValue) => {
        settabValue(newValue);
    };

    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl">

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} >
                    <Typography variant="h4" gutterBottom  >
                        <Stack direction="row" spacing={2}
                            sx={{
                                '& label ': {
                                    fontSize: config.selectLabelFontSize,
                                    top: config.selectLabelTop
                                },
                                '& input': {
                                    fontSize: config.selectItemFontSize,
                                },
                            }}>

                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <DatePicker
                                    label="起始時間"
                                    value={dayDateStart}
                                    onChange={(newValue) => {
                                        setdayDateStart(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <DatePicker
                                    label="結束時間"
                                    value={dayDateEnd}
                                    onChange={(newValue) => {
                                        setdayDateEnd(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Stack>

                    </Typography>
                    <Button variant="contained" size="large" item xs={2}
                        disabled={loading}
                        onClick={searchOnClick} >查詢</Button>
                    {loading && (
                        <CircularProgress
                            size={150}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                "z-index": 5000
                            }}
                        />
                    )}

                </Stack>

                <Grid container spacing={3}>
                    <Grid item xs={6} >
                        <AAppEvent title="停車場-入場數量" img="/static/icons/img/icon-car-in.svg" TOTAL={parkingInTotal} unit="輛" />
                    </Grid>
                    <Grid item xs={6} >
                        <AppEvent title="停車場-出場數量" img="/static/icons/img/icon-car-out.svg" TOTAL={parkingOutTotal} unit="輛" />
                    </Grid>
                </Grid>
                {/* <Grid container spacing={3}>
                    <Grid item xs={6} >
                        <AAppWeeklySales title="停車場-入場數量" subtitle={parkingInTotal}/>
                    </Grid>
                    <Grid item xs={6} >
                        <AAppWeeklySales title="停車場-出場數量" subtitle={parkingOutTotal}/>
                    </Grid>
                </Grid> */}

                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" style={{ 'marginTop': '10px' }}>
                    <Tab label="停車場-入場明細" {...a11yProps(0)} />
                    <Tab label="停車場-出場明細" {...a11yProps(1)} />
                </Tabs>


                <TabPanel value={tabValue} index={0}>
                    <Grid item xs={12} style={{ height: "100%" }}
                        sx={{
                            '& th': {
                                fontSize: config.tableTitleFontSize
                            },
                            '& td': {
                                fontSize: config.tableContentFontSize
                            }
                        }}>
                        <TTable Vehiclelist={parkingIn} headCells={headCells} />
                    </Grid>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <Grid item xs={12} style={{ height: "100%" }}
                        sx={{
                            '& th': {
                                fontSize: config.tableTitleFontSize
                            },
                            '& td': {
                                fontSize: config.tableContentFontSize
                            }
                        }}>
                        <TTable Vehiclelist={parkingOut} headCells={headCells} />
                    </Grid>
                </TabPanel>

                {/* <Grid container spacing={3} >
                    
                    
                    <Grid item xs={12} style={{ height: "100%" }}>
                        
                        <TTable Vehiclelist={parkingOut} headCells={headCells} />
                    </Grid>
                    

                </Grid> */}
            </Container>
        </Page>
    );
}
