import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader } from '@mui/material';
//
import { BaseOptionChart } from './charts';

// ----------------------------------------------------------------------

const CHART_DATA = [{ data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380] }];
// const ChartOptions = merge(BaseOptionChart(), {
//   tooltip: {
//     marker: { show: false },
//     y: {
//       formatter: (seriesName) => fNumber(seriesName),
//       title: {
//         formatter: (seriesName) => `#${seriesName}`
//       }
//     }
//   },
//   plotOptions: {
//     bar: { horizontal: true, barHeight: '28%', borderRadius: 2 }
//   },
//   xaxis: {
//     categories: [
//       'Italy',
//       'Japan',
//       'China',
//       'Canada',
//       'France',
//       'Germany',
//       'South Korea',
//       'Netherlands',
//       'United States',
//       'United Kingdom'
//     ]
//   }
// });


export default function AAppConversionRates(props) {
  const chartOptions = merge(BaseOptionChart(), props.chartOptions);

  return (
    <Card>
      <CardHeader title={props.title} subheader={props.subtitle} />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={props.data} options={chartOptions} height={450} />
      </Box>
    </Card>
  );
}
