import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AAppConversionRates from '../AAppConversionRates';

import BDataGrid from '../BDataGrid'

export default function TrafficAnalyzeOfConversionRates(props) {
    const { session, select, data, tagNam, chartTitle, sessionDataOfTrafficFlow, readerGroupInfoNameMap, setsessionDataOfTrafficFlow } = props
    const { unitAmountValue } = props
    const [tabledata, settabledata] = useState([]);
    const [sessionSelect, setsessionSelect] = useState();

    const [trafficFlowData, settrafficFlowData] = useState([]);
    useEffect(() => {
        // getSessionDataOfTrafficFlow();
        // // console.dir(session)
        // // console.dir(select)
        // // console.dir(data)        
    }, []);
    // useEffect(() => {        
    //     const list = [];     
    //     for (let i = 0; i < session.length; i += 1){
    //         for (let j = 0; j < select.length; j += 1){
    //             if(session[i].address === select[j] )
    //                 list.push(session[i].id)
    //         }
    //     }
    //     setsessionSelect(list);

    // }, [props]);

    useEffect(() => {
        if (sessionDataOfTrafficFlow.length > 0) {
            clearObjData();
            setTableData();
        }
    }, [sessionDataOfTrafficFlow, data, unitAmountValue]);
    // 取得路段資料
    // const getSessionDataOfTrafficFlow = async () => {
    //     await axios
    //         .get('https://${ipconfig.apiIP}/api/v1/trafficFlow/getSessionInfoTrafficFlow')
    //         .then(async (response) => {
    //             if (response.data.resultCode === "Success") {
    //                 // console.dir(response.data.rows)        
    //                 const list = [];
    //                 const data = response.data.rows;          
    //                 const rGroupInfoNameMap = new Map();          
    //                 for (let i = 0; i < data.length; i += 1){
    //                     const obj = {};
    //                     obj.id = i;
    //                     obj.sessionID = data[i].id;
    //                     obj.readerGroupInfoName = data[i].reader_group_info_name;
    //                     rGroupInfoNameMap.set(data[i].reader_group_info_name,i)

    //                     obj.total = 0;
    //                     obj.isLeon = 0;
    //                     obj.notLeon = 0;
    //                     // for(let j = 0;j<24;j+=1){
    //                     //     const hour = `hour${j}`
    //                     //     const hour1 = `hour${j}isLeon`
    //                     //     const hour2 = `hour${j}notLeon`
    //                     //     obj[hour] = 0
    //                     //     obj[hour1] = 0
    //                     //     obj[hour2] = 0
    //                     // }
    //                     list.push(obj);
    //                 }
    //                 setreaderGroupInfoNameMap(rGroupInfoNameMap);
    //                 setsessionDataOfTrafficFlow(list);


    //             }
    //         })
    //         .catch((error) => {
    //             // handle error
    //             // // console.log(error);
    //         })
    //         .then(() => {
    //             // always executed
    //             // // console.dir('leonReaderlist api then')
    //         });
    //     // setOpen(true);
    // };

    const clearObjData = async () => {
        const array = sessionDataOfTrafficFlow;

        for (let i = 0; i < array.length; i += 1) {
            Object.keys(array[i]).forEach(key => {
                const total = `total`
                const isLeon = `isLeon`
                const isEtag = `isEtag`
                const notLeon = `notLeon`
                const isTruckNotLeon = `isTruckNotLeon`
                const isTruck = `isTruck`
                if (key === total)
                    array[i][key] = 0
                if (key === isLeon)
                    array[i][key] = 0
                if (key === notLeon){
                    array[i][key] = 0
                    array[i][isEtag] = 0
                }
                if (key === isTruckNotLeon)
                    array[i][key] = 0
                if (key === isTruck)
                    array[i][key] = 0

                // for(let j = 0;j<24;j+=1){      
                //     const hour = `hour${j}`
                //     const hour1 = `hour${j}isLeon`
                //     const hour2 = `hour${j}notLeon`
                //     if(key === hour)
                //         array[i][key] = 0
                //     if(key === hour1)
                //         array[i][key] = 0
                //     if(key === hour2)
                //         array[i][key] = 0
                // }
            })
        }
        setsessionDataOfTrafficFlow(array);
    }

    // const [chartTitle, setchartTitle] = useState("");
    const [chartSubtitle, setchartSubtitle] = useState("");

    const [chartOptions, setchartOptions] = useState({});
    const [chartData, setchartData] = useState([]);

    const setTableData = async () => {

        // setreaderGroupInfoNameMap(rGroupInfoNameMap);
        // setsessionDataOfTrafficFlow(list);      

        const list = sessionDataOfTrafficFlow;
        for (let i = 0; i < data.length; i += 1) {
            if (readerGroupInfoNameMap.has(data[i].reader_group_info_name)) {
                const index = readerGroupInfoNameMap.get(data[i].reader_group_info_name);
                // const hour = `hour${data[i].Hours}`;
                // const hour1 = `hour${data[i].Hours}isLeon`;
                // const hour2 = `hour${data[i].Hours}notLeon`;
                const total = `total`
                const isLeon = `isLeon`
                const isEtag = `isEtag`
                const notLeon = `notLeon`
                const isTruckNotLeon = `isTruckNotLeon`
                const isTruck = `isTruck`
                list[index][total] += data[i].count;
                if (data[i].isLeonTag)
                    list[index][isLeon] += data[i].count * unitAmountValue;
                // else
                //     list[index][notLeon] += data[i].count;

                const isetagData = !Number.isNaN(data[i].isTruckNotLeon)
                const etagData = isetagData ? parseInt(data[i].isTruckNotLeon, 10) : 9999
                if (data[i].isLeonTag === 0 && (etagData === 4 || etagData === 5))
                    list[index][isTruckNotLeon] += data[i].count * unitAmountValue;
                else if (data[i].isLeonTag === 0 && (etagData === 3 ))
                    list[index][isEtag] += data[i].count;

                list[index][total] = list[index][isLeon] + list[index][isEtag]  + list[index][isTruckNotLeon];
                list[index][isTruck] = list[index][isLeon] + list[index][isTruckNotLeon];
            }
        }

        const arrCopy = [...list];
        const dataB = arrCopy.sort((a, b) =>
            a[tagNam] < b[tagNam] ? 1 : -1
        );

        // setchartTitle("Test");
        setChartData(dataB);
        // console.dir(list)

        // // console.dir(sessionSelect)
        // const list2 = [];
        // for (let i = 0; i < list.length; i += 1){        
        //     for (let j = 0; j < sessionSelect.length; j += 1){
        //         if(list[i].sessionID === sessionSelect[j])
        //         list2.push(list[i])
        //     }
        // }
        settabledata(dataB);
        // // // console.log(list2)
        // if(list2.length > 0){
        //     setchartTitle(list2[0].readerGroupInfoName);
        //     setChartData(list2);
        // }

    }


    // 統計顯示相關
    const setChartData = (tabledata) => {
        // A : Leon
        // B : notLeon
        // C : total

        // data
        // const dataA = [];
        // const dataB = [];
        // const dataC = [];
        // for(let i = 0;i<24;i+=1){
        //     const hour = `hour${i}`
        //     const hour1 = `hour${i}isLeon`
        //     const hour2 = `hour${i}notLeon`     

        //     dataA.push(tabledata[isLeon]);
        //     dataB.push(tabledata[notLeon]);
        //     dataC.push(tabledata[total]);
        // }
        const data = [];
        const dataA = [];
        const xaxisLable = [];
        for (let i = 0; i < tabledata.length; i += 1) {
            // const total = `total`     
            // const isLeon = `isLeon`   
            // const notLeon = `notLeon`    
            const readerGroupInfoName = 'readerGroupInfoName'
            // tagNam 外部傳入
            // if(tagNam === 'isTruck')
            //     dataA.push({"number":tabledata[i].isLeon,"xaxisLable":tabledata[i][readerGroupInfoName]})
            // else
            dataA.push({ "number": tabledata[i][tagNam], "xaxisLable": tabledata[i][readerGroupInfoName] })
        }
        // 排序 大到小
        const dataB = dataA.sort((a, b) =>
            a.number < b.number ? 1 : -1
        );

        const dataC = []
        for (let i = 0; i < dataB.length; i += 1) {
            if (i > 9)
                break;
            dataC.push(dataB[i].number);
            if (Array.isArray(dataB[i].xaxisLable))
                xaxisLable.push(`${i + 1}.${dataB[i].xaxisLable[0]}${dataB[i].xaxisLable[1]}`);
            else
                xaxisLable.push(`${i + 1}.${dataB[i].xaxisLable}`);
        }

        data.push({ "name": ' ', "data": dataC })
        setchartData(data);
        setChartOption(xaxisLable);
    }
    // 統計顯示相關
    const setChartOption = (xaxisLable) => {
        // const currentdate = new Date(); 
        // const datetime = `${currentdate.getMonth()+1}/${currentdate.getDate()}`
        // const subTitle = `統計日期 ${currentdate.getFullYear()}/${currentdate.getMonth()+1}/${currentdate.getDate()}`
        // setchartSubtitle(subTitle);
        const chartLabels = [];
        for (let j = 0; j < xaxisLable.length; j += 1) {
            chartLabels.push(j + 1);
        }
        // // console.log('chartLabels')

        const chartOption = {
            chart: {
                type: 'bar',
                height: 380,
                toolbar: {
                    show: true,
                    offsetY: -30,
                    export: {
                        csv: {
                            filename: undefined,
                        },
                        png: {
                            filename: undefined,
                        }
                    }
                },
            },
            plotOptions: {
                bar: {
                    barHeight: '100%',
                    distributed: true,
                    horizontal: true,
                    // dataLabels: {
                    //     position: 'bottom'
                    // },
                }
            },
            colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                '#f48024', '#69d2e7'],
            // dataLabels: {
            //     enabled: true,
            //     textAnchor: 'start',
            //     style: {
            //         colors: ['#000'],
            //         fontSize: '16px',
            //     },
            //     formatter: function (val, opt) {
            //         return `${opt.w.globals.labels[opt.dataPointIndex]} : ${val}`
            //     },
            // },
            stroke: {
                width: 2,
                colors: ['#fff']
            },
            xaxis: {
                categories: chartLabels,
            },
            yaxis: {
                labels: {
                    show: true,
                    formatter: function (val, opt) {
                        return `${val}`
                    },
                },
                title: {
                    text: ["排名　", "　"],
                    rotate: 0,
                    offsetX: 10,
                    style: {
                        fontSize: '16px',
                    }
                }
            },
            // title: {
            //     text: 'Custom DataLabels',
            //     align: 'center',
            //     floating: true
            // },
            // subtitle: {
            //     text: 'Category Names as DataLabels inside bars',
            //     align: 'center',
            // },
            tooltip: {
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function () {
                            return ''
                        }
                    }
                }
            },
            legend: {
                show: true,
                customLegendItems: xaxisLable,
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 80,
                    vertical: 0
                },
            }
        };
        // const chartOption = {
        //     stroke: { width: [0, 2, 3] },
        //     plotOptions: { bar: { columnWidth: '40%',barHeight: '100%', borderRadius: 4 } },
        //     fill: { type: ['solid', 'solid' , 'gradient'] },
        //     labels: chartLabels,
        //     xaxis: { type: 'category' ,       
        //         datetimeUTC: false,
        //     },
        //     tooltip: {
        //       shared: true,
        //       intersect: false,
        //       y: {
        //         formatter: (y) => {
        //           if (typeof y !== 'undefined') {
        //             return `${y.toFixed(0)}`;
        //           }
        //           return y;
        //         }
        //       }
        //     }
        // }
        setchartOptions(chartOption);
    }
    const columns = [
        { field: "id", headerName: "id", width: 70, hideable: true },
        {
            field: "readerGroupInfoName", headerName: "路段名稱(起)", flex: 0.6, width: 330, sortable: true, headerAlign: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value === 'string')
                    return (params.value);
                const children = params.value.map((val) => (
                    <div >{val}</div>
                ));
                return (
                    <div>
                        {children}
                    </div>
                );
            }
        },
        // { field: "total", headerName:  "總共車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center', },
        // { field: "isLeon", headerName:  "本計畫車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center',},
        // { field: "notLeon", headerName:  "非本計畫車輛",  width: 100, sortable: true, headerAlign: 'center', align: 'center',},
    ];

    if (tagNam === 'total')
        columns.push({ field: "total", headerName: "總車流數", flex: 0.4, width: 200, sortable: true, headerAlign: 'center', align: 'center', })
    else if (tagNam === 'isTruck')
        columns.push({ field: "isTruck", headerName: "大型車輛之車流數", flex: 0.4, width: 200, sortable: true, headerAlign: 'center', align: 'center', })
    else
        columns.push({ field: "isLeon", headerName: "本計畫車輛之車流數", flex: 0.4, width: 200, sortable: true, headerAlign: 'center', align: 'center', })

    return (

        <div>

            <AAppConversionRates title={chartTitle} subtitle={chartSubtitle} chartOptions={chartOptions} data={chartData} />
            {(() => {
                if (tagNam === 'total') {
                    return (<div style={{ color: '#ff7600', 'font-size': 20 }} >
                        ※大型車輛請於上述路段小心駕駛
                    </div>)
                }
            })()}
            <div style={{ 'margin-top': 60 }} >


                <BDataGrid data={tabledata} columns={columns}
                // TableOnClick={TableOnClick}
                />
            </div>

        </div>

    );
}
