import { Box, Grid, Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
// components
import Page from '../components/Page';
import {
  AAppEvent,
  AppEvent,
} from '../components/_dashboard/app';
import { fetchCmsList, fetchReaderList } from "../actions";
import { getCctvList } from '../services/cctvService';

export default function DashboardApp() {
  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const { users } = usersStore;
  const cmsStore = useSelector((state) => state.cmsManage);
  const { cms } = cmsStore;
  const readerStore = useSelector((state) => state.readerManage);
  const { readers } = readerStore;

  const initequipmentDashboard = {
    cms: 0,
    cmsError: 0,
    reader: 0,
    readerError: 0,
    cctvNormal: 0,
    cctvError: 0,
    cctvTotal: 0
  }
  const [equipmentDashboard, setEquipmentDashboard] = useState(initequipmentDashboard);

  // 統一處理所有設備狀態的 useEffect
  useEffect(() => {
    const updateDashboard = async () => {
      let readerNormal = 0;
      let readerError = 0;
      let cmsNormal = 0;
      let cmsError = 0;
      let cctvNormal = 0;
      let cctvError = 0;

      // 處理 readers 數據
      if (readers.length !== 0) {
        readers.forEach(reader => {
          if (reader.type !== 'fake') {
            if (reader.status) {
              readerNormal += 1;
            } else {
              readerError += 1;
            }
          }
        });
      }

      // 處理 cms 數據
      if (cms.length !== 0) {
        cms.forEach(item => {
          if (item.status) {
            cmsNormal += 1;
          } else {
            cmsError += 1;
          }
        });
      }

      // 處理 CCTV 數據
      try {
        const cctvList = await getCctvList();
        cctvList.forEach(cctv => {
          if (cctv.status === 'online') {
            cctvNormal += 1;
          } else {
            cctvError += 1;
          }
        });
      } catch (error) {
        console.error('Error fetching CCTV status:', error);
      }

      // 更新所有設備狀態
      setEquipmentDashboard({
        reader: readerNormal + readerError,
        readerError,
        cms: cmsNormal + cmsError,
        cmsError,
        cctvNormal,
        cctvError,
        cctvTotal: cctvNormal + cctvError
      });
    };

    updateDashboard();
  }, [readers, cms]);

  // 初始化數據
  useEffect(() => {
    if (users) {
      dispatch(fetchReaderList({ "userName": users.userName, "token": users.token }));
      dispatch(fetchCmsList({ "userName": users.userName, "token": users.token }));
    }
  }, [users, dispatch]);

  return (
    <Page title="儀錶板 | 智慧管理識別平台">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }} />
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={6}>
            <AppEvent
              title="CCTV攝影機異常數量"
              img="/static/icons/img/icon-camera-alert.svg"
              TOTAL={equipmentDashboard.cctvError}
              unit=""
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <AAppEvent
              title="CCTV攝影機總數量"
              img="/static/icons/img/icon-camera.svg"
              TOTAL={equipmentDashboard.cctvTotal}
              unit=""
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <AppEvent
              title="智慧感知讀取器異常數量"
              img="/static/icons/img/icon-rfid-reader-alert.svg"
              TOTAL={equipmentDashboard.readerError}
              unit=""
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <AAppEvent
              title="智慧感知讀取器總數量"
              img="/static/icons/img/icon-rfid-reader.svg"
              TOTAL={equipmentDashboard.reader}
              unit=""
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <AppEvent
              title="資訊可變標誌異常數量"
              img="/static/icons/img/icon-sign-alert.svg"
              TOTAL={equipmentDashboard.cmsError}
              unit=""
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <AAppEvent
              title="資訊可變標誌總數量"
              img="/static/icons/img/icon-sign.svg"
              TOTAL={equipmentDashboard.cms}
              unit=""
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}