import { call, put, takeEvery } from "redux-saga/effects";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../config/types";
import * as ipconfig from "../config/ipconfig";


const apiUrl = `https://${ipconfig.apiIP}/api/v1/driver/list`;

function getApi(parse) {
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },    
    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
};

function* fetchDriverList(action) {
  try {
    // console.log('driver');
    const drivers = yield call(getApi, action.payload.parse);    
    yield put({type: "CHANGE_TOKEN_STATUS", payload: drivers.resultCode});
    if(drivers.resultCode === 'Failed')
      yield put(getDriversError());
    else
      yield put(getDriversSuccess(drivers));
  } catch (error) {
    yield put(getDriversError(error.message));
  }
}

export const getDriversSuccess = (drivers) => {
  // console.log("request drivers success");
  return {
    type: types.GET_DRIVER_LIST_SUCCESS,
    payload: {
      loading: false,
      drivers: drivers
    }
  };
};

export const getDriversError = () => {
  // console.log("request drivers error");
  return {
    type: types.GET_DRIVER_LIST_FAILED,
    error: "FAILED"
  };
};

function* driverSaga() {
  yield takeEvery(types.GET_DRIVER_LIST_REQUEST, fetchDriverList);
}

export default driverSaga;
