
import * as types from "../config/types";

const initialState = {
  loading: false,
  users: [],
  tokenStatus:'Failed',
  parse:null,
  nackbarState:false,  
  userReciverStatus: false,
  error: null,
  resultLog:null
};

export default function userLogin (state = initialState, action)  {
  switch (action.type) {
    case types.GET_USERS_REQUEST: {
      return {
        ...state,
        loading: true,   
        nackbarState:false,
      };
    }
    case types.GET_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userReciverStatus:true,
        users: action.payload.users,
        tokenStatus:'Success'
      };
    }
    case types.GET_USERS_LOGOUT_REQUEST: {
      return {
        ...state,
        loading: true,   
        nackbarState:false,
      };
    }
    case types.GET_USERS_LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: []
      };
    }
    case types.GET_USERS_NOT_FOUND: {
      return {
        ...state, 
        loading: false,
        nackbarState:true,
        resultLog: action.payload.resultLog,
      };
    }
    case types.GET_USERS_FAILED: {
      return {
        ...state,
        loading: false,
        error: "FAILED",        
        nackbarState:true,
      };
    }
    case 'CHANGE_TOKEN_STATUS': {
      return {
        ...state,
        tokenStatus:action.payload
      };
    }
    default:
      return state;
  }
};

