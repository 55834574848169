import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import carFilled from '@iconify/icons-ant-design/car-filled';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// material
import {
    Grid, Button, Container, Stack, Typography,
    Tabs, Tab,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AAppWebsiteVisits from '../components/AAppWebsiteVisits';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import ToggleButtons from '../components/ToggleButton'
import ReaderCountDataTable from './ReaderCountDataTable'
import TTable from '../components/TTable'

import {
    AAppEvent,
    AppEvent,
} from '../components/_dashboard/app';

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";

// 定義切換頁
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        'style': { 'font-size': '24px' },
    };
}
export default function TestMap() {
    const [leonReaderlist, setleonReaderlist] = useState([]);
    const [truckLimitData, settruckLimitData] = useState([]);
    const [speedExceedData, setspeedExceedData] = useState([]);
    const [popupTableData, setpopupTableData] = useState([]);
    const [Vehiclelist, setVehiclelist] = React.useState([]);

    const [mapcenter, setmapcenter] = useState([24, 121]);
    const [openmap, setopenmap] = useState(null);
    const [ToggleButtonselect, setToggleButtonselect] = useState(60);
    const ToggleButtonsOptions = [
        { value: 5, Name: '5分鐘' },
        { value: 15, Name: '15分鐘' },
        { value: 30, Name: '30分鐘' },
        { value: 60, Name: '60分鐘' },
    ]
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;
    const dispatch = useDispatch();

    useEffect(() => {
        getData(ToggleButtonselect);
        // setVehiclelist()
    }, [users, ToggleButtonselect]);


    const [parkingIn, setparkingIn] = useState([]);
    const [parkingInTotal, setparkingInTotal] = useState(0);
    const [parkingOut, setparkingOut] = useState([]);
    const [parkingOutTotal, setparkingOutTotal] = useState(0);
    // app.post('/api/v1/parking/getParking', parkingRoute.getParking);
    // app.post('/api/v1/parking/getParkingAnalyze', parkingRoute.getParkingAnalyze);


    // 取德標格資料
    const getData = async (minutes) => {
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/parking/getParking`, { "token": users.token, "minutes": minutes })
            .then(async (response) => {
                dispatch({ type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode });
                // eslint-disable-next-line eqeqeq
                // console.log(response.data);
                if (response.data.resultCode === "Success") {
                    const list = [];
                    let index = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;

                        if (data.type === 'parking-in') {
                            obj.rownum = index
                            obj.id = index
                            list.push(obj)
                            index += 1;
                        }
                    }

                    setparkingIn(list);
                    setparkingInTotal(index - 1);

                    const outlist = [];
                    let outindex = 1;
                    // console.log(response.data.rows);
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        let obj = {};
                        const data = response.data.rows[i];
                        obj = data;

                        if (data.type === 'parking-out') {
                            obj.rownum = outindex
                            obj.id = outindex
                            outlist.push(obj)
                            outindex += 1;
                        }
                    }
                    setparkingOut(outlist);
                    setparkingOutTotal(outindex - 1);
                    // settruckLimitData(list)   
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
            });
        // setOpen(true);
    };

    const headCells = [
        {
            id: 'rownum',
            numeric: false,
            disablePadding: true,
            fillter: false,
            label: '項目'
        },
        {
            id: 'leonReaderName',
            numeric: true,
            disablePadding: true,
            fillter: true,
            label: '智慧感知讀取器名稱'
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            fillter: true,
            label: '設備編號'
        },
        {
            id: 'licensePlateNumber',
            numeric: false,
            disablePadding: true,
            fillter: true,
            label: '車牌'
        },
        {
            id: 'receiveTime',
            numeric: false,
            disablePadding: true,
            fillter: false,
            label: '時間',
            type: 'format',
            fn: (data) => {
                const time = new Date(data).toLocaleString()
                return (
                    time
                )
            }
        },
    ];
    // const searchOnClick = () => {
    //     getData(ToggleButtonselect);
    // }

    const [tabValue, settabValue] = React.useState(0);

    // tab切換
    const handleChange = (event, newValue) => {
        settabValue(newValue);
    };

    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        <Stack direction="row" spacing={2}>
                            {/* <MultipleSelectCheckmarks Option={roadArealist} select={roadAreaselect} setselect={setroadAreaselect} /> */}
                            <div style={{ 'marginTop': '8px' }} >
                                <ToggleButtons Options={ToggleButtonsOptions} setSelect={setToggleButtonselect} Select={ToggleButtonselect} />
                            </div>
                        </Stack>

                    </Typography>
                    {/* <Button variant="contained" size="large" onClick={searchOnClick} >查詢</Button> */}
                </Stack>

                <Grid container spacing={3}>
                    <Grid item xs={6} >
                        <AAppEvent title="停車場-入場數量" img="/static/icons/img/icon-car-in.svg" TOTAL={parkingInTotal} unit="輛" />
                    </Grid>
                    <Grid item xs={6} >
                        <AppEvent title="停車場-出場數量" img="/static/icons/img/icon-car-out.svg" TOTAL={parkingOutTotal} unit="輛" />
                    </Grid>
                </Grid>

                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" style={{ 'marginTop': '10px' }}>
                    <Tab label="停車場-入場明細" {...a11yProps(0)} />
                    <Tab label="停車場-出場明細" {...a11yProps(1)} />
                </Tabs>


                <TabPanel value={tabValue} index={0}>
                    <Grid item xs={12} style={{ height: "100%" }}
                        sx={{
                            '& th': {
                                fontSize: '22px'
                            },
                            '& td': {
                                fontSize: '20px'
                            }
                        }}>
                        <TTable Vehiclelist={parkingIn} headCells={headCells} />
                    </Grid>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <Grid item xs={12} style={{ height: "100%" }}
                        sx={{
                            '& th': {
                                fontSize: '22px'
                            },
                            '& td': {
                                fontSize: '20px'
                            }
                        }}>
                        <TTable Vehiclelist={parkingOut} headCells={headCells} />
                    </Grid>
                </TabPanel>

                {/* <Grid container spacing={3} >
                    
                    
                    <Grid item xs={12} style={{ height: "100%" }}>
                        
                        <TTable Vehiclelist={parkingOut} headCells={headCells} />
                    </Grid>
                    

                </Grid> */}
            </Container>
        </Page>
    );
}
