import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { visuallyHidden } from '@mui/utils';
import { filter } from 'lodash';
import {
    Button,
    Grid,
    TextField,
    Tabs,
    Tab,
    Typography,
    Stack,
    Card,
    Table,
    Box,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    TablePagination,
    TableSortLabel,
    Snackbar, List, ListItem, ListItemAvatar, ListItemText, DialogTitle, Dialog
} from '@mui/material';
import { CarListToolbar } from './_dashboard/user';
import Label from './Label';
import SearchNotFound from './SearchNotFound';
import { SimpleDialog } from './dialog';


function createData(name, calories, fat, carbs, protein) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (orderBy === 'date' || orderBy === 'updateTime' || orderBy === 'time' || orderBy === 'createTime') {
        const bb = new Date(b[orderBy]).getTime()
        const aa = new Date(a[orderBy]).getTime()
        if (bb < aa) {
            return -1;
        }
        if (bb > aa) {
            return 1;
        }
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export default function EnhancedTable(props) {
    const { Vehiclelist, headCells } = props
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [filterName, setFilterName] = React.useState('');



    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };
        const active = true;
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => {
                        if (!headCell.label) {
                            return (
                                <TableCell />
                            )
                        }
                        return (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'center' : 'center'}
                                padding={0 ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={active}
                                    // active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    })
                    }
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;



    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        // eslint-disable-next-line eqeqeq
        if (query != '') {
            const temp = [];
            for (let i = 0; i < headCells.length; i += 1) {
                if (headCells[i].fillter === true) {
                    temp.push(headCells[i].id)
                }
            }
            return filter(
                array,
                (carobj) => {
                    let status;
                    for (let i = 0; i < temp.length; i += 1) {
                        if (carobj[temp[i]] !== null) {
                            if (i === 0)
                                status = carobj[temp[i]].toString().toLowerCase().indexOf(query.toLowerCase()) !== -1;
                            else
                                status ||= carobj[temp[i]].toString().toLowerCase().indexOf(query.toLowerCase()) !== -1;
                        }
                    }
                    return status
                }


                // carobj.licensePlateNumber.toLowerCase().indexOf(query.toLowerCase()) !== -1 || carobj.leonTagProductNumber.toLowerCase().indexOf(query.toLowerCase()) !== -1
            );
        }
        return stabilizedThis.map((el) => el[0]);
    }


    const filteredUsers = applySortFilter(Vehiclelist, getComparator(order, orderBy), filterName);
    const isUserNotFound = filteredUsers.length === 0;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
                <Card sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <CarListToolbar
                            placeholder="關鍵字搜尋"
                            numSelected={selected.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                        />
                        <TableContainer component={Paper}>
                            <Table
                                sx={{
                                    minWidth: 750,
                                    fontSize: '1rem', // 16px
                                    '& .MuiTableCell-root': {
                                        fontSize: '1rem',
                                        padding: '8px'  // 保持原有的 padding
                                    }
                                }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={Vehiclelist.length}
                                />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    align="center"
                                                    onClick={(event) => handleClick(event, row.leonTagProductNumber)}
                                                    tabIndex={-1}
                                                    key={row.leonTagProductNumber}
                                                >
                                                    {headCells.map((headCell) => {
                                                        function renderCdll(row, cell) {
                                                            // // console.dir([row,cell])
                                                            if (cell.type === 'format') {
                                                                return (cell.fn(row[cell.id]))
                                                            }
                                                            if (cell.type === 'render') {
                                                                return (cell.fn(row))
                                                            }
                                                            return (
                                                                row[cell.id]
                                                            )
                                                        }
                                                        return (
                                                            (() => {
                                                                if (headCell.numeric) {
                                                                    return (
                                                                        <TableCell align="left" width={headCell.width} >
                                                                            {renderCdll(row, headCell)}
                                                                        </TableCell>
                                                                    )
                                                                }
                                                                return (
                                                                    <TableCell align="center" >
                                                                        {renderCdll(row, headCell)}
                                                                    </TableCell>
                                                                )
                                                            })()
                                                        )
                                                    }
                                                    )}

                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            component="div"
                            count={Vehiclelist.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />

                    </Paper>
                </Card>
            </Grid>
        </Grid>
    );
}
