import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

import SelectMenuItem from '../SelectMenuItem'
import TrafficAnalyzeChart from './TrafficAnalyzeChart copy'

import TrafficAnalyzeChart2 from './TrafficAnalyzeChart2'
import * as config from '../../config/config'


export default function TrafficAnalyzeOfConversionRates(props) {
    const { sessionlist, sessionselect, setsessionselect } = props
    const { unitAmountSelect, setunitAmountSelect, unitAmountList } = props
    const { readerSectionlist } = props
    const { dayDate, setdayDate } = props
    const tagNam = 'isTruck';
    const tagNam1 = 'total';
    const chartTitle5 = '晨峰時段07:00-09:00平均車流統計(單位:輛/月)';
    const chartTitle6 = '昏峰時17:00-19:00平均車流統計(單位:輛/月)';

    const sqlText5 = 'month ORDER BY month';
    const sqlText6 = 'month ORDER BY month';
    // const [dayDate, setdayDate] = React.useState('2022/5/19');
    const [dayDateStart, setdayDateStart] = React.useState('');
    const [dayDateEnd, setdayDateEnd] = React.useState('');
    const [optionSelect, setoptionSelect] = React.useState(0);
    const [optionReaderSelect, setoptionReaderSelect] = React.useState(0);

    const dispatch = useDispatch();
    const progressStore = useSelector((state) => state.progressStatus);
    const { loading } = progressStore;
    const [first, setfirst] = React.useState(true);

    const [year, setyear] = React.useState('');
    const [weeklist, setweeklist] = useState([]);
    const [weekSelect, setweekSelect] = useState(0);

    useEffect(() => {
        setyear(moment().format('YYYY'));
    }, []);

    useEffect(() => {
        const y1 = year
        const list = []
        list.push({ "id": 0, "description": "請選擇第幾季", "date1": '', "date2": '' })
        if (y1 !== '') {
            const y = moment(year).format('YYYY');
            const weeknumber = moment(`${y}/12/31`, "YYYY/MM/DD").quarter();
            const nowweeknumber = moment(new Date(), "YYYY/MM/DD").quarter();
            // console.log(nowweeknumber)
            for (let i = 0; i < 4; i += 1) {
                let d1 = moment(y).add(i, 'quarters').startOf('quarter').format('YYYY/MM/DD');
                let d2 = moment(y).add(i, 'quarters').endOf('quarter').format('YYYY/MM/DD');
                if (i === 0)
                    d1 = `${y}/01/01`
                else if (i === weeknumber - 1)
                    d2 = `${y}/12/31`

                const utc01 = new Date(`${moment(d1).format('YYYY/MM/DD')} 00:00:00`).toISOString().slice(0, 19).replace('T', ' ')
                const utc02 = new Date(`${moment(d2).format('YYYY/MM/DD')} 23:59:59`).toISOString().slice(0, 19).replace('T', ' ')

                list.push({
                    "id": i + 1,
                    "description": `第${i + 1}季(${d1} ~ ${d2})`,
                    "date1": utc01,
                    "date2": utc02
                })
            }
            setweeklist(list);
            setweekSelect(nowweeknumber)
        }

    }, [year,]);

    useEffect(() => {
        if (first && weekSelect !== 0) {
            const utc01 = weeklist[weekSelect].date1
            const utc02 = weeklist[weekSelect].date2
            // console.log(new Date(`${moment(dayDate).format('YYYY/MM/DD')} 00:00:00`))
            // console.log(utc01)
            // console.log(utc02)
            setdayDateStart(utc01)
            setdayDateEnd(utc02)
            setoptionSelect(sessionselect)
            dispatch({ type: "START" });
            setfirst(false)
        }
    }, [weekSelect]);

    const searchOnClick = () => {

        if (weekSelect !== 0) {
            const utc01 = weeklist[weekSelect].date1
            const utc02 = weeklist[weekSelect].date2
            // console.log(new Date(`${moment(dayDate).format('YYYY/MM/DD')} 00:00:00`))
            // console.log(utc02)             
            // console.log(utc01)
            // console.log(utc02)
            setdayDateStart(utc01)
            setdayDateEnd(utc02)
            setoptionSelect(sessionselect)
            setoptionReaderSelect(readerSectionlistselect)
            if (utc01 !== dayDateStart)
                dispatch({ type: "START" });
        }
    }

    const [readerlist, setreaderlist] = useState([]);
    const [readerSectionlistselect, setreaderSectionlistselect] = useState(0);

    useEffect(() => {
        if (readerSectionlist.length > 0 && sessionlist.length > 0) {
            const list = [];
            let index = 1;
            const data = readerSectionlist;
            const rGroupInfoNameMap = new Map();
            list.push({ "id": 0, "description": '全部', "description1": '全部' })
            for (let i = 0; i < data.length; i += 1) {
                if (sessionlist[sessionselect].description === data[i].reader_group_info_name) {
                    list.push({
                        "id": index,
                        "description": `${data[i].reader_info_name} - ${data[i].reader_info_leonReaderName}`,
                        "description1": data[i].reader_info_leonReaderName
                    })
                    index += 1
                }
            }
            setreaderlist(list);
        }
    }, [readerSectionlist, sessionlist, sessionselect]);

    return (
        <Grid>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} >
                <Typography variant="h4" gutterBottom  >
                    <Stack direction="row" spacing={2}
                        sx={{
                            '& label ': {
                                fontSize: config.selectLabelFontSize,
                                top: config.selectLabelTop
                            },
                            '& input': {
                                fontSize: config.selectItemFontSize,
                            },
                        }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}  >
                            <DatePicker
                                label="選擇年份"
                                views={['year']}
                                value={year}
                                onChange={(newValue) => {
                                    setyear(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <SelectMenuItem Option={weeklist} select={weekSelect} setselect={setweekSelect} labelName="選擇第幾季" />

                        <Stack>
                            {/* <SelectMenuItem Option={unitAmountList} select={unitAmountSelect} setselect={setunitAmountSelect} labelName="選擇計數單位" hidden="1"  /> */}
                        </Stack>
                    </Stack>
                </Typography>
                <Button variant="contained" size="large" item xs={2}
                    disabled={loading}
                    onClick={searchOnClick} >查詢
                </Button>
                {loading && (
                    <CircularProgress
                        size={150}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            "z-index": 5000
                        }}
                    />
                )}
            </Stack>
            <Stack direction="row" spacing={2} mb={3}  >
                <Stack>
                    <SelectMenuItem Option={sessionlist} select={sessionselect} setselect={setsessionselect} labelName="選擇路段" width={400} />
                </Stack>
                <Stack>
                    <SelectMenuItem Option={readerlist} select={readerSectionlistselect} setselect={setreaderSectionlistselect} labelName="選擇智慧感知讀取器" width={450} />
                </Stack>
            </Stack>

            <Grid item xs={12} mb={2} >
                <TrafficAnalyzeChart
                    chartTitle="車流統計(單位:輛/月)" tagNam={tagNam} sessionlist={sessionlist} select={optionSelect}
                    readerlist={readerlist} readerselect={optionReaderSelect}
                    startDate={dayDateStart} endDate={dayDateEnd} chartUnit="month" chartUnit1="quarter"
                    unitAmountValue={unitAmountList[unitAmountSelect].value}
                    hour1={0} hour2={23}
                />
            </Grid>

            <Grid item xs={12} mb={2}  >
                <TrafficAnalyzeChart2
                    chartTitle={chartTitle5} tagNam={tagNam} sessionlist={sessionlist} select={optionSelect}
                    readerlist={readerlist} readerselect={optionReaderSelect}
                    startDate={dayDateStart} endDate={dayDateEnd} sqlText={sqlText5} chartUnit="month" chartUnit1="quarter" time="morning"
                    unitAmountValue={unitAmountList[unitAmountSelect].value}
                    hour1={7} hour2={8}
                />
            </Grid>
            <Grid item xs={12} mb={2}  >
                <TrafficAnalyzeChart2
                    chartTitle={chartTitle6} tagNam={tagNam} sessionlist={sessionlist} select={optionSelect}
                    readerlist={readerlist} readerselect={optionReaderSelect}
                    startDate={dayDateStart} endDate={dayDateEnd} sqlText={sqlText6} chartUnit="month" chartUnit1="quarter" time="night"
                    unitAmountValue={unitAmountList[unitAmountSelect].value}
                    hour1={17} hour2={18}
                />
            </Grid>
        </Grid>

    );
}
