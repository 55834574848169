import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid, Box, TextField } from '@mui/material';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { zhTW } from 'date-fns/locale';
import { getDailyEventAggregation } from '../services/cctvService';
const colorMap = {
    '善化區市道178線(興農路)': '#CE93D8',    // 更深的粉紫色
    '區道南128-1線路口': '#FFAB91',          // 更深的粉橙色
    '善化區三民路': '#A5D6A7',               // 更深的粉綠色
    '善化區文昌路': '#EF9A9A',               // 更深的粉紅色
    '重劃區農路路口': '#90CAF9',             //  更深的粉藍色   
    '善化區中正路66巷': '#FFB300',           // 更深的琥珀黃色
};
const DailyVehicleDetectionChart = ({ cctvData, date, handleDateChange }) => {
    const [chartData, setChartData] = useState([]);
    const [visibleCameras, setVisibleCameras] = useState({});
    const [cameraNameMap, setCameraNameMap] = useState({});
    const [cameraObjectTypeMap, setCameraObjectTypeMap] = useState({});
    const [cameras, setCameras] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getDailyEventAggregation(date);
                const formattedData = response.vehicleDetectionStats.map(stat => ({
                    time: stat.timeTag,
                    ...stat.cameras
                }));
                setChartData(formattedData);
                const initialVisibility = {};
                const nameMap = {};
                const objectTypeMap = {};

                cctvData.forEach(cctv => {
                    initialVisibility[cctv.code] = true;
                    nameMap[cctv.code] = cctv.name;
                    objectTypeMap[cctv.code] = cctv.object_type; // 添加 object_type 到映射中
                });
                const codes = cctvData.map(cctv => cctv.code);
                setCameras(codes);
                setVisibleCameras(initialVisibility);
                setCameraNameMap(nameMap);
                setCameraObjectTypeMap(objectTypeMap); // 設置 object_type 映射
            } catch (error) {
                console.error('獲取每日車輛偵測數據時發生錯誤:', error);
            }
        };

        fetchData();
    }, [date, cctvData]);




    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc', position: 'relative', top: '-10px', left: '10px' }}>
                    <p>{`時間: ${label}`}</p>
                    {payload.map((entry, index) => (
                        <p key={index} style={{ color: entry.color }}>
                            {`${cameraNameMap[entry.dataKey] || entry.name}: ${entry.value}(${cameraObjectTypeMap[entry.dataKey]})`}
                        </p>
                    ))}
                    <p style={{ fontWeight: 'bold' }}>
                        {`總數: ${payload.reduce((sum, entry) => sum + entry.value, 0)}`}
                    </p>
                </div>
            );
        }
        return null;
    };

    const CustomLegend = ({ payload }) => {
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 8 }}>
                {payload.map((entry, index) => (
                    <Box
                        key={`item-${index}`}
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            m: 0.5,
                            cursor: 'pointer',
                            position: 'relative',
                        }}
                        onClick={() => toggleCamera(entry.dataKey)}
                    >
                        <Box sx={{
                            width: 10,
                            height: 10,
                            backgroundColor: entry.color,
                            mr: 0.5
                        }} />
                        <Typography
                            variant="body2"
                            sx={{
                                position: 'relative',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    top: '50%',
                                    borderTop: visibleCameras[entry.dataKey] ? 'none' : '1px solid black',
                                }
                            }}
                        >
                            {cameraNameMap[entry.dataKey] || entry.value}
                        </Typography>
                    </Box>
                ))}
            </Box>
        );
    };

    const toggleCamera = (camera) => {
        setVisibleCameras(prev => ({
            ...prev,
            [camera]: !prev[camera]
        }));
    };
    return (
        <Paper sx={{ p: 2, height: '100%' }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end' // 靠右對齊
            }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
                    <DatePicker
                        label="選擇日期"
                        value={date}
                        onChange={handleDateChange}
                        renderInput={(params) =>
                            <TextField {...params} variant="standard" />
                        }
                    />
                </LocalizationProvider>
            </Box>
            <ResponsiveContainer width="100%" height={450}>
                <BarChart barSize={30}  // 增加長條寬度
                    barGap={2}    // 調整長條間距
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 40, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" angle={-45} textAnchor="end" interval={0} dy={10} >
                        <Label value="時間(小時)" position="insideBottom" offset={-60} />
                    </XAxis>
                    <YAxis>
                        <Label value="數量(次)" angle={-90} position="insideLeft" dx={-10} /> {/* 調整 dx 屬性 */}
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={<CustomLegend />} />
                    {cameras.map((camera, index) => (
                        <Bar
                            key={camera}
                            dataKey={camera}
                            stackId="a"
                            fill={colorMap[cameraNameMap[camera]] || '#e6e6fa'} // 根據cameraNameMap設置顏色
                            name={cameraNameMap[camera] || camera}
                            hide={!visibleCameras[camera]}
                            fillOpacity={1}  // 添加這行，設定不透明度為 1
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </Paper>
    );
};

export default DailyVehicleDetectionChart;