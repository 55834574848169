import create from 'zustand';

// 定義一個函數來從 localStorage 加載狀態
const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('systemInfoState');
        if (serializedState === null) {
            return {
                platform: '智慧管理識別平台',
                useDashboardSidebar: true,
            };
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return {
            platform: '智慧管理識別平台',
            useDashboardSidebar: true,
        };
    }
};

// 定義一個函數來保存狀態到 localStorage
const saveStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('systemInfoState', serializedState);
    } catch (err) {
        // 忽略寫入錯誤
    }
};

const SystemInfoStore = create((set) => ({
    ...loadStateFromLocalStorage(),
    togglePlatform: () => set((state) => {
        const newPlatform = state.platform === '智慧管理識別平台' ? '智慧管理識別平台' : '智慧管理識別平台';
        const newState = {
            platform: newPlatform,
            useDashboardSidebar: newPlatform === '智慧管理識別平台',
        };
        saveStateToLocalStorage(newState);
        return newState;
    }),
}));

export default SystemInfoStore;