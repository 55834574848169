import { useState, useEffect } from 'react';
import { Outlet, useLocation, useHistory, useNavigate } from 'react-router-dom';
import { Fab } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useDispatch } from "react-redux";



// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import SystemInfoStore from '../../Store/useSystemInfoStore'; // 修正導入
import { userLogout } from "../../actions";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme, maxWidth }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: 0,
  [theme.breakpoints.up(maxWidth)]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const dispatch = useDispatch();
  const { useDashboardSidebar } = SystemInfoStore();
  const [open, setOpen] = useState(false);
  const [widthStyle, setWidthStyle] = useState('lg');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.includes('/Dashboard/Dashboard123')) {
      setWidthStyle('xxl');
    }
  }, [pathname]);

  const handleHomeClick = () => {
    navigate('/Dashboard/TrafficOverview');
  };

  const handleLogoutClick = () => {
    dispatch(userLogout({}));
    window.location.href = '/login';
  };

  return (
    <RootStyle>
      {useDashboardSidebar && (
        <>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        </>
      )}
      <MainStyle maxWidth={widthStyle} id="main">
        <Outlet />
        {!useDashboardSidebar && (
          <>
            <Fab color="secondary" aria-label="home" style={{ position: 'fixed', bottom: 86, right: 16 }} onClick={handleHomeClick}>
              <HomeIcon />
            </Fab>
            <Fab color="default" aria-label="logout" style={{ position: 'fixed', bottom: 156, right: 16 }} onClick={handleLogoutClick}>
              <ExitToAppIcon />
            </Fab>
          </>
        )}
      </MainStyle>
    </RootStyle>
  );
}