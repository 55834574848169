
import * as types from "../config/types";

const initialState = {
  loading: false,
  readers: [],
  error: null
};

export default function readerManage (state = initialState, action)  {
  switch (action.type) {
    case types.GET_READER_LIST_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case types.GET_READER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        readers: action.payload.readers.rows
      };
    }
    case types.GET_READER_LIST_FAILED: {
      return {
        ...state,
        loading: false,
        error: "FAILED",    
      };
    }
    default:
      return state;
  }
};

