import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';
const colorMap = {
    '善化區市道178線(興農路)': '#CE93D8',    // 更深的粉紫色
    '區道南128-1線路口': '#FFAB91',          // 更深的粉橙色
    '善化區三民路': '#A5D6A7',               // 更深的粉綠色
    '善化區文昌路': '#EF9A9A',               // 更深的粉紅色
    '重劃區農路路口': '#90CAF9',             //  更深的粉藍色   
    '善化區中正路66巷': '#FFB300',           // 更深的琥珀黃色
};

const AnomalyCard = ({ location, count, detectionType }) => (
    <Paper elevation={3} sx={{
        p: 2,
        textAlign: 'center',
        bgcolor: colorMap[location] || '#e6e6fa', // 根據location設置顏色
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100px', // 確保最小高度
    }}>
        <Typography variant="h4" sx={{ mb: 1 }}>{count}</Typography>
        <Typography variant="body2" sx={{ fontSize: '0.8rem', lineHeight: 1.5, mb: 0.1 }}>{location}</Typography>
        <Typography variant="body2" sx={{ fontSize: '0.8rem', lineHeight: 1.5 }}>{detectionType}</Typography>
    </Paper>
);

const AnomalyDetection = ({ cctvData }) => (
    <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>30日資訊可變號誌提醒駕駛次數(攝影機偵測次數)</Typography>
        <Grid container spacing={2} sx={{ justifyContent: 'flex-start' }}>
            {cctvData.map((cctv, index) => (
                <Grid item xs={2} key={index}>
                    <AnomalyCard detectionType={cctv.object_type} location={cctv.name} count={cctv.event_count || 0} />
                </Grid>
            ))}
        </Grid>
    </Paper>
);

export default AnomalyDetection;