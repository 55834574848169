// src/components/SpeedLegend.js
import React, { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const SpeedLegend = ({ speedColors }) => {
    const legendRef = useRef();
    const map = useMap();

    useEffect(() => {
        // 檢查 speedColors 是否存在
        if (!speedColors?.ranges) {
            const defaultData = [
                { color: '#f24a40', text: '時速 14 公里以下' },
                { color: '#fd7000', text: '時速 15~34 公里' },
                { color: '#43c548', text: '時速 35 公里以上' },
                { color: '#666666', text: '更新中' }
            ];
            createLegend(defaultData);
        } else {
            const speedData = [
                {
                    color: speedColors.ranges[0].color,
                    text: `時速 ${speedColors.ranges[0].max} 公里以下`
                },
                {
                    color: speedColors.ranges[1].color,
                    text: `時速 ${speedColors.ranges[1].min}~${speedColors.ranges[1].max} 公里`
                },
                {
                    color: speedColors.ranges[2].color,
                    text: `時速 ${speedColors.ranges[2].min} 公里以上`
                },
                {
                    color: '#666666',
                    text: '更新中'
                }
            ];
            createLegend(speedData);
        }

        function createLegend(data) {
            const legend = L.control({ position: 'topright' });
            legend.onAdd = () => {
                const div = L.DomUtil.create('div', 'info legend');
                div.innerHTML = `
                    <div style="background-color: white; padding: 10px; border: 2px solid rgba(0,0,0,0.8); border-radius: 4px;">
                        <table style="border-collapse: collapse; width: 100%; border: 1px solid #000;">
                            ${data.map(item => `
                                <tr>
                                    <td style="width: 30px; height: 30px; background:${item.color}; border: 1px solid #000;"></td>
                                    <td style="padding: 5px; border: 1px solid #000;">${item.text}</td>
                                </tr>
                            `).join('')}
                        </table>
                    </div>
                `;
                return div;
            };

            legend.addTo(map);
            legendRef.current = legend;
        }

        return () => {
            if (legendRef.current) {
                legendRef.current.remove();
            }
        };
    }, [map, speedColors]);

    return null;
};

export default SpeedLegend;