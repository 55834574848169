import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from 'moment';
import MomentUtils from "@date-io/moment"
import PropTypes from 'prop-types';


// material
import { Grid, Button, Container, Stack, Typography ,
    Tabs, Tab, } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DateRangePicker from '@mui/lab/DateRangePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';



import {
  AppTasks,
  AppNewUsers,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';
// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import SelectMenuItem from '../components/SelectMenuItem'
import ToggleButtons from '../components/ToggleButton'
import DayChart from '../components/travelSpeedAnalyze2/DayChart'
import SpeedLimit from '../components/travelSpeedAnalyze2/SpeedLimit'
import MorningRushHour from '../components/travelSpeedAnalyze2/MorningRushHour'
import NightRushHour from '../components/travelSpeedAnalyze2/NightRushHour'

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";

// 定義切換頁
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        'style': { 'font-size': '24px' },
    };
}

export default function TestMap() {
    const [trafficFlowHourData, settrafficFlowHourData] = useState([]);
    const [sessionTrafficFlow, setsessionTrafficFlow] = useState([]);
    const [sessionDataOfTrafficFlow, setsessionDataOfTrafficFlow] = useState([]);
    const [readerGroupInfoNameMap, setreaderGroupInfoNameMap] = useState();    
    // const [roadArealist, setroadArealist] = useState([]);
    // const [roadAreaselect, setroadAreaselect] = useState([]);
    // 1.isLeon 
    // 2.notLeon 
    // 3.isTruck 
    // 4.total 
    const [tagNam, settagNam] = useState('isTruck');
    const [tagNam1, settagNam1] = useState('total');
    const [chartTitle1, setchartTitle1] = useState('大型車輛車流計數前十排名');
    const [chartTitle2, setchartTitle2] = useState('總車流計數前十排名');

    const [sessionTurningTrafficList, setsessionTurningTrafficList] = useState([]);
    const [sessionlist, setsessionlist] = useState([]);
    const [sessionselect, setsessionselect] = useState([]);
    
    
    const [value, setValue] = React.useState('2022/4/1');
    const [value1, setValue1] = React.useState('2022/5/25');

    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;
    
    const [dayDate, setdayDate] = React.useState('');
    useEffect(() => {
        const date = moment(new Date()).format('YYYY/MM/DD')        
        setdayDate(date)
    }, []);
    
    useEffect(() => {
        getrouteData();
    }, [users]);
    
    // 取得路段資料
    const getrouteData = async () => {
        await axios
            .get(`https://${ipconfig.apiIP}/api/v1/trafficFlow/getTrafficFlowSafeInfo`)
            .then(async (response) => {
                // // console.dir(response.data.rows.length);
                // eslint-disable-next-line eqeqeq
                if (response.data.resultCode == "Success") {
                    const list = [];
                    const list1 = [];
                    for (let i = 0; i < response.data.rows.length; i += 1){         
                            list.push({"id":response.data.rows[i].id,"description":response.data.rows[i].pathName})       
                            // const sessionID = response.data.rows[i].id;
                            // const readerGroupInfoName = response.data.rows[i].reader_group_info_name.toString();
                            // list1.push({"id":sessionID,"description":readerGroupInfoName})    
                            
                    }
                    // setsessionTurningTrafficList(list1);
                    setsessionselect(list[0].id)
                    setsessionlist(list)
                    // // console.dir(obj)

                    // setsessionlist(response.data.rows)
                    // // console.dir(response.data.rows)

                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                // // console.dir('leonReaderlist api then')
            });
        // setOpen(true);
    };


    


    // tab切換
    const handleChange = (event, newValue) => {
        settabValue(newValue);
    };

    
    const [tabValue, settabValue] = React.useState(0);

    const [loading, setLoading] = React.useState(false);
    
    const locale = "zh-tw"
    return (
        <Page title="智慧管理" >
            <Container maxWidth="xl">                 
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="日統計" {...a11yProps(0)} />
                    <Tab label="晨峰時段統計" {...a11yProps(1)} />
                    <Tab label="昏峰時段統計" {...a11yProps(2)} />
                    <Tab label="危險駕駛統計" {...a11yProps(2)} />
                </Tabs>
                
                <TabPanel value={tabValue} index={0}>
                      <DayChart                              
                        dayDate={dayDate} setdayDate={setdayDate}                          
                        sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                      />               
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                      <MorningRushHour                   
                        dayDate={dayDate} setdayDate={setdayDate}                                     
                        sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                      />               
                </TabPanel>
                
                <TabPanel value={tabValue} index={2}>
                      <NightRushHour                 
                        dayDate={dayDate} setdayDate={setdayDate}                                       
                        sessionlist={sessionlist} sessionselect={sessionselect} setsessionselect={setsessionselect}
                      />               
                </TabPanel>
                
                <TabPanel value={tabValue} index={3}>
                      <SpeedLimit                              
                        dayDate={dayDate} setdayDate={setdayDate}                          
                        />               
                </TabPanel>
                
            </Container>
        </Page>
    );
}
