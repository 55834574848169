import { all } from "redux-saga/effects";
import userSagas from "./userSagas";
import teamSaga from "./teamSagas";
import truckSaga from "./truckManageSagas";
import driverSaga from "./driverManageSagas";
import readerSaga from "./readerManageSagas";
import leonTagSaga from "./leonTagManageSagas";
import cmsSaga from "./cmsManageSagas";
import overviewSaga from "./overviewManageSagas";
import userChangePasswordSaga from "./userChangePasswordSagas";
import userOperationRecordSaga from "./userOperationRecordSaga";
import detailRecordSaga from "./detailRecordSaga";
import alertMessageSaga from "./alertMessageSaga";
import userManageSaga from "./userManageSagas";
import specialmarkInfoSaga from "./specialMarkInfoSagas";


export default function* rootSaga() {
  yield all([
    userSagas(),
    teamSaga(),
    truckSaga(),
    driverSaga(),
    readerSaga(),
    cmsSaga(),
    leonTagSaga(),
    overviewSaga(),
    userChangePasswordSaga(),
    userOperationRecordSaga(),
    detailRecordSaga(),
    alertMessageSaga(),
    userManageSaga(),
    specialmarkInfoSaga(),
  ]);
}
