import { call, put, takeEvery } from "redux-saga/effects";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../config/types";
import * as ipconfig from "../config/ipconfig";


const apiUrl = `https://${ipconfig.apiIP}/api/v1/overview/list`;

function getApi(parse) {
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },    
    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
};

function* fetchOverviewList(action) {
  try {
    // // console.log('overview');
    const overviews = yield call(getApi, action.payload.parse);    
    yield put({type: "CHANGE_TOKEN_STATUS", payload: overviews.resultCode});
    if(overviews.resultCode === 'Failed')
      yield put(getTrucksError());
    else
      yield put(getTrucksSuccess(overviews));
  } catch (error) {
    yield put(getTrucksError(error.message));
  }
}

export const getTrucksSuccess = (overviews) => {
  // console.log("request overviews success");
  return {
    type: types.GET_OVERVIEW_LIST_SUCCESS,
    payload: {
      loading: false,
      overviews: overviews
    }
  };
};

export const getTrucksError = () => {
  // console.log("request overviews error");
  return {
    type: types.GET_OVERVIEW_LIST_FAILED,
    error: "FAILED"
  };
};

function* overviewSaga() {
  yield takeEvery(types.GET_OVERVIEW_LIST_REQUEST, fetchOverviewList);
}

export default overviewSaga;
