import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Box from '@mui/material/Box';
import clsx from 'clsx';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

// components
import Page from '../components/Page';
import BMap from '../components/BMap';
import BDataGrid from '../components/BDataGrid'
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks'
import ToggleButtons from '../components/ToggleButton'

// css
import Styles from '../css/readerCountTips.module.css'
import Tooltip from '../css/Tooltip.module.css'
import * as ipconfig from "../config/ipconfig";

export default function TestMap(props) {
    const { openmap, ToggleButtonValue, leonReaderlist, setleonReaderlist } = props
    // const [leonReaderlist, setleonReaderlist] = useState([]);
    const [roadArealist, setroadArealist] = useState([]);
    const [roadAreaselect, setroadAreaselect] = useState([]);
    const [mapcenter, setmapcenter] = useState([24, 121]);
    // const [openmap, setopenmap] = useState(null);

    const flymap = (point) => {
        openmap.flyTo(point, 15)
    }
    const usersStore = useSelector((state) => state.userLogin);
    const { users } = usersStore;
    const dispatch = useDispatch();

    useEffect(() => {
        getrouteData();
    }, [users]);
    
    useEffect(() => {
        getData(ToggleButtonValue);
    }, [roadArealist, ToggleButtonValue]);

    useEffect(() => {
        // console.dir('leonReaderlist useEffect')
        if (openmap != null) {
            const bounds = [
                [25.112698, 121.311192], [25.080091, 121.24397499999999]
            ]
            for (let i = 0; i < leonReaderlist.length; i += 1) {
                if (leonReaderlist[i].latitude !== null || leonReaderlist[i].longitude !== null) {
                    if (i === 0) {
                        bounds[0][0] = leonReaderlist[i].latitude
                        bounds[1][0] = leonReaderlist[i].latitude
                        bounds[0][1] = leonReaderlist[i].longitude
                        bounds[1][1] = leonReaderlist[i].longitude
                    } else if (leonReaderlist[i].latitude !== 0) {
                        if (leonReaderlist[i].latitude > bounds[0][0])
                            bounds[0][0] = leonReaderlist[i].latitude
                        else if (leonReaderlist[i].latitude < bounds[1][0])
                            bounds[1][0] = leonReaderlist[i].latitude
                        if (leonReaderlist[i].longitude > bounds[0][1])
                            bounds[0][1] = leonReaderlist[i].longitude
                        else if (leonReaderlist[i].longitude < bounds[1][1])
                            bounds[1][1] = leonReaderlist[i].longitude
                    }
                }
            }
            bounds[0][0] += 0.001
            bounds[0][1] += 0.001
            bounds[1][0] -= 0.001
            bounds[1][1] -= 0.001
            openmap.fitBounds(bounds)
        }

    }, [leonReaderlist]);
    // 取得路段資料
    const getrouteData = async () => {
        await axios
            .get(`https://${ipconfig.apiIP}/api/v1/map/getSessionInfo`)
            .then(async (response) => {
                // // console.dir(response.data.rows.length);
                // eslint-disable-next-line eqeqeq
                if (response.data.resultCode == "Success") {
                    setroadArealist(response.data.rows)
                    // // console.dir(response.data.rows)
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
                // // console.dir('leonReaderlist api then')
            });
        // setOpen(true);
    };

    // 取德標格資料
    const getData = async (minutes) => {
        await axios
            .post(`https://${ipconfig.apiIP}/api/v1/map/getTruckReaderCount`, { "token": users.token, "minutes": minutes })
            .then(async (response) => {
                dispatch({type: "CHANGE_TOKEN_STATUS", payload: response.data.resultCode});
                // eslint-disable-next-line eqeqeq
                if (response.data.resultCode == "Success") {
                    // const selectidarray = roadAreaselect.map(r=>roadArealist.find(a=>a.address===r).id)
                    const selectidarray = roadArealist.map(r => r.id)
                    const list = []
                    for (let i = 0; i < response.data.rows.length; i += 1) {
                        if (response.data.rows[i].reader_id !== null) {
                            response.data.rows[i].id = response.data.rows[i].reader_id
                            if (roadAreaselect.length > 0 && roadAreaselect.length !== roadArealist.length) {
                                if (selectidarray.findIndex(a => a === response.data.rows[i].session_info_id) > -1)
                                    list.push(response.data.rows[i])
                            } else
                                list.push(response.data.rows[i])
                        }
                    }
                    setleonReaderlist(list)
                    // // console.log(list);                 
                }
            })
            .catch((error) => {
                // handle error
                // console.log(error);
            })
            .then(() => {
                // always executed
            });
        // setOpen(true);
    };

    const columns = [
        { field: "id", headerName: "id", width: 70, hideable: true },
        { field: "rownum", headerName: "項目", width: 100, sortable: true, headerAlign: 'center', align: 'center', },
        {
            field: "reader_info_leonReaderName", headerName: "智慧感知讀取器名稱", flex: 0.3, sortable: true, headerAlign: 'center',
            renderCell: (params) => {
                if (params.value === undefined)
                    return (null);
                if (typeof params.value === 'string')
                    return (params.value);
                const children = params.value.map((val) => (
                    <div >{val}</div>
                ));
                return (
                    <div style={{ fontSize: '20px' }}>
                        {children}
                    </div>
                );
            }
        },
        { field: "reader_info_name", headerName: "設備編號", width: 100, flex: 0.1, sortable: true, headerAlign: 'center', align: 'center', },
        { field: "ReaderCount", headerName: "大型車輛數量", width: 100, flex: 0.2,sortable: true, headerAlign: 'center', align: 'center', },
        { field: "session_info_address", headerName: "所屬路段", width: 100, flex: 0.25, headerAlign: 'center', align: 'center', },
    ];

    const TableOnClick = (params, event) => {
        if (!event.ctrlKey) {
            event.defaultMuiPrevented = true;
            // alert(JSON.stringify(params));
            openmap.flyTo([params.row.latitude, params.row.longitude], 23);

        }
    }

    const TableCellClassName = (params) => {
        const value = params.row.ReaderCount;
        if (value > 150) {
            return 'red';
        }
        if (value > 80) {
            return 'orange';
        }
        if (value > 0) {
            return 'green';
        }
        return 'grey';
    }

    // // console.log(Styles);

    return (
        <Grid item xs={12} >
            <Box
                sx={{
                    height: '100%',
                    width: 1,
                    '& .red': {
                        // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                        color: '#ca2d40',
                        // fontWeight: '600',
                    },
                    '& .orange': {
                        // backgroundColor: '#d47483',
                        color: '#fd7000',
                        // fontWeight: '600',
                    },
                    '& .green': {
                        // backgroundColor: '#d47483',
                        color: '#26ac23',
                        // fontWeight: '600',
                    },
                    '& .grey': {
                        // backgroundColor: '#d47483',
                        color: '#666666',
                        // fontWeight: '600',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontSize: '22px'
                    },
                    '& .MuiDataGrid-cellContent': {
                        fontSize: '20px'
                    }
                }}
            >
                <BDataGrid data={leonReaderlist} columns={columns} TableOnClick={TableOnClick}
                // TableCellClassName={TableCellClassName}
                />
            </Box>
        </Grid>
    );
}
