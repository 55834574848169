
import * as types from "../config/types";

const initialState = {
  loading: false,
  trucks: [],
  error: null
};

export default function truckManage (state = initialState, action)  {
  switch (action.type) {
    case types.GET_TRUCK_LIST_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case types.GET_TRUCK_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        trucks: action.payload.trucks.data
      };
    }
    case types.GET_TRUCK_LIST_FAILED: {
      return {
        ...state,
        loading: false,
        error: "FAILED",    
      };
    }
    default:
      return state;
  }
};

